import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Item, OrganizationDetailedView, PointOfSalesView, UserDetailedView } from 'src/app/models/models';
import { State } from 'src/app/state';
import { modal } from 'src/app/utils/modal.util';
import { error_toast, success_toast } from 'src/app/utils/toast.util';
import { is_nothing } from 'src/app/utils/utils';
import { validation, validator } from 'src/app/utils/validator.util';

@Component({
  selector: 'app-user-configuration',
  templateUrl: './user-configuration.component.html',
  styleUrls: []
})
export class UserConfigurationComponent implements OnInit {
  user: UserDetailedView;
  was_new: boolean = false;
  get is_new(): boolean {
    return is_nothing(this.user?.id);
  }

  roles: Item[];
  
  fields;

  organizations: OrganizationDetailedView[] = [];
  points_of_sales: PointOfSalesView[] = [];

  select: string;
  working: string;
  new_password: string;

  get is_mobile(): boolean { return State.mobile; }

  constructor(active_route: ActivatedRoute, private api: ApiService, private router: Router) {
    active_route.params.subscribe(params => {
      if (params.id) {
        if (params.id === 'new') {
          this.was_new = true;
          this.reset();
        } else {
          api.get_user(params.id, response => {
            if (response.succeeded) {
              this.user = response.data;
              if (this.user.organization && this.organizations?.length)
                this.user.organization = this.organizations.find(o => o.id === this.user.organization.id);
            }
            else {
              error_toast(response.error.message);
              router.navigate(['system', 'users']);
            }
          });
        }
        api.get_user_roles(response => {
          if (response.succeeded)
            this.roles = response.data;
          else
            error_toast(response.error.message);
        });
        api.get_organizations(response => {
          if (response.succeeded) {
            this.organizations = response.data;
            if (this.user?.organization)
              this.user.organization = this.organizations.find(o => o.id === this.user.organization.id);
          }
          else
            error_toast(response.error.message);
        });
        api.get_points_of_sales(response => {
          if (response.succeeded)
            this.points_of_sales = response.data;
          else
            error_toast(response.error.message);
        });
      } else router.navigate(['system', 'users']);
    });
  }

  ngOnInit() {
    this.fields = validator({
      username: [ validation.required ],
      name: [ validation.required ],
      email: [ validation.email ],
      mobile_phone: [ validation.phone ]
    })
  }

  back() {
    this.router.navigate(['system', 'users']);
  }

  selectable_roles: Selectable<Item>[] = [];
  selectable(role: Item): Selectable<Item> {
    let selectable = this.selectable_roles.find(r => r.item.id === role.id);
    if (selectable)
      return selectable;
    else {
      selectable = { item: role, selected: this.user.roles.some(r => r.id === role.id) };
      this.selectable_roles.push(selectable);
      return selectable;
    }
  }
  get selected_roles(): number[] {
    return this.roles?.map(r => this.selectable(r))?.filter(s => s.selected)?.map(s => s.item.id) ?? [];
  }

  reset_password() {
    modal('Confirmar reseteo de contraseña', `¿Estás seguro de que quieres resetear la contraseña del usuario [${this.user.username} - ${this.user.name}]?`,
      ['!Resetear contraseña', '_Cancelar'], result => {
        if (result.id === 0) {
          this.working = 'resetting';
          this.api.reset_user_password(this.user.id, response => {
            this.working = null;
            if (response.succeeded) {
              this.new_password = response.data;
              success_toast('Nueva contraseña del usuario: ' + this.new_password);
            } else error_toast(response.error.message);
          });
        }
      });
  }
  
  org_name(organization): string {
    if (is_nothing(organization))
      return null;
    return organization.name ?? organization.description;
  }

  save() {
    if (this.fields.check_all_valid()) {
      this.working = 'saving';
      if (this.is_new) {
        this.api.create_user(this.user, this.selected_roles, response => {
          if (response.succeeded) {
            this.new_password = response.data.password;
            success_toast('Usuario creado.  Contraseña: ' + response.data.password);
            this.api.get_user(response.data.user_id, response => {
              this.working = null;
              if (response.succeeded) {
                this.user = response.data;
              }
            });
          } else {
            this.working = null;
            error_toast(response.error.message);
          }
        });
      } else {
        this.api.edit_user(this.user, this.selected_roles, response => {
          if (response.succeeded) {
            this.working = null;
            success_toast('Usuario actualizado.');
            // Update current user if was edited
            if (this.user.id === State.user.id)
              this.api.authenticate(response => {
                if (response.succeeded)
                  State.user = response.data;
              })
          } else {
            this.working = null;
            error_toast(response.error.message);
          }
        });
      }
    }
  }

  reset() {
    this.user = <any>{ username: null, name: null, email: null, organization: null, point_of_sales: null, mobile_phone: null, roles: [], enabled: true };
  }

  remove_user() {
    modal('Eliminar/Desactivar Usuario', `Usuario ${this.user.name} (${this.user.username}) será eliminado si no ha realizado transacciones.\n`
      + 'Si el usuario tiene transacciónes, será desactivado y removido de las lista de usuarios, pero se mantendrá su información en todas sus transacciónes.\n'
      + 'En ambos casos el usuario no podrá acceder a esta cuenta en el futuro.\n'
      + '\n¿Desea proceder?', ['!Proceder', '_Cancelar'], result => {
        if (result.id === 0) {
          this.working = 'removing';
          this.api.remove_user(this.user.id, response => {
            this.working = null;
            if (response.succeeded) {
              this.router.navigate(['system', 'users']);
              success_toast('Usuario eliminado/desactivado');
            } else error_toast(response.error.message);
          });
        }
      });
  }
}

interface Selectable<T> {
  item: T;
  selected: boolean;
}