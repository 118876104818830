<h1>2FA</h1>

<div class="mt-8 card">
   <div class="flex flex-col md:flex-row">
      <div class="md:mr-8">
         <div class="font-semibold text-lg">
            Escanea este código QR con<br class="hidden md:block">
            tu <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
               class="underline text-blue-900">aplicación de códigos 2FA</a>...
         </div>

         <qr-code *ngIf="key" class="mx-auto w-max block" [value]="otpauth_uri" [size]="200"></qr-code>
      </div>
      <div>
         <div class="font-semibold text-lg mb-6">
            ... o configúralo manualmente:
         </div>

         <div class="font-semibold">Clave 2FA:</div>
         <div>
            {{ key }}
            <button (click)="copy_key()" class="btn !py-[2px] !px-[8px] ml-2">
               <fa-icon icon="clipboard"></fa-icon>
            </button>
         </div>
   
         <div class="mt-4 font-semibold">Verificar código:</div>
         <input id="otp" type="number" [(ngModel)]="code" (keydown.enter)="test()" class="rounded py-1" [minLength]=6 [maxLength]=6>
         <button (click)="test()" class="btn btn-blue">
            Verificar
         </button>
      </div>
   </div>
</div>