import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import {
  InvoicerQueriableView,
  Item,
  MultiPayQueriableView,
} from 'src/app/models/models';
import { State } from 'src/app/state';
import { error_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.less'],
})
export class PaymentsComponent {
  invoicers = [
    { name: 'edeeste',     title: 'Edeeste' },
    { name: 'edenorte',    title: 'Edenorte' },
    { name: 'edesur',      title: 'Edesur' },
    { name: 'asdn',        title: 'Alcaldía de Santo Domingo Norte' },
    { name: 'nagua',       title: 'Ayuntamiento Municipal de Nagua' },
    { name: 'samana',      title: 'Ayuntamiento Municipal de Samaná' },
    { name: 'alv',         title: 'Alcaldía de La Vega' },
    { name: 'claro',       title: 'Claro Dominicana' },
    { name: 'caasd',       title: 'Corporación del Acueducto y Alcantarillado de Santo Domingo', },
    { name: 'altice',      title: 'Altice Dominicana' },
    { name: 'viva',        title: 'Viva Dominicana' },
    { name: 'inapa',       title: 'Instituto Nacional de Aguas Potables y Alcantarillados', },
    { name: 'jorem',       title: 'Funeraria Jorem' },
    { name: 'rancier',     title: 'Funeraria Rancier' },
    { name: 'natcom',      title: 'NATCOM' },
    { name: 'digicel',     title: 'DIGICEL' },
    { name: 'ppe',         title: 'Puerto Plata Electricidad' },
    { name: 'windtelecom', title: 'WIND Telecom', },
  ];

  available_invoicers: InvoicerQueriableView[] = [];
  available_multipays: MultiPayQueriableView[] = [];

  constructor(public router: Router, api: ApiService) {
    State.clear_payments();
    api.get_point_of_sales_queriables(
      State.user.point_of_sales.id,
      (response) => {
        if (response.succeeded) {
          this.available_invoicers = response.data.invoicers;
          this.available_multipays = response.data.multipays;

          State.invoicers = response.data.invoicers;

          if (this.available_invoicers.length === 1)
            this.go_to(this.available_invoicers[0].name);
        } else error_toast(response.error.message);
      }
    );
  }

  go_to(invoicer_name: string) {
    const invoicer = this.available_invoicers.find(
      (i) => i.name.toLowerCase() === invoicer_name.toLowerCase()
    );
    const multis = this.available_multipays.filter((m) =>
      m.invoicers.find((i) => i?.id === invoicer.id)
    );

    if (multis.length) {
      State.active_invoicer = invoicer;
      State.active_multipay = multis.reduce((a, b) =>
        a.invoicers.length >= b.invoicers.length ? a : b
      );
      this.router.navigate(['cashier', 'pay', 'multipay']);
    } else {
      State.active_invoicer = invoicer;
      this.router.navigate(['cashier', 'pay', invoicer.name.toLowerCase()]);
    }
  }

  available(invoicer_name: string): boolean {
    return this.available_invoicers.some(
      (i) => i.name.toLowerCase() === invoicer_name.toLowerCase()
    );
  }
}

export function invoicer_queriable_to_item(
  invoicer: InvoicerQueriableView
): Item {
  return { id: invoicer.id, description: invoicer.name };
}
