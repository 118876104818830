import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { UserDetailedView } from 'src/app/models/models';
import { error_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: []
})
export class UsersComponent {
  enabled_users: UserDetailedView[];
  disabled_users: UserDetailedView[];
  show_disabled = false;
  loaded = false;

  constructor(private api: ApiService, private router: Router) {
    api.get_users(response => {
      if (response.succeeded) {
        this.loaded = true;
        this.enabled_users  = response.data.filter(u => u.enabled);
        this.disabled_users = response.data.filter(u => !u.enabled);
      } else error_toast(response.error.message);
    });
  }

  new() {
    this.router.navigate(['system', 'users', 'new']);
  }
  
  edit(user: UserDetailedView) {
    this.router.navigate(['system', 'users', user.id]);
  }

  logs() {
    this.router.navigate(['system', 'users', 'logs']);
  }
}