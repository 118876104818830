import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
   selector: 'table-control'
   , template: `
      <div style="display: flex; flex-direction: row; align-items: baseline; margin-bottom: 0.25rem;">
         <span>Mostrar</span>
         <select [(ngModel)]="span" style="padding: 0 .5rem; padding-right: 2rem; margin: 0 .5rem; font-weight: 600; border-radius: 0.25rem;">
            <option *ngFor="let s of spans" [value]="s">{{ s }}</option>
            <option [value]="0">{{ masculine? 'Todos' : 'Todas' }}</option>
         </select>
         <span *ngIf="span == 0">{{ masculine? 'los' : 'las' }} {{ for }}</span>
         <span *ngIf="span != 0">{{ for }} por página</span>
      </div>
   `
   , styles: [ '* { color: rgba(0, 0, 0, .75) }' ]
   , host: { style: 'display: block' }
 })
export class TableControlComponent {
   private _for: string = "registros";
   get for(): string { return this._for; }
   @Input() set for(value) {
      TableControl.destroy_controller(value)
      this._for = value;
      TableControl.when_page_is_set(value, (page, name) => this.handle_page_change(page, name));
      TableControl.set_span(this.span, value);
      TableControl.set_length(this.source?.length, value);
      TableControl.set_page(0, value);
   }

   masculine = true;
   @Input() set fem(v) {
      if (v === '')
         v = true;
      this.masculine = !v;
   }

   spans: number[] = [5, 10, 25, 50];

   private _span: number;
   get span(): number { return this._span; }
   @Input() set span(value: number) {
      value = Number.parseInt(<any>value);
      this._span = value;
      TableControl.set_span(value, this.for);
   }

   private _source: any[];
   get source() { return this._source; }
   @Input() set source(value) {
      this._source = value;
      TableControl.set_length(this.filtered_source?.length, this.for);
   }

   private _filter_string;
   get filter() {
      const filter = this._filter_string?.trim();
      return filter?.length? filter : null;
   }

   get filtered_source(): any[] {
      const filter_term = this.filter;
      if (filter_term)
         return filter(this._source, filter_term);
      return this._source;
   }

   @Input() set filter(value: string) {
      this._filter_string = value;
      TableControl.set_length(this.filtered_source?.length, this.for);
   }

   slice: any[];
   @Output() sliced: EventEmitter<any[]> = new EventEmitter<any[]>();

   constructor() {
      TableControl.when_page_is_set(this.for, (page, name) => this.handle_page_change(page, name));
      this.span = this.spans[1];
   }

   handle_page_change(page: number, name: string) {
      if (name === this.for) {
         const index_start = page * this.span;
         const index_end   = this.span ? (page + 1) * this.span : this.source?.length;
         const slice = this.filtered_source?.slice(index_start, index_end);
         this.slice = slice;
         this.sliced.emit(slice);
      }
   }
}

function filter<T>(collection: T[], filter_term: string): T[] {
   filter_term = filter_term.toLowerCase();
   const contains: (obj: T) => boolean = (obj: T) => {
      if (obj === undefined || obj === null)
         return false;
      else if (typeof obj === 'string')
         return obj.toLowerCase().includes(filter_term);
      else if (typeof obj === 'number')
         return obj.toString().includes(filter_term);
      else if (typeof obj === 'object') {
         if (Array.isArray(obj)) {
            return obj.some(contains);
         } else {
            return Object.values(obj).some(contains);
         }
      }
   };
   return collection?.filter(contains);
}

export class TableControl {
   private static controllers: { [key: string]: {
         span: number,
         page: number,
         length: number,

         span_callbacks: ((span: number, name: string) => void)[],
         length_callbacks: ((length: number, name: string) => void)[]
         page_callbacks: ((page: number, name: string) => void)[],
      }
   } = { };

   private static get_or_create_controller(name: string) {
      let controller = this.controllers[name];
      if (controller)
         return controller;
      else {
         controller = { page: 0, span: 0, length: 0, page_callbacks: [], span_callbacks: [], length_callbacks: [] };
         this.controllers[name] = controller;
         return controller;
      }
   }

   static destroy_controller(name: string) {
      this.controllers[name] = null;
   }

   static set_span(span: number, name: string) {
      span = Number.parseInt(<any>span);
      
      const controller = this.get_or_create_controller(name);
      controller.span = span;
      controller.span_callbacks.forEach(callback => callback?.(span, name));
   }

   static when_span_is_set(name: string, callback: (span: number, name: string) => void) {
      const controller = this.get_or_create_controller(name);
      controller.span_callbacks.push(callback);
      callback?.(controller.span, name);
   }

   static set_page(page: number, name: string) {
      const controller = this.get_or_create_controller(name);
      controller.page = page;
      controller.page_callbacks.forEach(callback => callback?.(page, name));
   }

   static when_page_is_set(name: string, callback: (page: number, name: string) => void) {
      const controller = this.get_or_create_controller(name);
      controller.page_callbacks.push(callback);
      callback?.(controller.page, name);
   }

   static set_length(length: number, name: string) {
      const controller = this.get_or_create_controller(name);
      controller.length = length;
      controller.length_callbacks.forEach(callback => callback?.(length, name));
   }

   static when_length_is_set(name: string, callback: (length: number, name: string) => void) {
      const controller = this.get_or_create_controller(name);
      controller.length_callbacks.push(callback);
      callback?.(controller.length, name);
   }
}