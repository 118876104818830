<h1>Cambiar Clave</h1>

<div class="card mt-8">
   <div class="mx-2">
      <div class="font-semibold">Confirmar contraseña actual</div>
      <input id="old_password" type="password" [(ngModel)]="data.old_pass" [readonly]="working" class="w-full py-2 border rounded-lg px-3 border-gray-300 focus:ring-0">
      <div class="field-error">{{ fields.old_password.error }}</div>
   </div>
   <div class="my-4 pt-4 border-t flex flex-row flex-wrap">
      <div class="flex-1 mx-2">
         <div class="font-semibold">Nueva contraseña</div>
         <div class="flex flex-row border border-gray-300 rounded-lg px-3 py-1 items-baseline">
            <input id="new_password" [type]="!show_new_pass? 'password' : 'text'" [(ngModel)]="data.new_pass" [readonly]="working" class="flex-1 py-1 px-0 border-0 outline-none focus:ring-0">
            <fa-icon (click)="show_new_pass = !show_new_pass" [icon]="show_new_pass? 'eye' : 'eye-slash'" class="cursor-pointer px-2 text-gray-500 rounded-md hover:bg-gray-50 active:bg-gray-100"></fa-icon>
         </div>
         <div class="field-error">{{ fields.new_password.error }}</div>
      </div>
      <div class="flex-1 mx-2">
         <div class="font-semibold">Confirmar nueva contraseña</div>
         <input id="new_password_confirm" type="password" [(ngModel)]="show_new_pass? null : data.new_pass_confirm" [readonly]="show_new_pass || working" [class.bg-gray-200]="show_new_pass" [class.pointer-events-none]="show_new_pass" class="w-full py-2 px-3 border rounded-lg border-gray-300 focus:ring-0">
         <div *ngIf="!show_new_pass" class="field-error">{{ fields.new_password_confirm.error }}</div>
      </div>
   </div>
   <div class="pt-4 border-t mx-2">
      <button (click)="save()" [disabled]="working" class="btn btn-blue w-full md:w-max">
         <fa-icon icon="key"></fa-icon>
         {{ !working? 'Cambiar contraseña' : 'Guardando...' }}
         <working *ngIf="working"></working>
      </button>
   </div>
</div>