<h1>Bolsa de contratos</h1>

<div class="card mt-6">
   <h2 class="flex flex-row items-center space-x-3 font-semibold">
      <span>{{ bundle.description }}</span>
      <img class="h-5" src="/assets/logos/logo_{{ bundle.invoicer.description | lowercase }}.png" [alt]="bundle.invoicer.description">
   </h2>

   <div class="space-y-3 mt-6">
      <h3 class="font-medium">Pagos</h3>
      <hr>
      <div *ngFor="let payment of bundle.payments" routerLink="/cashier/bundles/{{bundle.id}}/payment/{{payment.id}}" class="border border-gray-300 p-4 rounded-lg cursor-pointer hover:bg-gray-200">
         <div>
            <span class="font-medium">Fecha: </span>
            {{ payment.date | date:'hh:mm aa, dd MMM yyyy':null:'es-DO' }}
         </div>
         <div>
            <span class="font-medium">Cajero: </span>
            {{ payment.user.name }}
         </div>
         <ng-container *ngIf="payment.processed">
            <div>
               <span class="font-medium">Facturas pagadas: </span>
               {{ payment.applied_payment_count }}
            </div>
            <div *ngIf="payment.applied_payment_count < payment.requested_payment_count">
               (No aplicados: {{ payment.requested_payment_count - payment.applied_payment_count }})
            </div>
            <div>
               <span class="font-medium">Monto pagado: </span>
               {{ payment.applied_amount | currency:'RD$ ' }}
            </div>
         </ng-container>
         <div *ngIf="!payment.processed">
            En proceso...
            <working color="black"></working>
         </div>
      </div>
   </div>
</div>