export const environment = {
	production: false,
	api_endpoint: '/api/',
	// api_endpoint: 'http://localhost:5000/',
	api: (...fragments: (string | number)[]) => environment.api_endpoint + fragments.join('/'),
	terminal_service_endpoint: 'http://localhost:4201/',
	terminal: (...fragments: string[]) => environment.terminal_service_endpoint + fragments.join('/'),
	debug: (...data) => {
		console.debug(...data)
	},
	error: (...data) => {
		console_error(...data)
	},
}

const console_error = (function () {
	const ce = window.console.error
	window.console.error = environment.error
	return ce
})()
