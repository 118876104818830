import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { ApiService } from 'src/app/api.service'
import { ContributorView, InvoiceView, PaymentOrderView } from 'src/app/models/invoicer_query.models'
import { Item, ZoneSummary } from 'src/app/models/models'
import { OfflineDatabase } from 'src/app/offline_db'
import { State } from 'src/app/state'
import { error_toast, success_toast } from 'src/app/utils/toast.util'
import { is_nothing, qs, since } from 'src/app/utils/utils'
import { environment } from 'src/environments/environment'
import { invoicer_queriable_to_item } from '../payments.component'

@Component({
	selector: 'app-asdn',
	templateUrl: './asdn.component.html',
	styleUrls: ['./asdn.component.less'],
})
export class AsdnComponent {
	id: string
	mode = 1
	working = {
		main: false,
		download: false,
	}
	date: Date;
	result: ContributorView
	showContractList: boolean  = false;
	zones: ZoneSummary[] = []
	zone_id: string
	contracts: ContributorView[]
	download_for_zone_contracts: ContributorView[]
	cantZones: number
	get partial_payments(): boolean {
		return State.active_invoicer.partial_payments
	}

	payment_policy: Item

	result_order: PaymentOrderView

	constructor(private api: ApiService, private router: Router) {
		if (is_nothing(State.active_invoicer) || State.active_invoicer.name.toLowerCase() != 'asdn')
			router.navigate(['cashier', 'pay'])
		else if (State.preloaded_query) {
			this.id = State.preloaded_query.id
			this.result = State.preloaded_query.data
			State.preloaded_query = null
		}
		this.load_zones()
		this.payment_policy = State.active_invoicer.invoice_payment_policy
		if (!State.online) this.load_cached_contracts()
	}
	load_zones() {
		if (State.online)
			this.api.get_zones(State.active_invoicer.id, (response) => {
				if (response.succeeded) this.zones = response.data
				else error_toast('No se pudieron buscar las zonas, ')
			})
	}

	since(_, __) {
		return since(_, __)
	}
	load_cached_contracts() {
		environment.debug('[i] loading offline edenorte contracts...')
		OfflineDatabase.get_asdn_contracts((data) => {
			if (data.length) {
				const expired_contracts = data.filter((d) => since(d.date, { hours: 6 }))
				this.contracts = data.filter((d) => !expired_contracts.includes(d)).map((d) => d)
				for (let contract of expired_contracts) {
					OfflineDatabase.delete_asdn_contract(contract.document)
				}
				if (data.length === expired_contracts.length) error_toast('Cartera offline expirada (6 horas)')
			} else {
				error_toast('Sin cartera offline')
			}
		})
	}
	download_for_zone() {
		this.working.download = true
		this.api.get_cached_clients(State.active_invoicer.id, this.zone_id, (response) => {
			if (response.succeeded) {
				this.contracts = response.data
				console.log(this.contracts)
				this.download_for_zone_contracts = response.data;
				this.date = new Date(Date.now())
				this.showContractList = true;
				this.cantZones = response.data.length
				for (let contract of response.data) {
					OfflineDatabase.add_or_update_asdn_contract({
						date: new Date(Date.now()),
						document: contract.document,
						name: contract.name,
						address: contract.address,
						total_debt: contract.total_debt,
						phone: contract.phone,
						pending_invoices: contract.pending_invoices,
					})
				}
				success_toast(response.data.length + 'contribuyentes añadidos a la cartera offline')
			} else error_toast('No fue posible descargar los contribuyentes de la zona')

			this.working.download = false
		})
	}
	getPendingInvoicesTitle(contractDoc: any): string {
		let pendingInvoicesTitle = '';
		let filtertedContracts = this.download_for_zone_contracts.filter(contract => contract.document === contractDoc);
		for(let contract of filtertedContracts){
			for (let pending_invoice of contract.pending_invoices) {
			  pendingInvoicesTitle += `${pending_invoice.id} | ${pending_invoice.amount}\n`;
			}
		}
		return pendingInvoicesTitle;
	  }

	query() {
		this.working.main = true
		const value = (qs('#document') as HTMLInputElement)?.value
		switch (this.mode) {
			case 1:
				if (this.contracts?.length) {
					this.result = this.contracts.find((c) => c.document == value)
				}
				if (!this.contracts?.length || !this.result)
					this.api.query_asdn(value, (response) => {
						this.working.main = false
						if (response.succeeded) {
							this.result = response.data
						} else error_toast(response.error.message)
					})
				break
			case 2:
				this.api.query_order_asdn(value, response => {
					this.working.main = false
					if (response.succeeded) {
						this.result_order = response.data
					} else error_toast(response.error.message)
				})
				break
		}
	}

	// Selectable invoices
	selectable_invoices: SelectableInvoice[] = []
	selectable(invoice: InvoiceView): SelectableInvoice {
		var sel_inv: SelectableInvoice = this.selectable_invoices.find((i) => i.id === invoice.id)
		if (sel_inv) return sel_inv
		else {
			const component: AsdnComponent = this
			let selected = true
			let amount_to_pay = invoice.amount
			sel_inv = {
				id: invoice.id,
				get selected(): boolean {
					return selected
				},
				set selected(value: boolean) {
					if (component.payment_policy.id === 2) {
						const issue_date = new Date(invoice.issue_date)
						if (value) {
							component.selectable_invoices
								.filter((si) => new Date(si.invoice.issue_date) < issue_date)
								.reverse()
								.forEach((si) => {
									si.selected = true
									si.amount_to_pay = si.invoice.amount
								})
						} else {
							component.selectable_invoices
								.filter(
									(si) =>
										si !== sel_inv && new Date(si.invoice.issue_date) >= issue_date && si.selected
								)
								.reverse()
								.forEach((si) => {
									si.selected = false
								})
						}
					}
					selected = value
				},
				invoice,
				get amount_to_pay(): number {
					return amount_to_pay
				},
				set amount_to_pay(value: number) {
					if (component.payment_policy.id === 2) {
						if (value < invoice.amount) {
							const issue_date = new Date(invoice.issue_date)
							component.selectable_invoices
								.filter(
									(si) =>
										si !== sel_inv && si.selected && new Date(si.invoice.issue_date) >= issue_date
								)
								.reverse()
								.forEach((si) => {
									si.selected = false
								})
						}
					}
					amount_to_pay = value
				},
			}
			this.selectable_invoices.push(sel_inv)
			return sel_inv
		}
	}
	get selected(): SelectableInvoice[] {
		return this.selectable_invoices.filter((s) => s.selected)
	}
	get can_pay(): boolean {
		return (
			this.selected.length > 0 &&
			this.selected.every((s) => s.amount_to_pay >= 0 && s.amount_to_pay <= s.invoice.amount)
		)
	}

	pay() {
		switch (this.mode) {
			case 1:
				State.payment_package = [
					{
						name: this.result.name,
						document: this.result.document,
						invoicer: invoicer_queriable_to_item(State.active_invoicer),
						type: 1,
						payloads: this.selected.map((s) => ({
							invoice_id: s.id,
							concept: s.invoice.concept,
							amount: s.amount_to_pay,
						})),
					},
				]
				break
			case 3:
				State.payment_package = [
					{
						name: this.result_order.name,
						document: this.result_order.document,
						invoicer: invoicer_queriable_to_item(State.active_invoicer),
						type: 3,
						payloads: [
							{
								invoice_id: this.result_order.id,
								concept: this.result_order.description,
								amount: this.result_order.amount,
							},
						],
					},
				]
				break;
		}
		this.router.navigate(['cashier', 'apply-payment'])
	}
}

interface SelectableInvoice {
	id: string
	invoice: InvoiceView
	selected: boolean
	amount_to_pay: number
}