import { AfterContentInit, Component, Input } from '@angular/core'
import { ApiService } from 'src/app/api.service'
import { LotReport, TransactionDetailedView } from 'src/app/models/models'
import PrintService from 'src/app/print.service'
import { is_nothing } from '../../utils'

@Component({
	selector: 'report-receipt',
	templateUrl: './report-receipt.component.html',
	styleUrls: ['./report-receipt.component.less'],
})
export class ReportReceiptComponent implements AfterContentInit {
	@Input() lot_report: LotReport

	print_type: 1 | 2 | 3 = 1

	constructor(private api: ApiService) {}
	ngAfterContentInit() {
		this.api.get_print_type(({ data: print_type }) => {
			this.print_type = print_type as any
			setTimeout(() => {
				;(window as any).cordova?.plugins?.printer?.print?.()
				window.print()
				// setTimeout(() => PrintService.done?.(), 1_000)
			}, 500)
		})
	}

	get active() {
		return is_nothing(this.lot_report.date_closed)
	}

	total_groups(report): { label: string; total: number }[] {
		return [
			{
				label: 'Total Efectivo',
				get total() {
					return report.total_cash
				},
			},
			{
				label: 'Total Cheque',
				get total() {
					return report.total_cheque
				},
			},
			{
				label: 'Total Tarjeta de Crédito',
				get total() {
					return report.total_card
				},
			},
			{
				label: 'Total Transferencia',
				get total() {
					return report.total_bank_transfer
				},
			},
			{
				label: 'Total Tarjeta Bonoluz',
				get total() {
					return report.total_bonoluz
				},
			},
			{
				label: 'Total Anulaciones',
				get total() {
					return report.total_nullified
				},
			},
			{
				label: 'Total Postpago',
				get total() {
					return report.total_postpaid
				},
			},
			{
				label: 'Total Prepago',
				get total() {
					return report.total_prepaid
				},
			},
			{
				label: 'Total General',
				get total() {
					return report.total
				},
			},
		].filter((g) => g.total)
	}
	transaction_groups(report): { label: string; transactions: TransactionDetailedView[] }[] {
		return [
			{
				label: 'Transacciones en Efectivo',
				get transactions() {
					return report.cash_transactions
				},
			},
			{
				label: 'Transacciones en Cheque',
				get transactions() {
					return report.cheque_transactions
				},
			},
			{
				label: 'Transacciones en Tarjeta de Crédito',
				get transactions() {
					return report.card_transactions
				},
			},
			{
				label: 'Transacciones en Transferencia',
				get transactions() {
					return report.bank_transfer_transactions
				},
			},
			{
				label: 'Transacciones en Tarjeta Bonoluz',
				get transactions() {
					return report.bonoluz_transactions
				},
			},
			{
				label: 'Anulaciones',
				get transactions() {
					return report.nullified_transactions
				},
			},
		].filter((g) => g.transactions?.length)
	}

	fix_time = (datetime: string) => datetime.replace(/a\.\sm\./g, 'am').replace(/p\.\sm\./g, 'pm')
}
