import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { ApiService } from 'src/app/api.service'
import { OfflineDatabase } from 'src/app/offline_db'
import { ApiResponse, Item, PaymentPayload, PaymentRequest, PaymentResult } from 'src/app/models/models'
import { State } from 'src/app/state'
import { modal } from 'src/app/utils/modal.util'
import { receipt, receipts, wrap_print } from 'src/app/utils/receipt.util'
import { error_toast, success_toast } from 'src/app/utils/toast.util'
import { clear_element, distinct, is_nothing, sum, titlecase } from 'src/app/utils/utils'
import { environment } from 'src/environments/environment'
import PrintService from 'src/app/print.service'

@Component({
	selector: 'app-apply-payment',
	templateUrl: './apply-payment.component.html',
	styleUrls: [],
})
export class ApplyPaymentComponent {
	banks: Item[]
	working = false
	working_state: string
	printing = null
	results: PaymentResult[] = []

	invoicer_payment_methods: {
		invoicer_id: number
		payment_methods: Item[]
	}[] = []

	get payment_methods(): Item[] {
		return distinct(
			this.invoicer_payment_methods
				?.map((ip) => ip.payment_methods)
				?.reduce((a, b) => a.concat(b))
				?.sort((a, b) => a.id - b.id) ?? [],
			(o) => o.id
		)
	}

	titlecase = titlecase

	get payloads(): PaymentPayload[] {
		return State.payment_package
	}

	get prepaid(): boolean {
		return this.payloads?.every((p) => p.type === 2)
	}

	get orders(): boolean {
		return this.payloads?.every((p) => p.type === 3)
	}

	get invoicers(): Item[] {
		return this.payloads.map((p) => p.invoicer).filter((inv, i, a) => a.indexOf(inv) === i)
	}
	payload_total(payload: PaymentPayload) {
		return payload.payloads.map((p) => p.amount).reduce((a, b) => a + b)
	}
	get total() {
		return this.payloads.map((p) => this.payload_total(p)).reduce((a, b) => a + b)
	}

	failed(result: PaymentResult): boolean {
		return result.payments.every((p) => !p.applied) ?? true
	}
	get all_failed(): boolean {
		return this.results.every((r) => this.failed(r))
	}

	data = {
		method: 0,
		cash: null,
		card: [],
		bank: 0,
		payment_document: null,
	}

	card_data(invoicer: Item) {
		let card = this.data.card.find((c) => c.invoicer_id === invoicer.id)
		if (card) return card
		else {
			card = { invoicer_id: invoicer.id, number: null, authorization: null, lot: null }
			this.data.card.push(card)
			return card
		}
	}

	get can_apply(): boolean {
		if (this.methods_loaded) {
			if (this.data.method == 1) {
				return this.data.cash && this.data.cash >= this.total
			} else if ([2, 6, 7, 8, 9].some((m) => this.data.method == m)) {
				return <any>(this.data.payment_document && this.data.bank)
			} else if ([3, 4, 5].some((m) => this.data.method == m)) {
				const supported = this.invoicers_that_support(this.data.method).map((i) => i.id)
				return this.data.card
					.filter((c) => supported.includes(c.invoicer_id))
					.every((card) => card.number && card.authorization && card.lot)
			}
		}
		return false
	}

	get methods_loaded(): boolean {
		return this.invoicer_payment_methods.length === this.invoicers.length
	}

	constructor(private api: ApiService, private router: Router) {
		if (is_nothing(this.payloads)) router.navigate(['cashier', 'pay'])

		const handle_payment_methods = (invoicer_id: number) => {
			return (response: ApiResponse<Item[]>) => {
				if (response.succeeded) {
					this.invoicer_payment_methods.push({ invoicer_id, payment_methods: response.data })
					if (this.payment_methods.some((p) => p.id === 8) && !this.banks?.length)
						api.get_banks((response) => {
							if (response.succeeded) {
								this.banks = response.data
							} else error_toast(response.error.message)
						})
					if (this.payment_methods?.length) this.data.method = this.payment_methods[0].id
				} else error_toast(response.error.message)
			}
		}

		for (let invoicer of this.invoicers) {
			api.get_point_of_sales_invoicer_payment_methods(
				State.user.point_of_sales.id,
				invoicer.id,
				handle_payment_methods(invoicer.id)
			)
		}
	}

	invoicers_that_support(method_id: number): Item[] {
		return this.invoicers.filter((inv) =>
			this.invoicer_payment_methods
				.find((ip) => ip.invoicer_id == inv.id)
				?.payment_methods.some((p) => p.id == method_id)
		)
	}

	invoicers_that_dont_support(method_id: number): Item[] {
		const supporting = this.invoicers_that_support(method_id)
		return this.invoicers.filter((inv) => !supporting.includes(inv))
	}

	total_for_invoicers(invoicers: Item[]) {
		const invoicer_ids = invoicers.map((i) => i.id)
		return this.payloads
			.filter((p) => invoicer_ids.includes(p.invoicer.id))
			.map((p) => this.payload_total(p))
			.concat([0])
			.reduce((a, b) => a + b)
	}

	get method_not_supported(): boolean {
		return this.invoicers_that_dont_support(this.data.method).length > 0
	}

	get supported_total(): number {
		return this.total_for_invoicers(this.invoicers_that_support(this.data.method))
	}

	get supported_payloads(): PaymentPayload[] {
		const supporting_ids = this.invoicers_that_support(this.data.method).map((i) => i.id)
		return this.payloads.filter((p) => supporting_ids.includes(p.invoicer.id))
	}

	invoicer_names(invoicers: Item[]): string[] {
		return invoicers.map((i) => i.description)
	}

	async print_receipt(result: PaymentResult) {
		this.printing = result
		await PrintService.render('transaction', {
			transaction: result.transaction,
		})
		this.printing = null
	}

	apply_payment() {
		this.working = true
		this.working_state = 'Obteniendo localización...'

		State.geolocation((geo) => {
			this.working_state = 'Aplicando pagos...'
			if (is_nothing(geo) && State.user.point_of_sales.geolocation_required) {
				window.navigator.permissions.query({ name: 'geolocation' }).then((perm) => {
					if (perm.state === 'denied') {
						this.working = false
						this.working_state = null
						error_toast('Debes permitir acceso a la ubicación para realizar el pago')
					} else this._apply_payment(geo)
				})
			} else this._apply_payment(geo)
		})
	}

	_apply_payment(geo: { latitude: number; longitude: number }) {
		const results: {
			invoicer: Item
			reference: string
			data: PaymentResult
			success: boolean
			message: string
			extra?: any
		}[] = []
		const handle_done = async () => {
			if (results.length === this.supported_payloads.length) {
				this.working = false
				this.working_state = null
				this.results = results.map((r) => r.data)

				const successful_results = results.filter((r) => r?.success)

				if (successful_results?.length) {
					this.printing = true
					if (successful_results.length == 1)
						await PrintService.render('transaction', {
							transaction: successful_results[0].data.transaction,
						})
					else
						await PrintService.render(
							'multiple transactions',
							successful_results.map((r) => r.data.transaction)
						)
					this.printing = null
				}

				for (let result of successful_results) {
					success_toast(result.message)
				}
			}
		}

		for (let payload of this.supported_payloads) {
			const payment: PaymentRequest = {
				invoicer_id: payload.invoicer.id,
				payment_method_id: Number.parseInt(<any>this.data.method),
				total: this.payload_total(payload),
				client_name: payload.name,
				client_reference: payload.document.toString(),
				invoices: payload.payloads,

				cash_recieved: null,
				card_number: null,
				authorization_number: null,
				external_lot: null,
				bank_id: null,
				cheque_number: null,
				transfer_number: null,

				geo_latitude: geo?.latitude,
				geo_longitude: geo?.longitude,
			}
			switch (Number.parseInt(<any>this.data.method)) {
				case 1:
					const payload_index = this.supported_payloads.indexOf(payload)
					const is_last_payload = payload_index === this.supported_payloads.length - 1
					const previous_cash = is_last_payload
						? this.supported_payloads
								.filter((_, i) => i < payload_index)
								.map((p) => this.payload_total(p))
								.concat([0])
								.reduce((a, b) => a + b)
						: 0
					payment.cash_recieved = is_last_payload
						? Number.parseFloat(this.data.cash ?? 0) - previous_cash
						: payment.total
					break
				case 2:
					payment.bank_id = Number.parseInt(<any>this.data.bank)
					payment.cheque_number = this.data.payment_document.toString()
					break
				case 3:
				case 4:
				case 5:
					const card_data = this.card_data(payload.invoicer)
					payment.card_number = card_data.number.toString()
					payment.authorization_number = card_data.authorization.toString()
					payment.external_lot = card_data.lot.toString()
					break
				case 8:
					payment.bank_id = Number.parseInt(<any>this.data.bank)
					payment.transfer_number = this.data.payment_document.toString()
					break
				default:
					error_toast(
						"[DEV] Modo de pago '" +
							this.payment_methods.find((m) => m.id === this.data.method)?.description +
							"' no soportado."
					)
					return
			}

			const postpaid = payload.type === 1
			const order = payload.type === 3
			const handle_payment_result = (response) => {
				if (response.succeeded) {
					const res = {
						invoicer: payload.invoicer,
						reference: payload.document,
						data: response.data,
						success: null,
						message: null,
						extra: payload.extra,
					}
					const data = response.data.payments
					if (data.every((r) => r.applied)) {
						res.success = true
						if (data.length === 1)
							res.message =
								payload.invoicer.description + `: ${postpaid ? 'Pago aplicado' : 'Recarga aplicada'}`
						else
							res.message =
								payload.invoicer.description +
								': ' +
								data.length +
								` ${postpaid ? 'pagos aplicados' : 'recagas aplicadas'}`
					} else if (data.every((r) => !r.applied)) {
						const s = data.length === 1 ? '' : 's'
						res.success = false
						res.message =
							payload.invoicer.description +
							': ' +
							(postpaid ? 'Pago' : 'Recarga') +
							s +
							' no aplicad' +
							(postpaid ? 'o' : 'a') +
							s
					} else {
						const al = data.filter((r) => r.applied).length
						const as = al == 1 ? '' : 's'
						const rl = data.length - al
						const rs = rl == 1 ? '' : 's'
						res.success = false
						res.message =
							payload.invoicer.description +
							': ' +
							al +
							' ' +
							(postpaid ? 'pago' : 'recarga') +
							as +
							' aplicad' +
							(postpaid ? 'o' : 'a') +
							as +
							', ' +
							rl +
							' ' +
							(postpaid ? 'pago' : 'recarga') +
							rs +
							' no aplicad' +
							(postpaid ? 'o' : 'a') +
							rs +
							'.'
					}
					results.push(res)
					handle_done()
				} else {
					results.push(null)
					error_toast(payload.invoicer.description + ': ' + response.error.message)
					handle_done()
				}
			}

			if (postpaid) {
				this.api.apply_payment(payment, (response) => {
					if (response.error?.code === 'OFFLINE' && payment.invoicer_id != 4) {
						modal(
							'Pago Sin Conexión',
							'¿Desear agendar este pago para realizarse cuando vuelva a tener conexión?',
							['Pagar', '_Cancelar'],
							(result) => {
								if (result.id == 0) {
									OfflineDatabase.add_queued_payment(payment, (success, queued_payment) => {
										this.working = false
										if (success) {
											environment.debug('queued payment:', queued_payment)
											const res = {
												invoicer: payload.invoicer,
												reference: payload.document,
												success: true,
												message: 'Pago(s) en cola',
												data: {
													transaction: {
														id: -1,
														lot: -1,
														sequence: queued_payment.key,
														client_name: payment.client_name,
														client_reference: payment.client_reference,
														amount: payment.total,
														date: new Date(Date.now()).toISOString(),
														invoicer: {
															id: payload.invoicer.id,
															name: payload.invoicer.description,
															rnc: null,
														},
														point_of_sales: State.user.point_of_sales,
														user: State.user,
														payment_method: State.get_payment_methods(payment.invoicer_id).find(
															(p) => p.id === payment.payment_method_id
														),
														type: { id: 1, description: 'Pago' },
														status: { id: -1, description: 'FUERA DE LINEA' },

														cash_recieved: payment.cash_recieved,
														bank: this.banks?.find((b) => b.id === payment.bank_id),
														payment_document: payment.cheque_number ?? payment.transfer_number,
														card_number: payment.card_number,
														authorization_code: payment.authorization_number,

														extra_info: null,

														details: payment.invoices.map((i) => ({
															invoice_id: i.invoice_id,
															amount: i.amount,
															applied: false,
														})),
													},
													payments: payment.invoices.map((i) => ({
														invoice_id: i.invoice_id,
														amount: i.amount,
														applied: true,
														error: null,
													})),
												},
											}
											results.push(res)
											handle_done()
										} else {
											error_toast('Error al agendar pago')
										}
									})
								} else {
									error_toast('Pago no aplicado')
									this.working = false
								}
							}
						)
					} else handle_payment_result(response)
				})
			} // is prepaid
			else if (order) {
				this.api.apply_order_payment(payment, (response) => handle_payment_result(response))
			} else this.api.apply_prepayment(payment, handle_payment_result)
		}
	}

	go_home() {
		this.router.navigate([''])
	}
}
