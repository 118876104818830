<h1>Contactos Telefónicos</h1>
<div class="mt-8 card text-center space-y-1">
   <div>
      <span class="text-4xl font-bold"><fa-icon icon="phone"></fa-icon> <a href="tel:+8092894799"> (809) 289-4799</a></span>
      <span class="text-xl text-gray-500 ml-2">línea fija</span>
   </div>
   <div>
      <span class="text-4xl font-bold"><fa-icon icon="mobile-alt"></fa-icon> <a href="tel:+8093461452"> (809) 346-1452</a></span>
      <span class="text-xl text-gray-500 ml-2">celular</span>
   </div>
   <div>
      <span class="text-4xl font-bold"><fa-icon icon="mobile-alt"></fa-icon> <a href="tel:+8093461453"> (809) 346-1453</a></span>
      <span class="text-xl text-gray-500 ml-2">celular</span>
   </div>
   <div class="mt-4">
      Soporte técnico via telefónica disponible de
      <br>
      Lunes a Viernes de <span class="font-semibold">8:00am–9:00pm</span>
      <br>
      Sábados, Domingos y días feriados de <span class="font-semibold">9:00am–1:00pm</span>
   </div>
</div>