<div class="flex items-center min-h-screen bg-blue-50 hero hero{{hero}}">
   <div class="mx-auto shadow-xl rounded-lg w-96 p-16 pb-6 bg-white box">
      <div *ngIf="!require_otp">
         <h1 class="font-semibold text-xl text-center">Bienvenido al Virtual PoS!</h1>
         <div class="w-full mt-6 space-y-2">
            <div class="flex flex-row items-center focus-within:ring-1 rounded pl-2" (keydown.enter)="focus_password()" [class.bg-red-100]="!fields.username.valid">
               <fa-icon icon="user" class="mr-3"></fa-icon>
               <input id="username" [(ngModel)]="username" type="text" placeholder="Usuario" class="w-full pl-1 border-0 border-b-2 border-gray-200 focus:ring-0 focus:outline-none" [class.bg-red-100]="!fields.username.valid">
            </div>
            <div class="field-error">{{ fields.username.error }}</div>
            <div class="flex flex-row items-center focus-within:ring-1 rounded pl-2" (keydown.enter)="login()" [class.bg-red-100]="!fields.password.valid">
               <fa-icon icon="key" class="mr-2"></fa-icon>
               <input id="password" [(ngModel)]="password" type="password" placeholder="Contraseña" class="w-full pl-1 border-0 border-b-2 border-gray-200 focus:ring-0 focus:outline-none" [class.bg-red-100]="!fields.password.valid">
            </div>
            <div class="field-error">{{ fields.password.error }}</div>
            <button (click)="login()" class="btn btn-blue w-full" [disabled]="working">
               Ingresar
               <fa-icon icon="sign-in-alt"></fa-icon>
               <working *ngIf="working"></working>
            </button>
         </div>
      </div>
      <div *ngIf="require_otp">
         <h1 class="font-semibold text-xl text-center">2FA</h1>
         <div class="w-full mt-6 space-y-2">
            <div class="flex flex-row items-center focus-within:ring-1 rounded pl-2">
               <fa-icon icon="user-shield" class="mr-3"></fa-icon>
               <input id="2fa" type="number" (keydown.enter)="login(cached_captcha_token)" [(ngModel)]="otp" placeholder="000000" minlength=6 maxlength=6 class="w-full pl-1 border-0 border-b-2 border-gray-200 focus:ring-0 focus:outline-none">
            </div>
            <button (click)="login(cached_captcha_token)" class="btn btn-blue w-full" [disabled]="working">
               Verificar OTP
               <fa-icon icon="user-check"></fa-icon>
               <working *ngIf="working"></working>
            </button>
            <button (click)="otp_back()" class="btn w-full">
               <fa-icon icon="arrow-left"></fa-icon>
               Volver
            </button>
         </div>
      </div>
      <img class="w-24 mx-auto pt-10" src="assets/logo_transneg.png">
   </div>
</div>
<re-captcha
   #captcha
   (resolved)="login($event)"
   size="invisible"
   siteKey="6LckADMbAAAAAKYMJjNdJf1ujvLJGZ0GRdgN6SRv">
</re-captcha>