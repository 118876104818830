import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { OrganizationDetailedView, PointOfSalesView } from 'src/app/models/models';
import { error_toast, success_toast } from 'src/app/utils/toast.util';
import { copy } from 'src/app/utils/utils';

@Component({
   selector: 'app-organizations',
   templateUrl: './organizations.component.html',
   styleUrls: []
})
export class OrganizationsComponent {
   new_org = false;
   working = false;
   organizations: OrganizationDetailedView[];
   active_item: OrganizationDetailedView;

   _points_of_sales: PointOfSalesView[];
   get points_of_sales(): PointOfSalesView[] {
      const active_poss = this.active_item.points_of_sales.map(p => p.id);
      return this._points_of_sales.filter(p => !active_poss.includes(p.id));
   }

   constructor(private api: ApiService, private router: Router) {
      api.get_organizations(response => {
         if (response.succeeded)
            this.organizations = response.data;
         else error_toast(response.error.message);
      });
      api.get_points_of_sales(response => {
         if (response.succeeded)
            this._points_of_sales = response.data;
         else error_toast(response.error.message);
      })
   }

   new() {
      this.new_org = true;
      this.active_item = { id: null, name: null, points_of_sales: [] };
   }

   edit(organization: OrganizationDetailedView) {
      this.new_org = false;
      this.active_item = copy(organization);
   }
   include_pos(pos: PointOfSalesView) {
      this.active_item.points_of_sales.push(pos);
   }
   exclude_pos(pos: PointOfSalesView) {
      this.active_item.points_of_sales.splice(this.active_item.points_of_sales.findIndex(p => p.id === pos.id), 1);
   }

   save() {
      if (this.active_item.name?.length > 0) {
         this.working = true;
         if (this.new_org) {
            this.api.create_organization(this.active_item.name, this.active_item.points_of_sales.map(p => p.id), response => {
               if (response.succeeded) {
                  this.new_org = false;
                  this.active_item.id = response.data;
                  success_toast('Unidad Organizacional creada.');
                  this.api.get_organizations(response => {
                     if (response.succeeded) {
                        this.organizations = response.data
                        this.working = false;
                     }
                     else
                        error_toast(response.error.message);
                  });

               } else {
                  this.working = false;
                  error_toast(response.error.message);
               }
            });
         } else {
            this.api.edit_organization(this.active_item.id, this.active_item.name, this.active_item.points_of_sales.map(p => p.id), response => {
               if (response.succeeded) {
                  success_toast('Unidad Organizacional actualizada.');
                  this.api.get_organizations(response => {
                     this.working = false;
                     if (response.succeeded) {
                        this.organizations = response.data;
                        this.active_item = copy(response.data.find(o => o.id === this.active_item.id));
                     } else error_toast(response.error.message);
                  })
               } else {
                  this.working = false;
                  error_toast(response.error.message);
               }
            });
         }
      }
   }
}
