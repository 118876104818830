import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module'
import { RecaptchaModule } from 'ng-recaptcha'

import { ApiService } from './api.service'

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { registerLocaleData } from '@angular/common'
import localeEs from '@angular/common/locales/es'
import localeEsDo from '@angular/common/locales/es-DO'

import { ServiceWorkerModule } from '@angular/service-worker'
import { QRCodeModule } from 'angular2-qrcode'
import { environment } from 'src/environments/environment'
import { App } from './app.component'
import { LegacyLoginComponent } from './login/legacy/legacy.component'
import { LoginComponent } from './login/login.component'
import { ApplyPaymentComponent } from './main/cashier/apply-payment/apply-payment.component'
import { ClosureComponent } from './main/cashier/closure/closure.component'
import { ContractBundleHistoryComponent } from './main/cashier/bundles/contract-bundle-history/contract-bundle-history.component'
import { ContractBundlePaymentComponent } from './main/cashier/bundles/contract-bundle-payment/contract-bundle-payment.component'
import { ContractBundlesComponent } from './main/cashier/bundles/contract-bundles/contract-bundles.component'
import { CreateEditContractBundleComponent } from './main/cashier/bundles/create-edit-contract-bundle/create-edit-contract-bundle.component'
import { NullificationComponent } from './main/cashier/nullification/nullification.component'
import { PayContractBundleComponent } from './main/cashier/bundles/pay-contract-bundle/pay-contract-bundle.component'
import { AlticeComponent } from './main/cashier/payments/altice/altice.component'
import { AlvComponent } from './main/cashier/payments/alv/alv.component'
import { AsdnComponent } from './main/cashier/payments/asdn/asdn.component'
import { CaasdComponent } from './main/cashier/payments/caasd/caasd.component'
import { ClaroComponent } from './main/cashier/payments/claro/claro.component'
import { DigicelComponent } from './main/cashier/payments/digicel/digicel.component'
import { EdeComponent } from './main/cashier/payments/edes/ede.component'
import { MultipayComponent } from './main/cashier/payments/multipay/multipay.component'
import { NatcomComponent } from './main/cashier/payments/natcom/natcom.component'
import { PaymentsComponent } from './main/cashier/payments/payments.component'
import { QuotaComponent } from './main/cashier/payments/quota/quota.component'
import { VivaComponent } from './main/cashier/payments/viva/viva.component'
import { WindTelecomComponent } from './main/cashier/payments/windtelecom/windtelecom.component'
import { ReportComponent } from './main/cashier/report/report.component'
import { EmailComponent } from './main/help/email/email.component'
import { FaqComponent } from './main/help/faq/faq.component'
import { PhonesComponent } from './main/help/phones/phones.component'
import { WhatsappComponent } from './main/help/whatsapp/whatsapp.component'
import { HomeComponent } from './main/home/home.component'
import { MainComponent } from './main/main.component'
import { LotCartographyComponent } from './main/supervisor/geo/lot-cartography/lot-cartography.component'
import { LotHistoryComponent as CashierLotHistoryComponent } from './main/cashier/lot-history/lot-history.component'
import { LotReportComponent } from './main/supervisor/lot-history/lot-report/lot-report.component'
import { OperationInfoComponent } from './main/supervisor/op-info/op-info.component'
import { PendingNullificationsComponent } from './main/supervisor/pending-nullifications/pending-nullifications.component'
import { PostponedPaymentsComponent } from './main/supervisor/postponed-payments/postponed-payments.component'
import { TfaConfigurationComponent } from './main/supervisor/tfa-configuration/tfa-configuration.component'
import { MultipayConfigurationComponent } from './main/system/multipay-configuration/multipay-configuration.component'
import { MultipayPointsOfSalesComponent } from './main/system/multipay-configuration/multipay-points-of-sales/multipay-points-of-sales.component'
import { OrganizationsComponent } from './main/system/organizations/organizations.component'
import { PointsOfSalesComponent } from './main/system/points-of-sales/points-of-sales.component'
import { PosConfigurationComponent } from './main/system/points-of-sales/pos-configuration/pos-configuration.component'
import { UserConfigurationComponent } from './main/system/users/user-configuration/user-configuration.component'
import { UserLogsComponent } from './main/system/users/user-logs/user-logs.component'
import { UsersComponent } from './main/system/users/users.component'
import { PasswordChangeComponent } from './main/user/password-change/password-change.component'
import { PrintConfigurationComponent } from './main/user/print-configuration/print-configuration.component'
import { QueuedPaymentsComponent } from './main/user/queued-payments/queued-payments.component'
import { TerminalService } from './terminal.service'
import { DateDeltaPipe } from './utils/deltadate.pipe'
import { FilterPipe } from './utils/filter.pipe'
import { MultiTransactionReceiptComponent } from './utils/receipts/multi-transaction-receipt/multi-transaction-receipt.component'
import { ReportReceiptComponent } from './utils/receipts/report-receipt/report-receipt.component'
import { TransactionReceiptComponent } from './utils/receipts/transaction-receipt/transaction-receipt.component'
import { SortPipe } from './utils/sort.pipe'
import { TableControlComponent } from './utils/table-control/table-control.component'
import { TablePaginatorComponent } from './utils/table-control/table-paginator.component'
import { WorkingComponent } from './utils/working.component'
import { LotHistoryComponent } from './main/supervisor/lot-history/lot-history.component'

import './utils/extensions'
import { CreateOrderComponent } from './main/cashier/orders/create-order.component'
import { OrdersComponent } from './main/cashier/orders/orders.component'
import { NaguaComponent } from './main/cashier/payments/nagua/nagua.component'
import { SamanaComponent } from './main/cashier/payments/samana/samana.component'

registerLocaleData(localeEs, 'es')
registerLocaleData(localeEsDo, 'es-DO')

@NgModule({
	declarations: [
		App,
		WorkingComponent,
		FilterPipe,
		DateDeltaPipe,
		SortPipe,
		TableControlComponent,
		TablePaginatorComponent,

		MainComponent,

		LegacyLoginComponent,
		LoginComponent,
		EmailComponent,
		FaqComponent,
		PhonesComponent,
		WhatsappComponent,
		HomeComponent,

		ApplyPaymentComponent,
		ClosureComponent,
		CashierLotHistoryComponent,
		NullificationComponent,
		PaymentsComponent,
		ReportComponent,
		CreateOrderComponent,
		OrdersComponent,

		AlticeComponent,
		AlvComponent,
		AsdnComponent,
		CaasdComponent,
		ClaroComponent,
		DigicelComponent,
		EdeComponent,
		MultipayComponent,
		NatcomComponent,
		QuotaComponent,
		VivaComponent,
		WindTelecomComponent,
		NaguaComponent,
		SamanaComponent,

		ContractBundlesComponent,
		ContractBundlePaymentComponent,
		ContractBundleHistoryComponent,
		PayContractBundleComponent,
		CreateEditContractBundleComponent,

		LotCartographyComponent,
		LotHistoryComponent,
		LotReportComponent,
		OperationInfoComponent,
		PendingNullificationsComponent,
		PostponedPaymentsComponent,
		TfaConfigurationComponent,

		MultipayConfigurationComponent,
		MultipayPointsOfSalesComponent,
		OrganizationsComponent,
		PointsOfSalesComponent,
		PosConfigurationComponent,
		UsersComponent,
		UserConfigurationComponent,
		UserLogsComponent,

		PasswordChangeComponent,
		PrintConfigurationComponent,
		QueuedPaymentsComponent,

		MultiTransactionReceiptComponent,
		TransactionReceiptComponent,
		ReportReceiptComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FontAwesomeModule,
		RouterModule,
		HttpClientModule,
		FormsModule,
		RecaptchaModule,
		QRCodeModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: true || environment.production,
			registrationStrategy: 'registerWhenStable:15000',
		}),
	],
	providers: [ApiService, TerminalService],
	bootstrap: [App],
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far, fab)
	}
}
