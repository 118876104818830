import { Component } from '@angular/core';

@Component({
  selector: 'app-phones',
  templateUrl: './phones.component.html',
  styleUrls: []
})
export class PhonesComponent {
  constructor() { }
}
