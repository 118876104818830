import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiResponse, TerminalPaymentResponse, TerminalStatus } from "./models/models";

const terminal = environment.terminal;

@Injectable()
export class TerminalService {
   constructor(private http: HttpClient) {  }

   private get<T>(url: string, callback: (response: ApiResponse<T>) => void) {
      environment.debug('terminal get:', { url });
      this.http.get<ApiResponse<T>>(url).subscribe(response => {
         environment.debug('terminal get to \"' + url + '\" response:', response);
         callback(response);
      }, err => {
         const error = { code: "UNKNOWN", message: 'Servicio interfaz terminal no disponible' };
         environment.error('terminal get to \"' + url + '\" error:', error);
         callback({ succeeded: false, data: null, error });
      });
   }

   private post<T>(url: string, body: any, callback: (response: ApiResponse<T>) => void) {
      environment.debug('terminal post:', { url, body });
      this.http.post<ApiResponse<T>>(url, body).subscribe(response => {
         environment.debug('terminal post to \"' + url + '\" response:', response);
         callback(response);
      }, err => {
         const error = { code: "UNKNOWN", message: 'Servicio interfaz terminal no disponible' };
         environment.error('terminal post to \"' + url + '\" error:', error);
         callback({ succeeded: false, data: null, error });
      });
   }

   status(callback: (response: ApiResponse<TerminalStatus>) => void) {
      this.get(terminal('status'), callback);
   }

   request_payment(amount: number, callback: (response: ApiResponse<TerminalPaymentResponse>) => void) {
      this.post(terminal('request_payment'), { amount }, callback);
   }
}