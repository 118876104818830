import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { BundlePayment, Item } from 'src/app/models/models';
import { State } from 'src/app/state';
import { bundle_receipt } from 'src/app/utils/bundle-receipt';
import { wrap_print } from 'src/app/utils/receipt.util';
import { error_toast } from 'src/app/utils/toast.util';
import { clear_element, sum } from 'src/app/utils/utils';

@Component({
  selector: 'app-contract-bundle-payment',
  templateUrl: './contract-bundle-payment.component.html',
  styleUrls: ['./contract-bundle-payment.component.less']
})
export class ContractBundlePaymentComponent {
  payment: BundlePayment;
  banks: Item[];

  get applied_count(): number{
    return this.payment.details.filter(d => d.applied).length;
  }

  get total(): number {
    return sum(this.payment.details.filter(d => d.applied).map(d => d.amount));
  }

  constructor(active_route: ActivatedRoute, api: ApiService) {
    active_route.params.subscribe(params => {
      api.get_contract_bundle_payment(params.bundle_id, params.payment_id, response => {
        if (response.succeeded) {
          this.payment = response.data;
        } else error_toast(response.error.message);
      });
      api.get_banks(response => {
        if (response.succeeded) {
          this.banks = response.data;
        } else error_toast(response.error.message);
      });
    });
  }

  frame_container: HTMLDivElement;
  ngOnInit() {
    this.frame_container = <HTMLDivElement>document.getElementById('frame_container');
  }

  print() {
    const print_contents = bundle_receipt(this.payment);

    if (State.on_cordova) {
      (window as any).cordova.plugins.printer.print(wrap_print(print_contents));
      return;
    }

    const frame = document.createElement('iframe');
    this.frame_container.appendChild(frame);
    frame.contentDocument.write(print_contents);
  }
}
