import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { ApiService } from 'src/app/api.service'
import { ContributorView, InvoiceView, PaymentOrderView } from 'src/app/models/invoicer_query.models'
import { Item } from 'src/app/models/models'
import { State } from 'src/app/state'
import { error_toast } from 'src/app/utils/toast.util'
import { is_nothing, qs } from 'src/app/utils/utils'
import { invoicer_queriable_to_item } from '../payments.component'

@Component({
	selector: 'app-alv',
	templateUrl: './alv.component.html',
	styleUrls: ['./alv.component.less'],
})
export class AlvComponent {
	id: string
	working = false
	result_invoices: ContributorView
	result_order: PaymentOrderView

	get partial_payments(): boolean {
		return State.active_invoicer.partial_payments
	}

	payment_policy: Item

	mode: 'invoices' | 'orders' = 'invoices'

	constructor(private api: ApiService, private router: Router) {
		if (is_nothing(State.active_invoicer) || State.active_invoicer.name.toLowerCase() != 'alv')
			router.navigate(['cashier', 'pay'])
		else if (State.preloaded_query) {
			this.id = State.preloaded_query.id
			this.result_invoices = State.preloaded_query.data
			State.preloaded_query = null
		}
		this.payment_policy = State.active_invoicer.invoice_payment_policy
	}

	query() {
		this.working = true
		const value = (qs('#document') as HTMLInputElement)?.value
		switch (this.mode) {
			case 'invoices':
				this.api.query_alv(value, (response) => {
					this.working = false
					if (response.succeeded) {
						this.result_invoices = response.data
					} else error_toast(response.error.message)
				})
				break
			case 'orders':
				this.api.query_order_alv(value, (response) => {
					this.working = false
					if (response.succeeded) {
						this.result_order = response.data
					} else error_toast(response.error.message)
				})
				break
		}
	}

	// Selectable invoices
	selectable_invoices: SelectableInvoice[] = []
	selectable(invoice: InvoiceView): SelectableInvoice {
		var sel_inv: SelectableInvoice = this.selectable_invoices.find((i) => i.id === invoice.id)
		if (sel_inv) return sel_inv
		else {
			const component: AlvComponent = this
			let selected = true
			let amount_to_pay = invoice.amount
			sel_inv = {
				id: invoice.id,
				get selected(): boolean {
					return selected
				},
				set selected(value: boolean) {
					if (component.payment_policy.id === 2) {
						const issue_date = new Date(invoice.issue_date)
						if (value) {
							component.selectable_invoices
								.filter((si) => new Date(si.invoice.issue_date) < issue_date)
								.reverse()
								.forEach((si) => {
									si.selected = true
									si.amount_to_pay = si.invoice.amount
								})
						} else {
							component.selectable_invoices
								.filter(
									(si) =>
										si !== sel_inv && new Date(si.invoice.issue_date) >= issue_date && si.selected
								)
								.reverse()
								.forEach((si) => {
									si.selected = false
								})
						}
					}
					selected = value
				},
				invoice,
				get amount_to_pay(): number {
					return amount_to_pay
				},
				set amount_to_pay(value: number) {
					if (component.payment_policy.id === 2) {
						if (value < invoice.amount) {
							const issue_date = new Date(invoice.issue_date)
							component.selectable_invoices
								.filter(
									(si) =>
										si !== sel_inv && si.selected && new Date(si.invoice.issue_date) >= issue_date
								)
								.reverse()
								.forEach((si) => {
									si.selected = false
								})
						}
					}
					amount_to_pay = value
				},
			}
			this.selectable_invoices.push(sel_inv)
			return sel_inv
		}
	}
	get selected(): SelectableInvoice[] {
		return this.selectable_invoices.filter((s) => s.selected)
	}

	pay() {
		switch (this.mode) {
			case 'invoices':
				State.payment_package = [
					{
						name: this.result_invoices.name,
						document: this.result_invoices.document,
						invoicer: invoicer_queriable_to_item(State.active_invoicer),
						type: 1,
						payloads: this.selected.map((s) => ({
							invoice_id: s.id,
							concept: s.invoice.concept,
							amount: s.amount_to_pay,
						})),
					},
				]
				break
			case 'orders':
				State.payment_package = [
					{
						name: this.result_order.name,
						document: this.result_order.document,
						invoicer: invoicer_queriable_to_item(State.active_invoicer),
						type: 3,
						payloads: [
							{
								invoice_id: this.result_order.id,
								concept: this.result_order.description,
								amount: this.result_order.amount,
							},
						],
					},
				]
				break
		}
		this.router.navigate(['cashier', 'apply-payment'])
	}
}

interface SelectableInvoice {
	id: string
	invoice: InvoiceView
	selected: boolean
	amount_to_pay: number
}
