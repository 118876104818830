import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { BundleDetails, BundlePaymentRequest, Item } from 'src/app/models/models';
import { State } from 'src/app/state';
import { error_toast, success_toast } from 'src/app/utils/toast.util';
import { export_text_file, matches, sum, to_snake_case } from 'src/app/utils/utils';

@Component({
  selector: 'app-pay-contract-bundle',
  templateUrl: './pay-contract-bundle.component.html',
  styleUrls: ['pay-contract-bundle.component.less']
})
export class PayContractBundleComponent {
  working = {
    loading: true,
    paying: false
  };

  paying: boolean = false;

  bundle: BundleDetails;

  filter: string = null;
  get filtered_invoices() {
    if (!this.filter)
      return this.bundle.details;
    return this.filter.split(/[\s,]+/g)
      .filter(_ => _)
      .map(filter => this.bundle?.details?.filter(d => matches(d, filter)))
      .concat([[]])
      .reduce((a, b) => a.concat(b))
      .distinct();
  }

  selected: { [invoice: string]: boolean } = {};
  amount: { [invoice: string]: number } = {};

  get total(): number {
    return sum(Object.keys(this.selected).filter(k => this.selected[k]).map(k => this.amount[k]));
  }

  get contracts(): number {
    return this.bundle?.details?.map(d => d.reference)?.distinct()?.length;
  }

  full_total: number;

  get selected_invoices(): { reference: string; invoice_number: string; amount: number }[] {
    return this.bundle?.details
      ?.filter(detail => this.selected[detail.invoice_number])
      ?.map(detail => ({
        reference: detail.reference,
        invoice_number: detail.invoice_number,
        amount: this.amount[detail.invoice_number]
      }));
  }

  get nothing_to_pay(): boolean {
    return !sum(this.selected_invoices?.map(i => i.amount));
  }

  payment_methods: Item[];

  data = {
    method: 0,
    cash: 0,
    bank: 0,
    document: null,
    card: null,
    code: null,
    lot: null,
  };

  banks: Item[];

  constructor(private api: ApiService, active_route: ActivatedRoute, private router: Router) {
    active_route.params.subscribe(params => {
      api.get_contract_bundle_details(params.id, response => {
        this.working.loading = false;
        if (response.succeeded) {
          this.bundle = response.data;
          this.full_total = sum(this.bundle.details.map(d => d.amount));
          for (let detail of this.bundle.details) {
            this.selected[detail.invoice_number] = true;
            this.amount[detail.invoice_number] = detail.amount;
          }

          api.get_point_of_sales_invoicer_payment_methods(
            State.user.point_of_sales.id,
            this.bundle.invoicer.id,
            response => {
              if (response.succeeded) {
                this.payment_methods = response.data;
              } else error_toast(`Error al consultar los modos de pago:\n${response.error.message}`);
            });

          api.get_banks(response => {
            if (response.succeeded) {
              this.banks = response.data;
            } else error_toast(`Error al consultar los bancos:\n${response.error.message}`)
          });

        } else error_toast(response.error.message);
      });
    });
  }

  toggle(detail: { reference: string; invoice_number: string; }) {
    this.selected[detail.invoice_number] = !this.selected[detail.invoice_number];
  }

  set_all(value: boolean) {
    for (let invoice_number of this.filtered_invoices.map(i => i.invoice_number))
      this.selected[invoice_number] = value;
  }

  get can_pay(): boolean {
    if (this.data.method) {
      switch (Number.parseInt(<any>this.data.method)) {
        case 1:
          return this.data.cash >= this.total;
        case 2:
        case 6:
        case 7:
        case 8:
        case 9:
          return this.data.bank && this.data.document;
        case 3:
        case 4:
        case 5:
          return this.data.card && this.data.code && this.data.lot;
      }
    }
    return false;
  }

  pay() {
    this.working.paying = true;
    const payment: BundlePaymentRequest = <any>{
      payment_method_id: Number.parseInt(<any>this.data.method),
      details: this.selected_invoices
    };
    switch (payment.payment_method_id) {
      case 1:
        payment.cash_received = Number.parseInt(<any>this.data.cash);
        break;
      case 2:
      case 6:
      case 7:
      case 8:
      case 9:
        payment.bank_id          = Number.parseInt(<any>this.data.bank);
        payment.payment_document = this.data.document;
        break;
      case 3:
      case 4:
      case 5:
        payment.card_number        = this.data.card;
        payment.authorization_code = this.data.code;
        payment.external_lot       = this.data.lot;
        break;
    }
    this.api.pay_contract_bundle(this.bundle.id, payment, response => {
      this.working.paying = false;
      if (response.succeeded) {
        this.router.navigate(['/', 'cashier', 'bundles', this.bundle.id, 'history']);
        success_toast('Pago en curso...')
      } else error_toast(response.error.message);
    })
  }

  round = Math.round;

  export() {
    export_text_file(`bundle_${to_snake_case(this.bundle.description)}_${formatDate(Date.now(), 'yyyy_MM_dd', 'es-DO')}.csv`,
      ['Contrato', 
       'Factura', 
       'Nombre cliente', 
       'Fecha', 
       'Monto (RD$)'].join(',') + '\n' 
      + this.bundle.details.map(d => 
        ['"' + d.reference + '"', 
         '"' + d.invoice_number + '"', 
         `"${d.client_name}"`, 
         formatDate(d.date_issued, 'yyyy-MM-dd', 'es-DO'), 
         d.amount].join(',')).join('\n'))
  }
}
