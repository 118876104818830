import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/api.service'
import { LotReport, TransactionView } from 'src/app/models/models'
import PrintService from 'src/app/print.service'
import { error_toast } from 'src/app/utils/toast.util'
import { sum } from 'src/app/utils/utils'

@Component({
	selector: 'app-lot-report',
	templateUrl: './lot-report.component.html',
	styleUrls: ['./lot-report.component.less'],
})
export class LotReportComponent {
	report: LotReport

	transaction_groups: { label: string; transactions: TransactionView[] }[]

	selected_invoicer: number = 0
	get selected_report() {
		if (this.selected_invoicer == 0) return this.report
		else return this.report.invoicers.find((i) => i.invoicer.id == this.selected_invoicer)
	}

	get has_multiple_modalities() {
		return this.report.total_prepaid && this.report.total_postpaid
	}

	constructor(private api: ApiService, active_route: ActivatedRoute, router: Router) {
		active_route.params.subscribe((params) => {
			if (!params.id) {
				router.navigate(['supervisor', 'lots'])
				return
			}
			api.get_operation_lot(params.id, (response) => {
				if (response.succeeded) {
					this.report = response.data
				} else {
					error_toast(response.error.message)
					router.navigate(['supervisor', 'lots'])
				}
			})

			const self: LotReportComponent = this
			this.transaction_groups = [
				{
					label: 'Transacciones en Efectivo',
					get transactions() {
						return self.selected_report.cash_transactions
					},
				},
				{
					label: 'Transacciones en Cheque',
					get transactions() {
						return self.selected_report.cheque_transactions
					},
				},
				{
					label: 'Transacciones en Tarjeta de Crédito',
					get transactions() {
						return self.selected_report.card_transactions
					},
				},
				{
					label: 'Transacciones en Transferencia',
					get transactions() {
						return self.selected_report.bank_transfer_transactions
					},
				},
				{
					label: 'Transacciones en Tarjeta Bonoluz',
					get transactions() {
						return self.selected_report.bonoluz_transactions
					},
				},
				{
					label: 'Anulaciones',
					get transactions() {
						return self.selected_report.nullified_transactions
					},
				},
			]
		})
	}

	printing: number
	print(transaction: TransactionView) {
		this.printing = transaction.id
		this.api.get_transaction(transaction.id, async (response) => {
			if (response.succeeded) {
				const transaction = response.data
				await PrintService.render('transaction', { transaction })
				this.printing = null
				return
			} else {
				this.printing = null
				error_toast(response.error.message)
			}
		})
	}

	async print_report(modality?: 'prepaid' | 'pospaid') {
		console.debug('print modality:', modality)
		switch (modality) {
			case undefined:
				this.printing = -1
				await PrintService.render('report', this.report)
				break
			case 'pospaid':
				this.printing = -1
				const postpaid_report = report_by_modality(this.report, 'postpaid')
				await PrintService.render('report', postpaid_report)
				break
			case 'prepaid':
				this.printing = -2
				const prepaid_report = report_by_modality(this.report, 'prepaid')
				await PrintService.render('report', prepaid_report)
				break
		}
		this.printing = null
		return
	}
}

function report_by_modality(report: LotReport, modality: 'postpaid' | 'prepaid'): LotReport {
	const modality_report = { ...report }
	modality_report.total_prepaid = modality_report.total_postpaid = modality_report.total = 0
	Object.keys(modality_report)
		.filter((k) => k.endsWith('transactions') && !k.startsWith('debit_card'))
		.forEach((key) => {
			modality_report[key] = modality_report[key].filter((t) => (modality === 'postpaid' ? !t.prepaid : t.prepaid))
			const total = sum(modality_report[key].map((t) => t.amount))
			modality_report['total_' + key.replace('_transactions', '')] = total
			if (modality === 'postpaid') modality_report.total_postpaid += total
			else modality_report.total_prepaid += total
		})
	modality_report.total = modality_report.total_postpaid + modality_report.total_prepaid
	modality_report.invoicers.forEach((report) => {
		report.total_prepaid = report.total_postpaid = report.total = 0
		Object.keys(report)
			.filter((k) => k.endsWith('transactions') && !k.startsWith('debit_card'))
			.forEach((key) => {
				report[key] = report[key].filter((t) => (modality === 'postpaid' ? !t.prepaid : t.prepaid))
				const total = sum(report[key].map((t) => t.amount))
				report['total_' + key.replace('_transactions', '')] = total
				if (modality === 'postpaid') report.total_postpaid += total
				else report.total_prepaid += total
			})
		report.total = report.total_postpaid + report.total_prepaid
	})
	modality_report.invoicers = modality_report.invoicers.filter((i) => i.total)
	return modality_report
}
