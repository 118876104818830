<h1>Contacto de Whatsapp</h1>
<div class="mt-8 card text-center">
   <div class="font-bold text-4xl text-green-700">
      <fa-icon [icon]="['fab', 'whatsapp']"></fa-icon> 
      <!-- @TODO(Gorky): Incluir nombre de punto de venta en mensaje -->
      <a href="https://wa.me/18293461450/?text=Solicitud de Asistencia Técnica para [Punto de Venta]:"> (829) 346-1450</a>
   </div>
   <div class="mt-4">
      Soporte técnico via WhatsApp disponible de
      <br>
      Lunes a Viernes de <span class="font-semibold">8:00am–9:00pm</span>
      <br>
      Sábados, Domingos y días feriados de <span class="font-semibold">9:00am–1:00pm</span>
   </div>
</div>