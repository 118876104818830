import { AfterContentInit, Component } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { error_toast, success_toast } from 'src/app/utils/toast.util';
import { is_nothing } from 'src/app/utils/utils';
import { validate, validation, validator } from 'src/app/utils/validator.util';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: []
})
export class PasswordChangeComponent implements AfterContentInit {
  show_new_pass = false;
  working = false;
  fields;

  data = {
    old_pass: null,
    new_pass: null,
    new_pass_confirm: null
  };

  constructor(private api: ApiService) { }

  ngAfterContentInit(): void {
    this.fields = validator({
      old_password: [ validation.required ],
      new_password: [
        validation.required,
        validate('Debe tener al menos 10 caracteres', _ => _.length >= 10),
        validate('No puede comenzar ni terminar con espacios', _ => !_.startsWith(' ') && !_.endsWith(' ')) ],
      new_password_confirm: [
        validate('Confirma la contraseña', _ => this.show_new_pass || (!is_nothing(_) && _.length > 0)),
        validate('Las contraseñas no concuerdan', _ => this.show_new_pass || (_ === this.data.new_pass))
      ]
    });
  }

  save() {
    if (this.fields.check_all_valid()) {
      this.working = true;
      this.api.change_password(this.data.old_pass, this.data.new_pass, response => {
        this.working = false;
        if (response.succeeded)
          success_toast('Contraseña actualizada.');
        else
          error_toast(response.error.message);
      });
    }
  }
}
