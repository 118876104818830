import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OfflineDatabase, when_processing_payment } from 'src/app/offline_db';
import { State } from 'src/app/state';
import { error_toast } from 'src/app/utils/toast.util';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.less']
})
export class HomeComponent {
   get point_of_sales(): string { return State.user.point_of_sales?.name; }

   get user_is_supervisor(): boolean    { return State.user.roles.some(r => r.id === 2 || r.id === 3); }
   get user_is_administrator(): boolean { return !this.user_is_supervisor && State.user.roles.some(r => r.id === 4); }
   get user_is_cashier(): boolean       { return !this.user_is_administrator && !this.user_is_supervisor && State.user.roles.some(r => r.id === 1); }

   get otp_required(): boolean { return State.user.point_of_sales?.otp_required; }

   get online(): boolean { return State.online; }

   get has_queued_payments(): boolean { return OfflineDatabase.has_queued_payments; }

   qppk: number[] = [];

   get processing_queued_payments(): boolean { return !!(this.qppk?.length); }

   constructor(public router: Router) {
      State.clear_payments();
      when_processing_payment(keys => this.qppk = keys);
   }

   process_offline_payments() {
      State.bypass_connection_loss_timeout();
      if (State.online) {
         // Force try apply payments
         OfflineDatabase.get_queued_payment_count(null);
      } else {
         error_toast('Sin conexión');
      }
   }
}
