import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { BundlePaymentHistory } from 'src/app/models/models';
import { error_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-contract-bundle-history',
  templateUrl: './contract-bundle-history.component.html',
  styleUrls: []
})
export class ContractBundleHistoryComponent {
  bundle: BundlePaymentHistory;
  
  constructor(private api: ApiService, active_route: ActivatedRoute) {
    active_route.params.subscribe(params => {
      api.get_contract_bundle_payment_history(params.id, response => {
        if (response.succeeded)
          this.bundle = response.data;
        else error_toast(response.error.message);
      });
    });
  }
}
