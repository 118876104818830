import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/api.service'
import { Item } from 'src/app/models/models'
import PrintService from 'src/app/print.service'
import { State } from 'src/app/state'
import { error_toast, success_toast } from 'src/app/utils/toast.util'
import { sum, titlecase } from 'src/app/utils/utils'

@Component({
	selector: 'create-order',
	template: `
		<h1>Crear orden de pago</h1>
		<div class="card mt-8 flex flex-col">
			<div class="w-full flex flex-col md:table md:w-max">
				<!-- <tr>
					<td class="w-0 pr-2"><div class="min-w-max">Facturador:</div></td>
					<td>
						<select [(ngModel)]="data.invoicer" class="border rounded-md px-2 pr-8 py-1 border-gray-300 w-full min-w-max">
							<option [value]="0" disabled>(Seleccionar)</option>
							<option *ngFor="let i of invoicers" [value]="i.id" [disabled]="!implemented.includes(i.id)">{{ i.description }}</option>
						</select>
					</td>
				</tr> -->
				<div class="flex flex-col md:table-row">
					<div class="md:table-cell w-0 pr-2"><div class="min-w-max">Documento de identificacion:</div></div>
					<div class="flex gap-2 md:contents">
						<div class="grid md:table-cell">
							<input
								[(ngModel)]="data.document"
								class="border rounded-md px-2 py-1 border-gray-300 w-full"
								[disabled]="!data.invoicer || is.working"
								(keydown.enter)="query()"
							/>
						</div>
						<div class="grid md:table-cell">
							<button (click)="query()" class="rounded-md px-2 py-1 border border-gray-300 min-w-max" [disabled]="!data.invoicer || is.working">
								{{ !is.querying ? 'Consultar' : 'Consultando...' }}
								<fa-icon *ngIf="!is.querying" icon="search"></fa-icon>
								<fa-icon *ngIf="is.querying" icon="spinner" [pulse]="true"></fa-icon>
							</button>
						</div>
					</div>
				</div>
				<div class="flex flex-col md:table-row">
					<div class="md:table-cell w-0 pr-2"><div class="min-w-max">Nombre del cliente:</div></div>
					<div class="grid md:table-cell">
						<input
							[(ngModel)]="data.name"
							class="border rounded-md px-2 py-1 border-gray-300 w-80"
							[disabled]="!data.invoicer || is.working || is.querying"
						/>
					</div>
				</div>
				<div class="flex flex-col md:table-row">
					<div class="md:table-cell w-0 pr-2"><div class="min-w-max">Direccion:</div></div>
					<div class="grid md:table-cell">
						<input
							[(ngModel)]="data.address"
							class="border rounded-md px-2 py-1 border-gray-300 w-full"
							[disabled]="!data.invoicer || is.working || is.querying"
						/>
					</div>
				</div>
				<div class="flex flex-col md:table-row">
					<div class="md:table-cell w-0 pr-2"><div class="min-w-max">Balance:</div></div>
					<div class="grid md:table-cell">
						<div class="border rounded-md px-2 py-1 border-gray-300 flex items-baseline gap-2 w-full bg-gray-200">
							{{ data.balance | currency : 'RD$ ' }}
						</div>
					</div>
				</div>
			</div>

			<table class="border border-gray-300 rounded-lg mt-8">
				<thead>
					<th class="border-r">Arbitrio</th>
					<th class="border-r">Precio</th>
					<th class="border-r">Cantidad</th>
					<th class="border-r">Total</th>
					<th class="w-0"></th>
				</thead>
				<tbody>
					<tr *ngFor="let detail of data.details; let pi = index" class="border-t">
						<td class="border-r px-2 py-1">
							<select [(ngModel)]="detail.code" class="w-full border border-gray-300 rounded-md px-2 py-1" [disabled]="!data.invoicer || is.working">
								<option *ngFor="let p of products[data.invoicer]" [value]="p.code">
									{{ p.code + ' - ' + p.description }}
								</option>
							</select>
						</td>
						<td class="border-r px-2 py-1 text-right">
							{{ product(detail.code)?.price | currency }}
						</td>
						<td class="border-r px-2 py-1">
							<input
								type="number"
								[(ngModel)]="detail.quantity"
								[min]="1"
								class="border border-gray-300 rounded-md px-2 py-1 w-full text-right"
								[disabled]="!data.invoicer || is.working"
							/>
						</td>
						<td class="border-r px-2 py-1 text-right">
							{{ product(detail.code)?.price * detail.quantity | currency }}
						</td>
						<td class="px-2 py-1">
							<button
								*ngIf="data.details.length > 1"
								(click)="data.details.splice(pi, 1)"
								class="rounded-lg text-white bg-red-500 px-2 py-1"
								title="Remover"
							>
								<fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
							</button>
						</td>
					</tr>
					<tr class="border-t">
						<td colspan="5" class="px-2 py-1">
							<button
								(click)="data.details.push({ code: '', quantity: 1 })"
								class="border border-gray-300 rounded-md px-2 py-2"
								[disabled]="!data.invoicer || is.working"
							>
								<fa-icon icon="plus"></fa-icon>
								Agregar arbitrio
							</button>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="text-right font-bold">Total: {{ total | currency : 'RD$ ' }}</div>

			<table class="my-8 w-full md:w-max">
				<tbody>
					<tr>
						<td class="pr-2">
							<label for="amount_to_pay" class="min-w-max">Monto a pagar:</label>
						</td>
						<td>
							<input
								type="number"
								id="amount_to_pay"
								[(ngModel)]="data.amount_to_pay"
								class="border rounded-md px-2 py-1 border-gray-300 w-full text-right"
							/>
						</td>
					</tr>
					<ng-container *ngIf="data.amount_to_pay > 0">
						<tr>
							<td colspan="2" class="py-2"><hr /></td>
						</tr>
						<tr>
							<td class="pr-2">
								<label for="method">Método de pago:</label>
							</td>
							<td>
								<select id="method" [(ngModel)]="data.method" class="border rounded-md px-2 py-1 border-gray-300 w-full">
									<option *ngFor="let method of methods" [value]="method.id">{{ titlecase(method.description) }}</option>
								</select>
							</td>
						</tr>
						<ng-container *ngIf="data.method == 1">
							<tr>
								<td class="pr-2"><label for="cash">Efectivo:</label></td>
								<td>
									<input
										type="number"
										id="cash"
										[(ngModel)]="data.cash_received"
										class="border rounded-md px-2 py-1 border-gray-300 w-full text-right"
									/>
								</td>
							</tr>
							<tr>
								<td class="pr-2">Devuelta:</td>
								<td>{{ data.cash_received < data.amount_to_pay ? 'Faltan' : (data.cash_received - data.amount_to_pay | currency) }}</td>
							</tr>
						</ng-container>
						<ng-container *ngIf="data.method == 2 || data.method == 8">
							<tr>
								<td class="pr-2"><label for="bank">Banco:</label></td>
								<td>
									<select id="bank" [(ngModel)]="data.bank" class="border rounded-md px-2 py-1 border-gray-300 w-full">
										<option *ngFor="let bank of banks" [value]="bank.id">{{ bank.description }}</option>
									</select>
								</td>
							</tr>
							<tr>
								<td class="pr-2"><label for="reference">Numero de documento:</label></td>
								<td>
									<input type="text" id="reference" [(ngModel)]="data.payment_reference" class="border rounded-md px-2 py-1 border-gray-300 w-full" />
								</td>
							</tr>
						</ng-container>
						<ng-container *ngIf="data.method == 3 || data.method == 4 || data.method == 5">
							<tr>
								<td class="pr-2"><label for="card_number">Terminal de la tarjeta:</label></td>
								<td><input id="card_number" [(ngModel)]="data.card_number" class="border rounded-md px-2 py-1 border-gray-300 w-full" /></td>
							</tr>
							<tr>
								<td class="pr-2"><label for="authorization_number">Número de aprobación:</label></td>
								<td>
									<input
										id="authorization_number"
										[(ngModel)]="data.authorization_number"
										class="border rounded-md px-2 py-1 border-gray-300 w-full"
									/>
								</td>
							</tr>
							<tr>
								<td class="pr-2"><label for="card_lot">Lote:</label></td>
								<td><input id="card_lot" [(ngModel)]="data.card_lot" class="border rounded-md px-2 py-1 border-gray-300 w-full" /></td>
							</tr>
						</ng-container>
					</ng-container>
				</tbody>
			</table>

			<div class="flex flex-col justify-stretch md:flex-row md:justify-end">
				<div class="flex flex-col gap-4">
					<button (click)="create_order()" class="bg-green-500 text-white font-semibold px-3 py-2 rounded-lg" [disabled]="!data.invoicer || is.working">
						<fa-icon *ngIf="!is.working" icon="save"></fa-icon>
						<fa-icon *ngIf="is.working" icon="spinner" [pulse]="true"></fa-icon>
						{{ !is.working ? 'Crear orden' : 'Creando...' }}
					</button>
				</div>
			</div>
		</div>
	`,
	styles: [],
})
export class CreateOrderComponent {
	data = {
		invoicer: 0,
		document: '',
		name: '',
		address: '',
		balance: 0,
		details: [
			{
				code: '',
				quantity: 1,
			},
		],
		amount_to_pay: 0,
		method: 1,
		cash_received: 0,
		bank: 0,
		payment_reference: null,
		card_number: null,
		authorization_number: null,
		card_lot: null,
	}
	is = {
		querying: false,
		working: false,
	}
	products = {
		0: [],
		4: [
			{ code: '114323', description: 'Mercado móvil (chimi, hotdog y otros)',   price: 1 },
			{ code: '114346', description: 'Permiso para operación de mercados',      price: 1 },
			{ code: '151439', description: 'Casetas fijas y móviles',                 price: 1 },
			{ code: '114337', description: 'Ocupación vía publica comercio informal', price: 1 },
			{ code: '114342', description: 'Construcción: Nichos, Fosas y Panteones', price: 1 },
			{ code: '151314', description: 'Inhumación',                              price: 1 },
			{ code: '151413', description: 'Exhumación',                              price: 1 },
			{ code: '151315', description: 'Certificación Inhumación',                price: 1 },
			{ code: '174301', description: 'Ventas de Terrenos en Cementerios B1',    price: 1 },
			{ code: '174301', description: 'Ventas de Terrenos en Cementerios A',     price: 1 },
			{ code: '174301', description: 'Ventas de Terrenos en Cementerios B2',    price: 1 },
			{ code: '174301', description: 'Ventas de Terrenos en Cementerios B',     price: 1 },
			{ code: '174301', description: 'Ventas de Terrenos en Cementerios C',     price: 1 },
			{ code: '151413', description: 'Certificado Exhumacion',                  price: 1 },
			{ code: '151510', description: 'Alquiler Nichos de Cementerio',           price: 1 },
			{ code: '151510', description: 'Alquiler Terreno de Cementerio',          price: 1 },
			{ code: '114342', description: 'Renovación: Nichos, Fosas y Panteones',   price: 1 },
			{ code: '114342', description: 'Derecho a la construcción Nicho',         price: 1 },
		],
		12: [
			{ code: '1', description: 'Factura regular residencial',  price: 1 },
			{ code: '2', description: 'Factura regular comercial',    price: 1 },
			{ code: '3', description: 'Factura regular industrial',   price: 1 },
			{ code: '4', description: 'Factura regular hotelera',     price: 1 },
			{ code: '5', description: 'Factura derecho construcción', price: 1 },
			{ code: '6', description: 'Factura permiso ingeniería',   price: 1 },
		],
	}
	product(code: string) {
		return this.products[this.data.invoicer].find((p) => p.code == code)
	}
	get total() {
		return sum(this.data.details.map((p) => (this.product(p.code)?.price ?? 0) * p.quantity))
	}

	methods: Item[] = []
	banks: Item[] = []

	constructor(private api: ApiService, private router: Router, active_route: ActivatedRoute) {
		active_route.queryParams.subscribe(({ invoicer_id }) => {
			this.data.invoicer = Number(invoicer_id)
			api.get_point_of_sales_invoicer_payment_methods(State.user.point_of_sales.id, this.data.invoicer, (r) => {
				this.methods = r.data
				if (r.data.some((m) => [2, 8].includes(m.id))) api.get_banks((r) => (this.banks = r.data))
			})
		})
	}

	implemented = [4, 12]

	query() {
		this.is.querying = true
		switch (Number(this.data.invoicer)) {
			case 4:
				this.api.query_asdn(this.data.document, (response) => {
					this.is.querying = false
					if (response.succeeded) {
						const data = response.data
						this.data.name = data.name
						this.data.address = data.address
						this.data.balance = data.total_debt
					} else error_toast(response.error.message)
				})
				break
			case 12:
				this.api.query_inapa(this.data.document, (response) => {
					this.is.querying = false
					if (response.succeeded) {
						const data = response.data
						this.data.name = data.name
						this.data.address = data.contracts[0].address
						this.data.balance = data.contracts[0].total_debt
					} else error_toast(response.error.message)
				})
			default:
				error_toast('No implementado para este facturador.')
				this.is.querying = false
		}
	}

	create_order() {
		this.is.working = true
		State.geolocation((geo) => {
			this.api.create_order(
				{
					...this.data,
					client: {
						document: this.data.document,
						name: this.data.name,
						address: this.data.address,
						phone: null,
					},
					details: this.data.details
						.map((d) => {
							const p = this.product(d.code)
							if (!p) return null
							return {
								code: d.code,
								description: p.description,
								price: p.price,
								quantity: d.quantity,
							}
						})
						.filter((i) => i),
					geo_latitude: geo?.latitude,
					geo_longitude: geo?.longitude,
				},
				(response) => {
					this.is.working = false
					if (response.succeeded) {
						success_toast('Orden creada exitosamente.')
						const id = response.data
						if (id) {
							this.api.get_transaction(id, async ({ succeeded, data: transaction }) => {
								if (succeeded) await PrintService.render('transaction', { transaction })
								this.router.navigateByUrl('/cashier/orders')
							})
						} else {
							this.router.navigateByUrl('/cashier/orders')
						}
					} else {
						error_toast(response.error.message)
					}
				}
			)
		})
	}

	titlecase = titlecase
}
