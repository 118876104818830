<h1>Pago de Facturas</h1>

<div *ngIf="!result" class="mt-8 card w-full">
   <h3 class="text-lg font-semibold border-b">
      Consulta de número telefónico
      <img src="assets/logos/logo_claro.png" class="inline h-5 mb-3">
   </h3>
   <div class="flex flex-col items-stretch md:flex-row flex-wrap mt-6 md:items-baseline">
      <div class="mr-4 font-semibold">Número de teléfono</div>
      <div class="flex-grow">
         <input type="number" inputmode="numeric" [(ngModel)]="phone" (keydown.enter)="query()" [readonly]="working" placeholder="Ingrese el número de teléfono que desea pagar" class="py-2 px-2 md:py-0 w-full rounded border-0 border-b border-gray-300">
      </div>
   </div>
   <button (click)="query()" [disabled]="working" class="btn btn-blue mt-8 w-full md:mt-3 md:w-max">
      {{ !working? 'Consultar' : 'Consultando...' }}
      <fa-icon *ngIf="!working" icon="search"></fa-icon>
      <working *ngIf="working"></working>
   </button>
</div>

<!-- Resultados Consulta -->
<div *ngIf="result" class="mt-8 card w-full">
   <h3 class="text-lg font-semibold border-b mb-4">
      Respuesta consulta de número telefónico 
      <img src="assets/logos/logo_claro.png" alt="" class="inline h-5 mb-3">
   </h3>

   <div class="tabs">
      <div *ngIf="result.postpaid" class="tab" (click)="tab = 0" [class.active]="tab == 0">Postpago</div>
      <div *ngIf="result.prepaid"  class="tab" (click)="tab = 1" [class.active]="tab == 1">Prepago</div>
      <!-- <div class="tab" (click)="tab = 2" [class.active]="tab == 2">Paquetes</div> -->
   </div>

   <!-- Tab Postpago -->
   <div *ngIf="tab == 0" id="tab-0">
      <div class="my-4">
         <h2 class="mb-3 font-semibold">{{ result.name | titlecase }}</h2>
         <div class="mb-2 flex flex-col md:flex-row text-xl">
            <div class="w-48 md:font-semibold">Deuda total:</div>
            <div class="font-semibold md:font-normal">
               {{ result.postpaid.debt_amount | currency:'RD$ ' }}
            </div>
         </div>
         <div class="mb-2 flex flex-col md:flex-row text-lg">
            <div class="w-48 md:font-semibold">Deuda atrasada:</div>
            <div class="font-semibold md:font-normal">
               {{ result.postpaid.debt_late_amount | currency:'RD$ ' }}
            </div>
         </div>
         <div class="flex flex-col md:flex-row">
            <div class="w-48 md:font-semibold">Fecha preferida de pago:</div>
            <div class="font-semibold md:font-normal">
               {{ result.postpaid.date_payment_preferred | date:'EEEE dd MMMM, yyyy':null:'es-DO' | titlecase }}
            </div>
         </div>
      </div>
      <div class="mt-4 border-t pt-4">
         <div class="font-semibold">Monto a pagar</div>
         <div class="flex flex-row">
            <div class="rounded-l border border-gray-400 pl-2 flex flex-row items-baseline">
               <span class="font-semibold">RD$</span>
               <input type="number" [(ngModel)]="post_pay_amount" (keydown.enter)="post_pay_amount? post_pay() : null" class="flex-1 py-1 border-0 focus:outline-none focus:ring-0 text-right w-32" placeholder="0.00" [min]=0 [max]=result.postpaid.debt_amount>
            </div>
            <button (click)="post_pay()" class="btn btn-blue !rounded-l-none" [disabled]="!post_pay_amount || post_pay_amount < result.postpaid.minimum_payment_amount">
               Pagar
               <fa-icon icon="hand-holding-usd"></fa-icon>
            </button>
         </div>
      </div>
   </div>

   <!-- Tab Prepago -->
   <div *ngIf="tab == 1" id="tab-1">
      <div class="my-4">
         <h2 class="mb-3 font-semibold">{{ result.name | titlecase }}</h2>
         <div class="mb-2 flex flex-col md:flex-row text-xl">
            <div class="w-60 md:font-semibold">Balance:</div>
            <div class="font-semibold md:font-normal">
               {{ result.prepaid.balance | currency:'RD$ ' }}
            </div>
         </div>
         <div class="flex flex-col md:flex-row text-lg">
            <div class="w-60 md:font-semibold">Monto mínímo de recarga:</div>
            <div class="font-semibold md:font-normal">
               {{ result.prepaid.minimum_charge_amount | currency:'RD$ ' }}
            </div>
         </div>
      </div>
      <div class="mt-4 border-t pt-4">
         <div class="font-semibold">Monto a recargar</div>
         <div class="flex flex-row">
            <div class="rounded-l border border-gray-400 pl-2 flex flex-row items-baseline">
               <span class="font-semibold">RD$</span>
               <input type="number" [(ngModel)]="pre_pay_amount" (keydown.enter)="pre_pay_amount? pre_pay() : null" class="flex-1 py-1 border-0 focus:outline-none focus:ring-0 text-right w-32" placeholder="0.00" [min]=result.prepaid.minimum_charge_amount>
            </div>
            <button (click)="pre_pay()" class="btn btn-blue !rounded-l-none" [disabled]="!pre_pay_amount || pre_pay_amount < result.prepaid.minimum_charge_amount">
               Recargar
               <fa-icon icon="hand-holding-usd"></fa-icon>
            </button>
         </div>
      </div>
   </div>
</div>