import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Item, MultiPayView } from 'src/app/models/models';
import { error_toast, success_toast } from 'src/app/utils/toast.util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-multipay-configuration',
  templateUrl: './multipay-configuration.component.html',
  styleUrls: []
})
export class MultipayConfigurationComponent {
  multipays: MultiPayView[];
  invoicer_ids: number[] = null;
  invoicers: Item[];
  working = true;

  constructor(private router: Router, private api: ApiService) {
    api.get_multipays(response => {
      if (response.succeeded) {
        this.working = false;
        this.multipays = response.data;
      } else error_toast(response.error.message);
    });
  }

  edit(multipay: MultiPayView) {
    this.router.navigate(['system', 'multipay-configuration', ''+multipay.id]);
  }

  new() {
    this.invoicer_ids = [];
    if (!this.invoicers) {
      this.api.get_invoicers(response => {
        if (response.succeeded)
          this.invoicers = response.data;
        else error_toast(response.error.message);
      });
    }
  }

  includes(invoicer: Item) {
    return this.invoicer_ids.findIndex(id => invoicer.id === id) >= 0;
  }

  toggle(invoicer: Item) {
    const ids: number[] = this.invoicer_ids;
    const index = ids.findIndex(id => invoicer.id === id);
    if (index >= 0)
      ids.splice(index, 1);
    else
      ids.push(invoicer.id);
    environment.debug('ids:', ids);
  }

  create() {
    this.working = true;
    this.api.create_multipay(this.invoicer_ids, response => {
      if (response.succeeded) {
        success_toast('MultiPago creado');
        this.api.get_multipays(response => {
          this.working = false;
          this.invoicer_ids = null;
          if (response.succeeded) {
            this.multipays = response.data;
          } else error_toast(response.error.message);
        });
      } else {
        this.working = false;
        error_toast(response.error.message);
      }
    });
  }
}
