<h1>Puntos de Venta</h1>
<h2 class="text-gray-500">Administración del Sistema</h2>

<working *ngIf="!points_of_sales" color="#3B82F6" [size]="30"></working>

<div *ngIf="points_of_sales" class="mt-8 card w-full space-y-6">
   <div class="md:table md:rounded-lg md:border w-full">
      <div class="hidden md:table-header-group font-semibold text-center text-lg">
         <div class="table-cell py-1 w-0"></div>
         <div class="table-cell py-1 border-l">Nombre</div>
         <div class="table-cell py-1 border-l">Dirección</div>
         <div class="table-cell py-1 border-l w-0"></div>
      </div>
      <div class="md:table-row-group">
         <div *ngIf="!points_of_sales?.length" class="table-row">
            <td title="No existen puntos de venta registrados en el sistema" class="border-t text-center" colspan="3"><fa-icon icon="times"></fa-icon></td>
         </div>
         <div *ngFor="let pos of points_of_sales" class="border rounded-md shadow-md p-2 mb-4 bg-gray-50 md:bg-transparent md:shadow-none md:table-row md:hover:bg-gray-100">
            <div class="hidden md:table-cell md:border-t md:px-2">
               <fa-icon *ngIf="pos.enabled"  icon="store-alt"       class="text-green-700"></fa-icon>
               <fa-icon *ngIf="!pos.enabled" icon="store-alt-slash" class="text-red-600"></fa-icon>
            </div>
            <div class="font-semibold text-lg md:text-base md:font-normal md:table-cell md:border-t md:border-l md:px-2">
               <fa-icon *ngIf="pos.enabled"  icon="store-alt"       class="text-base text-green-700 md:hidden"></fa-icon>
               <fa-icon *ngIf="!pos.enabled" icon="store-alt-slash" class="text-base text-red-600 md:hidden"></fa-icon>
               {{ pos.name }}
            </div>
            <div class="mt-2 md:table-cell md:border-t md:px-2 md:border-l">
               <span *ngIf="pos.address" class="font-semibold md:hidden">Dirección</span>
               {{ pos.address }}
            </div>
            <div class="flex flex-row-reverse md:table-cell md:border-t md:py-1 md:pr-[1px] md:border-l">
               <button (click)="router.navigate(['system', 'points-of-sales', pos.id])" class="block btn w-max" style="padding-top: 2.5px; padding-bottom: 2.5px">
                  <fa-icon icon="edit"></fa-icon>
                  Editar
               </button>
            </div>
         </div>
      </div>
   </div>
   <div class="mt-4 pt-4 border-t">
      <button (click)="router.navigate(['system', 'points-of-sales', 'new'])" class="btn btn-blue">
         <fa-icon icon="plus-circle"></fa-icon>
         Crear nuevo Punto de Venta
      </button>
   </div>
</div>