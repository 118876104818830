<h1>Recarga</h1>

<div class="card mt-8">
   <h3 class="text-lg font-semibold border-b">
      Recarga de número telefónico
      <img src="assets/logos/logo_altice.png" class="inline h-5 mb-3">
   </h3>
   <div class="w-full">
      <div class="flex flex-col items-stretch md:flex-row md:items-baseline md:flex-wrap">
         <div class="w-48">Número de teléfono:</div>
         <input type="number" inputmode="numeric" [(ngModel)]="phone" class="py-1 px-2 border border-gray-300 rounded flex-1 md:flex-auto">
      </div>
      <div class="flex flex-col items-stretch md:flex-row md:items-baseline mt-4">
         <div class="w-48">Monto de recarga:</div>
         <div class="flex flex-row items-baseline flex-1 md:flex-auto">
            <div class="mr-1">RD$ </div>
            <input type="number" [(ngModel)]="amount_to_pay" placeholder="0.00" class="py-1 px-2 border border-gray-300 rounded flex-1">
         </div>
      </div>
   </div>
   <div class="flex flex-row mt-6">
      <button (click)="pay()" class="btn btn-blue">
         <fa-icon icon="hand-holding-usd"></fa-icon>
         Pagar
      </button>
   </div>
</div>