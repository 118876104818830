import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { error_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-legacy',
  templateUrl: './legacy.component.html',
  styleUrls: ['./legacy.component.less']
})
export class LegacyLoginComponent {
  hero = 0;

  constructor(active_route: ActivatedRoute, router: Router, api: ApiService) {
    this.hero = 1 + Math.floor(Math.random() * 2.99);

    active_route.queryParams.subscribe(query => {
      if (query.token) {
        api.legacy_login(query.token, error => {
          if (error) {
            error_toast(error.message);
            router.navigate(['login']);
            error_toast('Inicie sesión manualmente.');
          }
        });
      } else {
        error_toast('Inicie sesión manualmente.\n(Token obligatorio faltante.)');
        router.navigate(['login']);
      }
    });
  }
}
