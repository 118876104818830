import { Component, Input } from '@angular/core';

@Component({
  selector: 'working',
  template: '<div class="loader" style="border-top: solid {{ color }}; width: {{ size }}px; height: {{ size }}px; border-width: {{ size * .25 }}px">',
  styleUrls: [ './working.component.less' ]
})
export class WorkingComponent {
  @Input() color = 'white'
  @Input() size = 14;
}