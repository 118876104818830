import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import {
  ContributorView,
  InvoiceView,
} from 'src/app/models/invoicer_query.models';
import { Item } from 'src/app/models/models';
import { State } from 'src/app/state';
import { error_toast } from 'src/app/utils/toast.util';
import { is_nothing, qs } from 'src/app/utils/utils';
import { invoicer_queriable_to_item } from '../payments.component';

@Component({
  selector: 'app-windtelecom',
  templateUrl: './windtelecom.component.html',
  styleUrls: ['./windtelecom.component.less'],
})
export class WindTelecomComponent {
  id: string;
  working = false;
  result: ContributorView;

  get partial_payments(): boolean {
    return State.active_invoicer.partial_payments;
  }

  payment_policy: Item;

  constructor(private api: ApiService, private router: Router) {
    if (
      is_nothing(State.active_invoicer) ||
      State.active_invoicer.name.toLowerCase() != 'windtelecom'
    )
      router.navigate(['cashier', 'pay']);
    else if (State.preloaded_query) {
      this.id = State.preloaded_query.id;
      this.result = State.preloaded_query.data;
      State.preloaded_query = null;
    }
    this.payment_policy = State.active_invoicer.invoice_payment_policy;
  }

  query() {
    this.working = true;
    this.api.query_windtelecom(
      (qs('#document') as HTMLInputElement)?.value,
      (response) => {
        this.working = false;
        if (response.succeeded) {
          this.result = response.data;
        } else error_toast(response.error.message);
      }
    );
  }

  // Selectable invoices
  selectable_invoices: SelectableInvoice[] = [];
  selectable(invoice: InvoiceView): SelectableInvoice {
    var sel_inv: SelectableInvoice = this.selectable_invoices.find(
      (i) => i.id === invoice.id
    );
    if (sel_inv) return sel_inv;
    else {
      const component: WindTelecomComponent = this;
      let selected = true;
      let amount_to_pay = invoice.amount;
      sel_inv = {
        id: invoice.id,
        get selected(): boolean {
          return selected;
        },
        set selected(value: boolean) {
          if (component.payment_policy.id === 2) {
            const issue_date = new Date(invoice.issue_date);
            if (value) {
              component.selectable_invoices
                .filter((si) => new Date(si.invoice.issue_date) < issue_date)
                .reverse()
                .forEach((si) => {
                  si.selected = true;
                  si.amount_to_pay = si.invoice.amount;
                });
            } else {
              component.selectable_invoices
                .filter(
                  (si) =>
                    si !== sel_inv &&
                    new Date(si.invoice.issue_date) >= issue_date &&
                    si.selected
                )
                .reverse()
                .forEach((si) => {
                  si.selected = false;
                });
            }
          }
          selected = value;
        },
        invoice,
        get amount_to_pay(): number {
          return amount_to_pay;
        },
        set amount_to_pay(value: number) {
          if (component.payment_policy.id === 2) {
            if (value < invoice.amount) {
              const issue_date = new Date(invoice.issue_date);
              component.selectable_invoices
                .filter(
                  (si) =>
                    si !== sel_inv &&
                    si.selected &&
                    new Date(si.invoice.issue_date) >= issue_date
                )
                .reverse()
                .forEach((si) => {
                  si.selected = false;
                });
            }
          }
          amount_to_pay = value;
        },
      };
      this.selectable_invoices.push(sel_inv);
      return sel_inv;
    }
  }
  get selected(): SelectableInvoice[] {
    return this.selectable_invoices.filter((s) => s.selected);
  }

  pay() {
    State.payment_package = [
      {
        name: this.result.name,
        document: this.result.document,
        invoicer: invoicer_queriable_to_item(State.active_invoicer),
        type: 1,
        payloads: this.selected.map((s) => ({
          invoice_id: s.id,
          concept: s.invoice.concept,
          amount: s.amount_to_pay,
        })),
      },
    ];
    this.router.navigate(['cashier', 'apply-payment']);
  }
}

interface SelectableInvoice {
  id: string;
  invoice: InvoiceView;
  selected: boolean;
  amount_to_pay: number;
}
