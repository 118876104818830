<h1>Información de Organización</h1>

<div id="printing-frames" class="invisible fixed top-0 insex-x-0 z-[-10]"></div>

<working *ngIf="!operation" color="#3B82F6" [size]="30"></working>

<div *ngIf="operation" class="mt-8 card w-full space-y-6">
   <h3 class="border-b font-semibold">Datos de la organización</h3>
   <div class="border-b pb-1">
      <h2 class="font-semibold">{{ operation?.name }}</h2>
      <h3>{{ operation?.address }}</h3>
   </div>
   <h3 class="font-semibold">Usuarios</h3>
   <div class="w-full md:table md:rounded-lg md:border">
      <div class="hidden md:table-header-group font-semibold text-center text-lg">
         <div class="table-cell py-1">Usuario</div>
         <div class="table-cell py-1 border-l">Nombres</div>
         <div class="table-cell py-1 border-l">Último inicio sesion</div>
      </div>
      <div class="md:table-row-group">
         <div *ngIf="!operation?.users?.length" class="md:table-row">
            <td title="No existen usuarios registrados para este punto de venta" colspan="4" class="border-t text-center text-gray-500">
               <fa-icon icon=times></fa-icon>
            </td>
         </div>
         <div *ngFor="let user of operation?.users" class="border border-gray-200 rounded-lg mb-2 flex flex-row bg-gray-100 md:bg-transparent items-center md:table-row">
            <div class="px-3 md:hidden"><fa-icon icon="user"></fa-icon></div>
            <div class="md:contents">
               <div class="flex flex-row md:contents">
                  <div class="flex flex-row-reverse md:contents">
                     <div class="ml-2 font-mono md:table-cell md:py-1 md:px-2 md:border-t">
                        <span class="md:hidden">('</span>{{ user.username }}<span class="md:hidden">')</span>
                     </div>
                     <div class="md:table-cell md:py-1 md:px-2 md:border-t md:border-l">{{ user.name }}</div>
                  </div>
               </div>
               <div class="md:table-cell md:py-1 md:px-2 md:border-t md:border-l md:text-right">
                  <span class="font-semibold md:hidden">Último inicio sesion:</span>
                  {{ user.date_last_login | date:'hh:mm aa dd/MMM/yy':null:'es-DO' }}
               </div>
            </div>
         </div>
      </div>
   </div>
   <hr>
   <h3 class="font-semibold">Lotes Abiertos</h3>
   <div class="md:table md:rounded-lg md:border md:w-full">
      <div class="hidden md:table-header-group font-semibold text-center text-lg">
         <div class="table-cell py-1"></div>
         <div class="table-cell py-1 border-l">Punto de Venta</div>
         <div class="table-cell py-1 border-l">Cajero</div>
         <div class="table-cell py-1 border-l" title="Inicio del Lote">Inicio Lote</div>
         <div class="table-cell py-1 border-l" title="Cantidad de Transacciones">Cant. Transacciones</div>
         <th  class="table-cell py-1 border-l" colspan=2>Total Cobrado</th>
         <div class="table-cell border-l w-0"></div>
      </div>
      <div class="md:table-row-group">
         <div *ngIf="!operation?.active_lots?.length" class="md:table-row">
            <td title="No existen lotes abiertos en este punto de venta" colspan="8" class="border-t text-center text-gray-500">
               <fa-icon icon=times></fa-icon>
            </td>
         </div>
         <div *ngFor="let lot of operation?.active_lots" class="border border-gray-200 rounded-lg mb-2 p-2 md:table-row {{ is_today(lot.date_opened)? 'bg-gray-100 md:bg-transparent hover:bg-gray-100' : 'bg-red-50 hover:bg-red-100' }}">
            <div class="font-bold md:table-cell md:py-1 md:px-2 md:border-t md:font-normal">{{ lot.id }}</div>
            <div class="flex flex-row md:table-cell md:py-1 md:px-2 md:border-t md:border-l">
               <div class="flex-1 font-semibold md:hidden">Punto de Venta:</div>
               <fa-icon icon="store-alt" class="text-gray-500 text-sm mr-1"></fa-icon>
               {{ lot.point_of_sales?.name }}
            </div>
            <div class="flex flex-row md:table-cell md:py-1 md:px-2 md:border-t md:border-l">
               <div class="flex-1 font-semibold md:hidden">Cajero:</div>
               <fa-icon icon="user" class="text-gray-500 text-sm mr-1"></fa-icon>
               {{ lot.user?.name }}
            </div>
            <div class="flex flex-row md:table-cell md:py-1 md:px-2 md:border-t md:border-l md:text-right">
               <div class="flex-1 font-semibold md:hidden">Inicio del lote: </div>
               {{ lot.date_opened | date:'hh:mm aa dd/MMM/yy' }}
            </div>
            <div class="flex flex-row md:table-cell md:py-1 md:px-2 md:border-t md:border-l md:text-right">
               <div class="flex-1 font-semibold md:hidden">Cant. de transacciones: </div>
               {{ lot.transaction_count }}
            </div>
            <div class="flex flex-row md:contents md:text-right">
               <div class="flex-1 font-semibold mr-1 md:hidden">Total cobrado:</div>
               <div class="md:table-cell md:py-1 md:px-2 md:border-t md:border-l font-mono"><span *ngIf="lot.total_charged < 0">-</span>RD$</div>
               <div class="md:table-cell md:py-1 md:px-2 md:border-t w-0 min-w-max font-mono">{{ (lot.total_charged < 0? -lot.total_charged : lot.total_charged) | currency:' ' }}</div>
            </div>
            <div class="flex flex-row-reverse mt-1 md:table-cell md:border-t md:border-l">
               <div class="flex flex-row min-w-max">
                  <button (click)="see(lot)" [disabled]="working" class="btn btn-blue" [class.hidden]="closing === lot.id">
                     <fa-icon icon="file-alt"></fa-icon>
                     Detalles
                  </button>
                  <button (click)="go_to_map(lot)" [disabled]=working class="btn mx-[2px]" [class.hidden]="closing === lot.id">
                     <span>Mapa <span class="hidden md:inline mr-1">cobros</span></span>
                     <fa-icon icon="map-marked-alt"></fa-icon>
                  </button>
                  <div *ngIf="closing === lot.id" style="flex-grow: 1;"></div>
                  <button (click)="close(lot)" [disabled]="working || closing" class="btn btn-yellow w-max" disabled>
                     <fa-icon *ngIf="closing !== lot.id" icon="calendar-check"></fa-icon>
                     {{  closing === lot.id ? 'Cerrando lote...' : 'Cerrar' }}
                     <working *ngIf="closing === lot.id" color="black"></working>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>