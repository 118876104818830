import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './login/login.component'
import { ClosureComponent } from './main/cashier/closure/closure.component'
import { ApplyPaymentComponent } from './main/cashier/apply-payment/apply-payment.component'
import { ClaroComponent } from './main/cashier/payments/claro/claro.component'
import { EdeComponent } from './main/cashier/payments/edes/ede.component'
import { AsdnComponent } from './main/cashier/payments/asdn/asdn.component'
import { PaymentsComponent } from './main/cashier/payments/payments.component'
import { ReportComponent } from './main/cashier/report/report.component'
import { EmailComponent } from './main/help/email/email.component'
import { FaqComponent } from './main/help/faq/faq.component'
import { PhonesComponent } from './main/help/phones/phones.component'
import { WhatsappComponent } from './main/help/whatsapp/whatsapp.component'
import { HomeComponent } from './main/home/home.component'
import { MainComponent } from './main/main.component'
import { OperationInfoComponent } from './main/supervisor/op-info/op-info.component'
import { PointsOfSalesComponent } from './main/system/points-of-sales/points-of-sales.component'
import { PosConfigurationComponent } from './main/system/points-of-sales/pos-configuration/pos-configuration.component'
import { NullificationComponent } from './main/cashier/nullification/nullification.component'
import { PendingNullificationsComponent } from './main/supervisor/pending-nullifications/pending-nullifications.component'
import { PrintConfigurationComponent } from './main/user/print-configuration/print-configuration.component'
import { MultipayConfigurationComponent } from './main/system/multipay-configuration/multipay-configuration.component'
import { MultipayPointsOfSalesComponent } from './main/system/multipay-configuration/multipay-points-of-sales/multipay-points-of-sales.component'
import { MultipayComponent } from './main/cashier/payments/multipay/multipay.component'
import { UsersComponent } from './main/system/users/users.component'
import { OrganizationsComponent } from './main/system/organizations/organizations.component'
import { PasswordChangeComponent } from './main/user/password-change/password-change.component'
import { LotHistoryComponent as SupervisorLotHistoryComponent } from './main/supervisor/lot-history/lot-history.component'
import { LotHistoryComponent as CashierLotHistoryComponent } from './main/cashier/lot-history/lot-history.component'
import { LotReportComponent } from './main/supervisor/lot-history/lot-report/lot-report.component'
import { UserConfigurationComponent } from './main/system/users/user-configuration/user-configuration.component'
import { LegacyLoginComponent } from './login/legacy/legacy.component'
import { QueuedPaymentsComponent } from './main/user/queued-payments/queued-payments.component'
import { LotCartographyComponent } from './main/supervisor/geo/lot-cartography/lot-cartography.component'
import { TfaConfigurationComponent } from './main/supervisor/tfa-configuration/tfa-configuration.component'
import { CaasdComponent } from './main/cashier/payments/caasd/caasd.component'
import { UserLogsComponent } from './main/system/users/user-logs/user-logs.component'
import { PostponedPaymentsComponent } from './main/supervisor/postponed-payments/postponed-payments.component'
import { AlticeComponent } from './main/cashier/payments/altice/altice.component'
import { ContractBundlesComponent } from './main/cashier/bundles/contract-bundles/contract-bundles.component'
import { CreateEditContractBundleComponent } from './main/cashier/bundles/create-edit-contract-bundle/create-edit-contract-bundle.component'
import { PayContractBundleComponent } from './main/cashier/bundles/pay-contract-bundle/pay-contract-bundle.component'
import { ContractBundleHistoryComponent } from './main/cashier/bundles/contract-bundle-history/contract-bundle-history.component'
import { ContractBundlePaymentComponent } from './main/cashier/bundles/contract-bundle-payment/contract-bundle-payment.component'
import { VivaComponent } from './main/cashier/payments/viva/viva.component'
import { QuotaComponent } from './main/cashier/payments/quota/quota.component'
import { AlvComponent } from './main/cashier/payments/alv/alv.component'
import { NatcomComponent } from './main/cashier/payments/natcom/natcom.component'
import { DigicelComponent } from './main/cashier/payments/digicel/digicel.component'
import { WindTelecomComponent } from './main/cashier/payments/windtelecom/windtelecom.component'
import { CreateOrderComponent } from './main/cashier/orders/create-order.component'
import { OrdersComponent } from './main/cashier/orders/orders.component'
import { NaguaComponent } from './main/cashier/payments/nagua/nagua.component'
import { SamanaComponent } from './main/cashier/payments/samana/samana.component'

const routes: Routes = [
	{ path: 'login', component: LoginComponent },
	{ path: 'migration', component: LegacyLoginComponent },
	{
		path: '',
		component: MainComponent,
		children: [
			{ path: 'home', component: HomeComponent },
			{
				path: 'cashier',
				children: [
					{
						path: 'pay',
						children: [
							{ path: '', component: PaymentsComponent },
							{ path: 'multipay', component: MultipayComponent },
							{ path: 'edeeste', component: EdeComponent },
							{ path: 'edenorte', component: EdeComponent },
							{ path: 'edesur', component: EdeComponent },
							{ path: 'asdn', component: AsdnComponent },
							{ path: 'nagua', component: NaguaComponent },
							{ path: 'samana', component: SamanaComponent },
							{ path: 'alv', component: AlvComponent },
							{ path: 'claro', component: ClaroComponent },
							{ path: 'caasd', component: CaasdComponent },
							{ path: 'altice', component: AlticeComponent },
							{ path: 'viva', component: VivaComponent },
							{ path: 'jorem', component: QuotaComponent },
							{ path: 'rancier', component: QuotaComponent },
							{ path: 'inapa', component: EdeComponent },
							{ path: 'natcom', component: NatcomComponent },
							{ path: 'digicel', component: DigicelComponent },
							{ path: 'ppe', component: EdeComponent },
							{ path: 'windtelecom', component: WindTelecomComponent },
							{ path: '**', redirectTo: '', pathMatch: 'prefix' },
						],
					},
					{ path: 'apply-payment', component: ApplyPaymentComponent },
					{
						path: 'report',
						children: [
							{ path: '', component: ReportComponent },
							{ path: ':id', component: ReportComponent },
						],
					},
					{ path: 'nullification', component: NullificationComponent },
					{ path: 'closure', component: ClosureComponent },
					{ path: 'lots', component: CashierLotHistoryComponent },
					{ path: 'bundles', component: ContractBundlesComponent },
					{ path: 'bundles/:id', component: CreateEditContractBundleComponent },
					{ path: 'bundles/:id/history', component: ContractBundleHistoryComponent },
					{ path: 'bundles/:id/pay', component: PayContractBundleComponent },
					{ path: 'bundles/:bundle_id/payment/:payment_id', component: ContractBundlePaymentComponent },
					{ path: 'orders', component: OrdersComponent },
					{ path: 'orders/create', component: CreateOrderComponent },
				],
			},
			{
				path: 'supervisor',
				children: [
					{ path: 'info', component: OperationInfoComponent },
					{ path: 'nullifications', component: PendingNullificationsComponent },
					{
						path: 'lots',
						children: [
							{ path: '', component: SupervisorLotHistoryComponent },
							{ path: ':id', component: LotReportComponent },
						],
					},
					{ path: 'postponed', component: PostponedPaymentsComponent },
					{
						path: 'geo',
						children: [{ path: 'lot/:id', component: LotCartographyComponent }],
					},
					{ path: '2fa', component: TfaConfigurationComponent },
				],
			},
			{
				path: 'system',
				children: [
					{
						path: 'points-of-sales',
						children: [
							{ path: '', component: PointsOfSalesComponent },
							{ path: ':id', component: PosConfigurationComponent },
						],
					},
					{
						path: 'users',
						children: [
							{ path: '', component: UsersComponent },
							{ path: 'logs', component: UserLogsComponent },
							{ path: ':id', component: UserConfigurationComponent },
						],
					},
					{ path: 'organizations', component: OrganizationsComponent },
					{
						path: 'multipay-configuration',
						children: [
							{ path: '', component: MultipayConfigurationComponent },
							{ path: ':id', component: MultipayPointsOfSalesComponent },
						],
					},
				],
			},
			{
				path: 'user',
				children: [
					{ path: 'password-change', component: PasswordChangeComponent },
					{
						path: 'print-configuration',
						component: PrintConfigurationComponent,
					},
					{ path: 'queued-payments', component: QueuedPaymentsComponent },
				],
			},
			{
				path: 'help',
				children: [
					{ path: 'faq', component: FaqComponent },
					{ path: 'whatsapp', component: WhatsappComponent },
					{ path: 'phones', component: PhonesComponent },
					{ path: 'email', component: EmailComponent },
				],
			},
			{ path: '**', redirectTo: '/home', pathMatch: 'prefix' },
		],
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
