const duration = 5000;

function toast(message, class_name) {
   const toastdock = document.getElementById('toast-dock');
   toastdock.classList.add('showing');
   const toast = document.createElement('div');
   toast.className = 'toast ' + class_name;
   toast.innerText = message;
   toastdock.appendChild(toast);
   setTimeout(() => {
      if (toast.parentElement === toastdock)
         toastdock.removeChild(toast);
      if (toastdock.childElementCount === 0)
         toastdock.classList.remove('showing');
   }, duration);
}

export function error_toast(message: string) {
   toast(message, 'error');
}

export function success_toast(message: string) {
   toast(message, 'success');
}

export function warning_toast(message: string) {
   toast(message, 'warning');
}