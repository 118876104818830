<h1>{{ new_org? 'Nueva ' : '' }}{{ active_item? 'Unidad Organizacional' : 'Unidades Organizacionales' }}</h1>

<working *ngIf="!organizations" color="#3B82F6" [size]="30"></working>

<h2 *ngIf="active_item" class="text-gray-500">{{ active_item?.name }}</h2>

<div *ngIf="organizations && !active_item" class="card mt-8">
   <div class="table rounded-md border w-full">
      <div class="table-header-group font-semibold text-lg">
         <div class="table-cell py-1 px-2">Nombre</div>
         <div class="table-cell py-1 border-l w-0"></div>
      </div>
      <div class="table-row-group">
         <div *ngFor="let organization of organizations" class="table-row hover:bg-gray-100">
            <div class="table-cell border-t py-1 px-2">{{ organization.name }}</div>
            <div class="table-cell border-t border-l">
               <div class="w-max mx-auto">
                  <button (click)="edit(organization)" title="Editar" class="btn" style="padding-top: 2.5px; padding-bottom: 2.5px">
                     <fa-icon icon="edit"></fa-icon>
                     Editar
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="mt-4 pt-4 border-t">
      <button (click)="new()" class="btn btn-blue">
         <fa-icon icon="plus-circle"></fa-icon>
         Crear nueva Unidad Organizacional
      </button>
   </div>
</div>

<div *ngIf="active_item" class="card mt-8">
   <div class="flex flex-col">
      <div class="flex flex-row items-baseline">
         <div class="font-semibold pr-4">Nombre</div>
         <input [(ngModel)]="active_item.name" type="text" class="border-gray-300 rounded-md py-1 w-80">
      </div>
      <div class="flex flex-col md:flex-row items-stretch mt-6">
         <div class="flex-1 flex flex-col mx-2 mb-4 md:mb-0">
            <h3 class="font-semibold text-center">
               <fa-icon icon="link"></fa-icon>
               Puntos de Venta asociados
            </h3>
            <div class="border border-gray-300 rounded-lg p-3 mt-2 flex-1 max-h-[400px] space-y-3 overflow-y-auto">
               <div *ngFor="let pos of active_item.points_of_sales" (click)="exclude_pos(pos)" class="border rounded-lg py-1 px-2 shadow cursor-pointer hover:shadow-md hover:bg-gray-100 active:bg-gray-200">
                  {{ pos.name }}
                  <fa-icon icon="chevron-right" class="float-right text-red-500"></fa-icon>
               </div>
            </div>
         </div>
         <div class="flex-1 flex flex-col mx-2">
            <h3 class="font-semibold text-center">
               <fa-icon icon="store"></fa-icon>
               Puntos de Venta
            </h3>
            <div class="border border-gray-300 rounded-lg p-3 mt-2 flex-1 max-h-[400px] space-y-3 overflow-y-auto">
               <div *ngFor="let pos of points_of_sales" (click)="include_pos(pos)" class="border rounded-lg py-1 px-2 shadow cursor-pointer hover:shadow-md hover:bg-gray-100 active:bg-gray-200">
                  <fa-icon icon="chevron-left" class="text-green-600 mr-4"></fa-icon>
                  {{ pos.name }}
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="mt-4 pt-4 border-t">
      <button (click)="active_item = null" [disabled]="working" class="btn">
         <fa-icon icon="arrow-left"></fa-icon>
         Volver
      </button>
      <button (click)="save()" [disabled]="working" class="ml-2 btn btn-blue">
         <fa-icon icon="save"></fa-icon>
         {{ !working? 'Guardar' : 'Guardando...' }}
         <working *ngIf="working"></working>
      </button>
   </div>
</div>