import { Component } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { State } from 'src/app/state';
import { error_toast, success_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-tfa-configuration',
  templateUrl: './tfa-configuration.component.html',
  styleUrls: []
})
export class TfaConfigurationComponent {
  key: string;
  code: string;

  get otpauth_uri(): string { return `otpauth://totp/VirtualPoS?secret=${this.key}&issuer=Transneg%20SRL`; }

  constructor(private api: ApiService) {
    api.get_point_of_sales_totp_key(State.user.point_of_sales.id, response => {
      if (response.succeeded) {
        this.key = response.data;
      } else error_toast(response.error.message);
    });
  }

  copy_key() {
    window.navigator.clipboard.writeText(this.key);
    success_toast('Clave copiada...');
  }

  test() {
    this.api.test_point_of_sales_totp(State.user.point_of_sales.id, this.code.toString(), response => {
      if (response.succeeded) {
        if (response.data)
          success_toast('Correcto!');
        else
          error_toast('Incorrecto');
      } else error_toast(response.error.message);
    });
  }
}
