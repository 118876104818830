import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import {
	EdeClientView,
	EdePrepaidClientView,
	ContributorView,
	QuotaContractView,
	QuotaContractDetailView,
	ClaroClientView,
	CAASDClientView,
	PaymentOrderView,
} from './models/invoicer_query.models'
import {
	LotReport,
	ApiError,
	ApiResponse,
	LoginResponse,
	LotView,
	MultiPayDetailedView,
	MultiPayView,
	NullificationRequestView,
	OrganizationDetailedView,
	PaymentRequest,
	PaymentResult,
	PaymentView,
	PointOfSalesDetailedView,
	OperationOverview,
	PointOfSalesView,
	TransactionDetailedView,
	TransactionView,
	UserDetailedView,
	UserNullificationRequestView,
	UserView,
	Item,
	PostponedPaymentView,
	InvoicerQueriableView,
	MultiPayQueriableView,
	GeolocationView,
	ApiCallback,
	AuthLog,
	PostponedPaymentDetailedView,
	BundleSummary,
	Bundle,
	BundleDetails,
	BundlePaymentHistory,
	BundlePaymentRequest,
	BundlePayment,
	ZoneSummary,
	OrderRequest,
	OrderView,
	OrderDetailedView,
} from './models/models'
import { OfflineDatabase } from './offline_db'
import { State } from './state'
import { error_toast } from './utils/toast.util'
import { is_nothing } from './utils/utils'

const api = environment.api

let offset = 0

@Injectable()
export class ApiService {
	constructor(private http: HttpClient, private router: Router) {}

	get header(): any {
		if (is_nothing(State.auth_token)) return {}
		return { Authorization: State.auth_token }
	}

	private get<T>(url: string, callback: ApiCallback<T>) {
		environment.debug('api get to "' + url + '"')
		if (State.online) {
			this.http.get<ApiResponse<T>>(url, { headers: this.header }).subscribe((response) => {
				environment.debug('api get to "' + url + '" response:', response.data)
				callback(response)
			}, this.handle_error(url, null, 'get', callback))
		} else {
			const error = {
				succeeded: false,
				data: null,
				error: { code: 'OFFLINE', message: 'Sin conexión' },
			}
			environment.error('api get to "' + url + '" error:', error)
			callback(error)
		}
	}

	private post<T>(url: string, body: any, callback: ApiCallback<T>) {
		environment.debug('api post to "' + url + '":', body)
		if (State.online) {
			this.http.post<ApiResponse<T>>(url, body, { headers: this.header }).subscribe((response) => {
				environment.debug('api post to "' + url + '" response:', response.data)
				callback(response)
			}, this.handle_error(url, body, 'post', callback))
		} else {
			const error = {
				succeeded: false,
				data: null,
				error: { code: 'OFFLINE', message: 'Sin conexión' },
			}
			environment.error('api get to "' + url + '" error:', error)
			callback(error)
		}
	}

	private handle_error<T>(url: string, body: any, method: 'get' | 'post', callback: ApiCallback<T>) {
		return (err) => {
			if (err.status === 504 || err.status === 0) {
				State.connection_lost()
				environment.error(err)
				if (State.on_cordova) {
					callback({
						succeeded: false,
						data: null,
						error: { code: 'UNKNOWN', message: err.error },
					})
					return
				}
				if (method === 'get') this.get<T>(url, callback)
				else this.post<T>(url, body, callback)
			} else if (err.error) {
				const error: ApiResponse<T> = err.error
				environment.error('api ' + method + ' to "' + url + '" error:', error.error ?? error)
				if (error.error?.code === 'NOT_AUTHENTICATED') {
					State.clear()
					OfflineDatabase.drop()
					this.router.navigate(['login'])
				}
				callback(
					error.error
						? error
						: {
								succeeded: false,
								data: null,
								error: { code: 'UNKNOWN', message: <any>error },
						  }
				)
			} else {
				const error = { code: 'UNKNOWN', message: err.message }
				environment.error('api ' + method + ' to "' + url + '" error:', error)
				callback({ succeeded: false, data: null, error })
			}
		}
	}

	private handle_cached<T>(url: string, data: T, callback: ApiCallback<T>) {
		environment.debug('api get to "' + url + '" [offline]')
		const cached = !is_nothing(data)
		const response: ApiResponse<T> = {
			succeeded: cached,
			data,
			error: cached ? null : { code: 'OFFLINE', message: 'No disponible' },
		}
		if (cached) environment.debug('api get to "' + url + '" response: [cached]', response.data)
		else environment.error('api get to "' + url + '" error: [cached]', response.error)
		callback?.(response)
	}

	// =====================
	// ==== CPE QUERIES ====
	// =====================

	query_edeeste(query: string, callback: ApiCallback<EdeClientView>) {
		this.get(api('query', 'edeeste', query), callback)
	}

	query_edenorte(query: string, callback: ApiCallback<EdeClientView>) {
		this.get(api('query', 'edenorte', query), callback)
	}

	query_edesur(query: string, callback: ApiCallback<EdeClientView>) {
		this.get(api('query', 'edesur', query), callback)
	}

	query_edesur_prepaid(nic: string, callback: ApiCallback<EdePrepaidClientView>) {
		this.get(api('query', 'edesur', 'prepaid', nic), callback)
	}

	query_ppe(query: string, callback: ApiCallback<EdeClientView>) {
		this.get(api('query', 'ppe', query), callback)
	}

	query_edenorte_prepaid(nic: string, callback: ApiCallback<EdePrepaidClientView>) {
		this.get(api('query', 'edenorte', 'prepaid', nic), callback)
	}

	query_asdn(contributor_id: string, callback: ApiCallback<ContributorView>) {
		this.get(api('query', 'asdn', contributor_id), callback)
	}

	query_nagua(contributor_id: string, callback: ApiCallback<ContributorView>) {
		this.get(api('query', 'nagua', contributor_id), callback)
	}

	query_samana(contributor_id: string, callback: ApiCallback<ContributorView>) {
		this.get(api('query', 'ams', contributor_id), callback)
	}

	query_alv(contributor_id: string, callback: ApiCallback<ContributorView>) {
		this.get(api('query', 'alv', contributor_id), callback)
	}

	query_claro(phone_number: string, callback: ApiCallback<ClaroClientView>) {
		this.get(api('query', 'claro', phone_number), callback)
	}

	query_caasd(contract: string, callback: ApiCallback<CAASDClientView>) {
		this.get(api('query', 'caasd', contract), callback)
	}

	query_inapa(contract: string, callback: ApiCallback<EdeClientView>) {
		console.warn(contract)
		this.get(api('query', 'inapa', contract), callback)
	}

	query_windtelecom(contributor_id: string, callback: ApiCallback<ContributorView>) {
		this.get(api('query', 'asdn', contributor_id), callback)
	}

	query_jorem_contracts(
		callback: ApiCallback<{
			contracts: QuotaContractView[]
			postponed_payments: PostponedPaymentView[]
			contracts_paid_this_month: string[]
		}>
	) {
		this.get(api('query', 'jorem', 'all'), callback)
	}

	query_jorem(contract: string, callback: ApiCallback<QuotaContractDetailView>) {
		this.get(api('query', 'jorem', contract), callback)
	}

	query_rancier_contracts(
		callback: ApiCallback<{
			contracts: QuotaContractView[]
			postponed_payments: PostponedPaymentView[]
			contracts_paid_this_month: string[]
		}>
	) {
		this.get(api('query', 'rancier', 'all'), callback)
	}

	query_rancier(contract: string, callback: ApiCallback<QuotaContractDetailView>) {
		this.get(api('query', 'rancier', contract), callback)
	}

	query_order_alv(reference: string, callback: ApiCallback<PaymentOrderView>) {
		this.get(api('query', 'order', 'alv', reference), callback)
	}

	query_order_asdn(reference: string, callback: ApiCallback<PaymentOrderView>) {
		this.get(api('query', 'order', 'asdn', reference), callback)
	}

	query_order_nagua(reference: string, callback: ApiCallback<PaymentOrderView>) {
		this.get(api('query', 'order', 'nagua', reference), callback)
	}

	query_order_samana(reference: string, callback: ApiCallback<PaymentOrderView>) {
		this.get(api('query', 'order', 'ams', reference), callback)
	}

	// ===============
	// === QUERIES ===
	// ===============

	// ## User ##
	authenticate(callback: ApiCallback<UserView>) {
		this.get<UserView>(api('authenticate'), (response) => {
			if (response.error?.code === 'OTP_REQUIRED') {
				State.clear()
				this.router.navigate(['login'])
			}
			callback(response)
		})
	}

	get_users(callback: ApiCallback<UserDetailedView[]>) {
		this.get(api('users'), callback)
	}

	get_user(id: string, callback: ApiCallback<UserDetailedView>) {
		this.get(api('user', id), callback)
	}

	get_user_roles(callback: ApiCallback<Item[]>) {
		this.get(api('user_roles'), callback)
	}

	// ## Point of Sales ##
	get_points_of_sales(callback: ApiCallback<PointOfSalesView[]>) {
		this.get(api('points_of_sales'), callback)
	}

	get_point_of_sales(id: number, callback: ApiCallback<PointOfSalesDetailedView>) {
		this.get(api('point_of_sales', id), callback)
	}

	// ## Operation ##
	get_operation_overview(callback: ApiCallback<OperationOverview>) {
		this.get(api('operation', 'overview'), callback)
	}

	// ## Organization ##
	get_organizations(callback: ApiCallback<OrganizationDetailedView[]>) {
		this.get(api('organizations'), callback)
	}

	// ## Invoicer ##
	get_invoicers(callback: ApiCallback<Item[]>) {
		this.get(api('invoicers'), callback)
	}

	get_point_of_sales_invoicers(id: number, callback: ApiCallback<Item[]>) {
		this.get(api('point_of_sales', id, 'invoicers'), callback)
	}

	// ## Payment Method ##
	get_payment_methods(callback: ApiCallback<Item[]>) {
		this.get(api('payment_methods'), callback)
	}

	get_point_of_sales_invoicer_payment_methods(point_of_sales_id: number, invoicer_id: number, callback: ApiCallback<Item[]>) {
		this.get<Item[]>(api('point_of_sales', point_of_sales_id, 'invoicer', invoicer_id, 'payment_methods'), (response) => {
			if (response.error?.code === 'OFFLINE') {
				this.handle_cached(
					api('point_of_sales', point_of_sales_id, 'invoicer', invoicer_id, 'payment_methods'),
					State.get_payment_methods(invoicer_id),
					callback
				)
			} else {
				if (response.succeeded) State.cache_payment_methods(invoicer_id, response.data)
				callback?.(response)
			}
		})
	}

	// ## Nullification Request ##
	get_user_nullification_requests(callback: ApiCallback<UserNullificationRequestView[]>) {
		this.get(api('nullification_requests'), callback)
	}

	get_operation_nullification_requests(callback: ApiCallback<NullificationRequestView[]>) {
		this.get(api('operation', 'nullifications'), callback)
	}

	// ## Multipays ##
	get_multipays(callback: ApiCallback<MultiPayView[]>) {
		this.get(api('multipays'), callback)
	}

	get_multipay(id: number, callback: ApiCallback<MultiPayDetailedView>) {
		this.get(api('multipay', id), callback)
	}

	get_point_of_sales_multipays(id: number, callback: ApiCallback<MultiPayView[]>) {
		this.get(api('point_of_sales', id, 'multipays'), callback)
	}

	get_point_of_sales_queriables(
		id: number,
		callback: ApiCallback<{
			invoicers: InvoicerQueriableView[]
			multipays: MultiPayQueriableView[]
		}>
	) {
		const was_online = State.online
		this.get<{
			invoicers: InvoicerQueriableView[]
			multipays: MultiPayQueriableView[]
		}>(api('point_of_sales', id, 'queriables'), (response) => {
			if (response.error?.code === 'OFFLINE') {
				const url = api('point_of_sales', id, 'queriables')
				environment.debug('api get to "' + url + '" [offline]')
				const cached = !is_nothing(State.invoicers)
				const response: ApiResponse<{
					invoicers: InvoicerQueriableView[]
					multipays: MultiPayQueriableView[]
				}> = {
					succeeded: cached,
					data: { invoicers: State.invoicers, multipays: [] },
					error: cached ? null : { code: 'OFFLINE', message: 'No disponible' },
				}
				if (cached) environment.debug('api get to "' + url + '" response: [cached]', response.data)
				else environment.error('api get to "' + url + '" error: [cached]', response.error)
				if (response.succeeded && was_online) error_toast('Sin conexión')
				callback?.(response)
			} else {
				if (response.succeeded) console.warn(Object.keys(response.data.invoicers))
				State.invoicers = response.data.invoicers
				callback?.(response)
			}
		})
	}

	get_point_of_sales_totp_key(id: number, callback: ApiCallback<string>) {
		this.get(api('point_of_sales', id, 'totp'), callback)
	}

	// ## Lot ##
	get_active_lot(callback: ApiCallback<LotView>) {
		this.get(api('active_lot'), callback)
	}

	get_active_lot_report(callback: ApiCallback<LotReport>) {
		this.get(api('active_lot', 'report'), callback)
	}

	get_user_lots(from: Date | string, to:Date | string,callback: ApiCallback<LotView[]>) {
		this.get(api('lots') + `?from=${from}&to=${to}`, callback)
	}

	get_user_lot(id: number, callback: ApiCallback<LotReport>) {
		this.get(api('lot', id), callback)
	}

	get_operation_lots(from: Date | string, to:Date | string,callback: ApiCallback<LotView[]>) {
		this.get(api('operation', 'lots') + `?from=${from}&to=${to}`, callback)
	}

	get_operation_lot(id: number, callback: ApiCallback<LotReport>) {
		this.get(api('operation', 'lot', id), callback)
	}

	// ## Transaction ##
	get_operation_lot_transactions(id: number, callback: ApiCallback<TransactionView[]>) {
		this.get(api('operation', 'lot', id, 'transactions'), callback)
	}

	get_completed_transactions(callback: ApiCallback<TransactionView[]>) {
		this.get(api('active_lot', 'completed_transactions'), callback)
	}

	get_nullable_last_transaction(callback: ApiCallback<TransactionView>) {
		this.get(api('active_lot', 'nullable_transaction'), callback)
	}

	get_transaction(id: number, callback: ApiCallback<TransactionDetailedView>) {
		this.get(api('transaction', id), callback)
	}

	// ## ##
	get_acquirers(callback: ApiCallback<Item[]>) {
		this.get(api('acquirers'), callback)
	}

	get_banks(callback: ApiCallback<Item[]>) {
		this.get<Item[]>(api('banks'), (response) => {
			if (response.error?.code === 'OFFLINE') this.handle_cached(api('banks'), State.banks, callback)
			if (response.succeeded) State.banks = response.data
			callback?.(response)
		})
	}

	get_payment_policies(callback: ApiCallback<Item[]>) {
		this.get(api('payment_policies'), callback)
	}

	get_print_type(callback: ApiCallback<number>) {
		this.get<number>(api('print_type'), (response) => {
			if (response.error?.code === 'OFFLINE') this.handle_cached(api('print_type'), State.print_type, callback)
			else {
				if (response.succeeded) State.print_type = response.data
				callback?.(response)
			}
		})
	}

	get_last_payment_geolocation(invoicer_id: number, client_reference: string, callback: ApiCallback<GeolocationView>) {
		this.get(api('invoicer', invoicer_id, 'last_payment_geolocation', client_reference), callback)
	}

	get_user_logs(callback: ApiCallback<AuthLog[]>) {
		this.get(api('user_logs'), callback)
	}

	get_operation_postponed_payments(callback: ApiCallback<PostponedPaymentDetailedView[]>) {
		this.get(api('operation/postponed_payments'), callback)
	}

	// ================
	// === COMMANDS ===
	// ================

	login(username: string, password: string, otp: string, captcha_token: string, failure_callback: (failure: ApiError) => void) {
		this.post<LoginResponse>(api('login'), { username, password, otp, captcha: captcha_token }, (response) => {
			if (response.succeeded) {
				State.user = response.data.user
				State.auth_token = response.data.token
				this.router.navigate(['home'])
			} else failure_callback(response.error)
		})
	}

	legacy_login(token: string, failure_callback: (failure: ApiError) => void) {
		this.post<LoginResponse>(api('login', 'legacy'), { token }, (response) => {
			if (response.succeeded) {
				State.user = response.data.user
				State.auth_token = response.data.token
				this.router.navigate(['home'])
			} else failure_callback(response.error)
		})
	}

	apply_payment(payment: PaymentRequest, callback: ApiCallback<PaymentResult>) {
		this.post(api('pay'), payment, callback)
	}

	apply_prepayment(payment: PaymentRequest, callback: ApiCallback<PaymentResult>) {
		this.post(api('prepay'), payment, callback)
	}

	apply_order_payment(payment: PaymentRequest, callback: ApiCallback<PaymentResult>) {
		this.post(api('pay', 'order'), payment, callback)
	}

	close_lot(id: number, callback: ApiCallback<LotReport>) {
		this.post(api('close_lot'), { id }, callback)
	}

	request_nullification(transaction_id: number, message: string, callback: ApiCallback<null>) {
		this.post(api('nullify'), { transaction_id, message }, callback)
	}

	process_nullification(nullification_request_id: number, approved: boolean, callback: ApiCallback<PaymentView[]>) {
		this.post(api('process_nullification'), { id: nullification_request_id, approved }, callback)
	}

	set_print_type(print_type: number, callback: ApiCallback<null>) {
		this.post<null>(api('print_type'), { print_type }, (response) => {
			if (response.error?.code === 'OFFLINE') {
				State.print_type = print_type
				callback?.({ succeeded: true, data: null, error: null })
			} else {
				if (response.succeeded) State.print_type = print_type
				callback?.(response)
			}
		})
	}

	create_multipay(invoicer_ids: number[], callback: ApiCallback<null>) {
		this.post(api('multipay'), { invoicer_ids }, callback)
	}

	associate_points_of_sales_to_multipay(multipay_id: number, point_of_sales_ids: number[], callback: ApiCallback<null>) {
		this.post(api('multipay', 'points_of_sales'), { multipay_id, point_of_sales_ids }, callback)
	}

	edit_point_of_sales(
		id: number,
		name: string,
		address: string,
		cpe_code: string,
		cpe_token: string,
		geolocation_required: boolean,
		otp_required: boolean,
		enabled: boolean,
		callback: ApiCallback<null>
	) {
		this.post(api('point_of_sales', id), { name, address, cpe_code, cpe_token, geolocation_required, otp_required, enabled }, callback)
	}

	associate_invoicers_to_point_of_sales(point_of_sale_id: number, invoicer_ids: number[], callback: ApiCallback<null>) {
		this.post(api('point_of_sales', point_of_sale_id, 'invoicers'), { invoicer_ids }, callback)
	}

	associate_payment_methods_to_point_of_sales_invoicers(
		point_of_sale_id: number,
		associations: { invoicer_id: number; payment_method_ids: number[] }[],
		callback: ApiCallback<null>
	) {
		this.post(api('point_of_sales', point_of_sale_id, 'invoicers', 'payment_methods'), { associations }, callback)
	}

	associate_acquirers_to_point_of_sales_invoicers(
		point_of_sale_id: number,
		associations: {
			invoicer_id: number
			acquirer_id: number
			affiliate_number: string
		}[],
		callback: ApiCallback<null>
	) {
		this.post(api('point_of_sales', point_of_sale_id, 'invoicers', 'acquirers'), { associations }, callback)
	}

	configure_point_of_sales_invoicer_policies(
		point_of_sales_id: number,
		payment_policies: {
			invoicer_id: number
			payment_policy_id: number
			partial_payments: boolean
			consolidate_payment: boolean
		}[],
		callback: ApiCallback<null>
	) {
		this.post(api('point_of_sales', point_of_sales_id, 'invoicers', 'payment_policies'), { payment_policies }, callback)
	}

	edit_user(user: UserDetailedView, roles: number[], callback: ApiCallback<null>) {
		this.post(
			api('user', user.id),
			{
				name: user.name,
				email: user.email,
				mobile_phone: user.mobile_phone,
				organization_id: user.organization?.id,
				point_of_sales_id: user.point_of_sales?.id,
				roles,
			},
			callback
		)
	}

	reset_user_password(id: string, callback: ApiCallback<string>) {
		this.post(api('user', id, 'reset_password'), null, callback)
	}

	edit_organization(id: number, name: string, point_of_sales_ids: number[], callback: ApiCallback<null>) {
		this.post(api('organization', id), { name, point_of_sales_ids }, callback)
	}

	change_password(old_password: string, new_password: string, callback: ApiCallback<null>) {
		this.post(api('user', 'password'), { old_password, new_password }, callback)
	}

	create_point_of_sales(
		name: string,
		address: string,
		cpe_code: string,
		cpe_token: string,
		geolocation_required: boolean,
		otp_required: boolean,
		enabled: boolean,
		callback: ApiCallback<number>
	) {
		this.post(api('point_of_sales'), { name, address, cpe_code, cpe_token, geolocation_required, otp_required, enabled }, callback)
	}

	create_user(user: UserDetailedView, roles: number[], callback: ApiCallback<{ user_id: string; password: string }>) {
		this.post(
			api('user'),
			{
				username: user.username,
				name: user.name,
				email: user.email,
				mobile_phone: user.mobile_phone,
				organization_id: user.organization?.id,
				point_of_sales_id: user.point_of_sales?.id,
				roles,
			},
			callback
		)
	}

	create_organization(name: string, point_of_sales_ids: number[], callback: ApiCallback<number>) {
		this.post(api('organization'), { name, point_of_sales_ids }, callback)
	}

	nullify_last_transaction(
		message: string,
		callback: ApiCallback<{
			nullification_results: PaymentView[]
			nullifying_transaction_id: number
		}>
	) {
		this.post(api('nullify', 'last'), { message }, callback)
	}

	postpone_payment(invoicer_id: number, client_reference: string, date_payment: string, reason: string, callback: ApiCallback<null>) {
		this.post(api('pay', 'postpone'), { invoicer_id, client_reference, date_payment, reason }, callback)
	}

	test_point_of_sales_totp(id: number, code: string, callback: ApiCallback<boolean>) {
		this.post(api('point_of_sales', id, 'totp'), { code }, callback)
	}

	remove_user(id: string, callback: ApiCallback<null>) {
		this.post(api('remove_user', id), {}, callback)
	}

	get_user_contract_bundles(callback: ApiCallback<BundleSummary[]>) {
		this.get(api('bundles', 'user'), callback)
	}

	get_point_of_sales_contract_bundles(callback: ApiCallback<BundleSummary[]>) {
		this.get(api('bundles', 'point_of_sales'), callback)
	}

	create_contract_bundle(description: string, invoicer_id: number, references: string[], callback: ApiCallback<number>) {
		this.post(api('bundle'), { description, invoicer_id, references }, callback)
	}

	get_contract_bundle(id: number, callback: ApiCallback<Bundle>) {
		this.get(api('bundle', id), callback)
	}

	edit_contract_bundle(id: number, description: string, references: string[], callback: ApiCallback<null>) {
		this.post(api('bundle', id), { description, references }, callback)
	}

	remove_contract_bundle(id: number, callback: ApiCallback<null>) {
		this.post(api('remove_bundle', id), null, callback)
	}

	query_contract_bundle(id: number, callback: ApiCallback<null>) {
		this.post(api('bundle', id, 'query'), null, callback)
	}

	get_contract_bundle_details(id: number, callback: ApiCallback<BundleDetails>) {
		this.get(api('bundle', id, 'details'), callback)
	}

	get_contract_bundle_payment_history(id: number, callback: ApiCallback<BundlePaymentHistory>) {
		this.get(api('bundle', id, 'history'), callback)
	}

	pay_contract_bundle(id: number, payment: BundlePaymentRequest, callback: ApiCallback<any>) {
		this.post(api('bundle', id, 'pay'), payment, callback)
	}

	get_contract_bundle_payment(bundle_id: number, payment_id: number, callback: ApiCallback<BundlePayment>) {
		this.get(api('bundle', bundle_id, 'payment', payment_id), callback)
	}

	get_zones(id: number, callback: ApiCallback<ZoneSummary[]>) {
		this.get(api('cache', 'zones', id), callback)
	}

	get_cached_clients(id: number, zone: string, callback: ApiCallback<ContributorView[]>) {
		this.get(api('cache', id, encodeURI(zone)), callback)
	}

	create_order(data: OrderRequest, callback: ApiCallback<number>) {
		this.post(api('order'), data, callback)
	}

	get_orders(invoicer_id: number, callback: ApiCallback<OrderView[]>) {
		this.get(api('order', 'all', 'invoicer', invoicer_id), callback)
	}

	get_order(id: number, invoicer_id: number, callback: ApiCallback<OrderDetailedView>) {
		this.get(api('order', id, 'invoicer', invoicer_id), callback)
	}
}
