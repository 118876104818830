<h1>Reporte de Operaciones</h1>
<h2 class="text-gray-500">{{ point_of_sales }}</h2>
<div *ngIf="!report" class="rounded-lg w-full py-6 px-8 shadow-md bg-blue-200 mt-8 text-lg">
   <fa-icon icon="info-circle"></fa-icon>
   <working *ngIf="!loaded" color="black"></working>
   <span *ngIf="loaded" class="font-semibold"> No tienes lotes activos en este momento</span>
</div>

<div id="printing-frames" class="invisible fixed top-0 insex-x-0 z-[-10]"></div>

<div *ngIf="report" class="mt-8 card {{ report.id === -1? 'card-red' : '' }}">
   <h3 class="border-b font-semibold {{ report.id === -1? 'text-red-500 border-gray-400' : '' }}">
      Operaciones {{ report.id === -1? 'fuera de línea' : 'del lote actual' }}
   </h3>

   <div class="table mt-6 w-full md:w-max">
      <div class="table-row">
         <div class="entry-cell">Lote</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max font-semibold md:pl-8">{{ report.id === -1? '(Offline)' : report.id }}</div>
         </div>
      </div>
      <div class="table-row">
         <div class="entry-cell">Inicio del Lote</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ report.date_opened | date:'hh:mm aa dd/MMM/yy':null:'es-DO' }}
            </div>
         </div>
      </div>
   </div>
   <ng-container *ngIf="!has_multiple_modalities">
      <button (click)="print_report()" [disabled]="printing" class="btn bg-white my-4">
         <fa-icon *ngIf="printing !== -1" icon="print"></fa-icon>
         {{ printing !== -1? 'Imprimir Lote' : 'Imprimiendo...' }}
         <working *ngIf="printing === -1" color="black"></working>
      </button>
   </ng-container>
   <ng-container *ngIf="has_multiple_modalities">
      <button (click)="print_report('pospaid')" [disabled]="printing" class="btn bg-white my-4">
         <fa-icon *ngIf="printing !== -1" icon="print"></fa-icon>
         {{ printing !== -1? 'Imprimir Lote Pospago' : 'Imprimiendo...' }}
         <working *ngIf="printing === -1" color="black"></working>
      </button>
      <button (click)="print_report('prepaid')" [disabled]="printing" class="btn bg-white my-4 ml-4">
         <fa-icon *ngIf="printing !== -2" icon="print"></fa-icon>
         {{ printing !== -2? 'Imprimir Lote Prepago' : 'Imprimiendo...' }}
         <working *ngIf="printing === -2" color="black"></working>
      </button>
   </ng-container>
   <div class="flex flex-row items-baseline mt-8 mb-4 border-b {{ report.id === -1? 'border-gray-400' : '' }}">
      <div class="text-lg font-semibold mr-2 flex-1 md:flex-none">Facturador:</div>
      <select [(ngModel)]="selected_invoicer" class="py-0 rounded-md">
         <option [value]="0">(Todos)</option>
         <option *ngFor="let report of report.invoicers" [value]="report.invoicer.id">{{ report.invoicer.description }}
         </option>
      </select>
   </div>
   <div class="table mb-6 w-full md:w-max">
      <div class="table-row" *ngIf="selected_report.total_cash">
         <div class="entry-cell">Total en Efectivo Pospago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_cash_postpaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_cash">
         <div class="entry-cell">Total en Efectivo Prepago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_cash_prepaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_cheque">
         <div class="entry-cell">Total en Cheque Pospago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_cheque_postpaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_cheque">
         <div class="entry-cell">Total en Cheque Prepago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_cheque_prepaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_card">
         <div class="entry-cell">Total en Tarjeta de Credito Pospago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_card_postpaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_card">
         <div class="entry-cell">Total en Tarjeta de Credito Prepago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_card_prepaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_bank_transfer">
         <div class="entry-cell">Total en Transferencia Pospago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_bank_transfer_postpaid | currency:'RD$ ' }}
            </div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_bank_transfer">
         <div class="entry-cell">Total en Transferencia Prepago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_bank_transfer_prepaid | currency:'RD$ ' }}
            </div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_bonoluz">
         <div class="entry-cell">Total en Tarjeta Bonoluz Pospago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_bonoluz_postpaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_bonoluz">
         <div class="entry-cell">Total en Tarjeta Bonoluz Prepago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_bonoluz_prepaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_nullified">
         <div class="entry-cell">Total en Anulaciones Pospago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_nullified_postpaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row" *ngIf="selected_report.total_nullified">
         <div class="entry-cell">Total en Anulaciones Prepago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_nullified_prepaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row font-semibold">
         <div class="entry-cell">Total Pospago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_postpaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row font-semibold">
         <div class="entry-cell">Total Prepago</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total_prepaid | currency:'RD$ ' }}</div>
         </div>
      </div>
      <div class="table-row font-semibold">
         <div class="entry-cell">Total General</div>
         <div class="entry-cell text-right">
            <div class="w-full min-w-max md:pl-8">{{ selected_report.total | currency:'RD$ ' }}</div>
         </div>
      </div>
   </div>

   <div class="mt-8 w-full md:table md:rounded-xl md:border-2 {{ report.id === -1? 'md:border-gray-400' : '' }}">
      <div class="hidden md:table-header-group font-bold text-center text-lg">
         <div class="table-cell" title="Secuencia">Sec.</div>
         <div class="table-cell border-l {{ report.id === -1? 'border-gray-400' : '' }}" title="ID de la Transacción">ID
            Tran.</div>
         <div *ngIf="selected_invoicer == 0" class="table-cell border-l {{ report.id === -1? 'border-gray-400' : '' }}">
            Facturador</div>
         <div class="table-cell border-l {{ report.id === -1? 'border-gray-400' : '' }}">Cliente</div>
         <div class="table-cell border-l {{ report.id === -1? 'border-gray-400' : '' }}">Nombre</div>
         <th class="table-cell border-l {{ report.id === -1? 'border-gray-400' : '' }}" colspan="2">Monto</th>
         <div class="table-cell border-l {{ report.id === -1? 'border-gray-400' : '' }}">Fecha</div>
         <div class="table-cell border-l {{ report.id === -1? 'border-gray-400' : '' }} w-0"></div>
      </div>
      <ng-container *ngFor="let group of transaction_groups">
         <div *ngIf="group.transactions?.length" class="mt-6 md:table-row-group">
            <div class="md:table-row font-semibold">
               <div class="border-b text-lg md:hidden {{ report.id === -1? 'border-gray-400' : '' }}">{{ group.label }}
               </div>
               <td class="hidden md:table-cell group-header {{ report.id === -1? 'border-gray-400' : '' }}" colspan="9">
                  {{ group.label }}</td>
            </div>
            <div *ngIf="!group.transactions?.length" class="w-full md:table-row">
               <td title="No se realizaron transacciones en efectivo en este lote" colspan="9"
                  class="block md:table-cell md:border-t {{ report.id === -1? 'md:border-gray-400' : '' }} text-center text-gray-500">
                  <fa-icon icon=times></fa-icon>
               </td>
            </div>
            <div *ngFor="let transaction of group.transactions"
               class="border {{ report.id === -1? 'border-gray-400' : '' }} rounded-lg my-4 py-2 px-4 {{ transaction.status.id === -1? 'bg-yellow-50' : 'bg-gray-50' }} shadow-sm md:shadow-none md:bg-transparent md:rounded-none md:table-row md:hover:bg-gray-100">
               <div class="flex flex-row md:contents">
                  <div
                     class="flex-1 font-bold md:font-normal md:px-2 md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50' : '' }} md:border-t {{ report.id === -1? 'md:border-gray-400' : '' }} md:text-right">
                     {{ (transaction.status.id === -1? 'OL ' : '') + transaction.sequence }}
                  </div>
                  <div
                     class="md:px-2 md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50' : '' }} md:border-t md:border-l {{ report.id === -1? 'md:border-gray-400' : '' }}">
                     <span class="font-semibold md:hidden">ID: </span>
                     {{ transaction.id === -1? '(Offline)' : transaction.id }}
                  </div>
               </div>
               <div *ngIf="selected_invoicer == 0"
                  class="md:px-2 md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50' : '' }} md:border-t md:border-l {{ report.id === -1? 'md:border-gray-400' : '' }}">
                  <span class="font-semibold md:hidden">Facturador: </span>
                  {{ transaction.invoicer?.description }}
               </div>
               <div class="flex flex-row md:contents">
                  <div
                     class="md:px-2 md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50' : '' }} md:border-t md:border-l {{ report.id === -1? 'md:border-gray-400' : '' }}">
                     <span class="font-semibold md:hidden">Cliente: </span>
                     {{ transaction.client_reference }}
                  </div>
                  <div
                     class="ml-1 md:ml-0 md:px-2 md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50' : '' }} md:border-t md:border-l {{ report.id === -1? 'md:border-gray-400' : '' }}">
                     <span *ngIf="transaction.client_name" class="md:hidden">- </span>
                     {{ transaction.client_name }}
                  </div>
               </div>
               <div class="flex flex-row flex-wrap md:contents">
                  <div class="flex-1 min-w-max pr-4 font-semibold md:font-normal md:contents">
                     <!-- <div class="inline-block mr-1 md:hidden">Monto: </div> -->
                     <div
                        class="inline-block md:px-2 md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50' : '' }} md:border-t md:border-l {{ report.id === -1? 'md:border-gray-400' : '' }} md:text-right md:font-mono">
                        <span *ngIf="transaction.amount < 0">-</span>RD$
                     </div>
                     <div
                        class="inline-block ml-2 md:ml-0 md:px-2 md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50' : '' }} md:border-t {{ report.id === -1? 'md:border-gray-400' : '' }} md:text-right md:font-mono md:w-0">
                        {{ (transaction.amount < 0? -transaction.amount : transaction.amount) | currency:' ' }}
                     </div>
                  </div>
                  <div class="md:px-2 md:table-cell {{ transaction.status.id === -1? ' md:bg-yellow-50' : '' }}
                           md:border-t md:border-l md:text-right {{ report.id===-1? 'md:border-gray-400' : '' }}">
                           <span class="font-semibold md:hidden">Fecha: </span>
                           {{ transaction.date | date:'hh:mm aa dd/MMM/yy':null:'es-DO' }}
                     </div>
                  </div>
                  <div
                     class="flex flex-row-reverse md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50' : '' }} md:border-t md:border-l {{ report.id === -1? 'md:border-gray-400' : '' }}">
                     <div *ngIf="transaction.status.id === -1 && is_being_processed(transaction.sequence)"
                        class="w-min mx-auto">
                        <working color="black"></working>
                     </div>
                     <button *ngIf="transaction.status.id !== -1 || !is_being_processed(transaction.sequence)"
                        (click)="print(transaction)"
                        class="btn !p-[3px] focus:outline-none mt-2 md:mt-0 md:block md:mx-auto" [disabled]="printing">
                        <div *ngIf="printing !== transaction.id" class="inline-block w-[30px]"><fa-icon
                              icon="print"></fa-icon></div>
                        <working *ngIf="printing === transaction.id" color="black"></working>
                        <span class="pr-2 md:hidden">{{ printing != transaction.id? 'Imprimir copia' :
                           'Imprimiendo...'}}</span>
                     </button>
                  </div>
               </div>
            </div>
      </ng-container>
   </div>
</div>