<h1>Detalle de Pago de Bolsa de Contratos</h1>

<div class="card mt-6">
   <div class="space-y-4">
      <header class="p-4 flex flex-row justify-between items-baseline">
         <img class="h-16" src="/assets/logos/logo_{{payment.bundle?.invoicer?.description | lowercase}}.png">
         <h4>{{ payment.date | date:'hh:mm aa, dd MMMM yyyy':null:'es-DO' }}</h4>
      </header>
      <section class="bg-gray-100 rounded-lg w-full p-5">
         <table class="info">
            <thead>
               <th class="w-1/5">Bolsa Pagada:</th>
               <th class="w-3/5">Localidad:</th>
               <th class="w-1/5">Pago:</th>
            </thead>
            <tr>
               <td>{{ payment.bundle?.description }}</td>
               <td>{{ payment.user?.point_of_sales?.name}}</td>
               <td rowspan=3 class="align-baseline">
                  <div>{{ payment.payment_method?.description | titlecase }}</div>
                  <div>{{ payment.bank?.description }}</div>
                  <div>{{ payment.payment_document }}</div>
                  <div *ngIf="payment.authorization_code">{{ payment.authorization_code}}</div>
               </td>
            </tr>
            <tr>
               <td>{{ applied_count }} contratos</td>
               <td>{{ payment.user?.point_of_sales?.address }}</td>
            </tr>
            <tr>
               <td></td>
               <td>Cajero: {{ payment.user?.name }}</td>
            </tr>
         </table>
      </section>

      <section class="p-5">
         <table class="details">
            <thead>
               <th>Contrato</th>
               <th>Factura</th>
               <th colspan="3">Monto</th>
            </thead>
            <tbody>
               <tr *ngFor="let detail of payment.details | filter:true:['applied']">
                  <td >{{ detail.reference }}</td>
                  <td >{{ detail.invoice_number }}</td>
                  <td class="w-0">RD$ </td>
                  <td class="w-0 text-right">{{ detail.amount | currency:' ' }}</td>
                  <td></td>
               </tr>
               <tr class="border-t border-gray-200">
                  <td></td>
                  <td class="font-medium text-right">Total:</td>
                  <td class="w-0">RD$ </td>
                  <td class="w-0 text-right">{{ total | currency:' ' }}</td>
                  <td></td>
               </tr>
            </tbody>
         </table>
      </section>
   </div>

   <div id="frame_container" class="fixed hidden w-full"></div>
   <button (click)="print()" [disabled]="!payment" class="mt-6 btn btn-blue">
      <fa-icon icon=print></fa-icon>
      Imprimir
   </button>
</div>