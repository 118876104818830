<h1>Configurar Impresión</h1>
<h2>Seleccionar tipo de impresora</h2>

<div class="flex flex-col md:flex-row md:flex-wrap mt-8">

   <div (click)="switch(1)" [class.disabled]="working"
      class="cursor-pointer bg-white shadow-lg rounded-lg p-4 my-4 md:mx-4 md:my-0 md:p-8 {{ (print_type !== 1? 'hover:bg-blue-100' : '') }} active:bg-blue-300"
      [class.bg-blue-200]="print_type === 1" style="min-width: 250px;">
      <div class="flex flex-row text-lg font-semibold mb-2 items-baseline w-max mx-auto">
         <input [checked]="print_type === 1" type="radio" class="mr-2 pointer-events-none">
         Integrada en POS Móvil
      </div>
      <img src="/assets/printers/mobile_pos.png" class="mx-auto" style="max-height: 250px; max-width: 250px;">
   </div>

   <div (click)="switch(2)" [class.disabled]="working"
      class="cursor-pointer bg-white shadow-lg rounded-lg p-4 my-4 md:mx-4 md:my-0 md:p-8 {{ (print_type !== 2? 'hover:bg-blue-100' : '') }} active:bg-blue-300"
      [class.bg-blue-200]="print_type === 2" style="min-width: 250px;">
      <div class="flex flex-row text-lg font-semibold mb-2 items-baseline w-max mx-auto">
         <input [checked]="print_type === 2" type="radio" class="mr-2 pointer-events-none">
         Térmica de escritorio
      </div>
      <img src="/assets/printers/thermal_printer.png" class="mx-auto" style="max-height: 250px; max-width: 250px;">
   </div>

   <div (click)="switch(3)" [class.disabled]="working"
      class="cursor-pointer bg-white shadow-lg rounded-lg p-4 my-4 md:mx-4 md:my-0 md:p-8 {{ (print_type !== 3? 'hover:bg-blue-100' : '') }} active:bg-blue-300"
      [class.bg-blue-200]="print_type === 3" style="min-width: 250px;">
      <div class="flex flex-row text-lg font-semibold mb-2 items-baseline w-max mx-auto">
         <input [checked]="print_type === 3" type="radio" class="mr-2 pointer-events-none">
         Solo texto
      </div>
      <img src="/assets/printers/text_only.png" class="mx-auto" style="max-height: 250px; max-width: 250px;">
   </div>
</div>