import { DatePipe } from '@angular/common';
import { AuthLog, Item } from 'src/app/models/models';
import { ApiService } from 'src/app/api.service';
import { Component, OnInit } from '@angular/core';
import { error_toast } from 'src/app/utils/toast.util';
import { contains, date_to_short_string, filter, range } from 'src/app/utils/utils';

@Component({
  selector: 'app-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrls: []
})
export class UserLogsComponent {
  loaded = false;
  logs: AuthLog[];

  filter: string;
  type_filter: number = -1;

  start_date: string | Date;
  end_date: string | Date;

  get filtered_logs(): AuthLog[] {
    let logs = this.logs;
    if (this.start_date) {
      const start_date = new Date(this.start_date);
      logs = logs.filter(l => new Date(l.date) >= start_date);
    }
    if (this.end_date) {
      const end_date = new Date(this.end_date);
      end_date.setDate(end_date.getDate() + 1);
      logs = logs.filter(l => new Date(l.date) <= end_date);
    }
    if (this.type_filter != -1) {
      logs = logs.filter(l => l.type == this.type_filter);
    }
    if (this.filter && this.filter.trim() !== '') {
      logs = logs.filter(l => contains({
        username: l.user?.username,
        user: l.user?.name,
        admin_username: l.admin?.username,
        admin: l.admin?.name,
        note: l.note
      }, this.filter));
    }
    return logs;
  }

  get types(): Item[] {
    return range(7).map(id => ({ id, description: this.type(id) }));
  }

  get min_date(): string | Date {
    const min_date = this.logs.map(l => new Date(l.date)).sort((a, b) => <any>a - <any>b)[0];
    return date_to_short_string(min_date);
  }

  get max_date(): string | Date {
    const max_date = this.logs.map(l => new Date(l.date)).sort((a, b) => <any>b - <any>a)[0];
    return date_to_short_string(max_date);
  }

  date_pipe: DatePipe = new DatePipe('es-DO');
  constructor(api: ApiService) {
    api.get_user_logs(response => {
      if (response.succeeded) {
        this.loaded = true;
        this.logs = response.data;
        this.start_date = this.min_date;
        this.end_date = this.max_date;
      } else error_toast
    });
  }

  type(log_type: number): string {
    switch(log_type) {
      case 0: return 'Creado';
      case 1: return 'Ingreso';
      case 2: return 'Bloqueado';
      case 3: return 'Reseteo';
      case 4: return 'Desactivado';
      case 5: return 'Eliminado';
      case 6: return 'Cambio de contraseña';
    }
    return null;
  }
}
