import { LotView } from 'src/app/models/models';
import { State } from 'src/app/state';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import { error_toast } from 'src/app/utils/toast.util';
import { date_to_short_string, is_today, today } from 'src/app/utils/utils';

@Component({
  selector: 'app-lot-history',
  templateUrl: './lot-history.component.html',
  styleUrls: []
})
export class LotHistoryComponent {
  get point_of_sales_name(): string { return State.user.point_of_sales?.name; }
  get mobile(): boolean { return State.mobile; }

  date_end: Date | string =  date_to_short_string(today());
  date_start: Date | string = date_to_short_string(today());

  earliest_date: Date | string ;
  latest_date: Date | string;

  lots: LotView[];
  get filtered_lots(): LotView[] {
    const start_date = new Date(this.date_start);
    const end_date = new Date(this.date_end);
    end_date.setDate(end_date.getDate() + 1);
    return this.lots?.filter(l => {
      const date_opened = new Date(l.date_opened);
      return date_opened >= start_date && date_opened <= end_date;
    }) ?? [];
  }

  constructor(private api: ApiService, private router: Router) {
    this.searchLost();
  }

  go(lot: LotView) { this.router.navigate(['cashier', 'report', lot.id]); }

  is_today(date: Date|string) { return is_today(date); }

  searchLost(){
    this.api.get_user_lots(this.date_start,this.date_end,response => {
      if (response.succeeded) {
        this.lots = response.data.sort();
      } else error_toast(response.error.message);
    });
  }
}
