<h1>Histórico de Lotes</h1>
<h2 class="text-gray-500">{{ operation_name }}</h2>

<working *ngIf="!lots" color="#3B82F6" [size]="30"></working>

<div *ngIf="lots" class="card mt-8">
   <!-- Date filter -->
   <div class="flex flex-row flex-wrap items-baseline">
      <div class="mb-2 flex flex-row">
         <div class="flex flex-col md:flex-row md:items-baseline">
            <div class="mr-2">Desde:</div>
            <div class="mr-8"><input type="date" [(ngModel)]="date_start" class="py-1 px-2 rounded border-gray-300"></div>
         </div>
         <div class="flex flex-col md:flex-row md:items-baseline">
            <div class="mr-2">Hasta:</div>
            <div class="mr-8"><input type="date" [(ngModel)]="date_end"   class="py-1 px-2 rounded border-gray-300"></div>
         </div>
      </div>
      <div class="mb-2 flex flex-row flex-wrap">
         <div class="flex flex-col md:flex-row md:items-baseline">
            <div class="mr-2">Punto de Venta:</div>
            <div class="mr-8">
               <select [(ngModel)]="selected_point_of_sales_id" class="py-1 border-gray-300 rounded">
                  <option value="">(Todos)</option>
                  <option *ngFor="let pos of points_of_sales" [value]="pos.id">{{ pos.name }}</option>
               </select>
            </div>
         </div>
         <div class="flex flex-col md:flex-row md:items-baseline">
            <div class="mr-2">Cajero:</div>
            <div class="mr-8">
               <select [(ngModel)]="selected_cashier_id" class="py-1 border-gray-300 rounded">
                  <option value="">(Todos)</option>
                  <option *ngFor="let cashier of cashiers" [value]="cashier.id">{{ cashier.name }}</option>
               </select>
            </div>
         </div>
         <div class="flex flex-col md:flex-row md:items-baseline">
          <button class="btn btn-blue" (click)="searchLost()">
              Buscar
          </button>
       </div>
      </div>
   </div>
   <div class="mt-6">
      <table-control #lotes for="lotes" [span]="mobile? 10 : 25" [source]="filtered_lots" class="mb-3"></table-control>
      <table-paginator *ngIf="lotes.slice?.length && lotes.span" for="lotes" [show]="mobile? 5 : 7" [notext]="mobile" class="{{ mobile? 'w-max mx-auto' : '' }}" [size]=2></table-paginator>
      <div class="w-full mt-2 md:table md:border md:rounded-md">
         <div class="hidden md:table-header-group md:text-center md:font-semibold md:text-lg">
            <div class="table-cell px-2 py-1"></div>
            <div *ngIf="show_pos" class="table-cell px-2 py-1 border-l">Punto de Venta</div>
            <div class="table-cell px-2 py-1 border-l">Cajero</div>
            <div class="table-cell px-2 py-1 border-l">Inicio lote</div>
            <div class="table-cell px-2 py-1 border-l">Cierre lote</div>
            <th  class="table-cell px-2 py-1 border-l font-semibold" colspan="2">Total cobrado</th>
            <div class="table-cell py-1 border-l"></div>
         </div>
         <div *ngFor="let lot of lotes.slice" class="border p-2 rounded-md shadow-md mb-4 md:shadow-none md:table-row {{ lot.date_closed? 'bg-gray-50 md:bg-transparent hover:bg-gray-100' : is_today(lot.date_opened)? 'bg-yellow-50 hover:bg-yellow-100' : 'bg-red-50 hover:bg-red-100' }}">
            <div class="font-semibold md:font-normal md:table-cell md:border-t md:px-2 md:py-1">{{ lot.id }}</div>
            <div *ngIf="show_pos" class="md:table-cell md:border-t md:px-2 md:py-1 md:border-l">
               <fa-icon icon="store-alt" class="md:hidden"></fa-icon>
               {{ lot.point_of_sales.name }}
            </div>
            <div class="md:table-cell md:border-t md:px-2 md:py-1 md:border-l">
               <fa-icon *ngIf="lot.user" icon="user" class="md:hidden"></fa-icon>
               {{ lot.user?.name }}
            </div>
            <div class="flex flex-row flex-wrap mt-2 md:contents">
               <div class="flex-1 mr-2 md:table-cell md:border-t md:px-2 md:py-1 md:border-l md:text-right">
                  <div class="font-semibold md:hidden">Fecha de inicio de lote:</div>
                  {{ lot.date_opened | date:'hh:mm aa dd/MMM/yy':null:'es-DO' }}
               </div>
               <div class="flex-1 md:table-cell md:border-t md:px-2 md:py-1 md:border-l md:text-right">
                  <div *ngIf="lot.date_closed" class="font-semibold md:hidden">Fecha de cierre de lote:</div>
                  {{ lot.date_closed | date:'hh:mm aa dd/MMM/yy':null:'es-DO' }}
               </div>
            </div>
            <div class="font-semibold mt-2 md:hidden">Total cobrado:</div>
            <div class="inline-block mr-2 md:table-cell md:border-t md:pl-2 md:py-1 md:border-l md:text-right md:font-mono"><span *ngIf="lot.total_charged < 0">-</span>RD$</div>
            <div class="inline-block md:table-cell md:border-t md:pr-2 md:py-1 md:pl-1 md:text-right md:font-mono md:w-0 md:min-w-max">{{ (lot.total_charged < 0? -1 : 1) * lot.total_charged | currency:' ' }}</div>
            <div class="flex flex-row-reverse md:table-cell md:border-t md:border-l md:w-0">
               <div class="flex flex-row">
                  <button (click)="go(lot)" [disabled]="busy" class="btn btn-blue w-max">
                     <fa-icon icon="file-alt"></fa-icon>
                     Detalles
                  </button>
                  <button (click)="export(lot)" [disabled]="busy" class="btn w-max">
                     <fa-icon icon="file-csv"></fa-icon>
                     {{ working.exporting !== lot? 'Exportar' : 'Exportando...' }}
                     <working *ngIf="working.exporting === lot"></working>
                  </button>
                  <button (click)="go_to_map(lot)" [disabled]="busy" class="btn w-max">
                     Mapa cobros
                     <fa-icon icon="map-marked-alt"></fa-icon>
                  </button>
               </div>
            </div>
         </div>
      </div>
      <table-paginator for="lotes" class="mt-2 {{ mobile? 'w-max mx-auto text-center' : '' }}" [size]=2></table-paginator>
   </div>
</div>
