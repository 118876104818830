import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { environment } from 'src/environments/environment'
import { ApiService } from '../api.service'
import { OfflineDatabase } from '../offline_db'
import { State } from '../state'
import { modal } from '../utils/modal.util'
import { error_toast, warning_toast } from '../utils/toast.util'
import { is_nothing } from '../utils/utils'

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.less'],
})
export class MainComponent {
	is_mobile_mode = false
	sidebar_enabled = false

	get menu(): MenuItem[] {
		let menu: MenuItem[] = [{ label: 'Inicio', icon: 'home', link: 'home' }]
		if (State.user_is_cashier)
			menu.push({
				label: 'Cajero',
				icon: 'cash-register',
				children: [
					{ label: 'Pagos', icon: 'money-check-alt', link: 'cashier/pay' },
					{ label: 'Reporte', icon: 'chart-bar', link: 'cashier/report' },
					{ label: 'Anulaciones', icon: 'eraser', link: 'cashier/nullification' },
					{ label: 'Cierre de Lote', icon: 'store-alt-slash', link: when_online('cashier/closure') },
					{ label: 'Histórico de Lotes', icon: 'book', link: when_online('cashier/lots') },
					...(State.user_is_big_clients
						? [
								{
									label: 'Bolsas de Contratos',
									icon: <IconProp>'suitcase',
									link: when_online('cashier/bundles'),
								},
						  ]
						: []),
					...(State.user_is_cashier_mobile
						? [
								{
									label: 'Ordenes de pago',
									icon: <IconProp>'file',
									link: when_online('cashier/orders'),
								},
						  ]
						: []),
				],
			})
		if (State.user_is_supervisor || State.user_is_manager) {
			const section = {
				label: State.user_is_manager ? 'Gerente' : 'Supervisor',
				icon: 'binoculars',
				children: [
					{
						label: 'Informacion de Organización',
						icon: 'align-left',
						link: when_online('supervisor/info'),
					},
					{
						label: 'Anulaciones Pendientes',
						icon: 'eraser',
						link: when_online('supervisor/nullifications'),
					},
					{
						label: 'Cobros Pospuestos',
						icon: 'hourglass-half',
						link: when_online('supervisor/postponed'),
					},
					{ label: 'Histórico de Lotes', icon: 'book', link: when_online('supervisor/lots') },
				],
			}
			if (State.user.point_of_sales?.otp_required)
				section.children.push({
					label: 'Autenticación 2 Factores',
					icon: 'user-shield',
					link: when_online('supervisor/2fa'),
				})
			menu.push(<any>section)
		}
		if (State.user_is_admin)
			menu.push({
				label: 'Sistema',
				icon: 'cogs',
				children: [
					{ label: 'Puntos de Venta', icon: 'store-alt', link: when_online('system/points-of-sales') },
					{ label: 'Usuarios', icon: 'users', link: when_online('system/users') },
					{
						label: 'Unidades Organizacionales',
						icon: 'sitemap',
						link: when_online('system/organizations'),
					},
					{
						label: 'Configurar Multi-pagos',
						icon: 'tasks',
						link: when_online('system/multipay-configuration'),
					},
				],
			})
		const section = {
			label: State.user.name,
			icon: 'user',
			children: [
				{ label: 'Cambiar Clave', icon: 'user-cog', link: when_online('user/password-change') },
				{ label: 'Configurar Impresión', icon: 'print', link: 'user/print-configuration' },
			],
		}
		if (OfflineDatabase.has_queued_payments)
			section.children.push({ label: 'Pagos en Cola', icon: 'stream', link: 'user/queued-payments' })
		menu.push(<any>section)
		menu.push({
			label: 'Ayuda y Soporte',
			icon: 'life-ring',
			children: [
				{ label: 'Preguntas', icon: 'question', link: 'help/faq' },
				{ label: 'WhatsApp', icon: ['fab', 'whatsapp'], link: 'help/whatsapp' },
				{ label: 'Teléfonos', icon: 'phone', link: 'help/phones' },
				{ label: 'Email', icon: 'envelope', link: 'help/email' },
			],
		})
		menu.push({ label: 'Salir', icon: 'sign-out-alt', link: 'signout' })
		return menu
	}

	constructor(private router: Router, api: ApiService) {
		if (is_nothing(State.user)) {
			State.auth_token = null
			router.navigate(['login'])
			// error_snackbar("Debe iniciar sesión.");
		} else if (State.online) {
			api.authenticate((response) => {
				if (response.succeeded) {
					State.user = response.data
					if (State.user.force_password_change) {
						router.navigate(['user', 'password-change'])
						error_toast('Debes cambiar la contraseña')
					} else if (State.user.force_tfa_verification) {
						router.navigate(['supervisor', '2fa'])
						warning_toast('Configura la autenticación de 2 factores')
					}
				} else error_toast(response.error.message)
			})
			// Pre-cache
			if (State.user_is_cashier && State.user.point_of_sales) {
				const pos_id = State.user.point_of_sales.id
				api.get_point_of_sales_queriables(pos_id, (response) => {
					if (response.succeeded) {
						for (let invoicer of State.invoicers)
							api.get_point_of_sales_invoicer_payment_methods(pos_id, invoicer.id, null)
						if (
							response.data.invoicers
								.map((i) => State.get_payment_methods(i.id).some((p) => p.id === 8))
								.some((_) => _)
						)
							api.get_banks(null)
					}
				})
				api.get_print_type(null)
			}
		} else {
			environment.debug('authentication ommited; offline.')
		}

		const resize_callback = () => {
			this.is_mobile_mode = window.innerWidth < 1024
		}
		window.onresize = resize_callback
		resize_callback()
	}

	is_disabled(item: { link?; children?: any[] }) {
		return !item.link && !item.children?.some((i) => i.link)
	}

	go_to(link?: string) {
		if (link) {
			this.sidebar_enabled = false
			if (link === 'signout') {
				if (OfflineDatabase.has_queued_payments) {
					modal(
						'Pagos offline sin sincronizar',
						'Registraste pagos fuera de línea que aún no han sido aplicados.  ' +
							'\nSi cierras la sesión ahora, esta información se perderá.  ' +
							'\n¿Estás seguro?',
						['!Cerrar sesión', '_Cancelar'],
						(result) => {
							if (result.id === 0) {
								State.clear()
								OfflineDatabase.drop()
								return this.router.navigate(['login'])
							}
						}
					)
				} else {
					State.clear()
					OfflineDatabase.drop()
					return this.router.navigate(['login'])
				}
			} else {
				environment.debug('go to:', link)
				this.router.navigateByUrl(link)
			}
		}
	}
}

function when_online(link: string): string {
	return State.online ? link : null
}

interface MenuItem {
	label: string
	icon: IconProp
	link?: string
	children?: {
		label: string
		icon: IconProp
		link: string
	}[]
}
