<h1>{{ !paying? 'Detalle de' : 'Pagar' }}  Bolsa de Contratos</h1>

<div *ngIf="!paying" class="card mt-6">
   <h2 class="font-medium flex flex-row space-x-4 items-center">
      <div>{{ bundle.description }}</div>
      <img src="/assets/logos/logo_{{ bundle.invoicer.description | lowercase }}.png" [alt]="bundle.invoicer.description" class="h-5">
   </h2>

   <button (click)="export()" class="btn btn-blue float-right">
      Exportar
      <fa-icon icon=file-csv></fa-icon>
   </button>

   <div class="flex flex-row space-x-3 items-baseline my-2">
      <div class="font-semibold">Filtrar:</div>
      <div>
         <input type=text [(ngModel)]="filter" placeholder="🔎" class="py-1 px-2 border !border-gray-400">
         <button (click)="filter = null" class="btn !rounded-l-none hover:!text-red-500">
            <fa-icon icon=times></fa-icon>
         </button>
      </div>
   </div>
   <div class="flex flex-row mb-2">
      <button (click)="set_all(true)" class="btn">
         <fa-icon [icon]="['far', 'check-square']"></fa-icon>
         Seleccionar todo
      </button>
      <button (click)="set_all(false)" class="btn">
         <fa-icon [icon]="['far', 'square']"></fa-icon>
         Deseleccionar todo
      </button>
   </div>
   <h3 class="font-semibold mb-2">
      <div>Contratos con deuda: {{ contracts }}</div>
      <div>Facturas pendientes: {{ bundle.details.length }}</div>
      <div>Monto total pendiente: {{ full_total | currency:'RD$ ' }}</div>
      <div>Monto a pagar: {{ total | currency:'RD$ ' }}</div>
   </h3>
   <table>
      <thead>
         <th>Contrato</th>
         <th>Nombre</th>
         <th>Direccion</th>
         <th>Factura</th>
         <th>Concepto</th>
         <th>Fecha</th>
         <th colspan="2">Monto</th>
         <th colspan="2">Monto a pagar</th>
         <th></th>
      </thead>
      <tbody>
         <tr *ngFor="let detail of filtered_invoices | sort:'date_issued'">
            <td (click)="toggle(detail)" class="cursor-pointer">{{ detail.reference }}</td>
            <td (click)="toggle(detail)" class="cursor-pointer">{{ detail.client_name }}</td>
            <td (click)="toggle(detail)" class="cursor-pointer">{{ detail.client_address }}</td>
            <td (click)="toggle(detail)" class="cursor-pointer">{{ detail.invoice_number }}</td>
            <td (click)="toggle(detail)" class="cursor-pointer">{{ detail.invoice_description }}</td>
            <td (click)="toggle(detail)" class="cursor-pointer">
               <div class="w-max">{{ detail.date_issued | date:'dd MMM yyyy':null:'es-DO' }}</div>
            </td>
            <div (click)="toggle(detail)" class="contents cursor-pointer align-middle">
               <td class="pr-1">RD$</td>
               <td class="text-right font-mono !border-l-0">{{ detail.amount | currency:' ' }}</td>
            </div>
            <div class="contents align-middle">
               <td class="pr-1">RD$</td>
               <td class="text-right font-mono !border-l-0">
                  <input type=number [(ngModel)]="amount[detail.invoice_number]" [min]="0" [max]="detail.amount" class="border-0 border-b rounded-none py-1 px-1 bg-transparent w-24 text-right">
               </td>
            </div>
            <td (click)="toggle(detail)" class="cursor-pointer">
               <input type=checkbox [checked]="selected[detail.invoice_number]" class="pointer-events-none">
            </td>
         </tr>
      </tbody>
   </table>
   <div class="flex flex-row mt-6 justify-between">
      <h3 class="font-semibold">Monto a pagar: {{ total | currency:'RD$ ' }}</h3>
      <button (click)="paying = true" [disabled]="nothing_to_pay" class="btn btn-blue">
         <fa-icon icon=cash-register></fa-icon>
         Pagar
      </button>
   </div>

   <div *ngIf="bundle.errors?.length" class="bg-red-500 rounded-lg p-4 text-white font-medium my-8">
      Ocurrieron los siguientes errores en la última consulta:
      <div *ngFor="let e of bundle.errors" class="mt-4">
         {{ e.references.join(', ') }}:<br>
         <span class="mr-4"></span>"{{ e.error }}"
      </div>
   </div>
</div>

<!------------>
<!-- Paying -->
<!------------>
<div *ngIf="paying" class="card mt-6">
   <h2 class="font-medium flex flex-row space-x-4 items-center">
      <div>{{ bundle.description }}</div>
      <img src="/assets/logos/logo_{{ bundle.invoicer.description | lowercase }}.png" [alt]="bundle.invoicer.description" class="h-5">
   </h2>

   <button (click)="paying = false" [disabled]="working.paying" class="btn mb-6">
      <fa-icon icon=arrow-left></fa-icon>
      Atrás
   </button>

   <h3 class="font-medium">Cantidad de facturas: {{ selected_invoices.length }}</h3>
   <h3 class="font-medium">Monto a pagar: {{ total | currency:'RD$ ' }}</h3>

   <div class="flex flex-row flex-wrap items-baseline mt-6">
      <div class="mr-4 font-medium">Modo de Pago:</div>
      <select [(ngModel)]="data.method" class="rounded-md py-1">
         <option [value]="0">(Seleccione un método de pago)</option>
         <option *ngFor="let method of payment_methods" [value]="method.id">{{ method.description | titlecase }}</option>
      </select>
   </div>

   <div *ngIf="data.method" class="mt-6 border border-gray-300 rounded-md p-4 w-max">
      <!-- Efectivo -->
      <div *ngIf="data.method == 1">
         <div class="font-medium">Monto recibido:</div>
         <div>
            <span class="mr-2">RD$</span>
            <input type=number [(ngModel)]="data.cash" [min]="total" class="text-right">
         </div>
         <div class="font-medium mt-4">Cambio del cliente:</div>
         <div>{{ data.cash >= total? (round(data.cash - total) | currency:'RD$ ') : 'RD$ -.--' }}</div>
      </div>
      <!-- Cheque / Transferencia -->
      <div *ngIf="data.method == 2 || data.method == 8"> 
         <div class="font-medium">Banco:</div>
         <select [(ngModel)]="data.bank" class="rounded-md py-1">
            <option [value]="0">(Seleccione un banco de la lista)</option>
            <option *ngFor="let bank of banks" [value]="bank.id">{{ bank.description }}</option>
         </select>
         <div class="font-medium mt-4">Número de {{ data.method == 2? 'cheque' : 'documento' }}:</div>
         <input type=text [(ngModel)]="data.document">
      </div>
      <!-- Tarjeta Crédito/Débito -->
      <div *ngIf="data.method == 3 || data.method == 4">
         <div class="font-medium">Terminal de la tarjeta:</div>
         <input type=text [(ngModel)]="data.card" placeholder="últmos 4 dígitos">
         <div class="font-medium mt-4">Número de aprobación:</div>
         <input type=text [(ngModel)]="data.code">
         <div class="font-medium mt-4">Número de lote:</div>
         <input type=text [(ngModel)]="data.lot">
      </div>
   </div>

   <button (click)="pay()" [disabled]="!can_pay || working.paying" class="btn btn-blue mt-6">
      Pagar
      <working *ngIf="working.paying"></working>
   </button>
</div>