export function is_nothing(something: any) {
   return something === undefined || something === null;
}

export function copy<T>(object: T): T {
   if (is_nothing(object)) return null;
   else if (typeof(object) === 'object') {
      if (Array.isArray(object)) {
         const array = [];
         for (let item of object)
            array.push(copy(item));
         return <any>array;
      } else {
         const obj: any = {};
         for (let key of Object.keys(object))
            obj[key] = copy(object[key]);
         return obj;
      }
   } else return object;
}

export function clear_element(element: HTMLElement, mode: number = 1) {
   if (mode === 1)
      element.replaceWith(element.cloneNode(false));
   if (mode === 2) {
      for (let child of element.children as any)
         element.removeChild(child);
   }
}

export function sum(collection: number[]): number {
   return collection?.concat(0)?.reduce((a, b) => a + b) ?? 0;
}

export function zero_pad(number: string|number, length: number = 2): string {
   number = number.toString();
   return (number.length < length? '0'.repeat(length - number.length) : '') + number;
}

export function since(date: Date|string|number, timespan: { days?: number, hours?: number, minutes?: number, seconds?: number }): boolean {
   date = typeof date == 'number'? date : new Date(date).valueOf();
   let now = Date.now();
   const elapsed_milliseconds = now - date;

   const timespan_milliseconds = (
      (timespan.days    ?? 0) * 24 * 60 * 60 + 
      (timespan.hours   ?? 0) * 60 * 60 +
      (timespan.minutes ?? 0) * 60 +
      (timespan.seconds ?? 0)
   ) * 1000;

   return elapsed_milliseconds >= timespan_milliseconds;
}

export function date_to_short_string(date: string | Date): string {
   date = new Date(date);
   return `${date.getFullYear()}-${zero_pad(date.getMonth() + 1)}-${zero_pad(date.getDate())}`;
}

export function is_today(date: Date | string): boolean {
   date = new Date(date);
   const today = new Date(Date.now());

   return today.getDate() === date.getDate()
      && today.getMonth() === date.getMonth()
      && today.getFullYear() === date.getFullYear();
}

export function today(): Date {
   const today = new Date(Date.now());
   today.setHours(0, 0, 0, 0);
   return today;
}

export function format_phone(phone: string): string {
   const digits = Array.from(phone).filter(c => !isNaN(<any>c));
   return `(${digits.slice(0, 3).join('')}) ${digits.slice(3, 6).join('')}-${digits.slice(6).join('')}`;
}

export function range(start_or_length: number, end?: number): number[] {
   const result = [];
   if (is_nothing(end)) {
      end = start_or_length;
      start_or_length = 0;
   }
   for (let i = start_or_length; i < end; i++) 
      result.push(i);
   return result;
}

export function contains(obj: any, filter_term: string): boolean {
   filter_term = filter_term.toLowerCase();
   if (obj === undefined || obj === null)
      return false;
   else if (typeof obj === 'string')
      return obj.toLowerCase().includes(filter_term);
   else if (typeof obj === 'number')
      return obj.toString().includes(filter_term);
   else if (typeof obj === 'object') {
      if (Array.isArray(obj)) {
         return obj.some(o => contains(o, filter_term));
      } else {
         return Object.values(obj).some(o => contains(o, filter_term));
      }
   }
};

export function filter<T>(collection: T[], filter_term: string): T[] {
   filter_term = filter_term.toLowerCase();
   const contains: (obj: T) => boolean = (obj: T) => {
      if (obj === undefined || obj === null)
         return false;
      else if (typeof obj === 'string')
         return obj.toLowerCase().includes(filter_term);
      else if (typeof obj === 'number')
         return obj.toString().includes(filter_term);
      else if (typeof obj === 'object') {
         if (Array.isArray(obj)) {
            return obj.some(contains);
         } else {
            return Object.values(obj).some(contains);
         }
      }
   };
   return collection?.filter(contains);
}

export function distinct<T>(collection: T[], key?: (obj: T) => any): T[] {
   if (key)
      return collection.filter((obj, index) => collection.findIndex(o => key(o) === key(obj)) === index);
   return collection.filter((obj, index) => collection.indexOf(obj) === index);
}

export function titlecase(value: string, language: 'es' | 'en' = 'es'): string {
   if (!value)
      return null;
   const title = value?.toLowerCase()?.split(' ')
      .map(w => w?.length? w[0]?.toUpperCase() + w.substr(1) : '').join(' ');
   switch (language) {
      case 'es': return title.replace(/ De /g, ' de ');
      case 'en': return title.replace(/ Of /g, ' of ');
   }
}

export function random(lower_bound: number, upper_bound: number): number {
   return Math.round(lower_bound + Math.random() * (upper_bound - lower_bound + 1));
}

export function choose_random<T>(collection: T[]): T {
   return collection[random(0, collection.length - 1)];
}

export function subtract_days(date: string | Date, days: number) {
   date = new Date(date);
   date.setDate(date.getDate() - days);
   return date;
}

export function time_delta(delta: { days?: number, hours?: number, minutes?: number, seconds?: number, milliseconds?: number }): string {
   const delta_milliseconds = (
      (delta.days         ?? 0) * 24 * 60 * 60 * 1000 +
      (delta.hours        ?? 0) * 60 * 60 * 1000 +
      (delta.minutes      ?? 0) * 60 * 1000 +
      (delta.seconds      ?? 0) * 1000 +
      (delta.milliseconds ?? 0)
   );
   const days = Math.floor(delta_milliseconds / (24 * 60 * 60 * 1000));
   if (days)
      return `${days} dia${days == 1? '' : 's'}`;
   const hours = Math.floor(delta_milliseconds / (60 * 60 * 1000));
   if (hours)
      return `${hours} hora${hours == 1? '' : 's'}`;
   const minutes = Math.floor(delta_milliseconds / (60 * 1000));
   if (minutes)
      return `${minutes} minuto${minutes == 1? '' : 's'}`;
   const seconds = Math.floor(delta_milliseconds / (1000));
   if (seconds)
      return `${seconds} segundo${seconds == 1? '' : 's'}`;
   return '~1 segundo';
}

export function date_delta(date: Date | string): string {
   return time_delta({ milliseconds: Date.now() - new Date(date).valueOf() });
}

export function export_text_file(filename: string, contents: string) {
   var element = document.createElement('a');
   element.href = `data:text/plain;charset=utf-8,${encodeURIComponent(contents)}`;
   element.download = filename;
   element.style.display = 'none';
   document.body.appendChild(element);
   element.click();
   document.body.removeChild(element);
}

export function to_snake_case(text: string): string {
   if (!text) return null;
   return text.trim().split(' ').map(w => w.toLowerCase()).join('_');
}

export function matches<T>(item: T, term: string): boolean {
   if (!term) return true;
   if (!item) return false;

   term = term.toLowerCase();
   switch (typeof item) {
      case 'string':
         return item.toLowerCase().includes(term);
      case 'number':
      case 'bigint':
         let item_string = item.toString();
         if (!item_string.includes('.'))
            item_string += '.00';
         return item_string.includes(term);
      case 'object':
         if (Array.isArray(item))
            return item.some(i => matches(i, term));
         else
            return Object.values(item).some(v => matches(v, term));
      case 'boolean':
         return item.toString() == term;
      default:
         return false;
   }
}

export function qs(selector: string): HTMLElement | undefined {
   return document.querySelector(selector)
}

export function qsa(selector: string): HTMLElement[] {
   return Array.from(document.querySelectorAll(selector))
}

export function wrap(value: number, limit: number): number;
export function wrap(value: number, range_start: number, range_end: number): number;
export function wrap(value: number, range_start_or_limit: number, range_end?: number): number {
   if (range_end == undefined)
      return wrap(value, 0, range_start_or_limit)
   if (value > range_end)
      return range_start_or_limit + (value - range_start_or_limit) % (range_end - range_start_or_limit) - 1
   if (value < range_start_or_limit)
      return range_end - (range_start_or_limit - value) % (range_end - range_start_or_limit) + 1
   return value
}