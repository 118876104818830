<h1>Anulaciones</h1>
<div *ngIf="!transactions?.length"
   class="rounded-lg w-full shadow-md px-8 py-6 mt-8 bg-yellow-100 text-lg font-semibold">
   <fa-icon icon="info-circle"></fa-icon>
   <working *ngIf="!loaded" color="black"></working>
   <span *ngIf="loaded"> No tiene transacciones para anular en el lote abierto.</span>
</div>

<div id="printing-frames" class="invisible fixed top-0 insex-x-0 z-[-10]"></div>

<!-- Ultima transaccion -->
<div *ngIf="can_nullify_last && latest_transaction" class="card mt-8">
   <h3 class="border-b font-semibold">Ultima transacción en lote activo</h3>
   <div class="flex flex-col my-1 md:flex-row md:items-baseline">
      <div class="font-normal md:font-semibold md:w-44">ID de la transacción:</div>
      <div class="font-semibold md:font-normal">{{ latest_transaction.id }}</div>
   </div>
   <div class="flex flex-col my-1 md:flex-row md:items-baseline">
      <div class="font-normal md:font-semibold md:w-44">Facturador:</div>
      <div class="font-semibold md:font-normal">{{ latest_transaction.invoicer.description }}</div>
   </div>
   <div class="flex flex-col my-1 md:flex-row md:items-baseline">
      <div class="font-normal md:font-semibold md:w-44">Fecha y hora:</div>
      <div class="font-semibold md:font-normal">{{ latest_transaction.date | date:"dd/MM/yy, hh:mm aa":null:'es-DO' }}
      </div>
   </div>
   <div class="flex flex-col my-1 md:flex-row md:items-baseline">
      <div class="font-normal md:font-semibold md:w-44">Cliente:</div>
      <div class="font-semibold md:font-normal">{{ (latest_transaction.client_name? latest_transaction.client_name :
         latest_transaction.client_reference) }}</div>
   </div>
   <div class="flex flex-col my-1 md:flex-row md:items-baseline">
      <div class="font-normal md:font-semibold md:w-44">Monto:</div>
      <div class="font-semibold md:font-normal">{{ latest_transaction.amount | currency:'RD$ ' }}</div>
   </div>
   <textarea *ngIf="nulling_last" [(ngModel)]="nulling_last_reason" rows="2" class="block w-full rounded-md"
      [placeholder]="'Describa la razón de la anulación...'"></textarea>
   <div class="flex flex-row w-full mt-4">
      <button *ngIf="nulling_last" (click)="nulling_last = false" class="btn mr-2 md:max-w-max bg-gray-200">
         <fa-icon icon="times"></fa-icon>
         Cancelar
      </button>
      <button (click)="nulling_last? null_latest() : nulling_last = true"
         [disabled]="working_latest || (nulling_last && !nulling_last_reason)" class="btn md:max-w-max"
         [class.btn-yellow]="nulling_last" style="flex-grow: 1;">
         {{ working_latest? 'Anulando...' : 'Anular última transacción' }}
         <fa-icon *ngIf="!working_latest" icon="eraser"></fa-icon>
         <working *ngIf="working_latest" color="black"></working>
      </button>
   </div>
</div>

<div *ngIf="nullable_transactions?.length || null_requests?.length" class="card mt-8" [class.card-red]="offline">
   <!-- Anulaciones solicitadas -->
   <div *ngIf="null_requests?.length">
      <h3 class="border-b font-semibold">Anulaciones solicitadas en lote activo</h3>
      <div class="w-full my-4 md:table md:w-max md:border md:rounded">
         <div class="hidden md:block md:table-header-group">
            <div class="table-cell text-center font-semibold py-1 px-2" title="ID de la transacción">ID</div>
            <div class="table-cell text-center font-semibold py-1 px-2 border-l">Facturador</div>
            <div class="table-cell text-center font-semibold py-1 px-2 border-l">Fecha</div>
            <div class="table-cell text-center font-semibold py-1 px-2 border-l">Cliente</div>
            <div class="table-cell text-center font-semibold py-1 px-2 border-l">Monto</div>
            <div class="table-cell text-center font-semibold py-1 px-2 border-l">Fecha solicitada</div>
            <div class="table-cell text-center font-semibold py-1 px-2 border-l">Estado</div>
         </div>
         <div class="contents space-y-4 md:table-row-group">
            <div *ngIf="!null_requests?.length" class="w-full text-center md:table-row">
               <td title="No has solicitado anulación de transacciones en este lote" colspan="8"
                  class="border-t text-center text-gray-500 contents md:table-cell">
                  <fa-icon icon=times></fa-icon>
               </td>
            </div>
            <div *ngFor="let request of null_requests"
               class="bg-gray-50 rounded-md py-2 px-3 shadow-md md:bg-transparent md:px-0 md:py-0 md:shadow-none md:table-row md:even:bg-gray-100">
               <div class="flex flex-row md:contents">
                  <div class="flex-1 md:contents">
                     <div class="md:table-cell md:py-2 md:px-2 md:border-t">{{ request.transaction.id }}</div>
                     <div class="md:table-cell md:py-2 md:px-2 md:border-t md:border-l">{{
                        request.transaction.invoicer.description }}</div>
                  </div>
                  <div class="text-right md:text-left md:contents">
                     <div class="md:table-cell md:py-2 md:px-2 md:border-t md:border-l">{{ request.transaction.date |
                        date:'dd/MM/yy, hh:mm aa':null:'es-DO' }}</div>
                     <div class="md:table-cell md:py-2 md:px-2 md:border-t md:border-l">{{
                        request.transaction.client_name? request.transaction.client_name :
                        request.transaction.client_reference }}</div>
                  </div>
               </div>
               <div class="flex flex-row items-baseline border-t border-gray-500 mt-2 pt-2 md:contents">
                  <div class="flex-1 md:table-cell md:py-2 md:px-2 md:border-t md:border-l">{{
                     request.transaction.amount | currency:'RD$ '}}</div>
                  <div class="flex-1 md:table-cell md:py-2 md:px-2 md:border-t md:border-l">{{ request.date_requested |
                     date:'dd/MM/yy, hh:mm aa':null:'es-DO' }}</div>
                  <div class="md:table-cell md:py-2 md:px-2 md:border-t md:border-l">
                     <fa-icon *ngIf="!request.processed" icon="clock" title="En espera de respuesta del supervisor..."
                        class="md:text-lg text-gray-500"></fa-icon>
                     <fa-icon *ngIf="request.processed && request.approved" icon="check-circle"
                        title="Anulación aprobada por supervisor {{ request.supervisor?.name }}"
                        class="md:text-lg text-green-500"></fa-icon>
                     <fa-icon *ngIf="request.processed && !request.approved" icon="times-circle"
                        title="Rechazada por supervisor {{ request.supervisor?.name }}" class="md:text-lg text-red-500">
                     </fa-icon>
                     {{ !request.processed? 'En espera...' : request.approved? 'Anulada' : 'Rechazada' }}
                  </div>
               </div>
            </div>
         </div>
      </div>
      <br>
   </div>
   <!-- Transacciones anulables -->
   <h3 *ngIf="nullable_transactions?.length" class="border-b font-semibold">Transacciones {{ offline? 'en cola' :
      'anteriores en lote activo' }}</h3>
   <div *ngIf="nullable_transactions?.length" class="w-full my-4 md:table md:w-max md:border md:rounded">
      <div class="hidden md:block md:table-header-group">
         <div class="table-cell text-center font-semibold py-1 px-2" title="ID de la transacción">ID</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l">Facturador</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l">Fecha</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l">Cliente</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l">Monto</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l"></div>
      </div>
      <div class="contents space-y-4 md:table-row-group">
         <div *ngFor="let transaction of nullable_transactions"
            class="{{ transaction.status.id === -1? 'bg-yellow-50' : 'bg-gray-50' }}  rounded-md py-2 px-3 shadow-md md:bg-transparent md:shadow-none md:table-row md:even:bg-gray-100 md:relative">
            <div class="flex flex-row md:contents">
               <div class="flex-1 md:contents">
                  <div
                     class="md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50':'' }} md:pt-2 md:px-2 md:border-t {{ nullification_to_request === transaction.id? 'md:pb-[120px]' : 'md:pb-2' }}">
                     <span class="font-semibold md:hidden">ID: </span>
                     {{ transaction.status.id === -1? '(Offline)' : transaction.id }}
                  </div>
                  <div
                     class="md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50':'' }} md:py-2 md:px-2 md:border-t md:border-l">
                     <span class="font-semibold md:hidden">Fac: </span>
                     {{ transaction.invoicer.description }}
                  </div>
               </div>
               <div class="text-right md:text-left md:contents">
                  <div
                     class="md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50':'' }} md:py-2 md:px-2 md:border-t md:border-l">
                     {{ transaction.date | date:'hh:mm aa dd/MM/yy':null:'es-DO' }}</div>
                  <div
                     class="md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50':'' }} md:py-2 md:px-2 md:border-t md:border-l">
                     <span class="font-semibold md:hidden">Cliente: </span>
                     {{ transaction.client_name? transaction.client_name : transaction.client_reference }}
                  </div>
               </div>
            </div>
            <div class="flex flex-row items-baseline border-t border-gray-500 mt-2 pt-2 md:contents">
               <div
                  class="flex-1 md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50':'' }} md:py-2 md:px-2 md:border-t md:border-l">
                  {{ transaction.amount | currency:'RD$ ' }}</div>
               <div
                  class="md:table-cell {{ transaction.status.id === -1? 'md:bg-yellow-50':'' }} md:py-2 md:px-2 md:border-t md:border-l">
                  <div class="flex flex-row">
                     <button *ngIf="nullification_to_request === transaction.id"
                        (click)="nullification_to_request = null" class="btn"
                        style="padding: 0 7px; margin-right: 2px;">
                        <fa-icon icon="times"></fa-icon>
                     </button>
                     <button *ngIf="transaction.status.id !== -1"
                        (click)="nullification_to_request === transaction.id? request_null(transaction) : nullification_to_request = transaction.id"
                        [disabled]="working_null || (nullification_to_request === transaction.id && !nullification_request_reason)"
                        class="btn" [class.btn-yellow]="nullification_to_request === transaction.id"
                        style="padding: 1px 14px; flex-grow: 1;"
                        title="Solicitar la anulación de esta transacción que debe ser aprobada por el supervisor del punto de venta">
                        {{ working_null === transaction? 'Solicitando...' : 'Solicitar anulación' }}
                        <fa-icon *ngIf="working_null !== transaction" icon="eraser"></fa-icon>
                        <working *ngIf="working_null === transaction" color="black"></working>
                     </button>
                     <button
                        *ngIf="transaction.status.id === -1 && !was_processed(transaction.sequence) && !is_being_processed(transaction.sequence)"
                        (click)="cancel(transaction.sequence)" [disabled]="working_cancel" class="btn"
                        style="padding: 1px 14px; flex-grow: 1;">
                        {{ working_cancel === transaction.sequence? 'Cancelando...' : 'Cancelar pago' }}
                        <fa-icon *ngIf="working_cancel !== transaction.sequence" icon="eraser"></fa-icon>
                        <working *ngIf="working_cancel === transaction.sequence" color="black"></working>
                     </button>
                     <div *ngIf="transaction.status.id === -1 && is_being_processed(transaction.sequence)"
                        class="w-min mx-auto">
                        <working color="black"></working>
                     </div>
                  </div>
               </div>
            </div>
            <div *ngIf="nullification_to_request === transaction.id" class="mt-2 md:absolute md:bottom-0 md:inset-x-0">
               <textarea [(ngModel)]="nullification_request_reason" class="w-full h-[100px] rounded-md"
                  placeholder="Describa la razón de la anulación..."></textarea>
            </div>
         </div>
      </div>
   </div>
</div>