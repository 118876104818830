<h1>Preguntas Frecuentes</h1>
<div class="mt-8 card">
   <h2>Cajeros</h2>
   <div class="mt-3">
      <fa-icon icon="question-circle"></fa-icon>
      <span class="font-bold ml-2">¿Qué datos necesito para realizar un pago a un cliente?</span>
      <div class="ml-6">
         Puedes consultar el balance de un cliente con cualquiera de los siguientes datos:
         <ul class="list list-disc list-inside">
            <li>Número de Contrato</li>
            <li>Cédula del Titular</li>
         </ul>
      </div>
   </div>
   <div class="mt-3">
      <fa-icon icon="question-circle"></fa-icon>
      <span class="font-bold ml-2">¿Es obligatorio que el cliente presente la factura al hacer un cobro?</span>
      <div class="ml-6">
         No.  El pago puede ser realizado sin que el cliente presente la factura.
      </div>
   </div>
   <div class="mt-3">
      <fa-icon icon="question-circle"></fa-icon>
      <span class="font-bold ml-2">¿Puedo reimprimir un comprobante de pago?</span>
      <br>
      <div class="ml-6">
         <!-- @TODO(Gorky): Convertir breadcrumb en router-link -->
         Sí.  Para reimprimir un comprobante, en el menú elige la opción <span class="font-mono text-blue-800 text-sm">Cajero <fa-icon icon="chevron-right" class="text-xs"></fa-icon> Reporte</span>.  
         <br>
         En el listado de transacciones busca la que te interesa reimprimir, y haz clic en el botón <button class="p-[3px] border border-gray-500 rounded-md font-semibold pointer-events-none"><div class="inline-block w-[30px]"><fa-icon icon="print"></fa-icon></div><span class="pr-2 md:hidden">Imprimir copia</span></button>.
         <br>
         Ten presente que sólo puedes reimprimir comprobantes del lote abierto que tengas.  
         <br>
         No es posible reimprimir comprobantes de un lote cerrado. 
      </div>
   </div>
   <div class="mt-3">
      <fa-icon icon="question-circle"></fa-icon>
      <span class="font-bold ml-2">¿Qué pasa si no ejecuto el cierre de lote?</span>
      <br>
      <div class="ml-6">
         Si no ejecutas el cierre de lote, tu supervisor puede hacerlo por ti.  
         <br>
         En caso de que ninguno de los dos lo haga, el sistema lo cerrará de manera automática cuando termine el día.
      </div>
   </div>
   <div class="mt-3">
      <fa-icon icon="question-circle"></fa-icon>
      <span class="font-bold ml-2">¿Puedo abrir más de un lote el mismo día?</span>
      <br>
      <div class="ml-6">
         Sí.  Puedes abrir cuantos lotes necesites, el mismo día.
      </div>
   </div>
</div>