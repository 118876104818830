import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Item } from 'src/app/models/models';
import { State } from 'src/app/state';
import { error_toast, success_toast, warning_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-create-edit-contract-bundle',
  templateUrl: './create-edit-contract-bundle.component.html',
})
export class CreateEditContractBundleComponent {
  working = {
    saving: false
  };

  bundle_id: number = null;

  invoicers: Item[];

  client_name: string;
  invoicer_id: number;
  contracts: string[] = [];
  contract: string;

  constructor(private api: ApiService, private router: Router, active_route: ActivatedRoute) {
    active_route.params.subscribe(params => {
      if (params.id !== 'new') {
        this.bundle_id = Number.parseInt(params.id);
        api.get_contract_bundle(this.bundle_id, response => {
          if (response.succeeded) {
            this.client_name = response.data.description;
            this.invoicer_id = response.data.invoicer.id;
            this.contracts = response.data.references;
          } else error_toast(response.error.message);
        });
      }
    })
    api.get_point_of_sales_invoicers(State.user.point_of_sales.id, response => {
      if (response.succeeded) {
        this.invoicers = response.data;
      } else error_toast(response.error.message);
    });
  }

  add() {
    if (this.contract?.trim()) {
      for (let contract of this.contract.split(/[\s,;]+/g).filter(_ => _?.trim()))
        this.contracts.push(contract?.trim());
      this.contract = null;
    }
  }

  save() {
    this.working.saving = true;
    if (this.bundle_id) {
      this.api.edit_contract_bundle(this.bundle_id, this.client_name, this.contracts, response => {
        this.working.saving = false;
        if (response.succeeded) {
          success_toast('Bolsa de contratos actualizada');
          this.router.navigate(['', 'cashier', 'bundles']);
        } else error_toast(response.error.message);
      });
    } else {
      this.api.create_contract_bundle(this.client_name, Number.parseInt(<any>this.invoicer_id), this.contracts, response => {
        this.working.saving = false;
        if (response.succeeded) {
          success_toast('Bolsa de contratos creada');
          this.router.navigate(['', 'cashier', 'bundles']);
          this.api.query_contract_bundle(response.data, response => {
            if (response.succeeded)
              warning_toast('Nueva bolsa de contratos en proceso de consulta...');
            else
              error_toast('Error al programar consulta de bolsa de contratos');
          });
        } else error_toast(response.error.message);
      });
    }
  }
}
