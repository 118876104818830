import { ChangeDetectorRef, Component } from '@angular/core'
import { ApiService } from 'src/app/api.service'
import { OfflineDatabase, QueuedPayment, when_processing_payment } from 'src/app/offline_db'
import {
	InvoicerQueriableView,
	Item,
	TransactionView,
	UserNullificationRequestView,
} from 'src/app/models/models'
import { State } from 'src/app/state'
import { modal } from 'src/app/utils/modal.util'
import { error_toast, success_toast } from 'src/app/utils/toast.util'
import { clear_element, is_nothing } from 'src/app/utils/utils'
import { receipt, wrap_print } from 'src/app/utils/receipt.util'
import PrintService from 'src/app/print.service'

@Component({
	selector: 'app-nullification',
	templateUrl: './nullification.component.html',
	styleUrls: [],
})
export class NullificationComponent {
	loaded = false
	working_latest = false
	working_null = null
	working_cancel = null
	transactions: TransactionView[] = null
	get nullable_transactions(): TransactionView[] {
		const req_ids = this.null_requests?.filter((n) => !n.processed).map((n) => n.transaction.id)
		return this.transactions?.filter(
			(t) => t.id !== this.latest_transaction?.id && !req_ids?.find((i) => i === t.id)
		)
	}
	latest_transaction: TransactionView
	null_requests: UserNullificationRequestView[] = null

	nulling_last = false
	nulling_last_reason: string

	nullification_to_request: number
	nullification_request_reason: string

	can_nullify_last: boolean = !State.user_is_cashier_no_void

	offline = false

	constructor(private api: ApiService, private change_detector: ChangeDetectorRef) {
		api.get_nullable_last_transaction((response) => {
			if (response.succeeded) this.latest_transaction = response.data
			if (this.transactions?.length) {
				this.transactions = this.transactions.filter((t) => t.id === this.latest_transaction?.id)
			}
		})
		const was_online = State.online
		api.get_completed_transactions((response) => {
			this.loaded = true
			if (response.succeeded) {
				this.transactions = response.data.filter((t) => t.id !== this.latest_transaction?.id)
				api.get_user_nullification_requests((response) => {
					if (response.succeeded) this.null_requests = response.data
					else error_toast(response.error.message)
				})
			} else if (response.error?.code !== 'OFFLINE' || was_online /* but detected connection loss */)
				error_toast(response.error.message)
			this.load_queued_payments()
			this.offline = !State.online
		})
	}

	load_queued_payments() {
		this.queued_payments_that_were_processed_keys = []
		this.queued_payments_being_processed_keys = []
		OfflineDatabase.get_queued_payments((data) => {
			if (data) {
				const transactions = data.map((qp) => payment_to_transaction(qp))
				if (is_nothing(this.transactions)) this.transactions = []
				this.transactions = transactions.concat(this.transactions)
				when_processing_payment((keys) => {
					this.queued_payments_that_were_processed_keys = (
						this.queued_payments_that_were_processed_keys ?? []
					).concat(this.queued_payments_being_processed_keys)
					this.queued_payments_being_processed_keys = keys
					this.change_detector.detectChanges()
					if (!keys) {
						this.api.get_nullable_last_transaction((response) => {
							if (response.succeeded) this.latest_transaction = response.data
						})
						this.api.get_completed_transactions((response) => {
							if (response.succeeded) {
								this.transactions = response.data
								this.load_queued_payments()
							}
						})
					}
				})
			}
		})
	}

	queued_payments_that_were_processed_keys: number[]
	queued_payments_being_processed_keys: number[]

	was_processed(key: number): boolean {
		return this.queued_payments_that_were_processed_keys?.includes(key)
	}
	is_being_processed(key: number): boolean {
		return this.queued_payments_being_processed_keys?.includes(key)
	}

	get printing_frame_container(): HTMLDivElement {
		return <HTMLDivElement>document.getElementById('printing-frames')
	}

	printing: number
	print(transactionId: number) {
		this.printing = transactionId
		this.api.get_transaction(transactionId, async (response) => {
			if (response.succeeded) {
				await PrintService.render('transaction', { transaction: response.data })
				this.printing = null
				return
			} else {
				this.printing = null
				error_toast(response.error.message)
			}
		})
	}


	null_latest() {
		this.working_latest = true
		this.api.nullify_last_transaction(this.nulling_last_reason, (response) => {
			if (response.succeeded) {
				const data = response.data.nullification_results
				const transactionId = response.data.nullifying_transaction_id
				if (data.every((d) => d.applied)) {
					success_toast('Transacción anulada')

					this.print(transactionId)

					// this.api.get_transaction(response.data.nullifying_transaction_id, (response) => {
					// 	if (response.succeeded) {
					// 		const transaction = response.data

					// 		clear_element(this.printing_frame_container)

					// 		this.api.get_print_type((response) => {
					// 			if (response.succeeded) {
					// 				const print_type = response.data
					// 				const print_contents = receipt(transaction, print_type)

					// 				if (State.on_cordova) {
					// 					;(window as any).codorva.plugins.printer.print(wrap_print(print_contents))
					// 					return
					// 				}

					// 				const frame = document.createElement('iframe')
					// 				frame.setAttribute(
					// 					'style',
					// 					'visibility: false; position: fixed; top: 0; left: 0; right: 0; z-index: -10;'
					// 				)
					// 				this.printing_frame_container.appendChild(frame)

					// 				frame.contentWindow.document.write(print_contents)
					// 			} else error_toast(response.error.message)
					// 		})
					// 	} else {
					// 		error_toast(response.error.message)
					// 	}
					// })
				} else if (data.every((d) => !d.applied)) {
					error_toast('Transacción NO anulada:\n - ' + data.map((d) => d.error).join('\n - ') + '')
				} else {
					error_toast('!!! Transacción anulada PARCIALMENTE.  Contacte a soporte tecnico. !!!')
				}

				this.api.get_nullable_last_transaction((response) => {
					this.working_latest = false
					if (response.succeeded) this.latest_transaction = response.data
					else error_toast(response.error.message)
				})
			} else {
				this.working_latest = false
				error_toast(response.error.message)
			}
		})
	}

	request_null(transaction: TransactionView) {
		this.working_null = transaction
		this.api.request_nullification(transaction.id, this.nullification_request_reason, (response) => {
			if (response.succeeded) {
				this.nullification_request_reason = null
				this.api.get_user_nullification_requests((response) => {
					this.working_null = null
					if (response.succeeded) this.null_requests = response.data
					else error_toast(response.error.message)
				})
			} else {
				this.working_null = null
				error_toast(response.error.message)
			}
		})
	}

	cancel(key: number) {
		modal(
			'Cancelar pago',
			'Este pago se realizó mientras el dispositivo estuvo fuera de linea y aún no se ha aplicado.  ' +
				'\n¿Desea cancelar este pago?',
			['!Eliminar pago', '_Atrás'],
			(result) => {
				if (result.id === 0) {
					this.working_cancel = key

					OfflineDatabase.delete_queued_payment(key, () => {
						this.working_cancel = null

						this.transactions.splice(
							this.transactions.findIndex((t) => t.status.id === -1 && t.sequence === key),
							1
						)
					})
				}
			}
		)
	}
}

function invoicer_queriable_to_item(invoicer: InvoicerQueriableView): Item {
	return { id: invoicer.id, description: invoicer.name }
}

function payment_to_transaction(queued_payment: QueuedPayment): TransactionView {
	const payment = queued_payment.payment
	return <TransactionView>{
		id: -1,
		lot: -1,
		sequence: queued_payment.key,
		amount: payment.total,
		client_name: payment.client_name,
		client_reference: payment.client_reference,
		date: <any>queued_payment.date,
		invoicer: invoicer_queriable_to_item(State.invoicers.find((i) => i.id === payment.invoicer_id)),
		prepaid: false,
		status: { id: -1, description: 'Fuera de línea' },
		user: State.user,
		payment_method_id: payment.payment_method_id,
		geo_longitude: payment.geo_longitude,
		geo_latitude: payment.geo_latitude,
	}
}
