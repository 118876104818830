import { ActivatedRoute } from '@angular/router'
import { ChangeDetectorRef, Component } from '@angular/core'
import { ApiService } from 'src/app/api.service'
import { OfflineDatabase, QueuedPayment, when_processing_payment } from 'src/app/offline_db'
import { ApiResponse, InvoicerQueriableView, Item, LotReport, TransactionView } from 'src/app/models/models'
import { State } from 'src/app/state'
import { error_toast } from 'src/app/utils/toast.util'
import { is_nothing, sum } from 'src/app/utils/utils'
import PrintService from 'src/app/print.service'

@Component({
	selector: 'app-report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.less'],
})
export class ReportComponent {
	loaded = false

	point_of_sales
	report: LotReport

	transaction_groups: { label: string; transactions: TransactionView[] }[]

	selected_invoicer: number = 0
	get selected_report() {
		if (this.selected_invoicer == 0) return this.report
		else return this.report.invoicers.find((i) => i.invoicer.id == this.selected_invoicer)
	}

	get has_multiple_modalities() {
		return this.report.total_prepaid && this.report.total_postpaid
	}

	constructor(private api: ApiService, private change_detector: ChangeDetectorRef, active_route: ActivatedRoute) {
		active_route.params.subscribe((params) => {
			const was_online = State.online
			this.point_of_sales = State.user.point_of_sales?.name

			const handle_lot_report = (response: ApiResponse<LotReport>) => {
				if (response.succeeded) {
					this.loaded = true
					this.report = response.data
				} else if (response.error?.code !== 'OFFLINE' || was_online /* but detected connection lost */) error_toast(response.error.message)
				this.load_queued_payments_into_report()
			}
			if (params.id) {
				api.get_user_lot(params.id, handle_lot_report)
			} else {
				api.get_active_lot_report(handle_lot_report)
			}

			const self: ReportComponent = this
			this.transaction_groups = [
				{
					label: 'Transacciones en Efectivo',
					get transactions() {
						return self.selected_report.cash_transactions
					},
				},
				{
					label: 'Transacciones en Cheque',
					get transactions() {
						return self.selected_report.cheque_transactions
					},
				},
				{
					label: 'Transacciones en Tarjeta de Crédito',
					get transactions() {
						return self.selected_report.card_transactions
					},
				},
				{
					label: 'Transacciones en Transferencia',
					get transactions() {
						return self.selected_report.bank_transfer_transactions
					},
				},
				{
					label: 'Transacciones en Tarjeta Bonoluz',
					get transactions() {
						return self.selected_report.bonoluz_transactions
					},
				},
				{
					label: 'Anulaciones',
					get transactions() {
						return self.selected_report.nullified_transactions
					},
				},
			]
		})
	}

	load_queued_payments_into_report() {
		OfflineDatabase.get_queued_payments((data) => {
			if (data?.length) {
				const transactions = data.map((qp) => payment_to_transaction(qp))
				const invoicers = transactions.map((t) => t.invoicer).filter((inv, i, arr) => arr.indexOf(inv) === i)
				this.loaded = true

				if (is_nothing(this.report)) this.report = <any>{}

				this.report = <any>{
					id: this.report.id ?? -1,
					point_of_sales: this.report.point_of_sales ?? null,
					date_opened: this.report.date_opened ?? <any>data[0].date,
					date_closed: this.report.date_closed ?? null,

					cash_transactions: transactions.filter((t: any) => t.payment_method_id === 1).concat(this.report.cash_transactions ?? []),
					cheque_transactions: transactions.filter((t: any) => t.payment_method_id === 2).concat(this.report.cheque_transactions ?? []),
					card_transactions: transactions.filter((t: any) => [3, 4].includes(t.payment_method_id)).concat(this.report.card_transactions ?? []),
					bonoluz_transactions: transactions.filter((t: any) => t.payment_method_id === 5).concat(this.report.bonoluz_transactions ?? []),
					bank_transfer_transactions: transactions.filter((t: any) => t.payment_method_id === 8).concat(this.report.bank_transfer_transactions ?? []),
					nullified_transactions: [],
				}
				this.report.invoicers = <any>invoicers.map((invoicer) => ({
					invoicer,
					cash_transactions: this.report.cash_transactions.filter((t) => t.invoicer === invoicer),
					cheque_transactions: this.report.cheque_transactions.filter((t) => t.invoicer === invoicer),
					card_transactions: this.report.card_transactions.filter((t) => t.invoicer === invoicer),
					bonoluz_transactions: this.report.bonoluz_transactions.filter((t) => t.invoicer === invoicer),
					bank_transfer_transactions: this.report.bank_transfer_transactions.filter((t) => t.invoicer === invoicer),
					nullified_transactions: [],
				}))

				for (let invoicer_report of this.report.invoicers.concat([<any>this.report])) {
					invoicer_report.total_cash = sum(invoicer_report.cash_transactions.map((t) => t.amount))
					invoicer_report.total_cheque = sum(invoicer_report.cheque_transactions.map((t) => t.amount))
					invoicer_report.total_card = sum(invoicer_report.card_transactions.map((t) => t.amount))
					invoicer_report.total_bonoluz = sum(invoicer_report.bonoluz_transactions.map((t) => t.amount))
					invoicer_report.total_bank_transfer = sum(invoicer_report.bank_transfer_transactions.map((t) => t.amount))
					invoicer_report.total_nullified = 0
					invoicer_report.total =
						invoicer_report.total_cash +
						invoicer_report.total_cheque +
						invoicer_report.total_card +
						invoicer_report.total_bonoluz +
						invoicer_report.total_bank_transfer
				}

				when_processing_payment((keys) => {
					this.queued_payments_being_processed_keys = keys
					this.change_detector.detectChanges()
					if (!keys)
						this.api.get_active_lot_report((response) => {
							if (response.succeeded) {
								this.report = response.data
								this.load_queued_payments_into_report()
								this.change_detector.detectChanges()
							}
						})
				})
			}
		})
	}

	queued_payments_being_processed_keys: number[]

	is_being_processed(key: number): boolean {
		return this.queued_payments_being_processed_keys?.includes(key)
	}

	get printing_frame_container(): HTMLDivElement {
		return <HTMLDivElement>document.getElementById('printing-frames')
	}

	printing: number
	print(transaction: TransactionView) {
		this.printing = transaction.id
		this.api.get_transaction(transaction.id, async (response) => {
			if (response.succeeded) {
				await PrintService.render('transaction', { transaction: response.data })
				this.printing = null
				return
			} else {
				this.printing = null
				error_toast(response.error.message)
			}
		})
	}

	async print_report(modality?: 'prepaid' | 'pospaid') {
		console.debug('print modality:', modality)
		switch (modality) {
			case undefined:
				this.printing = -1
				await PrintService.render('report', this.report)
				break
			case 'pospaid':
				this.printing = -1
				const postpaid_report = report_by_modality(this.report, 'postpaid')
				await PrintService.render('report', postpaid_report)
				break
			case 'prepaid':
				this.printing = -2
				const prepaid_report = report_by_modality(this.report, 'prepaid')
				await PrintService.render('report', prepaid_report)
				break
		}
		this.printing = null
		return
	}
}

function invoicer_queriable_to_item(invoicer: InvoicerQueriableView): Item {
	return { id: invoicer.id, description: invoicer.name }
}

function payment_to_transaction(queued_payment: QueuedPayment): TransactionView {
	const payment = queued_payment.payment
	return <TransactionView>{
		id: -1,
		lot: -1,
		sequence: queued_payment.key,
		amount: payment.total,
		client_name: payment.client_name,
		client_reference: payment.client_reference,
		date: <any>queued_payment.date,
		invoicer: invoicer_queriable_to_item(State.invoicers.find((i) => i.id === payment.invoicer_id)),
		prepaid: false,
		status: { id: -1, description: 'Fuera de línea' },
		user: State.user,
		payment_method_id: payment.payment_method_id,
		geo_longitude: payment.geo_longitude,
		geo_latitude: payment.geo_latitude,
	}
}

function report_by_modality(report: LotReport, modality: 'postpaid' | 'prepaid'): LotReport {
	const modality_report = { ...report }
	modality_report.total_prepaid = modality_report.total_postpaid = modality_report.total = 0
	Object.keys(modality_report)
		.filter((k) => k.endsWith('transactions') && !k.startsWith('debit_card'))
		.forEach((key) => {
			modality_report[key] = modality_report[key].filter((t) => (modality === 'postpaid' ? !t.prepaid : t.prepaid))
			const total = sum(modality_report[key].map((t) => t.amount))
			modality_report['total_' + key.replace('_transactions', '')] = total
			if (modality === 'postpaid') modality_report.total_postpaid += total
			else modality_report.total_prepaid += total
		})
	modality_report.total = modality_report.total_postpaid + modality_report.total_prepaid
	modality_report.invoicers.forEach((report) => {
		report.total_prepaid = report.total_postpaid = report.total = 0
		Object.keys(report)
			.filter((k) => k.endsWith('transactions') && !k.startsWith('debit_card'))
			.forEach((key) => {
				report[key] = report[key].filter((t) => (modality === 'postpaid' ? !t.prepaid : t.prepaid))
				const total = sum(report[key].map((t) => t.amount))
				report['total_' + key.replace('_transactions', '')] = total
				if (modality === 'postpaid') report.total_postpaid += total
				else report.total_prepaid += total
			})
		report.total = report.total_postpaid + report.total_prepaid
	})
	modality_report.invoicers = modality_report.invoicers.filter((i) => i.total)
	return modality_report
}
