import { Component } from '@angular/core'
import { environment } from 'src/environments/environment'
import { ApiService } from './api.service'
import PrintService from './print.service'
import { State } from './state'
import { is_nothing } from './utils/utils'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styles: [
		`
			.print-shown {
				display: none;
			}
			@media print {
				.print-hidden {
					display: none;
				}
				.print-shown {
					display: initial;
				}
			}
		`,
	],
})
export class App {
	get app_environment() {
		return environment.production ? null : 'AMBIENTE DESARROLLO'
	}

	get print() {
		return PrintService
	}

	get cordova() {
		return State.on_cordova
	}

	// API Service
	private static _api: ApiService
	private static _api_callbacks: ((api: ApiService) => void)[] = []
	private static set_api(value: ApiService) {
		App._api = value
		for (let callback of App._api_callbacks) callback?.(App._api)
	}
	static api(callback: (api: ApiService) => void) {
		if (!is_nothing(App._api)) callback?.(App._api)
		else App._api_callbacks.push(callback)
	}

	constructor(api: ApiService) {
		App.set_api(api)
		environment.debug('On cordova:', State.on_cordova)
		environment.debug('Location:', window.location)
	}
}
