import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/api.service'
import { PointOfSalesDetailedView } from 'src/app/models/models'
import { State } from 'src/app/state'
import { error_toast, success_toast } from 'src/app/utils/toast.util'

@Component({
	selector: 'app-pos-configuration',
	templateUrl: './pos-configuration.component.html',
	styleUrls: [],
})
export class PosConfigurationComponent {
	loaded = false
	working = false
	new_pos = false
	tab = 0
	pos: PointOfSalesDetailedView = null

	invoicers = []
	payment_methods = []
	acquirers = []
	invoice_payment_policies = []

	constructor(private api: ApiService, active_route: ActivatedRoute, private router: Router) {
		active_route.params.subscribe((params) => {
			const id = Number.parseInt(params.id)
			if (!Number.isNaN(id)) {
				api.get_point_of_sales(id, (response) => {
					if (response.succeeded) {
						this.pos = response.data
						this.loaded = true
					} else {
						error_toast(response.error.message)
						router.navigate(['system', 'points-of-sales'])
					}
				})
			} else if (params.id === 'new') {
				this.new_pos = true
				this.loaded = true
				this.pos = {
					id: null,
					name: null,
					address: null,
					cpe_code: null,
					cpe_token: null,
					geolocation_required: false,
					otp_required: false,
					enabled: true,
					invoicers: [],
					organizations: [],
				}
			} else {
				router.navigate(['system', 'points-of-sales'])
			}
			api.get_invoicers((response) => {
				if (response.succeeded) {
					this.invoicers = response.data
				} else error_toast(response.error.message)
			})
			api.get_payment_methods((response) => {
				if (response.succeeded) {
					this.payment_methods = response.data
				} else error_toast(response.error.message)
			})
			api.get_acquirers((response) => {
				if (response.succeeded) {
					this.acquirers = response.data
				} else error_toast(response.error.message)
			})
			api.get_payment_policies((response) => {
				if (response.succeeded) {
					this.invoice_payment_policies = response.data
				} else error_toast(response.error.message)
			})
		})
	}

	selectable_invoicers: SelectableInvoicer[] = []
	selectable_invoicer(id: number): SelectableInvoicer {
		let sel = this.selectable_invoicers.find((s) => s.id === id)
		if (sel) return sel
		else {
			sel = { id, selected: this.pos.invoicers.some((i) => i.id === id) }
			this.selectable_invoicers.push(sel)
			return sel
		}
	}
	get selected_invoicers(): number[] {
		return this.invoicers
			.map((i) => this.selectable_invoicer(i.id))
			.filter((s) => s.selected)
			.map((s) => s.id)
	}

	selectable_payment_methods: SelectablePaymentMethod[] = []
	selectable_payment_method(invoicer_id: number, payment_method_id: number) {
		let sel = this.selectable_payment_methods.find(
			(s) => s.invoicer_id === invoicer_id && s.payment_method_id === payment_method_id
		)
		if (sel) return sel
		else {
			sel = {
				invoicer_id,
				payment_method_id,
				selected: this.pos.invoicers
					.find((i) => i.id === invoicer_id)
					.payment_methods.some((p) => p.id === payment_method_id),
			}
			this.selectable_payment_methods.push(sel)
			return sel
		}
	}
	selected_payment_methods(invoicer_id: number): number[] {
		return this.payment_methods
			.map((p) => this.selectable_payment_method(invoicer_id, p.id))
			.filter((s) => s.selected)
			.map((s) => s.payment_method_id)
	}

	save_info() {
		this.working = true
		if (this.new_pos) {
			this.api.create_point_of_sales(
				this.pos.name?.trim(),
				this.pos.address?.trim(),
				this.pos.cpe_code?.trim(),
				this.pos.cpe_token?.trim(),
				this.pos.geolocation_required,
				this.pos.otp_required,
				this.pos.enabled,
				(response) => {
					this.working = false
					if (response.succeeded) {
						success_toast('Punto de Venta creado')
					} else {
						error_toast(response.error.message)
					}
				}
			)
		} else {
			this.api.edit_point_of_sales(
				this.pos.id,
				this.pos.name?.trim(),
				this.pos.address?.trim(),
				this.pos.cpe_code?.trim(),
				this.pos.cpe_token?.trim(),
				this.pos.geolocation_required,
				this.pos.otp_required,
				this.pos.enabled,
				(response) => {
					this.working = false
					if (response.succeeded) {
						success_toast('Información del Punto de Venta actualizada')
						if (this.pos.id === State.user.point_of_sales.id)
							this.api.authenticate((response) => {
								State.user = response.data
							})
					} else error_toast(response.error.message)
				}
			)
		}
	}

	save_invoicers() {
		this.working = true
		this.api.associate_invoicers_to_point_of_sales(this.pos.id, this.selected_invoicers, (response) => {
			if (response.succeeded) {
				this.api.get_point_of_sales(this.pos.id, (response) => {
					this.working = false
					if (response.succeeded) {
						this.pos = response.data
						this.selectable_invoicers = []
					} else {
						error_toast(response.error.message)
					}
				})
				success_toast('Facturadores del Punto de Venta actualizados')
			} else {
				this.working = false
				error_toast(response.error.message)
			}
		})
	}

	save_methods() {
		this.working = true
		this.api.associate_payment_methods_to_point_of_sales_invoicers(
			this.pos.id,
			this.pos.invoicers.map((i) => ({
				invoicer_id: i.id,
				payment_method_ids: this.selected_payment_methods(i.id),
			})),
			(response) => {
				if (response.succeeded) {
					this.api.get_point_of_sales(this.pos.id, (response) => {
						this.working = false
						if (response.succeeded) {
							this.pos = response.data
							this.selectable_payment_methods = []
						} else {
							error_toast(response.error.message)
						}
					})
					success_toast('Metodos de Pago del Punto de Venta actualizados')
				} else {
					this.working = false
					error_toast(response.error.message)
				}
			}
		)
	}

	save_acquirers() {
		this.working = true
		this.api.associate_acquirers_to_point_of_sales_invoicers(
			this.pos.id,
			this.pos.invoicers.map((i) => ({
				invoicer_id: i.id,
				acquirer_id: i.acquirer.id ? Number.parseInt(<any>i.acquirer.id) : null,
				affiliate_number: i.acquirer.id ? i.acquirer.affiliate_number : null,
			})),
			(response) => {
				this.working = false
				if (response.succeeded) success_toast('Adquirientes del Punto de Venta actualizados')
				else error_toast(response.error.message)
			}
		)
	}

	save_policies() {
		this.working = true
		this.api.configure_point_of_sales_invoicer_policies(
			this.pos.id,
			this.pos.invoicers.map((i) => ({
				invoicer_id: i.id,
				payment_policy_id: i.invoice_payment_policy.id
					? Number.parseInt(<any>i.invoice_payment_policy.id)
					: null,
				partial_payments: i.partial_payments,
				consolidate_payment: i.consolidate_payment,
			})),
			(response) => {
				this.working = false
				if (response.succeeded) success_toast('Políticas de Pago del Punto de Venta actualizadas')
				else error_toast(response.error.message)
			}
		)
	}
}

interface SelectableInvoicer {
	id: number
	selected: boolean
}

interface SelectablePaymentMethod {
	invoicer_id: number
	payment_method_id: number
	selected: boolean
}
