import { Pipe, PipeTransform } from "@angular/core";
import { matches } from "./utils";

@Pipe({ name: 'filter' })
export class FilterPipe<T> implements PipeTransform {
   transform(collection: T[], term: string, keys?: string[]): T[] {
      if (keys?.length) {
         if (collection?.length && typeof collection[0] != 'object')
            throw "FilterError: The items of this filtered collection do not accept named keys.";
         return collection?.filter(i => matches(keys.map(k => i[k]), term?.toString()));
      }
      return collection?.filter(i => matches(i, term?.toString()));
   }
}