import { AfterContentInit, Component, Input } from '@angular/core'
import { ApiService } from 'src/app/api.service'
import { TransactionDetailedView } from 'src/app/models/models'
import PrintService from 'src/app/print.service'
import { format_phone } from '../../utils'

@Component({
	selector: 'transaction-receipt',
	templateUrl: './transaction-receipt.component.html',
	styles: [],
})
export class TransactionReceiptComponent implements AfterContentInit {
	@Input() transaction: TransactionDetailedView
	@Input() extra: any

	@Input() auto_print = true

	get invoicer() {
		return this.transaction.invoicer
	}
	get offline() {
		return this.transaction.status.id === -1
	}
	get extra_info() {
		return this.transaction.extra_info
	}
	get nullification() {
		return this.transaction.type?.id === 2
	}
	get prepaid() {
		return this.transaction.type?.id === 3
	}
	get nullified() {
		return this.transaction.status.id === 6
	}
	get stepped_report() {
		return this.extra_info?.formatted_month_energy_report
			?.split('\n')
			?.map((r) => r.trim())
			?.filter((r) => r)
			?.map((r) => r.split('\t'))
			?.map(([d, a]) => [...d.replace(/[\(\)]/g, '').split(/[:\s]/), a])
			?.map(([range, cost, amount]) => {
				const unit_match = [...(cost?.matchAll(/([0-9\.]+)DOP\/(\w+)/g) ?? [])]
				if (!unit_match.length) return { range, rate: cost, amount }
				cost = unit_match[0][1]
				const unit = unit_match[0][2]
				return { range: `${range}${unit}`, rate: `RD$ ${cost}`, amount }
			})
	}

	scale: number = 0
	padding_right: number = 0
	padding_bottom: number = 0

	text_only = false

	constructor(private api: ApiService) {}
	ngAfterContentInit() {
		this.api.get_print_type(({ data: print_type }) => {
			switch (print_type) {
				case 1: // Mobile PoS
					this.scale = 35
					break
				case 2: // Desktop PoS
					this.scale = this.padding_right = 12
					this.padding_bottom = 35
					break
				case 3: // Text only
					this.text_only = true
					break
			}
			if (this.auto_print)
				setTimeout(() => {
					;(window as any).cordova?.plugins?.printer?.print?.()
					window.print()
					// setTimeout(() => PrintService.done?.(), 1_000)
				}, 500)
		})
	}

	fix_time = (datetime: string) => datetime.replace(/a\.\sm\./g, 'am').replace(/p\.\sm\./g, 'pm')
	format_phone = format_phone
	date = (d) => new Date(d)
}
