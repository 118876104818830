<h1>Pago de Facturas</h1>

<div *ngIf="!result" class="card mt-8">
   <h3 class="text-lg font-semibold border-b">
      Consulta de cliente
      <img src="assets/logos/logo_windtelecom.png" class="inline h-4 ml-1">
   </h3>
   <div class="flex flex-col items-stretch flex-wrap mt-6 md:flex-row md:items-baseline">
      <div class="mr-4 font-semibold">Contrato</div>
      <div class="flex-grow">
         <input id="document" [(ngModel)]="id" (keydown.enter)="query()" type="number" inputmode="numeric"
            placeholder="Ingrese el contrato que desea pagar"
            class="py-2 px-2 md:py-0 w-full rounded border-0 border-b border-gray-300">
      </div>
   </div>
   <button (click)="query()" [disabled]="working || !(id?.trim())" class="mt-8 w-full btn btn-blue md:mt-3 md:w-max">
      Consultar
      <fa-icon icon="search"></fa-icon>
      <working *ngIf="working"></working>
   </button>
</div>

<div *ngIf="result" class="card mt-8">
   <h3 class="font-semibold border-b">
      Respuesta de consulta de cliente
      <img src="assets/logos/logo_windtelecom.png" class="inline h-4 ml-1">
   </h3>
   <div class="mt-6 flex flex-col items-stretch space-y-2">
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Documento de identificación:</div>
         <div class="font-semibold md:font-normal md:ml-4">{{ result.document }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Nombre del cliente:</div>
         <div class="font-semibold md:font-normal text-2xl md:ml-4">{{ result.name }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Dirección:</div>
         <div class="font-semibold md:font-normal md:ml-4">{{ result.address }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Balance:</div>
         <div class="font-semibold md:font-normal text-2xl md:ml-4">{{ result.total_debt | currency }}</div>
      </div>
      <div class="pt-4 border-t md:hidden" style="margin-top: 24px;">
         <h3 class="font-semibold">Facturas:</h3>
      </div>
      <div class="w-full md:table md:border md:rounded-md" style="margin-top: 1.5rem;">
         <div class="hidden md:block md:table-header-group">
            <div class="table-cell"></div>
            <div class="table-cell border-l" title="Número de Factura">Nro. Factura</div>
            <div class="table-cell border-l">Concepto</div>
            <div class="table-cell border-l">Fecha</div>
            <div class="table-cell border-l">Monto</div>
            <div *ngIf="partial_payments" class="table-cell border-l">Monto a pagar</div>
         </div>
         <div class="md:table-row-group">
            <div *ngIf="!result.pending_invoices?.length" class="md:table-row">
               <td title="No existen facturas pendientes para este cliente" class="border-t text-center" colspan="6">
                  <fa-icon icon="times"></fa-icon>
               </td>
            </div>
            <div *ngFor="let invoice of result.pending_invoices"
               class="flex flex-row rounded-md mb-6 shadow md:shadow-none md:table-row">
               <div class="flex-none self-stretch pr-2 md:px-2 md:table-cell md:text-center"
                  style="border-left-width: 0;">
                  <input [(ngModel)]="selectable(invoice).selected" type="checkbox"
                     class="h-full rounded-l-md rounded-r-none md:h-auto md:rounded">
               </div>
               <div class="flex-1 md:contents">
                  <div (click)="selectable(invoice).selected = !selectable(invoice).selected"
                     class="flex flex-row md:cursor-pointer md:contents">
                     <div class="flex-1 md:border-l md:contents">
                        <div class=" md:table-cell md:px-3 md:text-right">{{ invoice.id }}</div>
                        <div class=" md:table-cell md:px-3">{{ invoice.concept }}</div>
                     </div>
                     <div class="pr-1 md:border-l md:contents">
                        <div class="text-right md:table-cell md:px-3 md:text-center">{{ invoice.issue_date | date }}
                        </div>
                        <div class="text-right md:table-cell md:px-3">{{ invoice.amount | currency:'RD$ ' }}</div>
                     </div>
                  </div>
                  <div *ngIf="partial_payments"
                     class="flex flex-row items-baseline border-t mt-1 md:table-cell md:px-2 md:text-right">
                     <div class="block min-w-max mr-1 md:hidden">Monto a pagar: RD$</div>
                     <input type="number" [(ngModel)]="selectable(invoice).amount_to_pay" [min]="0"
                        [max]="invoice.amount"
                        class="py-2 px-2 border-0 border-b boder-gray-300 w-full text-right rounded-br-md md:py-1 md:bg-gray-100 md:rounded-md">
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="flex flex-row mt-3 md:w-max">
      <button (click)="result = null" class="btn w-max mr-2">
         <fa-icon icon="arrow-left"></fa-icon>
         Volver
      </button>
      <button (click)="pay()" [disabled]="selected.length === 0" class="btn btn-blue flex-1">
         Pagar
         <fa-icon icon="hand-holding-usd"></fa-icon>
      </button>
   </div>
</div>