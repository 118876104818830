import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { BundleSummary } from 'src/app/models/models';
import { modal } from 'src/app/utils/modal.util';
import { error_toast, success_toast, warning_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-contract-bundles',
  templateUrl: './contract-bundles.component.html',
  styleUrls: ['./contract-bundles.component.less']
})
export class ContractBundlesComponent {
  working = {
    loading: true,
    removing: null,
    querying: { }
  }

  user_bundles: BundleSummary[];
  pos_bundles: BundleSummary[];

  get extra_bundles(): BundleSummary[] {
    const user_bundle_ids = this.user_bundles?.map(b => b.id);
    return this.pos_bundles?.filter(b => !user_bundle_ids?.includes(b.id));
  }

  constructor(private api: ApiService, private router: Router) {
    api.get_user_contract_bundles(response => {
      this.working.loading = false;
      if (response.succeeded) {
        this.user_bundles = response.data;
        this.check();
      } else error_toast(response.error.message);
    });
    api.get_point_of_sales_contract_bundles(response => {
      if (response.succeeded) {
        this.pos_bundles = response.data;
      } else error_toast(response.error.message);
    });
  }

  edit(bundle: BundleSummary) {
    this.router.navigate(['', 'cashier', 'bundles', bundle.id]);
  }

  remove(bundle: BundleSummary) {
    modal('Eliminar bolsa de contratos', 
      `¿Seguro que desea eliminar esta bolsa de contratos de ${bundle.invoicer.description} para el cliente ${bundle.description}?`,
      ['!Eliminar', '_Cancelar'], result => {
        if (!result.id) {
          this.working.removing = bundle.id;
          this.api.remove_contract_bundle(bundle.id, response => {
            this.working.removing = null;
            if (response.succeeded) {
              warning_toast('Bolsa de contratos eliminada');
              this.user_bundles.splice(this.user_bundles.findIndex(b => b.id == bundle.id), 1);
              if (this.pos_bundles.some(b => b.id == bundle.id))
                this.pos_bundles.splice(this.pos_bundles.findIndex(b => b.id == bundle.id), 1);
            } else error_toast(response.error.message);
          });    
        }
      });
  }

  query(bundle: BundleSummary) {
    if (bundle.contract_count < 100) {
      this.working.querying[bundle.id] = true;
      this.api.query_contract_bundle(bundle.id, response => {
        this.working.querying[bundle.id] = false;
        if (response.succeeded) {
          warning_toast('Bolsa de contratos en proceso de consulta...');
          bundle.processed = false;
          bundle.queried_count = 0;
          this.check();
        } else error_toast(response.error.message);
      });
    } else {
      modal('Refrescar bolsa de contratos',
        `¿Seguro que desea refrescar el balance de esta bolsa de contratos?  El proceso puede tomar varios minutos.`,
        ['_Cancelar', 'Refrescar'], result => {
          if (result.id) {
            this.working.querying[bundle.id] = true;
            this.api.query_contract_bundle(bundle.id, response => {
              this.working.querying[bundle.id] = false;
              if (response.succeeded) {
                warning_toast('Bolsa de contratos en proceso de consulta...');
                bundle.processed = false;
                bundle.queried_count = 0;
                this.check();
              } else error_toast(response.error.message);
            });
         }
       });
    }
  }

  check() {
    if (!window.location.href.includes('bundles') || !this.user_bundles.some(b => !b.processed))
        return;
    this.api.get_user_contract_bundles(response => {
      if (response.succeeded) {
        this.user_bundles = response.data;
        setTimeout(() => this.check(), 250);
      };
    });
  }

  errors_description(errors: { error: string; references: string[]; }[]): string {
    return errors.map(e => `${e.references.join(', ')}:\n - "${e.error}"`).join('\n\n');
  }
}
