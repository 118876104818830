import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { error_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-points-of-sales',
  templateUrl: './points-of-sales.component.html',
  styleUrls: []
})
export class PointsOfSalesComponent {
  points_of_sales;

  constructor(public router: Router, api: ApiService) {
    api.get_points_of_sales(response => {
      if (response.succeeded) {
        this.points_of_sales = response.data;
      } else error_toast(response.error.message);
    });
  }
}
