import { ChangeDetectorRef, Component } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { OfflineDatabase, QueuedPayment, when_processing_payment } from 'src/app/offline_db';
import { PaymentRequest, TransactionDetailedView, TransactionDetailView } from 'src/app/models/models';
import { State } from 'src/app/state';
import { modal } from 'src/app/utils/modal.util';
import { receipt, wrap_print } from 'src/app/utils/receipt.util';
import { error_toast } from 'src/app/utils/toast.util';
import { clear_element } from 'src/app/utils/utils';
import { Router } from '@angular/router';

@Component({
   selector: 'app-queued-payments',
   templateUrl: './queued-payments.component.html',
   styleUrls: []
})
export class QueuedPaymentsComponent {
   working = false;
   printing: PaymentRequest;
   cancelling: PaymentRequest;
   queued_payments: QueuedPayment[];

   queued_payments_being_processed_keys: number[];

   constructor(private api: ApiService, router: Router, change_detector: ChangeDetectorRef) {
      OfflineDatabase.get_queued_payments(data => {
         if (data?.length) {
            this.queued_payments = data;
   
            when_processing_payment(keys => {
               this.queued_payments_being_processed_keys = keys;
               OfflineDatabase.get_queued_payments(data => this.queued_payments = data);
               change_detector.detectChanges();
            });
         } else router.navigate(['home']);
      })
   }

   is_being_processed(qu: QueuedPayment): boolean {
      return this.queued_payments_being_processed_keys?.includes(qu.key);
   }

   invoicer_name(id: number): string {
      return State.invoicers.find(i => i.id === id).name;
   }

   method_name(invoicer_id: number, id: number): string {
      return State.get_payment_methods(invoicer_id).find(p => p.id === id).description;
   }

   get printing_frame_container(): HTMLDivElement {
      return <HTMLDivElement>document.getElementById('printing-frame-container');
   }
   print(queued_payment: QueuedPayment) {
      this.working = true;
      this.printing = queued_payment.payment;

      this.api.get_print_type(response => {
         if (response.succeeded) {
            const print_type = response.data;
            const print_contents = receipt(this.payment_to_transaction(queued_payment), print_type);

            if (State.on_cordova) {
               (window as any).cordova.plugins.printer.print(wrap_print(print_contents));
               this.working = false;
               this.printing = null;
               return;
            }

            clear_element(this.printing_frame_container);
            const frame = document.createElement('iframe');
            frame.setAttribute('style', 'visibility: false; position: fixed; top: 0; left: 0; right: 0; z-index: -10;');
            this.printing_frame_container.appendChild(frame);

            frame.contentDocument.write(print_contents);

            window.onmessage = evt => {
               if (evt?.data.type == 'receipt-done') {
                  setTimeout(() => {
                     this.working = false;
                     this.printing = null;
                  }, print_type === 1 ? 1000 : 0);
               }
            };
         } else
            error_toast(response.error.message);
      });
   }

   payment_to_transaction(queued_payment: QueuedPayment): TransactionDetailedView {
      const payment = queued_payment.payment;
      const invoicer = State.invoicers?.find(i => i.id === payment.invoicer_id);
      return <TransactionDetailedView>{
         id: -1,
         lot: -1,
         sequence: queued_payment.key,
         amount: payment.total,
         date: <any>queued_payment.date,
         invoicer: { id: invoicer.id, name: invoicer.name, rnc: null },
         user: State.user,
         point_of_sales: State.user.point_of_sales,
         client_reference: payment.client_reference,
         client_name: payment.client_name,
         payment_method: State.get_payment_methods(payment.invoicer_id)?.find(p => p.id === payment.payment_method_id),
         card_number: payment.card_number,
         cash_recieved: payment.cash_recieved,
         bank: State.banks?.find(b => b.id === payment.bank_id),
         authorization_code: payment.authorization_number,
         payment_document: payment.cheque_number ?? payment.transfer_number,
         status: { id: -1, description: 'Fuera de linea' },
         details: payment.invoices.map(i => (<TransactionDetailView>{
            invoice_id: i.invoice_id,
            amount: i.amount,
            applied: true
         }))
      };
   }

   cancel(queued_payment: QueuedPayment) {
      modal('Eliminar pago offline', 'Este pago no se realizó mientras el dispositivo estuvo fuera de linea y aún no se ha aplicado.  '
         + '\n¿Desea eliminar este pago offline sin sincronizar?', ['!Eliminar pago', '_Cancelar'], result => {
            if (result.id === 0) {
               this.working = true;
               this.cancelling = queued_payment.payment;

               const key = this.queued_payments.findIndex(qp => qp === queued_payment) + 1;
               OfflineDatabase.delete_queued_payment(key, () => {
                  this.queued_payments.splice(key - 1, 1);
                  this.working = false;
                  this.cancelling = null;
               });
            }
         });
   }

   process_payments() {
      State.bypass_connection_loss_timeout();
      if (State.online) {
         // Force try apply payments
         OfflineDatabase.get_queued_payment_count(null);
      } else {
         error_toast('Sin conexión');
      }
   }
}

interface Processable<T> {
   item: T;
   processing: boolean;
}