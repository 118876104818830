import { environment } from "src/environments/environment";
import { Item } from "../models/models";
import { clear_element } from "./utils";

export function modal(title: string, message: string, buttons: string[], callback: (result: Item) => void) {
   const modal_container = document.getElementById('modal-container');

   const modal = document.createElement('div');

   {
      const title_ = document.createElement('h3');
      title_.innerText = title;
      modal.appendChild(title_);
   }
   {
      const message_ = document.createElement('div');
      message_.className = 'body';
      message_.innerText = message;
      modal.appendChild(message_);
   }
   {
      const buttons_ = document.createElement('div');
      buttons_.className = 'buttons';
      for (let button of buttons.map((b, i) => ({ id: i, description: b })).reverse()) {
         let button_ = document.createElement('button');
         if (button.description.startsWith('_')) {
            button.description = button.description.substring(1);
            button_.className = 'secondary';
         } else if (button.description.startsWith('!')) {
            button.description = button.description.substring(1);
            button_.className = 'danger';
         }
         button_.innerText = button.description;
         button_.addEventListener('click', event => {
            clear_modal();
            environment.debug('modal result:', button);
            callback(button);
         });
         buttons_.appendChild(button_);
      }
      modal.appendChild(buttons_);
   }

   modal_container.appendChild(modal);
   modal_container.className = 'showing';
}

export function clear_modal() {
   const modal_container = document.getElementById('modal-container');
   modal_container.className = null;
   clear_element(modal_container);
}