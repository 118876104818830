<h1>Pago de Facturas</h1>

<div *ngIf="!result" class="card mt-8">
   <h3 class="text-lg font-semibold border-b flex flex-row flex-wrap">
      <div class="mr-2">Consulta de cliente</div>
      <div class="flex flex-row flex-wrap">
         <div *ngFor="let invoicer of multipay.invoicers" class="mr-2">
            <fa-icon *ngIf="multipay.invoicers.indexOf(invoicer)" icon="plus" class="mr-2"></fa-icon>
            <img src="/assets/logos/logo_{{ invoicer.name | lowercase }}.png" class="h-5 inline-block" [class.mb-3]="invoicer.id < 4">
         </div>
      </div>
   </h3>
   <div class="flex flex-col items-stretch flex-wrap mt-6 md:flex-row md:items-baseline">
      <div class="mr-4 font-semibold">Cédula:</div>
      <div class="flex-grow">
         <input [(ngModel)]="id" (keydown.enter)="multi_query()" type=text inputmode=numeric placeholder="Ingrese la cédula que desea consultar" class="py-2 px-2 md:py-0 w-full rounded border-0 border-b border-gray-300">
      </div>
   </div>
   <button (click)="multi_query()" [disabled]="working || !(id?.trim())" class="mt-8 w-full btn btn-blue md:mt-3 md:w-max">
      Consultar
      <fa-icon icon="search"></fa-icon>
      <working *ngIf="working"></working>
   </button>
</div>
<div *ngIf="result" class="card mt-8 space-y-6">
   <div class="flex flex-col space-y-2">
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="w-44 pr-2 md:font-semibold">Documento:</div>
         <div class="font-semibold md:font-normal">{{ id }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="w-44 pr-2 md:font-semibold">Nombre del Cliente:</div>
         <div class="text-xl font-semibold md:font-normal">{{ result.name }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="w-44 pr-2 md:font-semibold">Monto a Pagar:</div>
         <div class="text-2xl font-bold">{{ amount_to_pay | currency:'RD$ ' }}</div>
      </div>
   </div>
   <div class="space-y-4">
      <div *ngFor="let result of result.queries" class="rounded-md border shadow-md p-4 flex flex-row flex-wrap items-center bg-gray-100">
         <div>
            <input type="checkbox" [(ngModel)]="selectable(result).selected">
         </div>
         <div (click)="go_to(result.invoicer)" class="cursor-pointer px-4 hover:bg-gray-200 active:bg-gray-300 md:min-w-[155px]">
            <img src="/assets/logos/logo_{{ result.invoicer.name | lowercase }}.png" [alt]="result.invoicer.name" class="h-8" [class.mb-2]="result.invoicer.id < 4">
         </div>
         <div class="flex flex-row min-w-max items-center" style="flex-grow: 1">
            <div class="font-semibold text-lg md:min-w-[250px]" style="flex-grow: 1;">
               {{ result.invoicer.id < 4? 'Contrato' : result.invoicer.id === 5? 'Teléfono' : '' }} {{ result.reference }}
            </div>
            <div class="font-semibold text-right">
               <div>Deuda:</div>
               <div>{{ to_pay(result) | currency:'RD$ ' }}</div>
            </div>
         </div>
      </div>
   </div>
   <button (click)="pay()" [disabled]="!selected.length" class="btn btn-blue">
      Pagar
      <fa-icon icon="hand-holding-usd"></fa-icon>
   </button>
</div>