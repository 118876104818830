<h1>Pago de Facturas</h1>

<div *ngIf="!result" class="card mt-8">
   <h3 class="text-lg font-semibold border-b">
      Consulta de cliente
      <img src="assets/logos/logo_caasd.png" class="inline h-5 mb-1">
   </h3>
   <div class="flex flex-col items-stretch md:flex-row flex-wrap mt-6 md:items-baseline">
      <div class="mr-4 font-semibold">Contrato</div>
      <div class="flex-grow">
         <input type="number" inputmode="numeric" [(ngModel)]="id" (keydown.enter)="query()" [readonly]="working" placeholder="Ingrese el número de contrato a consultar" class="py-2 px-2 md:py-0 w-full rounded border-0 border-b border-gray-300">
      </div>
   </div>
   <button (click)="query()" [disabled]="!id || working" class="btn btn-blue mt-8 w-full md:mt-3 md:w-max">
      {{ !working? 'Consultar' : 'Consultando...' }}
      <fa-icon *ngIf="!working" icon="search"></fa-icon>
      <working *ngIf="working"></working>
   </button>
</div>

<div *ngIf="result" class="card mt-8">
   <h3 class="text-lg font-semibold border-b">
      Respuesta consulta de cliente 
      <img src="assets/logos/logo_caasd.png" class="inline h-5 mb-1">
   </h3>
   <div class="mt-4">
      <div class="flex flex-col md:flex-row md:items-baseline">
         <div class="w-48 md:font-semibold">Nombre del Cliente:</div>
         <div class="text-2xl font-semibold">{{ result.name }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:items-baseline">
         <div class="w-48 md:font-semibold">Compañía:</div>
         <div class="font-semibold md:font-normal">{{ result.company }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:items-baseline">
         <div class="w-48 md:font-semibold">Deuda:</div>
         <div class="font-semibold text-xl">{{ result.debt | currency:'RD$ ' }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:items-baseline">
         <div class="w-48 md:font-semibold">Impuestos:</div>
         <div class="font-semibold md:font-normal">{{ result.taxes | currency:'RD$ ' }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:items-baseline">
         <div class="w-48 md:font-semibold">Fecha de Expiración:</div>
         <div class="font-semibold md:font-normal">{{ result.date_expiration | date:'EEEE dd MMM, yyyy':'':'es-DO' | titlecase }}</div>
      </div>
   </div>
   <div class="mt-4 border-t pt-4">
      <div class="font-semibold">Monto a pagar</div>
      <div class="flex flex-row">
         <div class="rounded-l border border-gray-400 pl-2 flex flex-row items-baseline">
            <span class="font-semibold">RD$</span>
            <input type="number" [(ngModel)]="amount_to_pay" (keydown.enter)="amount_to_pay? pay() : null" class="flex-1 py-1 border-0 focus:outline-none focus:ring-0 text-right w-32"
               placeholder="0.00" [min]=0 [max]=result.debt>
         </div>
         <button (click)="pay()" class="btn btn-blue !rounded-l-none" [disabled]="!amount_to_pay || amount_to_pay < 0 || amount_to_pay > result.debt">
            Pagar
            <fa-icon icon="hand-holding-usd"></fa-icon>
         </button>
      </div>
   </div>
</div>