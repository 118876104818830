import { ApiResponse, Item } from 'src/app/models/models'
import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/api.service'
import {
	EdeClientView,
	EdePrepaidClientView,
	EdeContractView,
	EdeInvoiceView,
} from 'src/app/models/invoicer_query.models'
import { State } from 'src/app/state'
import { error_toast, success_toast } from 'src/app/utils/toast.util'
import { is_nothing, qs, since, sum, today } from 'src/app/utils/utils'
import { invoicer_queriable_to_item } from '../payments.component'
import { state } from '@angular/animations'
import { ZoneSummary } from '../../../../models/models'
import { OfflineDatabase } from 'src/app/offline_db'
import { environment } from 'src/environments/environment'
import { ContributorView } from '../../../../models/invoicer_query.models'

@Component({
	selector: 'app-edesur',
	templateUrl: '../edes/ede.html',
	styleUrls: ['./ede.less'],
})
export class EdeComponent {
	date: Date;
	pendingInvoicesTitle: string = "";
	showContractList:boolean = false
	totalAmount: number = 0;
	ede: string
	mode = 1
	working = {
		main: false,
		download: false,
	}
	id: string
	postpaid_result: EdeClientView
	prepaid_result: EdePrepaidClientView
	zones: ZoneSummary[] = []
	zone_id: string
	contracts: ContributorView[]
	download_for_zone_contracts: ContributorView[]

	amount_to_prepay: number

	get partial_payments(): boolean {
		return State.active_invoicer.partial_payments
	}
	get consolidated_payment(): boolean {
		return State.active_invoicer.consolidate_payment
	}

	payment_policy: Item

	get prepayment_enabled(): boolean {
		return ['edesur', 'edenorte'].includes(this.ede)
	}

	constructor(private api: ApiService, private router: Router, active_route: ActivatedRoute) {
		if (is_nothing(State.active_invoicer)) router.navigate(['cashier', 'pay'])
		else if (State.preloaded_query) {
			this.id = State.preloaded_query.id
			this.postpaid_result = State.preloaded_query.data
			State.preloaded_query = null
		}
		this.payment_policy = State.active_invoicer.invoice_payment_policy
		this.ede = active_route.snapshot.routeConfig.path
		if (this.ede === 'edenorte') {
			this.load_zones()
			// if (!State.online)
			this.load_cached_contracts()
		}
	}
	load_zones() {
		if (State.online)
			this.api.get_zones(State.active_invoicer.id, (response) => {
				if (response.succeeded) this.zones = response.data
				else error_toast('No se pudieron buscar las zonas, ')
			})
	}

	since(_, __) {
		return since(_, __)
	}
	load_cached_contracts() {
		if (!['edenorte'].includes(this.ede)) return

		environment.debug('[i] loading offline edenorte contracts...')

		OfflineDatabase.get_edenorte_contracts((data) => {
			if (data.length) {
				const expired_contracts = data.filter((d) => since(d.date, { hours: 6 }))
				if (data.length === expired_contracts.length) error_toast('Cartera offline expirada (6 horas)')
				for (let contract of expired_contracts) {
					OfflineDatabase.delete_edenorte_contract(contract.document)
				}
				this.contracts = data.filter((d) => !expired_contracts.includes(d))
			} else {
				error_toast('Sin cartera offline')
			}
		})
	}

	download_for_zone() {
		if (this.ede !== 'edenorte') return
		this.working.download = true
		this.api.get_cached_clients(State.active_invoicer.id, this.zone_id, (response) => {
			if (response.succeeded) {
				this.contracts = response.data
				this.download_for_zone_contracts = response.data;
				this.date = new Date(Date.now())
				this.showContractList = true;
				for (let contract of response.data) {
					OfflineDatabase.add_or_update_edenorte_contract({
						date: new Date(Date.now()),
						document: contract.document,
						name: contract.name,
						address: contract.address,
						total_debt: contract.total_debt,
						phone: contract.phone,
						pending_invoices: contract.pending_invoices,
					})
				}
				
				success_toast(response.data.length + 'contratos añadidos a la cartera offline')
			} else error_toast('No fue posible descargar los contratos de la zona')
			this.working.download = false
		})
	}
	getPendingInvoicesTitle(contractDoc: any): string {
		let pendingInvoicesTitle = '';
		let filtertedContracts = this.download_for_zone_contracts.filter(contract => contract.document === contractDoc);
		for(let contract of filtertedContracts){
			for (let pending_invoice of contract.pending_invoices) {
			  pendingInvoicesTitle += `${pending_invoice.id} | ${pending_invoice.amount}\n`;
			}
		}
		return pendingInvoicesTitle;
	  }
	query() {
		const id = (qs('#nic') as HTMLInputElement).value
		this.working.main = true
		if (this.mode === 1) {
			const handle_postpaid = (response: ApiResponse<EdeClientView>) => {
				this.working.main = false
				if (response.succeeded) {
					this.postpaid_result = response.data
				} else if (response.error.code === 'OFFLINE' && this.contracts?.length) {
					const contract = this.contracts.find((c) => c.document == id)
					if (contract)
						this.postpaid_result = {
							name: contract.name,
							contracts: [
								{
									id: contract.document,
									address: contract.address,
									total_debt: contract.total_debt,
									pending_invoices: contract.pending_invoices.map((pi) => ({
										id: pi.id,
										description: pi.concept,
										amount: pi.amount,
										issue_date: pi.issue_date,
										expiration_date: null,
									})),
								},
							],
						}
				} else error_toast(response.error.message)
			}
			switch (this.ede) {
				case 'edeeste':
					this.api.query_edeeste(id, handle_postpaid)
					break
				case 'edenorte':
					if (!State.online && this.contracts?.length) {
						let found_contract = this.contracts.find((c) => c.document == id)
						this.postpaid_result = {
							name: found_contract.name,
							contracts: [
								{
									id: found_contract.document,
									address: found_contract.address,
									total_debt: found_contract.total_debt,
									pending_invoices: found_contract.pending_invoices.map((p) => ({
										id: p.id,
										description: p.concept,
										amount: p.amount,
										issue_date: p.issue_date,
										expiration_date: p.issue_date,
									})),
								},
							],
						}
					} else this.api.query_edenorte(id, handle_postpaid)
					break
				case 'edesur':
					this.api.query_edesur(id, handle_postpaid)
					break
				case 'ppe':
					this.api.query_ppe(id, handle_postpaid)
					break
				case 'inapa':
					this.api.query_inapa(id, handle_postpaid)
					break
			}
		} else {
			switch (this.ede) {
				case 'edesur':
					this.api.query_edesur_prepaid(id, (response) => {
						this.working.main = false
						if (response.succeeded) {
							this.prepaid_result = response.data
						} else error_toast(response.error.message)
					})
					break
				case 'edenorte':
					this.api.query_edenorte_prepaid(id, (response) => {
						this.working.main = false
						if (response.succeeded) {
							this.prepaid_result = response.data
						} else error_toast(response.error.message)
					})
					break
			}
		}
	}

	expandable_contracts: ExpandableContract[] = []
	expandable(contract: EdeContractView): ExpandableContract {
		let ex_con = this.expandable_contracts.find((ec) => ec.contract === contract)
		if (ex_con) return ex_con
		else {
			const component = this
			ex_con = {
				contract,
				expanded: this.postpaid_result.contracts.length === 1,
				get amount_to_pay(): number {
					return sum(
						contract.pending_invoices
							.map((i) => component.selectable(i))
							.filter((si) => si.selected)
							.map((si) => si.amount_to_pay)
					)
				},
			}
			this.expandable_contracts.push(ex_con)
			return ex_con
		}
	}
	toggle_expandable(contract: EdeContractView) {
		const ex_con = this.expandable(contract)
		ex_con.expanded = !ex_con.expanded
	}

	selectable_invoices: SelectableInvoice<EdeInvoiceView>[] = []
	selectable(invoice: EdeInvoiceView): SelectableInvoice<EdeInvoiceView> {
		let si = this.selectable_invoices.find((si) => si.invoice === invoice)
		if (si) return si
		else {
			si = {
				invoice,
				selected: true,
				amount_to_pay: invoice.amount,
			}
			this.selectable_invoices.push(si)
			return si
		}
	}
	toggle_selection(invoice: EdeInvoiceView) {
		const selectable = this.selectable(invoice)
		let value = !selectable.selected
		if (this.payment_policy.id === 3) {
			if (new Date(invoice.expiration_date) <= new Date(Date.now())) value = true
		}
		if (this.payment_policy.id >= 2 && selectable.selected !== value) {
			const issue_date = new Date(invoice.issue_date)
			if (value) {
				this.selectable_invoices
					.filter((si) => new Date(si.invoice.issue_date) < issue_date)
					.forEach((si) => {
						si.selected = true
						si.amount_to_pay = si.invoice.amount
					})
			} else {
				this.selectable_invoices
					.filter((si) => si !== selectable && new Date(si.invoice.issue_date) >= issue_date)
					.forEach((si) => (si.selected = false))
			}
		}
		selectable.selected = value
		return true
	}

	selected(contract: EdeContractView): SelectableInvoice<EdeInvoiceView>[] {
		return contract.pending_invoices
			.map((i) => this.selectable_invoices.find((si) => si.invoice === i))
			.filter((i) => i && i.selected)
	}

	handle_amount_change(invoice: EdeInvoiceView) {
		const si = this.selectable(invoice)
		let value = Number.parseFloat(<any>si.amount_to_pay)
		if (this.payment_policy.id === 3) {
			if (new Date(invoice.expiration_date) <= new Date(Date.now())) value = invoice.amount
		} else if (this.payment_policy.id === 2) {
			const issue_date = new Date(invoice.issue_date)
			if (value < invoice.amount)
				this.selectable_invoices
					.filter((si) => si.invoice !== invoice && new Date(si.invoice.issue_date) >= issue_date)
					.forEach((si) => (si.selected = false))
		}
		si.amount_to_pay = value
	}

	consolidated_contracts: ConsolidatedContract[] = []
	consolidated(contract: EdeContractView): ConsolidatedContract {
		let cc = this.consolidated_contracts.find((c) => c.contract === contract)
		if (cc) return cc
		else {
			const debt = Number.parseFloat(sum(contract.pending_invoices.map((i) => i.amount)).toFixed(2))
			cc = { contract, debt, amount_to_pay: debt }
			this.consolidated_contracts.push(cc)
			return cc
		}
	}

	// Pay
	pay(contract: EdeContractView) {
		let cc = this.consolidated(contract)
		let ccc = 0
		State.payment_package = [
			{
				name: this.postpaid_result.name,
				document: contract.id,
				invoicer: invoicer_queriable_to_item(State.active_invoicer),
				type: 1,
				payloads: this.consolidated_payment
					? cc.contract.pending_invoices
							.map((i) => {
								const left = cc.amount_to_pay - ccc
								if (left) {
									if (i.amount <= left) {
										ccc += i.amount
										return { invoice_id: i.id, concept: i.description, amount: i.amount }
									} else {
										ccc += left
										return { invoice_id: i.id, concept: i.description, amount: left }
									}
								}
								return null
							})
							.filter((_) => _)
					: this.selected(contract).map((i) => ({
							invoice_id: i.invoice.id,
							concept: i.invoice.description,
							amount: i.amount_to_pay,
					  })),
			},
		]
		this.router.navigate(['cashier', 'apply-payment'])
	}

	pre_pay() {
		State.payment_package = [
			{
				name: this.prepaid_result.name,
				document: this.id,
				invoicer: invoicer_queriable_to_item(State.active_invoicer),
				type: 2,
				payloads: [
					{
						invoice_id: null,
						concept: 'Recarga de energía',
						amount: this.amount_to_prepay,
					},
				],
			},
		]
		this.router.navigate(['cashier', 'apply-payment'])
	}

	before_today(date: string | Date): boolean {
		return new Date(date) < today()
	}
	
}

interface ExpandableContract {
	contract: EdeContractView
	expanded: boolean
	amount_to_pay: number
}
interface SelectableInvoice<T> {
	invoice: T
	selected: boolean
	amount_to_pay: number
}
interface ConsolidatedContract {
	contract: EdeContractView
	debt: number
	amount_to_pay: number
}
