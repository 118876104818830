<div class="print-hidden">
	<router-outlet></router-outlet>
	<div id="toast-dock"></div>
	<div id="modal-container"></div>
	<div *ngIf="app_environment" id="app-environment">{{ app_environment }}</div>
</div>
<div *ngIf="print.printing" class="fixed inset-0 bg-transparent z-100" (mousedown)="print.done()"></div>

<div class="print-shown" [class.block]="cordova && print.printing">
	<multi-transaction-receipt *ngIf="print.type == 'multiple transactions'" [transactions]="print.data">
	</multi-transaction-receipt>
	<transaction-receipt *ngIf="print.type == 'transaction'" [transaction]="print.data.transaction"
		[extra]="print.data.extra"></transaction-receipt>
	<report-receipt *ngIf="print.type == 'report'" [lot_report]="print.data"></report-receipt>
</div>