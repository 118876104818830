<h1>¡Bienvenido!</h1>
<h2 class="text-gray-500">{{ point_of_sales }}</h2>

<div *ngIf="user_is_cashier && has_queued_payments" class="my-4 float-right md:float-none">
   <button (click)="process_offline_payments()" class="btn" [disabled]="processing_queued_payments">
      {{ !processing_queued_payments? 'Procesar pagos offline' : 'Procesando pagos offline...' }}
      <fa-icon *ngIf="!processing_queued_payments" icon="sync"></fa-icon>
      <working *ngIf="processing_queued_payments" color="black"></working>
   </button>
</div>

<div *ngIf="user_is_cashier" class="mt-8 flex flex-col space-y-8 items-stretch w-full md:flex-row md:flex-wrap md:space-x-8 md:h-34">
   <div class="home-btn green" (click)="router.navigate(['cashier', 'pay'])" style="border-bottom: 3px solid #10B981;">
      <fa-icon icon="money-check-alt"></fa-icon>
      <span>Pago de Facturas</span>
   </div>
   <div class="home-btn blue"  (click)="router.navigate(['cashier', 'report'])" style="border-bottom: 3px solid #1D4ED8">
      <fa-icon icon="chart-bar"></fa-icon>
      <span>Reporte de Operaciones</span>
   </div>
   <div class="home-btn red"   (click)="router.navigate(['cashier', 'nullification'])" style="border-bottom: 3px solid #F87171;">
      <fa-icon icon="eraser"></fa-icon>
      <span>Anulaciones</span>
   </div>
   <div class="home-btn yellow" [class.disabled]="!online" (click)="router.navigate(['cashier', 'closure'])" style="border-bottom: 3px solid #FBBF24;">
      <fa-icon icon="store-alt-slash"></fa-icon>
      <span>Cierre de Lote</span>
   </div>
   <div class="home-btn green" [class.disabled]="!online" (click)="router.navigate(['cashier', 'lots'])" style="border-bottom: 3px solid #10B981;">
      <fa-icon icon="book"></fa-icon>
      <span>Histórico de Lotes</span>
   </div>
</div>

<div *ngIf="user_is_supervisor" class="mt-8 flex flex-col space-y-8 items-stretch w-full md:flex-row md:flex-wrap md:space-x-8 md:h-34">
   <div class="home-btn blue" [class.disabled]="!online" (click)="router.navigate(['supervisor', 'info'])" style="border-bottom: 3px solid #1D4ED8">
      <fa-icon icon="align-left"></fa-icon>
      <span>Información de Organización</span>
   </div>
   <div class="home-btn yellow" [class.disabled]="!online" (click)="router.navigate(['supervisor', 'nullifications'])" style="border-bottom: 3px solid #FBBF24;">
      <fa-icon icon="eraser"></fa-icon>
      <span>Anulaciones Pendientes</span>
   </div>
   <div class="home-btn green" [class.disabled]="!online" (click)="router.navigate(['supervisor', 'lots'])" style="border-bottom: 3px solid #10B981;">
      <fa-icon icon="book"></fa-icon>
      <span>Histórico de Lotes</span>
   </div>
   <div *ngIf="otp_required" class="home-btn" [class.disabled]="!online" (click)="router.navigate(['supervisor', '2fa'])" style="border-bottom: 3px solid black;">
      <fa-icon icon="user-shield"></fa-icon>
      <span>Autenticación de 2 factores</span>
   </div>
</div>

<div *ngIf="user_is_administrator" class="mt-8 flex flex-col space-y-8 items-stretch w-full md:flex-row md:flex-wrap md:space-x-8 md:h-34">
   <div class="home-btn" [class.disabled]="!online" (click)="router.navigate(['system', 'points-of-sales'])" style="border-bottom: 3px solid black;">
      <fa-icon icon="store-alt"></fa-icon>
      <span>Puntos de Venta</span>
   </div>
   <div class="home-btn" [class.disabled]="!online" (click)="router.navigate(['system', 'users'])" style="border-bottom: 3px solid black;">
      <fa-icon icon="users"></fa-icon>
      <span>Usuarios</span>
   </div>
   <div class="home-btn" [class.disabled]="!online" (click)="router.navigate(['system', 'organizations'])" style="border-bottom: 3px solid black;">
      <fa-icon icon="sitemap"></fa-icon>
      <span>Unidades Organizacionales</span>
   </div>
   <div class="home-btn" [class.disabled]="!online" (click)="router.navigate(['system', 'multipay-configuration'])" style="border-bottom: 3px solid black;">
      <fa-icon icon="tasks"></fa-icon>
      <span>Configuración Multi-pagos</span>
   </div>
</div>