<h1>Usuarios</h1>

<working *ngIf="!loaded" color="#3B82F6" [size]="30"></working>

<div *ngIf="loaded" class="card mt-8">
   <div class="mb-4 pb-4 border-b flex flex-row items-center">
      <div class="flex-1">
         <div *ngIf="disabled_users?.length" class="bg-gray-200 rounded-lg py-1 px-2 flex flex-row items-center w-max">
            <input type="checkbox" id=include-disabled [(ngModel)]="show_disabled">
            <label for=include-disabled class=ml-2>Incluir usuarios desactivados ({{ disabled_users.length }})</label>
         </div>
      </div>
      <button (click)="logs()" class="btn">
         <fa-icon icon="book"></fa-icon>
         Bitácora
      </button>
      <button (click)="new()" class="btn btn-blue">
         <fa-icon icon="user-plus"></fa-icon>
         Crear nuevo Usuario
      </button>
   </div>
   <div class="md:table md:rounded-md md:border md:w-full">
      <div class="font-bold text-center text-lg hidden md:table-header-group">
         <div class="table-cell py-1"></div>
         <div class="table-cell py-1 border-l px-2">Usuario</div>
         <div class="table-cell py-1 border-l px-2">Nombres</div>
         <div class="table-cell py-1 border-l px-2">Correo Electrónico</div>
         <div class="table-cell py-1 border-l px-2">Núm. Teléfono</div>
         <div class="table-cell py-1 border-l px-2">Ultimo Inicio de Sesión</div>
         <div class="table-cell py-1 border-l px-2">Punto de Venta</div>
         <div class="table-cell py-1 border-l w-0"></div>
      </div>
      <div class="md:table-row-group">
         <div *ngIf="!enabled_users?.length" class="md:table-row">
            <td class="text-center text-lg text-gray-500 border-t" colspan="7" title="No existen usuarios">
               <fa-icon icon="times"></fa-icon>
            </td>
         </div>
         <div *ngFor="let user of enabled_users.concat(show_disabled? disabled_users : [])" class="border p-4 rounded-md mb-4 shadow-md bg-gray-50 md:bg-transparent md:shadow-none md:table-row md:hover:bg-gray-100" [class.!bg-gray-100]=!user.enabled>
            <div class="flex flex-row md:contents">
               <div class="md:table-cell md:border-t md:py-1 md:px-1 md:text-center" [title]="(user.is_locked_out? 'Usuario BLOQUEADO' : 'Usuario habilitado')">
                  <fa-icon *ngIf="!user.is_locked_out" icon="user-check" class="text-gray-300"></fa-icon>
                  <fa-icon *ngIf="user.is_locked_out"  icon="user-lock"  class="text-red-600"></fa-icon>
               </div>
               <div class="mx-2 md:table-cell md:border-t md:border-l md:py-1 md:px-2" style="flex-grow: 1;">{{ user.username }}</div>
               <div class="text-right md:text-left md:table-cell md:border-t md:border-l md:py-1 md:px-2">{{ user.name }}</div>
            </div>
            <div class="flex flex-row-reverse flex-wrap justify-end md:contents">
               <div class="md:table-cell md:border-t md:border-l md:py-1 md:px-2">
                  <a *ngIf="user.email" href="mailto:{{ user.email }}" class="text-blue-900">
                     <fa-icon icon="envelope" class="text-xs md:hidden"></fa-icon>
                     {{ user.email }}
                  </a>
               </div>
               <div class="md:table-cell md:border-t md:border-l md:py-1 md:px-2" style="flex-grow: 1;">
                  <div class="min-w-max">
                     <a *ngIf="user.mobile_phone" href="tel:+1 {{ user.mobile_phone }}" class="text-blue-900">
                        <fa-icon icon="phone" class="text-xs md:hidden"></fa-icon>
                        {{ user.mobile_phone }}
                     </a>
                  </div>
               </div>
            </div>

            <div class="flex flex-col-reverse mt-3 md:contents">
               <div class="md:table-cell md:border-t md:border-l md:py-1 md:px-2">
                  <div *ngIf="user.date_last_login" class="font-semibold mt-4 md:hidden">Ultimo inicio de sesión:</div>
                  <div class="flex md:flex-row-reverse">
                     <div class="flex flex-row flex-wrap">
                        <div class="mr-2">{{ user.date_last_login | date:'dd MMM, yy':null:'es-DO' }}</div>
                        <div class="mr-2">{{ user.date_last_login | date:'hh:mm aa':null:'es-DO' }}</div>
                     </div>
                  </div>
               </div>
               <div class="md:table-cell md:border-t md:border-l md:py-1 md:px-2">
                  <fa-icon *ngIf="user.point_of_sales" icon="store" class="text-gray-600"></fa-icon>
                  {{ user.point_of_sales?.name }}
               </div>
            </div>

            <div class="flex flex-row-reverse md:table-cell md:border-t md:border-l">
               <button (click)="edit(user)" class="btn w-max" style="padding-top: 2.5px; padding-bottom: 2.5px">
                  <fa-icon icon="edit"></fa-icon>
                  Editar
               </button>
            </div>
         </div>
      </div>
   </div>
   <div class="mt-4 pt-4 border-t flex flex-row-reverse">
      <button (click)="new()" class="btn btn-blue">
         <fa-icon icon="user-plus"></fa-icon>
         Crear nuevo Usuario
      </button>
   </div>
</div>