import {
  CurrencyPipe,
  DatePipe,
  formatCurrency,
  formatDate,
} from '@angular/common';
import { environment } from 'src/environments/environment';
import { QuotaContractDetailView } from '../models/invoicer_query.models';
import {
  LotReport,
  InvoicerDetailedView,
  TransactionDetailedView,
} from '../models/models';
import {
  ppe_logo_dataurl,
  natcom_logo_dataurl,
  digicel_logo_dataurl,
  coraavega_logo_dataurl,
  coraaplata_logo_dataurl,
  windtelecom_logo_dataurl,
} from './receipt.logos';
import { format_phone, is_nothing, range, sum, wrap } from './utils';

const {
  edeeste_logo_dataurl,
  edenorte_logo_dataurl,
  edesur_logo_dataurl,
  asdn_logo_dataurl,
  claro_logo_dataurl,
  puertadivina_logo_dataurl,
  caasd_logo_dataurl,
  transneg_logo_dataurl,
  solincorp_logo_dataurl,
  coraasan_logo_dataurl,
  altice_logo_dataurl,
  jorem_logo_dataurl,
  viva_logo_dataurl,
  inapa_logo_dataurl,
  rancier_logo_dataurl,
  alv_logo_dataurl,
} = {} as any;

const date_pipe: DatePipe = new DatePipe('es-DO');
const currency_pipe: CurrencyPipe = new CurrencyPipe('es-DO');

function invoicer_rnc(invoicer: InvoicerDetailedView) {
  switch (invoicer.name.toLowerCase()) {
    case 'asdn':
      return '425-00033-9';
    case 'puertadivina':
      return '131-06414-2';
    case 'claro':
      return '101-00157-7';
    case 'coraasan':
      return '402-00623-8';
    case 'jorem':
      return '131-83976-2';
    default:
      return invoicer.rnc ?? '000-00000-0';
  }
}

function invoicer_logo(invoicer_name): string {
  switch (invoicer_name) {
    case 'edeeste':
      return edeeste_logo_dataurl;
    case 'edenorte':
      return edenorte_logo_dataurl;
    case 'edesur':
      return edesur_logo_dataurl;
    case 'asdn':
      return asdn_logo_dataurl;
    case 'claro':
      return claro_logo_dataurl;
    case 'puertadivina':
      return puertadivina_logo_dataurl;
    case 'caasd':
      return caasd_logo_dataurl;
    case 'coraasan':
      return coraasan_logo_dataurl;
    case 'altice':
      return altice_logo_dataurl;
    case 'viva':
      return viva_logo_dataurl;
    case 'jorem':
      return jorem_logo_dataurl;
    case 'inapa':
      return inapa_logo_dataurl;
    case 'rancier':
      return rancier_logo_dataurl;
    case 'alv':
      return alv_logo_dataurl;
    case 'ppe':
      return ppe_logo_dataurl;
    case 'natcom':
      return natcom_logo_dataurl;
    case 'digicel':
      return digicel_logo_dataurl;
    case 'coraavega':
      return coraavega_logo_dataurl;
    case 'coraaplata':
      return coraaplata_logo_dataurl;
    case 'windtelecom':
      return windtelecom_logo_dataurl;
  }
}

function fix_time(time_string: string): string {
  return time_string.replace(/a\.\sm\./g, 'am').replace(/p\.\sm\./g, 'pm');
}

function text_only_receipt(
  transaction: TransactionDetailedView,
  auto_print: boolean = true
) {
  function space_evenly(first: string, second: string): string {
    if (first.length + second.length < 36)
      return (
        first +
        ' '.repeat(40 - first.length - (second.length ?? 0)) +
        (second ?? '')
      );
    return first + '\n' + second;
  }
  function center(text: string): string {
    if (text.length < 40) return ' '.repeat((40 - text.length) / 2) + text;
    return text;
  }
  function line(char: string = '-'): string {
    return char.repeat(40);
  }

  const prepaid = transaction.type?.id === 3;
  const nullified = transaction.status.id === 6;
  const nullification = transaction.type?.id === 2;
  const extra = transaction.extra_info;

  const stepped_report = extra?.formatted_month_energy_report
    ?.split('\n')
    ?.map((l) => l.split('\t'))
    ?.map((d) => d[0].split('：').concat([d[1]]))
    ?.map((d) => ({
      range: d[0].replace('~', '–').replace('(', '').replace(')', ''),
      rate: 'RD$ ' + d[1].replace('RD$/kwh', ''),
      amount: d[2],
    }));

  return `<pre>${[
    space_evenly(
      transaction.invoicer.name,
      'RNC: ' + invoicer_rnc(transaction.invoicer)
    ),
    center(
      !nullification
        ? 'Comprobante de ' + !prepaid
          ? 'Pago'
          : 'Recarga'
        : 'Anulacion'
    ),
    ...(nullified ? [center('(TRANSACCION ANULADA)')] : []),
    center(transaction.point_of_sales.name),
    center(transaction.point_of_sales.address),
    line(),
    space_evenly(
      'Fecha:',
      fix_time(formatDate(transaction.date, 'dd/MMM/yy hh:mm aa', 'es-DO'))
    ),
    space_evenly('# Tran:', transaction.id.toString()),
    space_evenly('# Lote:', transaction.lot.toString()),
    space_evenly('# Secuencia:', transaction.sequence.toString()),
    ...(extra?.ncf ? [space_evenly('NCF:', extra.ncf)] : []),
    line(),
    space_evenly(
      transaction.client_name,
      transaction.invoicer.id != 5
        ? transaction.client_reference
        : format_phone(transaction.client_reference)
    ),
    ...(extra?.meter_serial_number
      ? [space_evenly('Medidor #:', extra.meter_serial_number)]
      : []),
    space_evenly(
      'Monto pagado:',
      formatCurrency(transaction.amount, 'es-DO', 'RD$ ')
    ),
    ...(transaction.details.length
      ? [
          line(),
          center('Detalle de Pago por Factura:'),
          ...transaction.details.map((d) =>
            space_evenly(
              (!d.applied ? '[NO APLICADA] ' : '') + d.invoice_id,
              formatCurrency(d.amount, 'es-DO', 'RD$ ')
            )
          ),
        ]
      : []),
    line(),
    space_evenly('Forma de pago:', transaction.payment_method.description),
    ...(transaction.payment_method.id == 1
      ? [
          space_evenly(
            'Pago:',
            formatCurrency(transaction.cash_recieved, 'es-DO', 'RD$ ')
          ),
          space_evenly(
            'Cambio:',
            formatCurrency(
              transaction.cash_recieved - transaction.amount,
              'es-DO',
              'RD$ '
            )
          ),
        ]
      : []),
    ...([2, 8].includes(transaction.payment_method.id)
      ? [
          space_evenly('Banco:', transaction.bank.description),
          space_evenly(
            transaction.payment_method.id == 2 ? '# Cheque:' : 'Documento',
            transaction.payment_document
          ),
        ]
      : []),
    ...([3, 4, 5].includes(transaction.payment_method.id)
      ? [
          space_evenly('# Tarjeta:', transaction.card_number),
          space_evenly('# Aprobacion:', transaction.authorization_code),
        ]
      : []),
    ...(stepped_report
      ? [
          line(),
          center('Desglose escalonado de recarga:'),
          ...stepped_report.map((r) =>
            space_evenly(`${r.range}(${r.rate}):`, r.amount)
          ),
        ]
      : []),
    line(),
    space_evenly('Le atendio:', transaction.user.name),
    center('Servicios de Plataforma brindados por'),
    center(transaction.invoicer.id != 3 ? 'TRANSNEG' : 'SOLINCORP'),
    line(),
    ...(nullified ? [center('(TRANSACCION ANULADA)'), line()] : []),
  ].join('<br>')}</pre>
   <script>
      ${
        auto_print
          ? `window.print();
      window.parent.postMessage({ type: 'receipt-done' }, '*');`
          : null
      }
   </script>`;
}

let id = 0;
export function receipt(
  transaction: TransactionDetailedView,
  print_type: number,
  extra?: any,
  auto_print: boolean = true
) {
  environment.debug('generate receipt for transaction:', transaction);

  let scale = 0,
    padding_right = 0,
    padding_bottom = 0;
  switch (print_type) {
    case 1: // Mobile PoS
      scale = 37;
      break;
    case 2: // Desktop PoS
      scale = padding_right = 12;
      padding_bottom = 35;
      break;
    case 3: // Text only
      return text_only_receipt(transaction);
  }

  const invoicer_id = transaction.invoicer.id;
  const invoicer_name = transaction.invoicer.name.toLowerCase();

  const offline = transaction.status.id === -1;
  const nullified = transaction.status.id === 6;

  const nullification = transaction.type?.id === 2;
  const prepaid = transaction.type?.id === 3;

  const extra_info = transaction.extra_info;
  const stepped_report = extra_info?.formatted_month_energy_report
    ?.split('\n')
    ?.map((r) => r.trim())
    ?.filter((r) => r)
    ?.map((r) => r.split('\t'))
    ?.map(([d, a]) => [d.replace(/[\(\)]/g, '').split(':'), a])
    ?.map(([[range, cost], amount]) => {
      const unit_match = [...(cost?.matchAll(/([0-9\.]+)DOP\/(\w+)/g) ?? [])];
      if (!unit_match.length) return { range, rate: cost, amount };
      cost = unit_match[0][1];
      const unit = unit_match[0][2];
      return { range: `${range}${unit}`, rate: `RD$ ${cost}`, amount };
    });

  const months_paid = (() => {
    if (!['jorem', 'rancier'].includes(invoicer_name)) return undefined;
    const contract = extra as QuotaContractDetailView;
    if (contract.debt_amount >= 0 || contract.fee_amount < 1)
      return [
        {
          label: '(Abono)',
          amount: transaction.amount,
        },
      ];
    const months_owed = Math.ceil(-contract.debt_amount / contract.fee_amount);
    const months_paid = Math.ceil(transaction.amount / contract.fee_amount);
    const current_month = new Date().getMonth();
    const current_year = new Date().getFullYear();
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    const months_data = range(months_owed)
      .map((m) => ({
        label: `${months[wrap(current_month - m, months.length - 1)]} ${
          current_month - m < 0 ? current_year - 1 : current_year
        }`,
        amount: 0,
      }))
      .reverse()
      .take(months_paid);
    let paying = transaction.amount;
    let debt = -contract.debt_amount;
    for (let month of months_data) {
      month.amount = Math.min(paying, contract.fee_amount, debt);
      debt -= month.amount;
      paying -= month.amount;
    }
    return months_data.concat(
      paying > 0 ? [{ label: '(Abono)', amount: paying }] : []
    );
  })();

  return `<style>@page { margin: 0; size: A4; }</style>
      <div style="width: 100%; font-family: monospace; font-size: ${scale}px; padding-right: ${padding_right}px">
         <div style="text-align: center;">
            <img id="invoicer-logo-${++id}" src="${invoicer_logo(
    invoicer_name
  )}" alt="${invoicer_name}" style="display: block; margin: 0 auto; max-width: 100%; height: ${
    scale * 5
  }px">
            RNC ${invoicer_rnc(transaction.invoicer)}

            <div style="font-weight: bold; margin-top: 0.25rem; font-size: ${
              scale * 1.75
            }px">
               ${
                 !nullification
                   ? `Comprobante de ${!prepaid ? 'Pago' : 'Recarga'}`
                   : 'Anulación'
               }
            </div>
            ${
              nullified
                ? `<div style="font-weight: bold">(TRANSACCION ANULADA)</div>`
                : ''
            }
            ${
              offline
                ? `<div style="font-weight: bold">(TRANSACCION FUERA DE LINEA)</div>`
                : ''
            }
            <div>${transaction.point_of_sales.name}</div>
            <div style="font-size: ${scale * 0.8}px;">${
    transaction.point_of_sales.address
  }</div>
         </div>
         <div style="border-top: 1px solid black; padding-top: 0.25rem; margin-top: 0.25rem; text-align: center;">
            ${fix_time(
              date_pipe.transform(transaction.date, 'dd/MMM/yy hh:mm aa')
            )}
         </div>
         <div style="border-top: 1px solid black; margin: 0.25rem 0; padding: 0.25rem 0;">
            <div style="display: flex; flex-direction:  row;">
               <div style="flex: none;">No. Tran</div>
               <div style="flex: 1 1 0%; text-align: right;">${
                 offline ? '(Offline)' : transaction.id
               }</div>
            </div>
            <div style="display: flex; flex-direction:  row;">
               <div style="flex: none;">No. Lote</div>
               <div style="flex: 1 1 0%; text-align: right;">${
                 offline ? '(Offline)' : transaction.lot
               }</div>
            </div>
            <div style="display: flex; flex-direction:  row;">
               <div style="flex: none;">Secuencia</div>
               <div style="flex: 1 1 0%; text-align: right;">${
                 offline ? 'OL ' : ''
               }${transaction.sequence}</div>
            </div>
            ${
              extra_info?.ncf
                ? `
            <div style="display: flex; flex-direction: row;">
               <div style="flex: none;">NCF</div>
               <div style="flex: 1 1 0%; text-align: right;">${extra_info.ncf}</div>
            </div>`
                : ''
            }
         </div>
         <div style="text-align: center; font-weight: bold; padding: 0.25rem 0; border-top: 1px solid black;">
            <div>${transaction.client_name ?? ''}</div>
            <div>${[1, 2, 3, 6, 7].includes(invoicer_id) ? 'Contrato ' : ''}${
    invoicer_id === 5
      ? format_phone(transaction.client_reference)
      : transaction.client_reference
  }</div>
            ${
              extra_info?.meter_serial_number
                ? `
            <div>Medidor # ${extra_info.meter_serial_number}</div>`
                : ''
            }
            ${extra?.plan ? `<div>Plan: ${extra.plan}</div>` : ''}
         </div>
         <div style="text-align: center; border-top: 1px solid black; margin-bottom: 0.25rem; padding: 0.25rem 0;">
            <div>Monto ${
              !nullification ? (!prepaid ? 'Pagado' : 'Recarga') : 'Anulado'
            }</div>
            <div style="font-weight: bold; ${
              nullified ? 'text-decoration: line-through;' : ''
            }">${currency_pipe.transform(transaction.amount, 'RD$ ')}</div>
            ${
              extra_info?.prepayment_unit_amount
                ? `
            <div style="margin-top: .25rem;">Energía Recargada</div>
            <div style="font-weight: bold;">${extra_info.prepayment_unit_amount} ${extra_info.prepayment_unit}</div>
            <div style="margin-top: .25rem;">Energía Recargada en el Mes</div>
            <div style="font-weight: bold;">${extra_info?.monthly_cummulative_prepayment} ${extra_info.prepayment_unit}</div>`
                : ''
            }
         </div>
         ${
           transaction.details.length
             ? `
         <div style="text-align: center; border-top: 1px solid black; padding-top: 0.25rem;">
            Detalle de Pago por Factura
         </div>
         <div style="border-top: 1px solid black; padding-bottom: 0.25rem;">
            ${transaction.details
              .map(
                (detail) => `
            <div style="display: flex; flex-direction: row; ${
              !detail.applied ? 'text-decoration: line-through;' : ''
            }">
               <div style="flex: 1 1 0%">${detail.invoice_id}${
                  !detail.applied ? ' (NO APLICADA)' : ''
                }</div>
               <div style="text-align: right; flex: none; display: flex; flex-direction: row;">
                  RD$ <div style="width: ${
                    scale * 5.15
                  }px">${currency_pipe.transform(detail.amount, ' ')}</div>
               </div>
            </div>`
              )
              .join('')}
         </div>`
             : ''
         }
         ${
           months_paid
             ? `
         <div style="text-align: center; border-top: 1px solid black; padding-top: 0.25rem;">
            Detalle de Pago por Mes
         </div>
         <div style="border-top: 1px solid black; padding-bottom: 0.25rem;">
            ${months_paid
              .map(
                (month) => `
            <div style="display: flex; flex-direction: row;">
               <div style="flex: 1 1 0%">${month.label}</div>
               <div style="text-align: right; flex: none; display: flex; flex-direction: row;">
                  RD$ <div style="width: ${
                    scale * 5.15
                  }px">${currency_pipe.transform(month.amount, ' ')}</div>
               </div>
            </div>`
              )
              .join('')}
         </div>`
             : ''
         }
         ${
           !nullification
             ? `
         <div style="padding: 0.25rem 0; border-top: 1px solid black">
            <div style="display: flex; flex-direction: row;">
               <div style="flex: none;">Forma de Pago</div>
               <div style="font-weight: 600; text-align: right; flex: 1 1 0%;">${
                 transaction.payment_method?.description
               }</div>
            </div>
            ${
              transaction.payment_method?.id === 1
                ? `
            <!-- Efectivo -->
            <div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: 1 1 0%">Pago</div>
                  <div style="text-align: right; flex: none; display: flex; flex-direction: row;">
                     RD$ <div style="width: ${
                       scale * 5.15
                     }px">${currency_pipe.transform(
                    transaction.cash_recieved,
                    ' '
                  )}</div>
                  </div>
               </div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: 1 1 0%">Cambio</div>
                  <div style="text-align: right; flex: none; display: flex; flex-direction: row;">
                     RD$ <div style="width: ${
                       scale * 5.15
                     }px">${currency_pipe.transform(
                    transaction.cash_recieved - transaction.amount,
                    ' '
                  )}</div>
                  </div>
               </div>
            </div>`
                : ''
            }
            ${
              transaction.payment_method?.id === 2
                ? `
            <!-- Cheque -->
            <div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">Banco</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transaction.bank.description}</div>
               </div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">No. Cheque</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transaction.payment_document}</div>
               </div>
            </div>`
                : ''
            }
            ${
              [3, 4].includes(transaction.payment_method?.id)
                ? `<!-- Tarjeta de Crédito/Débito -->
            <div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">No. Tarjeta</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transaction.card_number}</div>
               </div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">No. Aprobación</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transaction.authorization_code}</div>
               </div>
            </div>`
                : ''
            }
            ${
              transaction.payment_method?.id === 8
                ? `
            <!-- Transferencia Bancaria -->
            <div *ngIf="">
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">Banco</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transaction.bank.description}</div>
               </div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">Documento</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transaction.payment_document}</div>
               </div>
            </div>`
                : ''
            }
         </div>`
             : ''
         }
         ${
           stepped_report
             ? `
         <div style="text-align: center; border-top: 1px solid black; padding: 0.25rem 0;">
            <div style="font-weight: bold; margin-bottom: .5rem;">Desglose escalonado de recarga</div>
            <table style="width: 100%; font-size: ${scale}px; text-align: right;">
               ${stepped_report
                 .map(
                   (r) => `
               <tr>
                  <td style="width: 0; text-align: left;">${r.range}</td>
                  <td style="width: 0; text-align: left;">(${r.rate}):</td>
                  <td>${r.amount}</td>
               </tr>`
                 )
                 .join('\n')}
            </table>
         </div>`
             : ''
         }
         <div style="text-align: center; border-top: 1px solid black; padding: 0.25rem 0;">
            Le atendió<br>
            ${transaction.user.name}
         </div>
         ${
           extra_info?.token
             ? `
         <div style="text-align: center; font-weight: bold; border-top: 1px solid black; margin-top: 0.25rem; padding-top: 0.25rem;">
            <div>Token de Recarga</div>
            <div style="font-size: ${scale * 1.5}px; font-weight: bold">${
                 extra_info.token
               }</div>
         </div>`
             : ''
         }
         ${
           transaction.invoicer.name.toLowerCase() === 'puertadivina'
             ? `
         <div style="text-align: center; border-top: 1px solid black; padding: 0.75rem 0;">
            Estamos para servirle 24/7<br>
            Contáctenos al (809) 584-3823
         </div>
         <div style="text-align: center; border-top: 1px solid black; padding-top: 0.75rem;">`
             : `<div style="text-align: center; border-top: 1px solid black; margin-top: 0.75rem; padding-top: 0.75rem;">`
         }
            Servicios de Plataforma de Cobros brindados por
            <br>
            <img src="${
              invoicer_name === 'edesur'
                ? solincorp_logo_dataurl
                : transneg_logo_dataurl
            }" alt="TRANSNEG SRL" style="display: block; margin: 0 auto; height: ${
    scale * 2
  }px">
         </div>
         ${
           nullified
             ? `
         <div style="text-align: center; font-weight: bold; border-top: 1px solid black; margin-top: 0.25rem; padding-top: 0.25rem;">
            (TRANSACCION ANULADA)
         </div>`
             : ''
         }
         ${
           offline
             ? `
         <div style="text-align: center; font-weight: bold; border-top: 1px solid black; margin-top: 0.25rem; padding-top: 0.25rem;">
            (TRANSACCION FUERA DE LINEA)
         </div>`
             : ''
         }
         <div style="margin-top: ${padding_bottom}px; text-align: center; color: #fff">_</div>
      </div>
      <script>
         const invoicer_logo_${id} = document.getElementById('invoicer-logo-${id}');
         function loaded(logo) { return logo?.complete && logo?.naturalHeight !== 0; }
         const check_loaded_${id} = setInterval(() => {
            if (loaded(invoicer_logo_${id})) {
               clearInterval(check_loaded_${id});
               if (typeof(ready) !== 'undefined') ready.push(${id});
               ${
                 auto_print
                   ? `window.print();
               window.parent.postMessage({ type: 'receipt-done' }, '*');`
                   : ''
               }
            }
         }, 100);
      </script>`;
}

function multipayment_summary(
  transactions: TransactionDetailedView[],
  print_type: number
) {
  let scale = 0,
    padding_right = 0;
  switch (print_type) {
    case 1: // Mobile PoS
      scale = 37;
      break;
    case 2: // Desktop PoS
      scale = padding_right = 12;
      break;
  }

  const point_of_sales = transactions[0].point_of_sales;
  const date = transactions[0].date;
  const client_name = transactions
    .map((t) => t.client_name)
    .sort((a, b) => b.length - a.length)[0];
  const total_amount = transactions
    .map((t) => t.amount)
    .reduce((a, b) => a + b);
  const payment_method = transactions[0].payment_method;

  return `<style>@page { margin: 0; size: A4; }</style>
      <div style="width: 100%; font-family: monospace; font-size: ${scale}px; padding-right: ${padding_right}px">
         <div style="text-align: center;">
            <div style="font-weight: bold; margin-top: 0.25rem; font-size: ${
              scale * 1.75
            }px">Resúmen de Pagos</div>
            <div>${point_of_sales.name}</div>
            <div style="font-size: ${scale * 0.8}px;">${
    point_of_sales.address
  }</div>
         </div>
         <div style="border-top: 1px solid black; padding-top: 0.25rem; margin-top: 0.25rem; text-align: center;">
            ${fix_time(date_pipe.transform(date, 'dd/MMM/yy hh:mm aa'))}
         </div>
         <div style="text-align: center; font-weight: bold; margin: 0.25rem 0; border-top: 1px solid black;">
            <div>${client_name}</div>
         </div>
         <div style="text-align: center; border-top: 1px solid black; margin: 0.25rem; padding: 0.25rem;">
            Monto Pagado
            <div style="font-weight: bold;">${currency_pipe.transform(
              total_amount,
              'RD$ '
            )}</div>
         </div>
         <div style="text-align: center; border-top: 1px solid black; padding-top: 0.25rem;">
            Detalle de Pago por Facturadores
         </div>
         <div style="border-bottom: 1px solid black; padding-bottom: 0.25rem;">
            ${transactions
              .map(
                (transaction) => `
            <div style="display: flex; flex-direction: row;">
               <div style="flex: 1 1 0%">${transaction.invoicer.name} (${
                  transaction.client_reference
                })</div>
               <div style="text-align: right; flex: none; display: flex; flex-direction: row;">
                  RD$ <div style="width: ${
                    scale * 5.15
                  }px">${currency_pipe.transform(transaction.amount, ' ')}</div>
               </div>
            </div>`
              )
              .join('')}
         </div>
         <div style="margin: 0.25rem 0;">
            <div style="display: flex; flex-direction: row;">
               <div style="flex: none;">Forma de Pago</div>
               <div style="font-weight: 600; text-align: right; flex: 1 1 0%;">${
                 payment_method?.description
               }</div>
            </div>
            ${
              payment_method?.id === 1
                ? `
            <!-- Efectivo -->
            <div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: 1 1 0%">Pago</div>
                  <div style="text-align: right; flex: none; display: flex; flex-direction: row;">
                     RD$ <div style="width: ${
                       scale * 5.15
                     }px">${currency_pipe.transform(
                    sum(transactions.map((t) => t.cash_recieved)),
                    ' '
                  )}</div>
                  </div>
               </div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: 1 1 0%">Cambio</div>
                  <div style="text-align: right; flex: none; display: flex; flex-direction: row;">
                     RD$ <div style="width: ${
                       scale * 5.15
                     }px">${currency_pipe.transform(
                    sum(transactions.map((t) => t.cash_recieved)) -
                      total_amount,
                    ' '
                  )}</div>
                  </div>
               </div>
            </div>`
                : ''
            }
            ${
              payment_method?.id === 2
                ? `
            <!-- Cheque -->
            <div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">Banco</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transactions[0].bank.description}</div>
               </div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">No. Cheque</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transactions[0].payment_document}</div>
               </div>
            </div>`
                : ''
            }
            ${
              [3, 4].includes(payment_method?.id)
                ? transactions
                    .map(
                      (transaction) => `
            <!-- Tarjeta de Crédito/Débito -->
            <div style="border-top: 1px solid black;">
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">No. Tarjeta</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transaction.card_number}</div>
               </div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">No. Aprobación</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transaction.authorization_code}</div>
               </div>
            </div>`
                    )
                    .join('')
                : ''
            }
            ${
              payment_method?.id === 8
                ? `
            <!-- Transferencia Bancaria -->
            <div *ngIf="">
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">Banco</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transactions[0].bank.description}</div>
               </div>
               <div style="display: flex; flex-direction: row;">
                  <div style="flex: none;">Documento</div>
                  <div style="text-align: right; flex: 1 1 0%;">${transactions[0].payment_document}</div>
               </div>
            </div>`
                : ''
            }
         </div>
         <div style="text-align: center; border-top: 1px solid black; margin-top: 0.75rem; padding-top: 0.75rem;">
            Servicios de Plataforma de Cobros brindados por
            <br>
            <img src="${transneg_logo_dataurl}" alt="TRANSNEG SRL" style="display: block; margin: 0 auto; height: ${
    scale * 2
  }px">
         </div>
      </div>`;
}

export function receipts(
  transactions: TransactionDetailedView[],
  print_type: number,
  auto_print: boolean = true
) {
  const separation = 150;
  return `<style>@page { margin: 0; size: A4; }</style>
   <script>
      const ready = [];
      const check_all_ready = setInterval(() => {
         if (ready.length === ${transactions.length}) {
            clearInterval(check_all_ready);
            ${
              auto_print
                ? `
            window.print();
            window.parent.postMessage({ type: 'receipt-done' }, '*');`
                : ''
            }
         }
      }, 100);
   </script>

   <div>
      <div>
         ${multipayment_summary(transactions, print_type)}
      </div>
      ${transactions
        .map(
          (t, i) => `
      <div style="margin-top: ${separation}px; padding-top: ${separation}px; border-top: 4px dotted black;">
         ${receipt(t, print_type, null, false)}
      </div>`
        )
        .join('')}
   </div>`;
}

function total_groups(report): { label: string; total: number }[] {
  return [
    {
      label: 'Total Efectivo',
      get total() {
        return report.total_cash;
      },
    },
    {
      label: 'Total Cheque',
      get total() {
        return report.total_cheque;
      },
    },
    {
      label: 'Total Tarjeta de Crédito',
      get total() {
        return report.total_card;
      },
    },
    {
      label: 'Total Transferencia',
      get total() {
        return report.total_bank_transfer;
      },
    },
    {
      label: 'Total Tarjeta Bonoluz',
      get total() {
        return report.total_bonoluz;
      },
    },
    {
      label: 'Total Anulaciones',
      get total() {
        return report.total_nullified;
      },
    },
    {
      label: 'Total Postpago',
      get total() {
        return report.total_postpaid;
      },
    },
    {
      label: 'Total Prepago',
      get total() {
        return report.total_prepaid;
      },
    },
    {
      label: 'Total General',
      get total() {
        return report.total;
      },
    },
  ];
}

function transaction_groups(
  report
): { label: string; transactions: TransactionDetailedView[] }[] {
  return [
    {
      label: 'Transacciones en Efectivo',
      get transactions() {
        return report.cash_transactions;
      },
    },
    {
      label: 'Transacciones en Cheque',
      get transactions() {
        return report.cheque_transactions;
      },
    },
    {
      label: 'Transacciones en Tarjeta de Crédito',
      get transactions() {
        return report.card_transactions;
      },
    },
    {
      label: 'Transacciones en Transferencia',
      get transactions() {
        return report.bank_transfer_transactions;
      },
    },
    {
      label: 'Transacciones en Tarjeta Bonoluz',
      get transactions() {
        return report.bonoluz_transactions;
      },
    },
    {
      label: 'Anulaciones',
      get transactions() {
        return report.nullified_transactions;
      },
    },
  ];
}

function text_only_report(lot_report: LotReport) {
  function space_evenly(
    first: string | number,
    second: string | number
  ): string {
    first = first.toString();
    second = second.toString();
    if (first.length + second.length < 36)
      return (
        first +
        ' '.repeat(40 - first.length - (second.length ?? 0)) +
        (second ?? '')
      );
    return first + '\n' + second;
  }
  function center(text: string): string {
    if (text.length < 40) return ' '.repeat((40 - text.length) / 2) + text;
    return text;
  }
  function line(char: string = '-'): string {
    return char.repeat(40);
  }
  function flatten<T>(collection: T[][]): T[] {
    return collection.length ? collection.reduce((a, b) => a.concat(b)) : [];
  }

  const active = is_nothing(lot_report.date_closed);

  return `<pre>${[
    center('Reporte de ' + (active ? 'Lote Activo' : 'Cierre')),
    center(lot_report.point_of_sales.name),
    line(),
    space_evenly('Lote:', lot_report.id),
    space_evenly('Cajero:', lot_report.user.name),
    space_evenly(
      'Inicio del lote:',
      fix_time(formatDate(lot_report.date_opened, 'hh:mmaa dd/MMM/yy', 'es-DO'))
    ),
    ...(active
      ? [
          space_evenly(
            'Cierre del lote:',
            fix_time(
              formatDate(lot_report.date_closed, 'hh:mmaa dd/MMM/yy', 'es-DO')
            )
          ),
        ]
      : []),
    line(),
    ...(lot_report.invoicers.length > 1
      ? [
          center('Resumen General'),
          ...total_groups(lot_report)
            .filter((g) => g.total)
            .map((group) =>
              space_evenly(
                group.label + ':',
                formatCurrency(group.total, 'es-DO', 'RD$ ')
              )
            ),
          line(),
        ]
      : []),
    ...flatten(
      lot_report.invoicers.map((inv_report) => [
        center(inv_report.invoicer.description),
        ...total_groups(inv_report)
          .filter((g) => g.total)
          .map((group) => space_evenly(group.label + ':', group.total)),
        line(),
        ...flatten(
          transaction_groups(inv_report)
            .filter((g) => g.transactions.length)
            .map((group) => [
              group.label,
              ...(!group.transactions.length
                ? [`(No hubo ${group.label.toLowerCase()})`]
                : []),
              ...flatten(
                group.transactions.map((transaction) => [
                  space_evenly(
                    `${transaction.sequence} - ${transaction.id}`,
                    fix_time(
                      formatDate(
                        transaction.date,
                        'dd/MM/yyyy hh:mm aa',
                        'es-DO'
                      )
                    )
                  ),
                  space_evenly(
                    ([1, 2, 3].includes(transaction.invoicer.id)
                      ? 'NIC'
                      : transaction.invoicer.id == 6
                      ? 'Contr.'
                      : '') +
                      ' ' +
                      transaction.client_reference,
                    formatCurrency(transaction.amount, 'es-DO', 'RD$ ')
                  ),
                ])
              ),
              line(),
            ])
        ),
        line(),
      ])
    ),
  ].join('<br>')}</pre>
   <script>
      window.print();
      window.parent.postMessage({ type: 'receipt-done' }, '*');
   </script>`;
}

export function report(lot_report: LotReport, print_type: number) {
  environment.debug('generate receipt for lot:', lot_report.id);

  let scale = 0,
    padding_right = 0,
    padding_bottom = 0;
  switch (print_type) {
    case 1: // Mobile PoS
      scale = 37;
      break;
    case 2: // Desktop PoS
      scale = padding_right = 12;
      padding_bottom = 35;
      break;
    case 3:
      return text_only_report(lot_report);
  }

  const active = is_nothing(lot_report.date_closed);

  return `
   <style>@page { margin: 0; size: A4; }</style>
   <style>
      img { display: block; width: 100%; }
      .break { margin-top: 0.5rem; padding-top: 0.5rem; border-top: 1px solid black; }
   </style>
   <div style="width: 100%; font-family: monospace; font-size: ${scale}px; padding-right: ${padding_right}px">
      <div style="font-size: ${
        scale * 1.75
      }px; font-weight: bold; text-align: center;">
         ${active ? 'Reporte de Lote Activo' : 'Reporte de Cierre'}
      </div>
      <div style="text-align: center">${lot_report.point_of_sales.name}</div>

      <div class="break"></div>

      <div style="display: flex; flex-direction: row;">
         <div style="flex-grow: 1;">Lote:</div>
         <div>${lot_report.id}</div>
      </div>
      <div style="display: flex; flex-direction: row;">
         <div style="flex-grow: 1;">Cajero:</div>
         <div>${lot_report.user.name}</div>
      </div>
      <div style="display: flex; flex-direction: row;">
         <div style="flex-grow: 1;">Inicio del lote:</div>
         <div style="text-align: right;">${fix_time(
           date_pipe.transform(lot_report.date_opened, 'hh:mmaa dd/MMM/yy')
         )}</div>
      </div>
      ${
        !active
          ? `<div style="display: flex; flex-direction: row;">
         <div style="flex-grow: 1;">Cierre del lote:</div>
         <div style="text-align: right;">${fix_time(
           date_pipe.transform(lot_report.date_closed, 'hh:mmaa dd/MMM/yy')
         )}</div>
      </div>`
          : ''
      }

      ${
        lot_report.invoicers.length > 1
          ? `
      <div class="break"></div>

      <div style="font-weight: bold; text-align: center; font-size: ${
        scale * 1.5
      }px">
         Resúmen General
      </div>

      <table style="width: 100%; font-size: ${scale}px;">
         ${total_groups(lot_report)
           .filter((g) => g.total)
           .map(
             (group) => `
         <tr ${
           ['Total General', 'Total Postpago', 'Total Prepago'].includes(
             group.label
           )
             ? 'style="font-weight: bold;"'
             : ''
         }>
            <td>${group.label}</td>
            <td style="text-align: right; vertical-align: bottom; width: 0;">
               <div style="min-width: max-content;">${
                 group.total < 0 ? '-' : ''
               }RD$</div>
            </td>
            <td style="text-align: right; vertical-align: bottom;">
               ${currency_pipe.transform(Math.abs(group.total), ' ')}
            </td>
         </tr>
         `
           )
           .join('')}
      </table>`
          : ''
      }

      ${lot_report.invoicers
        .map(
          (invoicer_report) => `
         <div class="break"></div>

         <img src="${invoicer_logo(
           invoicer_report.invoicer.description.toLowerCase()
         )}" alt="[LOGO ${invoicer_report.invoicer.description}]"/>

         <table style="width: 100%; font-size: ${scale}px">
            ${total_groups(invoicer_report)
              .filter((g) => g.total)
              .map(
                (group) => `
            <tr ${
              ['Total General', 'Total Postpago', 'Total Prepago'].includes(
                group.label
              )
                ? 'style="font-weight: bold;"'
                : ''
            }>
               <td>${group.label}</td>
               <td style="text-align: right; vertical-align: bottom; width: 0;">
                  <div style="min-width: max-content;">${
                    group.total < 0 ? '-' : ''
                  }RD$</div>
               </td>
               <td style="text-align: right; vertical-align: bottom;">
                  ${currency_pipe.transform(Math.abs(group.total), ' ')}
               </td>
            </tr>
            `
              )
              .join('')}
         </table>

         ${transaction_groups(invoicer_report)
           .filter((g) => g.transactions.length)
           .map(
             (group) => `
            <div class="break"></div>

            <div style="font-weight: bold;">${group.label}</div>

            ${group.transactions
              .map(
                (transaction) => `
               <div style="margin-bottom: 0.25rem; padding-bottom: 0.25rem; border-bottom: 0.5px dashed rgba(0, 0, 0, .5)">
                  <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                     <div style="flex-grow: 1;">${transaction.sequence} - ${
                  transaction.id
                }</div>
                     <div style="text-align: right;">${fix_time(
                       date_pipe.transform(
                         transaction.date,
                         'dd/MM/yy hh:mm aa'
                       )
                     )}</div>
                  </div>
                  <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                     <div style="flex-grow: 1;">${
                       [1, 2, 3].includes(invoicer_report.invoicer.id)
                         ? 'NIC '
                         : invoicer_report.invoicer.id === 6
                         ? 'Contr. '
                         : ''
                     }${transaction.client_reference}</div>
                     <div style="text-align: right;">${currency_pipe.transform(
                       transaction.amount,
                       'RD$ '
                     )}</div>
                  </div>
               </div>
            `
              )
              .join('')}
            ${
              !group.transactions.length
                ? `
               <div style="font-size: ${
                 scale * 0.75
               }px;">(No hubo ${group.label.toLowerCase()})</div>
            `
                : ''
            }
         `
           )
           .join('')}
      `
        )
        .join('')}
      <div style="margin-top: ${padding_bottom}px; text-align: center; color: #fff">_</div>
   </div>
   <script>
      const images = document.getElementsByTagName('img');
      function loaded(logo) { return logo?.complete && logo?.naturalHeight !== 0; }
      const check_loaded = setInterval(() => {
         for (let img of images)
            if (!loaded(img))
               return;
         clearInterval(check_loaded);
         window.print();
         window.parent.postMessage({ type: 'receipt-done' }, '*');
      }, 100);
   </script>
   `;
}

export function wrap_print(body: string): string {
  return `<html><head></head><body>${body}</body></html>`;
}
