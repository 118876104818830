<div class="w-full font-mono" [class.mobile]="print_type == 1" [class.desktop]="print_type == 2">
	<div style="text-align: center;">
		<div class="big text-center">
			Resúmen de Pagos
		</div>
		<div>{{point_of_sales.name}}</div>
		<div style="font-size: .8em;">{{point_of_sales.address}}</div>
	</div>
	<div style="border-top: 1px solid black; padding-top: 0.25rem; margin-top: 0.25rem; text-align: center;">
		{{fix_time(date | date:'dd/MMM/yy hh:mm aa')}}
	</div>
	<div style="text-align: center; font-weight: bold; margin: 0.25rem 0; border-top: 1px solid black;">
		<div>{{client_name}}</div>
	</div>
	<div style="text-align: center; border-top: 1px solid black; margin: 0.25rem; padding: 0.25rem;">
		Monto Pagado
		<div style="font-weight: bold;">{{total_amount | currency:'RD$ '}}</div>
	</div>
	<div style="text-align: center; border-top: 1px solid black; padding-top: 0.25rem;">
		Detalle de Pago por Facturadores
	</div>
	<div style="border-bottom: 1px solid black; padding-bottom: 0.25rem;">
		<div *ngFor="let transaction of transactions" style="display: flex; flex-direction: row;">
			<div style="flex: 1 1 0%">{{transaction.invoicer.name}} ({{transaction.client_reference}})</div>
			<div style="text-align: right; flex: none; display: flex; flex-direction: row;">
				RD$ <div style="width: 5.15em;">{{(transaction.amount) | currency:' '}}</div>
			</div>
		</div>
	</div>
	<div style="margin: 0.25rem 0;">
		<div style="display: flex; flex-direction: row;">
			<div style="flex: none;">Forma de Pago</div>
			<div style="font-weight: 600; text-align: right; flex: 1 1 0%;">{{payment_method?.description}}</div>
		</div>
		<!-- Efectivo -->
		<div *ngIf="payment_method?.id === 1">
			<div style="display: flex; flex-direction: row;">
				<div style="flex: 1 1 0%">Pago</div>
				<div style="text-align: right; flex: none; display: flex; flex-direction: row;">
					RD$ <div style="width: 5.15em;">{{total_cash | currency:' '}}</div>
				</div>
			</div>
			<div style="display: flex; flex-direction: row;">
				<div style="flex: 1 1 0%">Cambio</div>
				<div style="text-align: right; flex: none; display: flex; flex-direction: row;">
					RD$ <div style="width: 5.15em;">{{(total_cash - total_amount) | currency:' '}}</div>
				</div>
			</div>
		</div>
		<!-- Cheque -->
		<div *ngIf="payment_method?.id === 1">
			<div style="display: flex; flex-direction: row;">
				<div style="flex: none;">Banco</div>
				<div style="text-align: right; flex: 1 1 0%;">{{transactions[0].bank.description}}</div>
			</div>
			<div style="display: flex; flex-direction: row;">
				<div style="flex: none;">No. Cheque</div>
				<div style="text-align: right; flex: 1 1 0%;">{{transactions[0].payment_document}}</div>
			</div>
		</div>
		<!-- Tarjeta de Crédito/Débito -->
		<ng-container *ngIf="[3, 4].includes(payment_method?.id)">
			<div *ngFor="let transaction of transactions" style="border-top: 1px solid black;">
				<div style="display: flex; flex-direction: row;">
					<div style="flex: none;">No. Tarjeta</div>
					<div style="text-align: right; flex: 1 1 0%;">{{transaction.card_number}}</div>
				</div>
				<div style="display: flex; flex-direction: row;">
					<div style="flex: none;">No. Aprobación</div>
					<div style="text-align: right; flex: 1 1 0%;">{{transaction.authorization_code}}</div>
				</div>
			</div>
		</ng-container>
		<!-- Transferencia Bancaria -->
		<div *ngIf="payment_method?.id === 8">
			<div style="display: flex; flex-direction: row;">
				<div style="flex: none;">Banco</div>
				<div style="text-align: right; flex: 1 1 0%;">{{transactions[0].bank.description}}</div>
			</div>
			<div style="display: flex; flex-direction: row;">
				<div style="flex: none;">Documento</div>
				<div style="text-align: right; flex: 1 1 0%;">{{transactions[0].payment_document}}</div>
			</div>
		</div>
	</div>
	<div style="text-align: center; border-top: 1px solid black; margin-top: 0.75rem; padding-top: 0.75rem;">
		Servicios de Plataforma de Cobros brindados por
		<br>
		<img src="/assets/logo_transnseg.png" alt="TRANSNEG SRL" style="display: block; margin: 0 auto; height: 2em">
	</div>
</div>
<transaction-receipt *ngFor="let transaction of transactions" [transaction]="transaction" [auto_print]="false"></transaction-receipt>