import { formatCurrency, formatDate } from '@angular/common';
import { BundlePayment } from '../models/models';
import {
  altice_logo_dataurl,
  asdn_logo_dataurl,
  caasd_logo_dataurl,
  claro_logo_dataurl,
  coraasan_logo_dataurl,
  edeeste_logo_dataurl,
  edenorte_logo_dataurl,
  edesur_logo_dataurl,
  puertadivina_logo_dataurl,
  viva_logo_dataurl,
  inapa_logo_dataurl
} from './receipt.logos';
import { sum, titlecase } from './utils';

function invoicer_logo(invoicer_name): string {
  switch (invoicer_name?.toLowerCase()) {
    case 'edeeste':
      return edeeste_logo_dataurl;
    case 'edenorte':
      return edenorte_logo_dataurl;
    case 'edesur':
      return edesur_logo_dataurl;
    case 'asdn':
      return asdn_logo_dataurl;
    case 'claro':
      return claro_logo_dataurl;
    case 'puertadivina':
      return puertadivina_logo_dataurl;
    case 'caasd':
      return caasd_logo_dataurl;
    case 'coraasan':
      return coraasan_logo_dataurl;
    case 'altice':
      return altice_logo_dataurl;
    case 'viva':
      return viva_logo_dataurl;
    case 'inapa':
      return inapa_logo_dataurl;
  }
}

export function bundle_receipt(payment: BundlePayment): string {
  return `
      <style>
         html {
            font-family: Rubik, Roboto, "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
            font-size: 14px;
         }
         .flex { display: flex; }
         .flex-row { flex-direction: row; }
         .justify-between { justify-content: space-between; }
         .items-baseline { align-items: baseline; }
         .align-baseline { vertical-align: baseline; }
         .h-16 { height: 4rem; }
         .bg-gray-100 { background-color: rgba(243, 244, 246, 1);}
         .rounded-lg { border-radius: 0.5rem; }
         .space-x-6 > *:not(:last-child) { margin-right: 1.5rem; }
         .space-y-4 > *:not(:last-child) { margin-bottom: 1rem; }
         .font-medium { font-weight: 500; }
         .mt-8 { margin-top: 2rem; }
         .border-t { border-top: 1px solid black; }
         .border-b { border-bottom: 1px solid black; }
         .border-gray-400 {  }
         .p-4 { padding: 1rem; }
         .p-5 { padding: 1.25rem; }
         .px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
         .pl-2 { padding-left: 0.5rem; }
         .pr-2 { padding-right: 0.5rem; }
         .text-right { text-align: right; }
         .w-0 { width: 0px; }
         .w-1/5 { width: 20%; }
         .w-3/5 { width: 60%; }
         .w-full { width: 100%; }
         table.info th { padding: 0.25rem 0.5rem; text-align: left; font-weight: 500; color: black; }
         table.info td { padding: 0.25rem 0.5rem; color: rgba(75, 85, 99, 1); }
         table.details { width: 100%; border-collapse: collapse; }
         table.details thead { border-top: 1px solid black; border-bottom: 1px solid black;  }
         table.details thead th { padding: 0.5rem; text-align: left; width: auto !important; }
         table.details td { padding: 0.5rem; }
      </style>

      <div class="space-y-4">
         <header class="p-4 flex flex-row justify-between items-baseline">
            <img class="h-16" src="${invoicer_logo(
              payment.bundle.invoicer.description
            )}">
            <h4>${formatDate(
              payment.date,
              'hh:mm aa, dd MMMM yyyy',
              'es-DO'
            )}</h4>
         </header>
         <section class="bg-gray-100 w-full p-5">
            <table class="info">
               <thead>
                  <th class="w-1/5">Bolsa Pagada:</th>
                  <th class="w-3/5">Localidad:</th>
                  <th class="w-1/5">Pago:</th>
               </thead>
               <tr>
                  <td>${payment.bundle?.description}</td>
                  <td>${payment.user?.point_of_sales?.name}</td>
                  <td rowspan=3 class="align-baseline">
                     <div>${titlecase(
                       payment.payment_method?.description
                     )}</div>
                     <div>${payment.bank?.description ?? ''}</div>
                     <div>${payment.payment_document ?? ''}</div>
                     <div *ngIf="payment.authorization_code">${
                       payment.authorization_code ?? ''
                     }</div>
                  </td>
               </tr>
               <tr>
                  <td>${
                    payment.details.filter((d) => d.applied).length
                  } contratos</td>
                  <td>${payment.user?.point_of_sales?.address}</td>
               </tr>
               <tr>
                  <td></td>
                  <td>Cajero: ${payment.user?.name}</td>
               </tr>
            </table>
         </section>

         <section class="p-5 w-full">
            <table class="details">
               <thead>
                  <th>Contrato</th>
                  <th>Factura</th>
                  <th colspan="3">Monto</th>
               </thead>
               <tbody>
                  ${payment.details
                    .filter((d) => d.applied)
                    .map(
                      (detail) => `
                     <tr>
                        <td >${detail.reference}</td>
                        <td >${detail.invoice_number}</td>
                        <td style="width: 0px;" >RD$ </td>
                        <td style="width: 0px;" class="text-right">${formatCurrency(
                          detail.amount,
                          'es-DO',
                          ' '
                        )}</td>
                        <td></td>
                     </tr>
                  `
                    )
                    .join('\n')}
                  <tr class="border-t">
                     <td class="border-t"></td>
                     <td class="border-t font-medium text-right">Total:</td>
                     <td style="width: 0px;" class="border-t">RD$ </td>
                     <td style="width: 0px;" class="border-t text-right">${formatCurrency(
                       sum(
                         payment.details
                           .filter((d) => d.applied)
                           .map((d) => d.amount)
                       ),
                       'es-DO',
                       ' '
                     )}</td>
                     <td class="border-t"></td>
                  </tr>
               </tbody>
            </table>
         </section>
      </div>

      <script>setTimeout(() => window.print(), 100)</script>
   `;
}
