import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { EdeClientView } from 'src/app/models/invoicer_query.models';
import { MultiPayQueriableView, ApiResponse, InvoicerQueriableView } from 'src/app/models/models';
import { State } from 'src/app/state';
import { error_toast } from 'src/app/utils/toast.util';
import { is_nothing, sum } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { invoicer_queriable_to_item } from '../payments.component';

@Component({
  selector: 'app-multipay',
  templateUrl: './multipay.component.html',
  styleUrls: []
})
export class MultipayComponent {
  get multipay(): MultiPayQueriableView { return State.active_multipay; }
  working = false;

  id: string;

  result: MultiQueryResult;
  responses: any[];

  constructor(private api: ApiService, private router: Router) {
    if (is_nothing(this.multipay))
      router.navigate(['cashier', 'pay']);
  }

  multi_query() {
    this.working = true;

    let name: string;
    const multi_results: QueryResult[] = [];
    const results = [];
    const done = [];
    const check = () => {
      if (done.length === this.multipay.invoicers.length) {
        this.result = { name, queries: multi_results };
        this.responses = results;
        this.working = false;
        environment.debug('result:', this.result);
      }
    };

    for (let invoicer of this.multipay.invoicers) {
      switch (invoicer.name.toLowerCase()) {
        case 'asdn':  {
          this.api.query_asdn(this.id, response => {
            if (response.succeeded) {
              results.push({ invoicer, data: response.data });
              name = response.data.name;
              multi_results.push({
                invoicer,
                reference: response.data.document,
                invoices: response.data.pending_invoices.map(i => ({ id: i.id, amount: i.amount }))
              });
              done.push(invoicer.name);
              check();
            } else {
              error_toast('ASDN: ' + response.error.message);
              this.working = false;
            };
          });
        } break;
        case 'edeeste': case 'edenorte': case 'edeeste': {
          const callback = (response: ApiResponse<EdeClientView>) => {
            if (response.succeeded) {
              results.push({ invoicer, data: response.data });
              name = response.data.name;
              for (let contract of response.data.contracts)
                multi_results.push({
                  invoicer,
                  reference: contract.id,
                  invoices: contract.pending_invoices.map(i => ({ id: i.id, amount: i.amount }))
                });
              done.push(invoicer.name);
              check();
            } else {
              error_toast(invoicer.name + ': ' + response.error.message);
              this.working = false;
            }
          };
          switch(invoicer.name.toLowerCase()) {
            case 'edeeste':
              this.api.query_edeeste(this.id, callback); break;
            case 'edenorte':
              this.api.query_edenorte(this.id, callback); break;
            case 'edesur':
              this.api.query_edesur(this.id, callback); break;
          }
        } break;
      }
    }
  }

  go_to(invoicer: InvoicerQueriableView) {
    State.active_invoicer = invoicer;
    const result = this.responses.find(r => r.invoicer === invoicer);
    State.preloaded_query = { id: this.id, data: result.data };
    this.router.navigate(['cashier', 'pay', invoicer.name.toLowerCase()]);
  }

  selectable_results: { selected: boolean; result: QueryResult; }[] = []
  selectable(result: QueryResult) {
    let sel = this.selectable_results.find(s => s.result === result);
    if (sel) return sel;
    else {
      sel = { selected: true, result };
      this.selectable_results.push(sel);
      return sel;
    }
  }

  get selected(): QueryResult[] {
    return this.result.queries
      .map(r => this.selectable(r))
      .filter(s => s.selected)
      .map(s => s.result);
  }
  get amount_to_pay(): number {
    return this.selected.length? this.selected.map(r => this.to_pay(r)).reduce((a, b) => a + b) : 0;
  }
  to_pay(result: QueryResult) {
    return sum(result.invoices.map(i => i.amount));
  }

  pay() {
    State.payment_package = this.selected.map(r => ({
      name: this.result.name,
      document: r.reference,
      invoicer: invoicer_queriable_to_item(r.invoicer),
      type: 1,
      payloads: r.invoices.map(i => ({
        invoice_id: i.id,
        concept: null,
        amount: i.amount
      }))
    }));
    this.router.navigate(['cashier', 'apply-payment']);
  }
}

interface MultiQueryResult {
  name: string;
  queries: QueryResult[];
}

interface QueryResult {
  invoicer: InvoicerQueriableView;
  reference: string;
  invoices: QueryInvoice[];
}

interface QueryInvoice {
  id: string;
  amount: number;
}