<h1>Pago de Facturas</h1>
<div *ngIf="!(postpaid_result || prepaid_result)" class="card mt-8">
   <h3 class="text-lg font-semibold border-b">
      Consulta de cliente
      <img src="assets/logos/logo_{{ ede }}.png" class="inline h-5 mb-3">
   </h3>
   <div *ngIf=prepayment_enabled class="tabs">
      <div (click)="mode = 1" class="tab" [class.active]="mode === 1">Facturas</div>
      <div (click)="mode = 2" class="tab" [class.active]="mode === 2">Recarga</div>
      <div *ngIf="this.ede == 'edenorte'" (click)="mode = 3" class="tab" [class.active]="mode === 3">Fuera de Linea</div>
   </div>
   <div *ngIf="mode !== 3" class="flex flex-col items-stretch flex-wrap mt-6 md:flex-row md:items-baseline">
      <div class="mr-4 font-semibold">{{ mode === 1 ? 'NIC o Cédula' : 'NIC o Serial del Medidor' }}</div>
      <div class="flex-grow">
         <input id="nic" [(ngModel)]="id" (keydown.enter)="query()" type="number" inputmode="numeric"
            placeholder="Ingrese el NIC{{ mode === 1? ' o la cédula' : ' o el serial del medidor' }} que desea {{ mode === 1? 'pagar' : 'recargar' }}"
            class="py-2 px-2 w-full rounded border-0 border-b border-gray-300 md:py-0">
      </div>
   </div>
   <div *ngIf="mode !== 3" class="flex gap-2 w-full mt-8 md:mt-3 md:w-max">
      <button (click)="query()" [disabled]="working.main || !id" class="btn btn-blue">
         Consultar
         <fa-icon icon="search" class="text-xs"></fa-icon>
         <working *ngIf="working.main"></working>
      </button>
      <button *ngIf="['inapa'].includes(ede.toLowerCase())" routerLink="/cashier/orders/create"
         [queryParams]="{ invoicer_id: 12 }" class="btn">
         <fa-icon icon="plus-circle"></fa-icon>
         Crear orden
      </button>
   </div>
   <div *ngIf="mode === 3" class="flex flex-col items-stretch flex-wrap mt-6 md:flex-row md:items-baseline">
      <div class="mr-4 font-semibold">Zona seleccionada:</div>
      <div class="flex-grow">
         <select id="zone" [(ngModel)]="zone_id" [disabled]="zones.length < 1" (keydown.enter)="download_for_zone()"
            type="number" inputmode="numeric" placeholder="Ingrese la zona que desea buscar"
            class="py-2 px-2 w-full rounded border-0 border-b border-gray-300 md:py-0">
            <option [value]="zone.zone" *ngFor="let zone of zones">{{zone.zone}} ({{zone.count}})</option>
         </select>
      </div>
   </div>

   <button *ngIf="mode === 3" (click)="download_for_zone()" [disabled]="working.download || !zone_id"
      class="btn btn-blue mt-8 mb-4 w-full md:mt-3 md:w-max">
      Descargar
      <fa-icon icon="search" class="text-xs"></fa-icon>
      <working *ngIf="working.download"></working>
   </button>
   <div *ngIf="mode === 3 && showContractList">
      <div class="md:table md:rounded-lg md:border w-full">
         <div class="hidden md:table-header-group font-semibold text-center text-lg">
            <div class="table-cell py-1 border-l">Fecha</div>
            <div class="table-cell py-1 border-l">Documento</div>
            <div class="table-cell py-1 border-l">Nombre</div>            
            <div class="table-cell py-1 border-l">Dirección</div>
            <div class="table-cell py-1 border-l">Deuda total</div>
            <div class="table-cell py-1 border-l">Teléfono</div>
            <div class="table-cell py-1 border-l">Facturas pendientes</div>
         </div>
         <div class="md:table-row-group"*ngFor="let contract of contracts">
            <div   class="font-semibold text-lg md:text-base md:font-normal md:table-cell md:border-t md:border-l md:px-2">
               {{ date | date: 'dd MMM yyyy' }}
            </div>
            <div   class="font-semibold text-lg md:text-base md:font-normal md:table-cell md:border-t md:border-l md:px-2">
               {{ contract.document }}
            </div>
            <div   class="font-semibold text-lg md:text-base md:font-normal md:table-cell md:border-t md:border-l md:px-2">
               {{ contract.name }}
            </div>
            <div   class="font-semibold text-lg md:text-base md:font-normal md:table-cell md:border-t md:border-l md:px-2">
               {{ contract.address }}
            </div>
            <div   class="font-semibold text-lg md:text-base md:font-normal md:table-cell md:border-t md:border-l md:px-2">
               {{ contract.total_debt }}
            </div>
            <div   class="font-semibold text-lg md:text-base md:font-normal md:table-cell md:border-t md:border-l md:px-2">
               {{ contract.phone == null ? '' : contract.phone }}
            </div>
            <div class="font-semibold text-lg text-center items-center justify-center md:text-base md:font-normal md:table-cell md:border-t md:border-l md:px-2">
               <fa-icon  icon="info" class="cursor-help relative" [title]="getPendingInvoicesTitle(contract.document)"></fa-icon>
            </div>
         </div>
      </div>
   </div>
</div>

<div *ngIf=postpaid_result class="card w-full mt-8">
   <h3 class="text-lg font-semibold border-b">
      Resultado consulta de cliente
      <img src="assets/logos/logo_{{ ede }}.png" class="inline h-5 mb-3">
   </h3>
   <div class="mt-6 flex flex-col items-stretch space-y-2">
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-44">Documento Consultado:</div>
         <div class="font-semibold md:ml-4 md:font-normal">{{ id }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-44">Nombre del Cliente:</div>
         <div class="text-2xl font-semibold md:font-normal md:ml-4">{{ postpaid_result.name }}</div>
      </div>
      <div *ngFor="let contract of postpaid_result.contracts" class="border rounded-lg" style="margin-top: 16px">
         <div class="flex flex-row py-1 cursor-pointer bg-gray-100 active:bg-gray-200 rounded-t-lg"
            [class.rounded-b-lg]="!expandable(contract).expanded" (click)="toggle_expandable(contract)">
            <div class="flex-1">
               <div class="font-semibold px-2 md:px-4">Contrato {{ contract.id }}</div>
               <div class="px-2 md:px-4">Dirección: {{ contract.address }}</div>
            </div>
            <div class="flex-none mr-3 md:mr-5 mt-3" style="vertical-align: middle;">
               <fa-icon *ngIf="expandable(contract).expanded" icon="chevron-down"></fa-icon>
               <fa-icon *ngIf="!expandable(contract).expanded" icon="chevron-right"></fa-icon>
            </div>
         </div>
         <div *ngIf="expandable(contract).expanded" class="px-2 md:px-4 pb-4 border-t">
            <div *ngIf="!consolidated_payment">
               <h3 class="font-semibold mt-4 mb-2">Facturas:</h3>
               <div class="w-full md:table md:border md:rounded-md">
                  <div class="hidden md:table-header-group">
                     <div class="table-cell"></div>
                     <div class="table-cell border-l" title="Número de Factura">Nro. Factura</div>
                     <div class="table-cell border-l">Concepto</div>
                     <div class="table-cell border-l">Fecha</div>
                     <div class="table-cell border-l">Fecha Vencimiento</div>
                     <div class="table-cell border-l">Monto</div>
                     <div *ngIf="partial_payments" class="table-cell border-l">Monto a Pagar</div>
                  </div>
                  <div class="md:table-row-group">
                     <div *ngIf="!contract.pending_invoices?.length" class="md:table-row">
                        <td title="No existen facturas pendientes para este contrato"
                           class="border-t text-center font-semibold text-red-600" colspan="6">
                           <fa-icon icon="times"></fa-icon>
                           Este contrato no tiene facturas pendientes
                        </td>
                     </div>
                     <div *ngFor="let invoice of contract.pending_invoices"
                        class="flex flex-row rounded-md mb-6 shadow md:shadow-none md:table-row"
                        [class.!bg-gray-200]="payment_policy.id === 3 && before_today(invoice.expiration_date)">
                        <div class="flex-none self-stretch pr-2 md:px-2 md:table-cell md:text-center"
                           style="border-left-width: 0;">
                           <input [checked]="selectable(invoice).selected" (click)="toggle_selection(invoice)"
                              [readonly]="payment_policy.id === 3 && before_today(invoice.expiration_date)"
                              type="checkbox" class="h-full rounded-l-md rounded-r-none md:h-auto md:rounded">
                        </div>
                        <div class="flex-1 md:contents">
                           <div (click)="toggle_selection(invoice)" class="flex flex-row md:cursor-pointer md:contents">
                              <div class="flex-1 md:contents md:border-l">
                                 <div class="md:table-cell md:px-3 md:text-right">{{ invoice.id }}</div>
                                 <div class="md:table-cell md:px-3">{{ invoice.description }}</div>
                              </div>
                              <div class="pr-1 md:contents md:border-l">
                                 <div class="text-right md:table-cell md:px-3 md:text-center">{{ invoice.issue_date |
                                    date }}</div>
                                 <div class="text-right md:table-cell md:px-3 md:text-center">
                                    <span class="md:hidden">Vence: </span>
                                    {{ invoice.expiration_date | date }}
                                 </div>
                              </div>
                           </div>
                           <div class="text-right md:table-cell md:px-3">{{ invoice.amount | currency:'RD$ ' }}</div>
                           <div *ngIf="partial_payments"
                              class="flex flex-row items-baseline border-t mt-1 md:table-cell md:px-2 md:text-right">
                              <div class="block min-w-max md:hidden">Monto a pagar: RD$</div>
                              <input [(ngModel)]="selectable(invoice).amount_to_pay"
                                 (input)="handle_amount_change(invoice)"
                                 [readonly]="payment_policy.id === 3 && before_today(invoice.expiration_date)" [min]="0"
                                 [max]="invoice.amount" type="number"
                                 class="py-2 px-2 border-0 border-b boder-gray-300 w-full text-right rounded-br-md md:py-1 md:bg-gray-100 md:rounded-md">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div *ngIf="consolidated_payment">
               <h3 class="font-semibold mt-4 mb-1">Deuda: {{ consolidated(contract).debt | currency:'RD$ ' }}</h3>
               <h4>Facturas pendientes: {{ contract.pending_invoices.length }}</h4>
               <div class="mt-4 border-t py-4 border-b">
                  <div class="font-semibold">Monto a pagar</div>
                  <div class="flex flex-row">
                     <div class="rounded border border-gray-400 pl-2 pr-1 flex flex-row items-baseline">
                        <span class="font-semibold">RD$</span>
                        <input type="number" [(ngModel)]="consolidated(contract).amount_to_pay"
                           (keydown.enter)="consolidated(contract).amount_to_pay? pay(contract) : null"
                           class="flex-1 py-1 border-0 focus:outline-none focus:ring-0 text-right w-32"
                           placeholder="0.00" [min]=0 [max]=consolidated(contract).debt>
                     </div>
                  </div>
               </div>
            </div>
            <div class="flex flex-row mt-4 md:w-max">
               <button (click)="postpaid_result = null" class="btn w-max mr-2">
                  <fa-icon icon="arrow-left"></fa-icon>
                  Volver
               </button>
               <button (click)="pay(contract)"
                  [disabled]="consolidated_payment? consolidated(contract).amount_to_pay <= 0 : selected(contract).length === 0"
                  class="btn btn-blue flex-1">
                  Pagar
                  <fa-icon icon="hand-holding-usd"></fa-icon>
               </button>
            </div>
         </div>
      </div>
   </div>
</div>

<div *ngIf=prepaid_result class="card w-full mt-8">
   <h3 class="text-lg font-semibold border-b">
      Resultado consulta de cliente prepago
      <img src="assets/logos/logo_{{ ede }}.png" class="inline h-5 mb-3">
   </h3>
   <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
      <div class="md:font-semibold w-44">ID consultado:</div>
      <div class="font-semibold md:ml-4 md:font-normal">{{ id }}</div>
   </div>
   <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
      <div class="md:font-semibold w-44">Nombre del Cliente:</div>
      <div class="text-2xl font-semibold md:font-normal md:ml-4">{{ prepaid_result.name }}</div>
   </div>
   <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
      <div class="md:font-semibold w-44">Balance:</div>
      <div class="text-xl font-semibold md:ml-4 md:font-normal">{{ prepaid_result.balance | currency:'RD$ ' }}</div>
   </div>
   <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
      <div class="md:font-semibold w-44">Pago mínimo:</div>
      <div class="font-semibold md:ml-4 md:font-normal">{{ prepaid_result.minimum_payment | currency:'RD$ ' }}</div>
   </div>
   <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
      <div class="md:font-semibold w-44">Recargado en el mes:</div>
      <div class="font-semibold md:ml-4 md:font-normal">{{ prepaid_result.monthly_amount | currency:'RD$ ' }}</div>
   </div>
   <hr class="my-4">
   <div class="mt-4">
      <div class="font-semibold">Monto a recargar:</div>
      <div>
         <div>
            <span class="text-lg">RD$ </span>
            <input type="number" [(ngModel)]="amount_to_prepay" (keydown.enter)="pre_pay()" placeholder="0.00"
               [min]=prepaid_result.minimum_payment
               class="inline text-lg py-0 border-0 border-b border-gray-300 text-right outline-none focus:ring-0 w-24 px-0">
         </div>
         <div class="flex flex-row mt-4 md:w-max">
            <button (click)="prepaid_result = null" class="btn w-max mr-2">
               <fa-icon icon="arrow-left"></fa-icon>
               Volver
            </button>
            <button (click)="pre_pay()"
               [disabled]="!amount_to_prepay || amount_to_prepay < prepaid_result.minimum_payment"
               class="btn btn-blue flex-1">
               Recargar
               <fa-icon icon="hand-holding-usd"></fa-icon>
            </button>
         </div>
      </div>
   </div>
</div>