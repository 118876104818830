<h1>Cobros Pospuestos</h1>

<working *ngIf="!loaded" color="#3B82F6" [size]="30"></working>

<div *ngIf="loaded && !postponed_payments?.length" class="card mt-8 !bg-yellow-100 text-lg font-medium">
   <fa-icon icon="times-circle"></fa-icon>
   No existen cobros pospuestos activos actualmente en la organizacion
</div>

<div *ngIf="postponed_payments?.length" class="card mt-8">
   <div class="flex flex-row">
      <table-control #cobros for="cobros pospuestos" [source]="postponed_payments" [filter]="filter" class="flex-1"></table-control>
      <input type=text [(ngModel)]="filter" class="rounded py-px px-2" placeholder="🔍 Filtrar...">
   </div>
   <div class="table-list my-4">
      <div class="header">
         <div>Cobrador</div>
         <div>Punto de Venta</div>
         <div>Cliente/Contrato</div>
         <div>Fecha Pospuesto</div>
         <div>Fecha Próxima de Pago</div>
         <div>Razón</div>
      </div>
      <div class="items">
         <div *ngFor="let postponed of cobros.slice" class="item">
            <div class="cell first">
               <fa-icon icon="user" class="md:hidden mr-2"></fa-icon>
               {{ postponed.user.name }}
            </div>
            <div class="cell">
               <fa-icon icon="store-alt" class="md:hidden mr-2"></fa-icon>
               {{ postponed.point_of_sales.name }}
            </div>
            <div class="cell">
               <span class="md:hidden mr-2 font-medium">Cliente/Contrato:</span>
               {{ postponed.client_reference }}
            </div>
            <div class="cell">
               <span class="md:hidden mr-2 font-medium">Fecha pospuesto:</span>
               {{ postponed.date_postponed | date:'dd/MMM/yyyy, hh:mm aa':null:'es-DO' }}
            </div>
            <div class="cell">
               <span class="md:hidden mr-2 font-medium">Fecha próximo pago:</span>
               {{ postponed.date_next_payment | date:'EEEE dd MMM yyyy':null:'es-DO' | titlecase }}
            </div>
            <div class="cell">
               <span class="md:hidden mr-2 font-medium">Razón:</span>
               "{{ postponed.reason }}"
            </div>
         </div>
      </div>
   </div>
   <table-paginator for="cobros pospuestos"></table-paginator>
</div>
