<h1>Pago de Facturas</h1>

<div *ngIf="!result" class="card mt-8">
   <h3 class="text-lg font-semibold border-b">
      Consulta de constribuyente
      <img src="assets/logos/logo_samana.png" class="inline h-4 ml-1">
   </h3>
   <div class="tabs">
      <div (click)="mode = 1" class="tab" [class.active]="mode === 1">Facturas</div>
      <!-- <div (click)="mode = 3" class="tab" [class.active]="mode === 3">Ordenes de pago</div> -->
   </div>
   <div *ngIf="mode === 1" class="flex flex-col items-stretch flex-wrap mt-6 md:flex-row md:items-baseline">
      <div class="mr-4 font-semibold">Cédula o RNC:</div>
      <div class="flex-grow">
         <input id="document" [(ngModel)]="id" (keydown.enter)="query()"
            placeholder="Ingrese la cédula, o el RNC que desea pagar"
            class="py-2 px-2 md:py-0 w-full rounded border-0 border-b border-gray-300">
      </div>
   </div>
   <div *ngIf="mode === 1" class="mt-8 md:mt-3 flex flex-col md:flex-row md:gap-2 items-center">
      <button (click)="query()" [disabled]="working || !id?.trim()" class="w-full btn btn-blue md:w-max">
         Consultar
         <fa-icon icon="search"></fa-icon>
         <working *ngIf="working"></working>
      </button>
      <!-- <button routerLink="/cashier/orders/create" [queryParams]="{ invoicer_id: 21 }" class="w-full btn md:w-max">
         <fa-icon icon="plus-circle"></fa-icon>
         Crear orden
      </button> -->
   </div>
   <div *ngIf="mode === 3" class="flex flex-col items-stretch flex-wrap mt-6 md:flex-row md:items-baseline">
      <div class="mr-4 font-semibold">Referencia:</div>
      <div class="flex-grow">
         <input id="document" [(ngModel)]="id" (keydown.enter)="query()"
            placeholder="Ingrese el código o el ID de la orden de pago"
            class="py-2 px-2 md:py-0 w-full rounded border-0 border-b border-gray-300">
      </div>
   </div>
</div>

<div *ngIf="result" class="card mt-8">
   <h3 class="font-semibold border-b">
      Respuesta de consulta de contribuyente
      <img src="assets/logos/logo_samana.png" class="inline h-4 ml-1">
   </h3>
   <div class="mt-6 flex flex-col items-stretch space-y-2">
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Documento de identificación:</div>
         <div class="font-semibold md:font-normal md:ml-4">{{ result.document }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Nombre del contribuyente:</div>
         <div class="font-semibold md:font-normal text-2xl md:ml-4">{{ result.name }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Dirección:</div>
         <div class="font-semibold md:font-normal md:ml-4">{{ result.address }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Balance:</div>
         <div class="font-semibold md:font-normal text-2xl md:ml-4">{{ result.total_debt | currency }}</div>
      </div>
      <div class="pt-4 border-t md:hidden" style="margin-top: 24px;">
         <h3 class="font-semibold">Facturas:</h3>
      </div>
      <div class="w-full md:table md:border md:rounded-md" style="margin-top: 1.5rem;">
         <div class="hidden md:table-header-group">
            <div class="table-cell"></div>
            <div class="table-cell border-l" title="Número de Factura">Nro. Factura</div>
            <div class="table-cell border-l">Concepto</div>
            <div class="table-cell border-l">Fecha</div>
            <div class="table-cell border-l">Monto</div>
            <div *ngIf="partial_payments" class="table-cell border-l">Monto a pagar</div>
         </div>
         <div class="md:table-row-group">
            <div *ngIf="!result.pending_invoices?.length" class="md:table-row">
               <td title="No existen facturas pendientes para este contribuyente" class="border-t text-center"
                  colspan="6">
                  <fa-icon icon="times"></fa-icon>
               </td>
            </div>
            <div *ngFor="let invoice of result.pending_invoices"
               class="flex flex-row rounded-md mb-6 shadow md:shadow-none md:table-row">
               <div class="flex-none self-stretch pr-2 md:px-2 md:table-cell md:text-center"
                  style="border-left-width: 0;">
                  <input [(ngModel)]="selectable(invoice).selected" type="checkbox"
                     class="h-full rounded-l-md rounded-r-none md:h-auto md:rounded">
               </div>
               <div class="flex-1 md:contents">
                  <div (click)="selectable(invoice).selected = !selectable(invoice).selected"
                     class="flex flex-row md:cursor-pointer md:contents">
                     <div class="flex-1 md:border-l md:contents">
                        <div class=" md:table-cell md:px-3 md:text-right">{{ invoice.id }}</div>
                        <div class=" md:table-cell md:px-3">{{ invoice.concept }}</div>
                     </div>
                     <div class="pr-1 md:border-l md:contents">
                        <div class="text-right md:table-cell md:px-3 md:text-center">{{ invoice.issue_date | date }}
                        </div>
                        <div class="text-right md:table-cell md:px-3">{{ invoice.amount | currency:'RD$ ' }}</div>
                     </div>
                  </div>
                  <div *ngIf="partial_payments"
                     class="flex flex-row items-baseline border-t mt-1 md:table-cell md:px-2 md:text-right">
                     <div class="block min-w-max mr-1 md:hidden">Monto a pagar: RD$</div>
                     <input type="number" [(ngModel)]="selectable(invoice).amount_to_pay" [min]="0"
                        [max]="invoice.amount"
                        class="py-2 px-2 border-0 border-b boder-gray-300 w-full text-right rounded-br-md md:py-1 md:bg-gray-100 md:rounded-md">
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="flex flex-row mt-3 md:w-max">
      <button (click)="result = null" class="btn w-max mr-2">
         <fa-icon icon="arrow-left"></fa-icon>
         Volver
      </button>
      <button (click)="pay()" [disabled]="!can_pay" class="btn btn-blue flex-1">
         Pagar
         <fa-icon icon="hand-holding-usd"></fa-icon>
      </button>
   </div>
</div>

<div *ngIf="result_order" class="card mt-8">
   <h3 class="font-semibold border-b">
      Respuesta de consulta de orden de pago
      <img src="assets/logos/logo_samana.png" class="inline h-4 ml-1">
   </h3>
   <div class="grid gap-2">
      <table>
         <tbody>
            <tr>
               <td class="font-medium">Código:</td>
               <td class="!px-0">{{ result_order.code }}</td>
            </tr>
            <tr>
               <td class="font-medium">Descripción:</td>
               <td class="!px-0">{{ result_order.description }}</td>
            </tr>
            <tr>
               <td class="font-medium">Nombre del titular:</td>
               <td class="!px-0">{{ result_order.name }}</td>
               <td class="font-medium">Documento del titular:</td>
               <td class="!px-0">{{ result_order.document }}</td>
            </tr>
            <tr>
               <td class="font-medium">Fecha de creación:</td>
               <td class="!px-0">{{ result_order.date_created | date:'dd MMM, yyyy':null:'es-DO' }}</td>
               <td class="font-medium">Fecha de vencimiento:</td>
               <td class="!px-0">{{ result_order.date_expiration | date:'dd MMM, yyyy':null:'es-DO' }}</td>
            </tr>
            <tr>
               <td class="font-medium">Monto total:</td>
               <td class="!px-0">{{ result_order.amount | currency:'RD$ ' }}</td>
               <td class="font-medium">Monto pendiente:</td>
               <td class="!px-0">{{ result_order.pending | currency:'RD$ ' }}</td>
            </tr>
         </tbody>
      </table>

      <table class="border border-gray-300">
         <thead>
            <tr>
               <th class="border-gray-300 border-b border-r">Código</th>
               <th class="border-gray-300 border-b border-r">Descripción</th>
               <th class="border-gray-300 border-b border-r">Precio unitario</th>
               <th class="border-gray-300 border-b border-r">Cantidad</th>
               <th class="border-gray-300 border-b ">Total</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let detail of result_order.details">
               <td class="px-4 border-gray-300 border-t border-r">{{ detail.code }}</td>
               <td class="px-4 border-gray-300 border-t border-r">{{ detail.description }}</td>
               <td class="px-4 border-gray-300 border-t border-r">{{ detail.unit_amount | currency:'RD$ ' }}</td>
               <td class="px-4 border-gray-300 border-t border-r">{{ detail.quantity }}</td>
               <td class="px-4 border-gray-300 border-t ">{{ detail.total | currency:'RD$ ' }}</td>
            </tr>
         </tbody>
      </table>
   </div>
   <div class="flex flex-row mt-3 md:w-max">
      <button (click)="result_order = null" class="btn w-max mr-2">
         <fa-icon icon="arrow-left"></fa-icon>
         Volver
      </button>
      <button (click)="pay()" [disabled]="!result_order.pending" class="btn btn-blue flex-1">
         {{ result_order.pending? 'Pagar' : '(Pagada)' }}
         <fa-icon icon="hand-holding-usd"></fa-icon>
      </button>
   </div>
</div>