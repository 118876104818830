import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { LotReport, LotView, UserNullificationRequestView } from 'src/app/models/models';
import { OfflineDatabase } from 'src/app/offline_db';
import { State } from 'src/app/state';
import { modal } from 'src/app/utils/modal.util';
import { report, wrap_print } from 'src/app/utils/receipt.util';
import { error_toast, success_toast } from 'src/app/utils/toast.util';
import { clear_element } from 'src/app/utils/utils';
import PrintService from 'src/app/print.service'

@Component({
  selector: 'app-closure',
  templateUrl: './closure.component.html',
  styleUrls: []
})
export class ClosureComponent {
  working = false;

  get point_of_sales() { return State.user.point_of_sales.name; }
  lot: LotView;

  closed = false;
  report: LotReport;

  null_requests: UserNullificationRequestView[];

  get pending_null_request_count(): number {
    return this.null_requests?.filter(nr => !nr.processed)?.length ?? 0;
  }

  get printing_frame_container(): HTMLDivElement {
    return <HTMLDivElement>document.getElementById('printing-frames');
  }

  get has_queued_payments(): boolean { return OfflineDatabase.has_queued_payments; }

  constructor(private api: ApiService, private router: Router) {
    this.working = true;
    api.get_active_lot(response => {
      this.working = false;
      if (response.succeeded)
        this.lot = response.data;
      else
        error_toast(response.error.message);
    });
    api.get_user_nullification_requests(response => {
      if (response.succeeded)
        this.null_requests = response.data;
      else
        error_toast(response.error.message);
    });
  }

  close() {
    if (this.pending_null_request_count) {
      modal('Solicitudes de anulacion pendientes', `Actualmente tienes ${ this.pending_null_request_count } solicitudes de anulacion pendientes.` + '\n'
        + 'Si cierras el lote ahora, esas transacciones NO seran anuladas.\n\n¿Deseas cerrar el lote sin anular estas transacciones?', ['_Cerrar sin anular', 'Cancelar'], result => {
          if (result.id === 0)
            this._close();
        })
    } else this._close();
  }

  _close() {
    this.working = true;
    this.api.close_lot(this.lot.id, response => {
      this.working = false;

      if (response.succeeded) {
        this.closed = true;
        this.report = response.data;
        this.print();
        success_toast('Lote cerrado.');
      } else {
        this.working = false;
        error_toast(response.error.message);
      }
    });
  }

  print() {

    this.api.get_operation_lot(this.lot.id, async (response) => {
      this.working = false;
      if (response.succeeded) {
        this.report = response.data
        await PrintService.render('report', this.report)
      } else {
        error_toast(response.error.message)
      }
    })
	}
  
  print_report() {
    this.working = true;

    clear_element(this.printing_frame_container);

    this.api.get_print_type(response => {
      if (response.succeeded) {
        const print_type = response.data;
        const print_contents = report(this.report, print_type);

        if (State.on_cordova) {
          (window as any).cordova.plugins.printer.print(wrap_print(print_contents));
          this.working = false;
          return;
        }

        const frame = document.createElement('iframe');
        frame.setAttribute('style', 'visibility: false; position: fixed; top: 0; left: 0; right: 0; z-index: -10;');
        this.printing_frame_container.appendChild(frame);
        frame.contentWindow.document.write(print_contents);
        window.onmessage = evt => {
          if (evt?.data.type == 'receipt-done')
            this.working = false;
        };
      } else {
        this.working = false;
        error_toast(response.error.message);
      }
    });
  }

  go_to_queued_payments() {
    this.router.navigate(['user', 'queued-payments']);
  }
}