import { Component, Input } from '@angular/core'
import { ApiService } from 'src/app/api.service'
import { TransactionDetailedView } from 'src/app/models/models'
import PrintService from 'src/app/print.service'
import { sum } from '../../utils'

@Component({
	selector: 'multi-transaction-receipt',
	templateUrl: './multi-transaction-receipt.component.html',
	styleUrls: ['./multi-transaction-receipt.component.less'],
})
export class MultiTransactionReceiptComponent {
	@Input() transactions: TransactionDetailedView[]

	get point_of_sales() {
		return this.transactions[0].point_of_sales
	}
	get date() {
		return this.transactions[0].date
	}
	get client_name() {
		return this.transactions.map((t) => t.client_name).sort((a, b) => b.length - a.length)[0]
	}
	get total_amount() {
		return this.transactions.map((t) => t.amount).reduce((a, b) => a + b)
	}
	get total_cash() {
		return sum(this.transactions.map((t) => t.cash_recieved))
	}
	get payment_method() {
		return this.transactions[0].payment_method
	}

	print_type: 1 | 2 | 3 = 1

	constructor(private api: ApiService) {}
	ngAfterContentInit() {
		this.api.get_print_type(({ data: print_type }) => {
			this.print_type = print_type as any
			setTimeout(() => {
				;(window as any).cordova?.plugins?.printer?.print?.()
				window.print()
				// setTimeout(() => PrintService.done?.(), 1_000)
			}, 500)
		})
	}

	fix_time = (datetime: string) => datetime.replace(/a\.\sm\./g, 'am').replace(/p\.\sm\./g, 'pm')
}
