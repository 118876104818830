import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { OfflineDatabase } from 'src/app/offline_db';
import { State } from 'src/app/state';
import { clear_modal, modal } from 'src/app/utils/modal.util';
import { error_toast, success_toast } from 'src/app/utils/toast.util';
import { is_nothing, is_today, since, zero_pad } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { validation, validator } from 'src/app/utils/validator.util';
import { invoicer_queriable_to_item } from '../payments.component';
import { QuotaContractView, QuotaContractDetailView } from 'src/app/models/invoicer_query.models';
import { PostponedPaymentView, InvoicerQueriableView, ApiResponse } from 'src/app/models/models';

@Component({
   selector: 'app-quota',
   templateUrl: './quota.component.html',
   styleUrls: []
})
export class QuotaComponent {
   invoicer: string
   contracts: QuotaContractView[];
   postponed_payments: PostponedPaymentView[];
   filter: string;
   working: string;
   result: QuotaContractDetailView;
   result_date: Date;
   mode = 1;
   amount_to_pay: number;
   
   fields;
   postpone_date: string;
   postpone_reason: string;

   get partial_payments(): boolean { return State.active_invoicer.partial_payments; }

   set_mode(mode: number) {
      this.mode = mode;
      if (mode === 2) {
         this.fields = validator({
            date:   [validation.required],
            reason: [validation.required]
         });
      }
   }

   postponed(contract_number: string): boolean {
      return this.postponed_payments?.some(p => p.client_reference === contract_number);
   }

   reason_postponed(contract_number: string): string {
      return this.postponed_payments?.find(p => p.client_reference === contract_number).reason;
   }

   date_postponed(contract_number: string): string | Date {
      return this.postponed_payments?.find(p => p.client_reference === contract_number).date_next_payment;
   }

   selected_payday: any = '';
   selected_zone: any = '';
   available_invoicers: InvoicerQueriableView[] = [];
   get online(): boolean { return State.online; }
   get mobile(): boolean { return State.mobile; }

   get filter_is_numeric(): boolean { return this.filter && /[0-9]+/.test(this.filter.trim()); }

   get filtered_contracts(): QuotaContractView[] {
      let contracts = this.contracts ?? [];

      if (this.selected_payday) {
         contracts = contracts.filter(c => Number.parseInt(c.pay_day) == this.selected_payday);
      }

      if (this.selected_zone) {
         contracts = contracts.filter(c => c.zone === this.selected_zone);
      }
      
      return contracts;
   }

   get date_tomorrow(): string {
      const tomorrow = new Date(Date.now() + 1000 * 60 * 60 * 24);
      return `${tomorrow.getFullYear()}-${zero_pad(tomorrow.getMonth() + 1)}-${zero_pad(tomorrow.getDate())}`;
   }
   
   get date_max(): string {
      const max_date = new Date(Date.now() + 1000 * 60 * 60 * 24 * 20);
      return `${max_date.getFullYear()}-${zero_pad(max_date.getMonth() + 1)}-${zero_pad(max_date.getDate())}`;
   }

   constructor(private api: ApiService, private router: Router, active_route: ActivatedRoute) {
      this.invoicer = active_route.snapshot.routeConfig.path
      if (is_nothing(State.active_invoicer))
         router.navigate(['cashier', 'pay']);
      else {
         const was_online = State.online;
         const callback = (response: ApiResponse<{ contracts: QuotaContractView[]; postponed_payments: PostponedPaymentView[]; contracts_paid_this_month: string[]; }>) => {
            if (response.succeeded) {     
               this.postponed_payments = response.data.postponed_payments;
               
               const postponed_contract_numbers = this.postponed_payments.map(p => p.client_reference);
               const contracts_postponed     = response.data.contracts.filter(c => postponed_contract_numbers.includes(c.contract_number));
               const contracts_not_postponed = response.data.contracts.filter(c => !contracts_postponed.includes(c));

               const contracts_postponed_for_today = contracts_postponed.filter(c => is_today(this.postponed_payments.find(pp => pp.client_reference === c.contract_number).date_next_payment));
               const contracts_postponed_for_later = contracts_postponed.filter(c => !contracts_postponed_for_today.includes(c));

               const contracts_already_paid     = contracts_not_postponed.filter(c => response.data.contracts_paid_this_month.includes(c.contract_number));
               const contracts_not_already_paid = contracts_not_postponed.filter(c => !contracts_already_paid.includes(c));

               this.contracts = contracts_postponed_for_today
                  .map(c => {
                     (<any>c).postponed_for_today = true;
                     return c;
                  })
                  .concat(contracts_not_already_paid)
                  .concat(contracts_already_paid.map(c => {
                     (<any>c).already_paid = true;
                     return c;
                  }))
                  .concat(contracts_postponed_for_later.map(c => {
                     (<any>c).postponed_for_later = true;
                     return c;
                  }));
            } else {
               if (response.error?.code !== 'OFFLINE' || was_online /* but connection was lost */)
               error_toast(response.error.message);
               this.load_cached_contracts();
            }
         };
         switch (this.invoicer) {
            case 'jorem': {
               api.query_jorem_contracts(callback)
            } break;
            case 'rancier': {
               api.query_rancier_contracts(callback)
            } break;
         }
      }
   }

   since(_, __) { return since(_, __) }

   load_cached_contracts() {
      if (this.invoicer !== 'jorem') return
      environment.debug('[i] loading offline jorem contracts...');
      OfflineDatabase.get_jorem_contracts(data => {
         if (data.length) {
            const expired_contracts = data.filter(d => since(d.date, { hours: 6 }));
            this.contracts = data.filter(d => !expired_contracts.includes(d)).map(d => d.contract);
   
            if (data.length === expired_contracts.length)
               error_toast('Cartera offline expirada (6 horas)');
         } else {
            error_toast('Sin cartera offline');
         }
      });
   }

   query(contract: { contract_number: string }) {  
      this.working = contract.contract_number;
      if (State.online) {
         const callback = (response: ApiResponse<QuotaContractDetailView>) => {
            this.working = null;
            if (response.succeeded) {
               this.result = response.data;
               this.amount_to_pay = response.data.fee_amount;
               console.log(this.result);
            }
            else
               error_toast(response.error.message);
         };
         switch (this.invoicer) {
            case 'jorem': {
               this.api.query_jorem(contract.contract_number, callback)
            } break;
            case 'rancier': {
               this.api.query_rancier(contract.contract_number, callback)
            } break;
         }
      } else {
         // OfflineDatabase.get_jorem_contract(contract.contract_number, contract => {
         //    if (contract) {
         //       this.result = <any>{
         //          contract_number: contract.contract_number,
         //          client_name: contract.contract.client_name,
         //          address: contract.address,
         //          debt_amount: contract.debt_amount,
         //          fee_amount: 0,
         //       }
         //       this.result_date = contract.date
         //    } else
         //       error_toast('Sin conexión.\nContrato no descargado')
         //       this.working = null
         // });
      }
   }

   pay() {
      State.payment_package = [{
         name: this.result.client_name,
         document: this.result.contract_number,
         invoicer: invoicer_queriable_to_item(State.active_invoicer),
         type: 1,
         extra: { ...this.contracts?.find(c => c.contract_number == this.result.contract_number), ...this.result },
         payloads: [{
            invoice_id: null,
            concept: null,
            amount: this.amount_to_pay
         }],
      }];
      this.router.navigate(['cashier', 'apply-payment']);
   }

   postpone() {
      if (this.fields.check_all_valid()) {
         this.working = 'postpone';
         this.api.postpone_payment(State.active_invoicer.id, this.result.contract_number, this.postpone_date, this.postpone_reason, response => {
            if (response.succeeded) {
               success_toast('Pago pospuesto');
               this.router.navigate(['cashier', 'pay']);
            } else {
               error_toast(response.error.message);
               this.working = null;
            }
         });
      }
   }

   download_for_zone(zone: string) {
      if (this.invoicer !== 'jorem') return
      this.working = 'download';

      modal('Descargando contratos', `Espere mientras se descarga los datos de los contratos de la zona '${zone}'...`, [], null);

      const contracts_to_download = this.contracts.filter(c => c.zone === zone);

      const batch_size = 10;
      let i = 0;

      let batch = [];
      let errors = [];

      const download_batch = () => {
         environment.debug(`[download] download batch [${i}–${Math.min(i + batch_size, contracts_to_download.length)})`);
         for (let contract of contracts_to_download.slice(i, i + batch_size)) {
            this.api.query_jorem(contract.contract_number, response => {
               if (response.succeeded) {
                  OfflineDatabase.add_or_update_jorem_contract({
                     date: new Date(Date.now()),
                     contract_number: contract.contract_number,
                     contract,
                     debt_amount: response.data.debt_amount,
                     address: response.data.address
                  }, (success, __) => {
                     if (!success) 
                        errors.push(contract.contract_number);
                     batch.push(contract.contract_number);
                     check_and_handle_batch_done();
                  })
               } else {
                  batch.push(contract.contract_number);
                  errors.push(contract.contract_number)
                  check_and_handle_batch_done();
               }
            })
         }
      }
      const check_and_handle_batch_done = () => {
         if (batch.length === Math.min(batch_size, contracts_to_download.length - i)) {
            environment.debug(`[download] finished downloading bach [${i}–${Math.min(i + batch_size, contracts_to_download.length)})`);
            i += batch_size;
            if (i < contracts_to_download.length) {
               batch = [];
               download_batch();
            } else {
               const succeeded = contracts_to_download.length - errors.length;
               if (succeeded)
                  success_toast(`${succeeded} contrato(s) descargado(s)`);
               if (errors.length)
                  error_toast(`Error al descargar ${errors.length} de ${contracts_to_download.length} contrato(s)`);

               clear_modal();
               this.working = null;

               OfflineDatabase.get_jorem_contracts(data => {
                  for (let expired_contract of data.filter(d => since(d.date, { hours: 6 })))
                     OfflineDatabase.delete_jorem_contract(expired_contract.contract_number);
               })
            }
         }
      };
      download_batch();
   }

   query_location() {
      this.working = 'geolocation';
      this.api.get_last_payment_geolocation(State.active_invoicer.id, this.result.contract_number, response => {
         this.working = null;
         if (response.succeeded) {
            const map_link = document.createElement('a');
            map_link.target = '_blank';
            map_link.href = `https://maps.google.com/maps?q=${response.data.latitude },${ response.data.longitude }`;
            document.body.appendChild(map_link);
            map_link.click();
            document.body.removeChild(map_link);
         } else error_toast(response.error.message);
      })
   }

   get paydays(): number[] {
      return this.contracts
         ?.map(c => Number.parseInt(c.pay_day))
         ?.filter((p, i, a) => a.indexOf(p) === i)
         ?.sort((a, b) => a - b) ?? [];
   }

   get zones(): string[] {
      return this.contracts
         ?.map(c => c.zone)
         ?.filter((z, i, arr) => arr.indexOf(z) === i);
   }
}