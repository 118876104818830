<h1>Bitácora de Usuarios</h1>

<working *ngIf="!loaded" color="#3B82F6" [size]="30"></working>

<div *ngIf="logs" class="card mt-8">
   <div class="flex flex-row items-center mb-2">
      <div class="flex-1"></div>
      <div class="flex flex-row items-baseline mr-6">
         <div class="mr-2">Desde:</div>
         <input type="date" [(ngModel)]=start_date [min]=min_date [max]=max_date class="py-1 rounded">
         <div class="mr-2 ml-4">Hasta:</div>
         <input type="date" [(ngModel)]=end_date [min]=min_date [max]=max_date class="py-1 rounded">
      </div>
      <input type="text" [(ngModel)]=filter placeholder="🔎 Filtrar..." class="rounded py-1 mr-6">
      <select [(ngModel)]="type_filter" class="py-1 rounded">
         <option [value]=-1>(Todos los tipos)</option>
         <option *ngFor="let type of types" [value]=type.id>{{ type.description }}</option>
      </select>
   </div>
   <table-control #registros [source]=filtered_logs></table-control>
   <table-paginator></table-paginator>
   <div class="table w-full border rounded-md">
      <div class="table-header-group font-semibold text-center">
         <div class="table-cell py-1 px-2">Fecha</div>
         <th colspan=2 class="table-cell py-1 px-2 border-l">Usuario</th>
         <th colspan=2 class="table-cell py-1 px-2 border-l">Admin</th>
         <div class="table-cell py-1 px-2 border-l">Tipo</div>
         <div class="table-cell py-1 px-2 border-l">Nota</div>
      </div>
      <div class="table-row-group">
         <div *ngFor="let log of registros.slice" class="table-row">
            <div class="table-cell px-2 py-1 border-t">{{ log.date | date:'hh:mm aa dd/MMM/yy':null:'es-DO' }}</div>
            <div class="table-cell px-2 py-1 border-t border-l font-mono w-0">{{ log.user.username }}</div>
            <div class="table-cell px-2 py-1 border-t border-l">{{ log.user.name }}</div>
            <div class="table-cell px-2 py-1 border-t border-l font-mono w-0">{{ log.admin?.username }}</div>
            <div class="table-cell px-2 py-1 border-t border-l">{{ log.admin?.name }}</div>
            <div class="table-cell px-2 py-1 border-t border-l">{{ type(log.type) }}</div>
            <div class="table-cell px-2 py-1 border-t border-l">{{ log.note }}</div>
         </div>
      </div>
   </div>
   <table-paginator></table-paginator>
</div>