<div
	[ngStyle]="{ width: '100%', fontFamily: 'monospace', fontSize: '' + scale + 'px', paddingRight: '' + padding_right + 'px' }">
	<div style="text-align: center;">
		<img src="/assets/logos/logo_{{invoicer.name | lowercase}}.png" [alt]="invoicer.name"
			[ngStyle]="{ display: 'block',  margin: '0 auto',  maxWidth: '100%',  height: '' + (scale * 5) + 'px' }">
		RNC {{invoicer.rnc}}

		<div [ngStyle]="{ fontWeight: 'bold', marginTop: '0.25rem', fontSize: '' + (scale * 1.75) + 'px' }">
			{{!nullification? 'Comprobante de ' + (!prepaid? 'Pago' : 'Recarga') : 'Anulación' }}
		</div>
		<div *ngIf="nullified" style="font-weight: bold">(TRANSACCION ANULADA)</div>
		<div *ngIf="offline" style="font-weight: bold">(TRANSACCION FUERA DE LINEA)</div>
		<div>{{transaction.point_of_sales.name}}</div>
		<div [ngStyle]="{ fontSize: '' + (scale * .8) + 'px' }">
			{{transaction.point_of_sales.address}}
		</div>
	</div>
	<div style="border-top: 1px solid black; padding-top: 0.25rem; margin-top: 0.25rem; text-align: center;">
		{{ fix_time(transaction.date | date:'dd/MMM/yy hh:mm aa') }}
	</div>
	<div style="border-top: 1px solid black; margin: 0.25rem 0; padding: 0.25rem 0;">
		<div style="display: flex; flex-direction:  row;">
			<div style="flex: none;">No. Tran</div>
			<div style="flex: 1 1 0%; text-align: right;">{{ offline? '(Offline)' : transaction.id }}</div>
		</div>
		<div style="display: flex; flex-direction:  row;">
			<div style="flex: none;">No. Lote</div>
			<div style="flex: 1 1 0%; text-align: right;">{{ offline? '(Offline)' : transaction.lot }}</div>
		</div>
		<div style="display: flex; flex-direction:  row;">
			<div style="flex: none;">Secuencia</div>
			<div style="flex: 1 1 0%; text-align: right;">{{ offline? 'OL ' : '' }}{{transaction.sequence}}</div>
		</div>
		<div *ngIf="extra_info?.ncf" style="display: flex; flex-direction: row;">
			<div style="flex: none;">NCF</div>
			<div style="flex: 1 1 0%; text-align: right;">{{ extra_info.ncf }}</div>
		</div>
	</div>
	<div style="text-align: center; font-weight: bold; padding: 0.25rem 0; border-top: 1px solid black;">
		<div>{{transaction.client_name? transaction.client_name : ''}}</div>
		<div>{{ [1, 2, 3, 6, 7].includes(invoicer.id)? 'Contrato ' : '' }}{{ invoicer.id === 5?
			format_phone(transaction.client_reference) : transaction.client_reference }}</div>
		<div *ngIf="extra_info?.meter_serial_number">Medidor # {{extra_info.meter_serial_number}}</div>
		<div *ngIf="extra?.plan">Plan: {{extra.plan}}</div>
	</div>
	<div style="text-align: center; border-top: 1px solid black; margin-bottom: 0.25rem; padding: 0.25rem 0;">
		<div>Monto {{!nullification? (!prepaid? 'Pagado' : 'Recargado') : 'Anulado' }}</div>
		<div [ngStyle]="{ fontWeight: 'bold', textDecoration: nullified? 'line-through' : 'initial' }">
			{{transaction.amount | currency:'RD$ '}}
		</div>
		<ng-container *ngIf="extra_info?.prepayment_unit_amount">
			<div style="margin-top: .25rem">Energía Recargada</div>
			<div style="font-weight: bold">{{extra_info.prepayment_unit_amount}} {{extra_info.prepayment_unit}}</div>
			<div style="margin-top: .25rem">Energía Recargada en el Mes</div>
			<div style="font-weight: bold">{{extra_info?.monthly_cummulative_prepayment}} {{extra_info.prepayment_unit}}
			</div>
		</ng-container>
	</div>
	<ng-container *ngIf="transaction.details.length">
		<div style="text-align: center; border-top: 1px solid black; padding-top: 0.25rem;">
			Detalle de Pago por Factura
		</div>
		<div style="border-top: 1px solid black; padding-bottom: 0.25rem;">
			<div *ngFor="let detail of transaction.details"
				[ngStyle]="{ display: 'flex', flexDirection: 'row', textDecoration: (!detail.applied? 'line-through' : 'initial') }">
				<div style="flex: 1 1 0%">{{detail.invoice_id}}{{!detail.applied? ' (NO APLICADA)' : ''}}</div>
				<div style="text-align: right; flex: none; display: flex; flex-direction: row;">
					RD$ <div [ngStyle]="{ width: '' + (scale * 5.15) + 'px' }">{{ detail.amount | currency:' ' }}</div>
				</div>
			</div>
		</div>
	</ng-container>
	<div *ngIf="!nullification" style="padding: 0.25rem 0; border-top: 1px solid black">
		<div style="display: flex; flex-direction: row;">
			<div style="flex: none;">Forma de Pago</div>
			<div style="font-weight: 600; text-align: right; flex: 1 1 0%;">{{transaction.payment_method?.description}}
			</div>
		</div>
		<!-- Efectivo -->
		<div *ngIf="transaction.payment_method?.id === 1">
			<div style="display: flex; flex-direction: row;">
				<div style="flex: 1 1 0%">Pago</div>
				<div style="text-align: right; flex: none; display: flex; flex-direction: row;">
					RD$ <div [ngStyle]="{ width: '' + (scale * 5.15) + 'px' }">{{transaction.cash_recieved | currency:' '}}
					</div>
				</div>
			</div>
			<div style="display: flex; flex-direction: row;">
				<div style="flex: 1 1 0%">Cambio</div>
				<div style="text-align: right; flex: none; display: flex; flex-direction: row;">
					RD$ <div [ngStyle]="{ width: '' + (scale * 5.15) + 'px' }">{{(transaction.cash_recieved -
						transaction.amount) | currency:' '}}</div>
				</div>
			</div>
		</div>
		<!-- Cheque -->
		<div *ngIf="transaction.payment_method?.id === 2">
			<div style="display: flex; flex-direction: row;">
				<div style="flex: none;">Banco</div>
				<div style="text-align: right; flex: 1 1 0%;">{{transaction.bank.description}}</div>
			</div>
			<div style="display: flex; flex-direction: row;">
				<div style="flex: none;">No. Cheque</div>
				<div style="text-align: right; flex: 1 1 0%;">{{transaction.payment_document}}</div>
			</div>
		</div>
		<!-- Tarjeta de Crédito/Débito -->
		<div *ngIf="[3, 4].includes(transaction.payment_method?.id)">
			<div style="display: flex; flex-direction: row;">
				<div style="flex: none;">No. Tarjeta</div>
				<div style="text-align: right; flex: 1 1 0%;">{{transaction.card_number}}</div>
			</div>
			<div style="display: flex; flex-direction: row;">
				<div style="flex: none;">No. Aprobación</div>
				<div style="text-align: right; flex: 1 1 0%;">{{transaction.authorization_code}}</div>
			</div>
		</div>
		<!-- Transferencia Bancaria -->
		<div *ngIf="transaction.payment_method?.id === 8">
			<div style="display: flex; flex-direction: row;">
				<div style="flex: none;">Banco</div>
				<div style="text-align: right; flex: 1 1 0%;">{{transaction.bank.description}}</div>
			</div>
			<div style="display: flex; flex-direction: row;">
				<div style="flex: none;">Documento</div>
				<div style="text-align: right; flex: 1 1 0%;">{{transaction.payment_document}}</div>
			</div>
		</div>
	</div>
	<div *ngIf="stepped_report" style="text-align: center; border-top: 1px solid black; padding: 0.25rem 0;">
		<div style="font-weight: bold; margin-bottom: .5rem;">Desglose escalonado de recarga</div>
		<table [ngStyle]="{ width: '100%', fontSize: '' + scale + 'px', textAlign: 'right' }">
			<tr *ngFor="let r of stepped_report">
				<td style="width: 0; text-align: left;">
					<div style="width: max-content">{{r.range}}</div>
				</td>
				<td style="width: 0; text-align: left;">{{r.rate}}:</td>
				<td>{{r.amount}}</td>
			</tr>
		</table>
	</div>
	<div style="text-align: center; border-top: 1px solid black; padding: 0.25rem 0;">
		Le atendió<br>
		{{transaction.user.name}}
	</div>
	<div *ngIf="extra_info?.token"
		style="text-align: center; font-weight: bold; border-top: 1px solid black; margin-top: 0.25rem; padding-top: 0.25rem;">
		<div>Token de Recarga</div>
		<div [ngStyle]="{ fontSize: '' + (scale * 1.5) + 'px', fontWeight: 'bold' }">
			{{extra_info.token}}
		</div>
	</div>
	<div style="text-align: center; border-top: 1px solid black; margin-top: 0.75rem; padding-top: 0.75rem;">
		Servicios de Plataforma de Cobros brindados por
		<br>
		<img src="/assets/logo_{{invoicer.name === 'edesur'? 'solincorp' : 'transneg'}}.png" alt="TRANSNEG SRL"
			[ngStyle]="{ display: 'block', margin: '0 auto', height: '' + (scale * 2) + 'px' }">
	</div>
	<div *ngIf="nullified"
		style="text-align: center; font-weight: bold; border-top: 1px solid black; margin-top: 0.25rem; padding-top: 0.25rem;">
		(TRANSACCION ANULADA)
	</div>
	<div *ngIf="offline"
		style="text-align: center; font-weight: bold; border-top: 1px solid black; margin-top: 0.25rem; padding-top: 0.25rem;">
		(TRANSACCION FUERA DE LINEA)
	</div>
	<div [ngStyle]="{ marginTop: '' + padding_bottom + 'px', textAlign: 'center', color: '#fff' }">_</div>
</div>