<h1>Aplicar {{ !prepaid? (!orders? 'Pago' : 'pago a orden') : 'Recarga' }}</h1>
<h2 class="space-x-4">
   <span *ngFor="let invoicer of invoicers">
      <fa-icon *ngIf="invoicers.indexOf(invoicer)" icon="plus" class="mr-4"></fa-icon>
      <img src="/assets/logos/logo_{{ invoicer.description | lowercase }}.png" width="110px" class="inline"
         [class.mb-4]="invoicer.id < 4">
   </span>
</h2>

<div class="card mt-8 flex flex-col items-stretch">
   <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
      <div class="font-semibold md:w-36 md:min-w-max">Nombre de Cliente:</div>
      <div class="text-xl font-semibold md:ml-4">{{ payloads[0].name }}</div>
   </div>
   <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline mt-4 md:mt-0">
      <div class="font-semibold md:w-36">Total a Pagar:</div>
      <div *ngIf="methods_loaded" class="text-2xl font-bold md:ml-4">
         <span class="{{ method_not_supported? 'text-gray-300 line-through' : '' }}">
            {{ total | currency:'RD$ ' }}
         </span>
         <span *ngIf="method_not_supported" class="ml-2 text-red-500">
            {{ supported_total | currency:'RD$ ' }}
         </span>
      </div>
   </div>

   <working *ngIf="!methods_loaded" color="#3B82F6" [size]=50></working>

   <!-- ============= -->
   <!-- Datos de Pago -->
   <!-- ============= -->
   <div *ngIf="!results?.length && methods_loaded" class="my-8 space-y-4" [class.pointer-events-none]="working"
      [class.cursor-not-allowed]="working">
      <div class="flex flex-row flex-wrap items-baseline">
         <div class="mr-4">Modo de Pago:</div>
         <select [(ngModel)]="data.method" class="rounded-md py-1">
            <option *ngFor="let method of payment_methods" [value]="method.id">
               {{ titlecase(method.description) }}
            </option>
         </select>
      </div>

      <div *ngIf="method_not_supported" class="text-red-500">
         <fa-icon icon="exclamation-triangle"></fa-icon>
         Modo de pago no soportado por {{ invoicer_names(invoicers_that_dont_support(data.method)).join(', ') }}
      </div>

      <!-- Efectivo -->
      <div *ngIf="data.method == 1"
         class="rounded-md border p-2 w-full md:w-max md:border-0 md:border-t md:border-b md:rounded-none">
         <div class="table table-auto">
            <div class="table-row">
               <div class="table-cell">Monto recibido:</div>
               <div class="table-cell pl-2 text-normal font-normal">
                  <div class="w-max">
                     RD$<input [(ngModel)]="data.cash" (keydown.enter)="can_apply? apply_payment() : null"
                        class="py-1 border-0 border-b -font-semibold w-32 md:w-auto" type="number" placeholder="0.00"
                        [min]="supported_total">
                  </div>
               </div>
            </div>
            <div class="table-row">
               <div class="table-cell pt-4">Cambio del cliente:</div>
               <div class="table-cell pl-2 text-left -font-semibold -text-lg">
                  <span *ngIf="data.cash < supported_total">RD$ -.--</span><span *ngIf="data.cash >= supported_total">{{
                     (data.cash - supported_total) | currency:'RD$ ' }}</span>
               </div>
            </div>
         </div>
         <div *ngIf="data.cash < supported_total" class="mt-2 text-yellow-600 opacity-80 font-semibold">
            <fa-icon icon="exclamation-circle"></fa-icon>
            Efectivo es menor al monto a pagar
         </div>
      </div>

      <!-- Cheque -->
      <div *ngIf="data.method == 2"
         class="rounded-md border py-4 p-2 w-full md:w-max md:border-0 md:border-t md:border-b md:rounded-none">
         <div class="flex-col space-y-4 md:table">
            <div class="flex-col items-stretch md:table-row">
               <div class="md:table-cell">Banco:</div>
               <div class="md:table-cell md:pl-2">
                  <select [(ngModel)]="data.bank" class="rounded py-1 -text-lg -font-semibold w-full">
                     <option [value]="0">(Seleccione un banco de la lista)</option>
                     <option *ngFor="let bank of banks" [value]="bank.id">
                        {{ bank.description | titlecase }}
                     </option>
                  </select>
               </div>
            </div>
            <div class="flex-col items-stretch md:table-row">
               <div class="md:table-cell">Número de cheque:</div>
               <div class="md:table-cell md:pl-2">
                  <input [(ngModel)]="data.payment_document" (keydown.enter)="can_apply? apply_payment() : null"
                     class="py-1 border-0 border-b -font-semibold w-full" type="number" placeholder="00000000" min="0">
               </div>
            </div>
         </div>
      </div>

      <!-- Tarjeta de Crédito/Débito, Bonoluz -->
      <div *ngIf="data.method == 3 || data.method == 4 || data.method == 5"
         class="rounded-md border p-2 w-full md:w-max md:border-0 md:border-t md:border-b md:rounded-none">
         <div class="flex-col space-y-4 mt-4 md:table">
            <div *ngFor="let invoicer of invoicers_that_support(data.method)">
               <img src="/assets/logos/logo_{{ invoicer.description | lowercase }}.png" class="h-5">
               <div class="flex-col items-stretch md:table-row">
                  <div class="md:table-cell">Terminal de la Tarjeta:</div>
                  <div class="md:table-cell md:pl-2">
                     <input [(ngModel)]="card_data(invoicer).number" type="number"
                        placeholder="Ultimos 4 dígitos de la tarjeta"
                        class="-text-lg -font-semibold py-1 border-0 border-b w-full md:min-w-[300px]">
                  </div>
               </div>
               <div class="flex-col items-stretch md:table-row">
                  <div class="md:table-cell">Número de Aprobación:</div>
                  <div class="md:table-cell md:pl-2">
                     <input [(ngModel)]="card_data(invoicer).authorization"
                        placeholder="No. aprobación de la transacción"
                        class="-text-lg -font-semibold py-1 border-0 border-b w-full md:min-w-[300px]" type="text">
                  </div>
               </div>
               <div class="flex-col items-stretch md:table-row">
                  <div class="md:table-cell">Número de Lote:</div>
                  <div class="md:table-cell md:pl-2">
                     <input [(ngModel)]="card_data(invoicer).lot" (keydown.enter)="can_apply? apply_payment() : null"
                        placeholder="No. lote" type="number"
                        class="-text-lg -font-semibold py-1 border-0 border-b w-full md:min-w-[300px]">
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- Transferencia Bancaria -->
      <div *ngIf="data.method == 8"
         class="rounded-md border p-2 w-full md:w-max md:border-0 md:border-t md:border-b md:rounded-none">
         <div class="flex-col space-y-4 md:table">
            <div class="flex-col items-stretch md:table-row">
               <div class="md:table-cell">Banco:</div>
               <div class="md:table-cell md:pl-2">
                  <select [(ngModel)]="data.bank" class="rounded py-1 -text-lg -font-semibold w-full">
                     <option [value]="0">(Seleccione un banco de la lista)</option>
                     <option *ngFor="let bank of banks" [value]="bank.id">{{ bank.description | titlecase }}</option>
                  </select>
               </div>
            </div>
            <div class="flex-col items-stretch md:table-row">
               <div class="md:table-cell">Número de documento:</div>
               <div class="md:table-cell md:pl-2">
                  <input [(ngModel)]="data.payment_document" (keydown.enter)="can_apply? apply_payment() : null"
                     class="py-1 border-0 border-b -font-semibold w-full" type="text">
               </div>
            </div>
         </div>
      </div>
   </div>
   <div *ngIf="working_state" class="text-gray-500 mb-2 font-semibold">
      <fa-icon icon="info-circle" class="text-gray-300 font-normal"></fa-icon>
      {{ working_state }}
   </div>
   <button (click)="apply_payment()" *ngIf="!results?.length && methods_loaded" [disabled]="working || !can_apply"
      class="btn btn-blue md:w-max">
      {{ working? 'Aplicando pago...' : 'Aplicar pago' }}
      <fa-icon *ngIf="!working" icon="stamp"></fa-icon>
      <working *ngIf="working"></working>
   </button>

   <!-- ================= -->
   <!-- Resultado de Pago -->
   <!-- ================= -->
   <div class="flex flex-col md:flex-row md:flex-wrap md:items-stretch">
      <div *ngFor="let result of results" class="w-full mt-8 md:flex md:flex-col md:w-max md:mr-8">
         <img src="/assets/logos/logo_{{ result.transaction.invoicer.name | lowercase }}.png" class="h-8 mx-auto" />
         <div class="font-semibold text-center text-lg">
            {{ [1, 2, 3, 6, 7].includes(result.transaction.invoicer.id)? 'Contrato ' : '' }}{{
            result.transaction.client_reference }}
         </div>
         <div class="flex-1">
            <div class="w-full flex flex-col md:mt-2 md:table md:border md:rounded md:w-min">
               <div class="hidden md:table-header-group">
                  <div class="table-cell px-1 py-1 text-center font-semibold"></div>
                  <div class="table-cell px-1 py-1 text-center font-semibold border-l">
                     <div class="min-w-max">Monto</div>
                  </div>
                  <div class="table-cell px-1 py-1 text-center font-semibold border-l">Aplicado</div>
               </div>
               <div *ngFor="let payment of result.payments" [class.bg-red-50]="!payment.applied"
                  class="border rounded-md bg-gray-50 p-1 mt-2 md:p-0 md:rounded-none md:bg-transparent md:table-row">
                  <div class="flex flex-row md:contents">
                     <div class="flex-1 md:table-cell md:border-t md:py-1 md:px-2"
                        [class.md:bg-red-50]="!payment.applied">
                        {{ payment.invoice_id }}
                     </div>
                     <div class="md:table-cell md:border-t md:py-1 md:px-2 md:border-l"
                        [class.md:bg-red-50]="!payment.applied">
                        <div class="min-w-max">{{ payment.amount | currency:'RD$ ' }}</div>
                     </div>
                  </div>
                  <div class="md:table-cell md:border-t md:py-1 md:px-2 md:border-l"
                     [class.md:bg-red-50]="!payment.applied">
                     <div class="w-full md:min-w-max">
                        <fa-icon *ngIf="payment.applied" icon="check-circle" class="text-green-500" title="Aplicado">
                        </fa-icon>
                        <fa-icon *ngIf="!payment.applied" icon="times-circle" class="text-red-500" title="No aplicado">
                        </fa-icon>
                        {{ payment.applied? 'Pago aplicado' : 'Pago NO aplicado' }}
                        <span *ngIf="!payment.applied"><br>({{ payment.error }})</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="mt-2">
            <button *ngIf="!failed(result)" (click)="print_receipt(result)" [disabled]="printing"
               class="btn btn-blue w-full">
               <fa-icon icon="print"></fa-icon>
               {{ (printing !== result? 'Imprimir comprobante' : 'Imprimiendo...') }}
               <working *ngIf="printing === result"></working>
            </button>
         </div>
      </div>
   </div>
   <div *ngIf="results?.length" class="mt-8 space-x-4">
      <button (click)="apply_payment()" [disabled]="working" *ngIf="all_failed" class="btn btn-yellow">
         <fa-icon icon="redo"></fa-icon>
         {{ (!working? 'Reintentar' : 'Reintentando...') }}
         <working *ngIf="working" color="black"></working>
      </button>
      <button (click)="go_home()" [disabled]="working" class="btn mt-4">
         <fa-icon icon="home"></fa-icon>
         Volver a Inicio
      </button>
   </div>
</div>