import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ApiService } from '../api.service';
import { error_toast } from '../utils/toast.util';
import { is_nothing } from '../utils/utils';
import { validator, validation, validate } from '../utils/validator.util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  hero = 0;
  working = false;
  
  username: string;
  password: string;
  otp;

  fields: any;

  @ViewChild('captcha') captcha: RecaptchaComponent;

  require_otp = false;
  cached_captcha_token: string;

  constructor(public router: Router, private api: ApiService) {
    this.hero = 1 + Math.floor(Math.random() * 2.99);
  }

  ngOnInit() {
    this.fields = validator({
      username: [ validation.required ],
      password: [ validation.required ]
    });
  }

  focus_password() {
    document.getElementById('password').focus();
  }

  login(captcha: string = null) {
    if (this.fields.check_all_valid()) {
      if (is_nothing(captcha)) {
        this.captcha.execute();
      } else {
        this.working = true;
        this.api.login(this.username, this.password, this.otp?.toString(), captcha, failure => {
          this.working = false;
          if (failure.code === 'OTP_REQUIRED') {
            this.require_otp = true;
            this.otp = null;
            this.cached_captcha_token = captcha;
            setTimeout(() => {
              document.getElementById('2fa').focus();
            }, 200);
          } else {
            error_toast(failure.message);
            this.captcha.reset();
          }
        });
      }
    }
  }

  otp_back() {
    this.require_otp = false;
    this.cached_captcha_token = null;
  }
}