declare global {
   interface Array<T> {
      distinct<K>(key?: (item: T) => K): T[]
      group<K>(key: (item: T) => K): { key: K, items: T[] }[]
      sort_by<K>(key: (item: T) => K, reverse?: boolean): T[]
      resembles(collection: T[]): boolean
      random(): T
      choose_random(count: number): T[]
      take(count: number): T[]
   }
}

Array.prototype.distinct ??= function<T, K>(this: T[], key?: (item: T) => K): T[] {
   if (key)
      return this.filter((item, index) => {
         const k = key(item)
         return this.findIndex(i => key(i) == k) === index
      })
   return this.filter((item, index) => this.indexOf(item) === index)
}

Array.prototype.group ??= function<T, K>(this: T[], key: (item: T) => K): { key: K, items: T[] }[] {
   const groups = new Map<K, T[]>()
   for (let item of this) {
      const _key = key(item)
      if (groups.has(_key))
         groups.get(_key).push(item)
      else
         groups.set(_key, [item])
   }
   return Array.from(groups.entries()).map(([key, items]) => ({ key, items }))
}

Array.prototype.sort_by = function<T, K>(this: T[], key: (item: T) => K, reverse: boolean = false): T[] {
   if (reverse)
      return this.sort((a, b) => <any>key(b) - <any>key(a))
   return this.sort((a, b) => <any>key(a) - <any>key(b))
}

Array.prototype.resembles ??= function<T>(this: T[], collection: T[]): boolean {
   if (this.length != collection.length)
      return false
   for (let element of collection)
      if (!this.includes(element))
         return false
   return true
}

Array.prototype.random ??= function<T>(this: T[]): T {
   if (this.length)
      return this[Math.floor(Math.random() * this.length)]
   return undefined
}

Array.prototype.choose_random ??= function<T>(this: T[], count: number): T[] {
   if (!this.length)
      return []
   const copy = this.slice()
   if (this.length <= count)
      return copy
   const result: T[] = []
   for (let i = 0; i < count; i++)
      result.push(copy.splice(Math.floor(Math.random() * copy.length), 1)[0])
   return result
}

Array.prototype.take ??= function<T>(this: T[], count: number): T[] {
   return this.filter((item, index) => index < count)
}

export {}