import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { State } from 'src/app/state';
import { environment } from 'src/environments/environment';

@Component({
   selector: 'app-lot-cartography',
   templateUrl: './lot-cartography.component.html',
   styleUrls: []
})
export class LotCartographyComponent {
   lot: number;
   has_data = true;

   constructor(private api: ApiService, active_route: ActivatedRoute, private change_detector: ChangeDetectorRef) {
      active_route.params.subscribe(params => {
         this.lot = Number.parseInt(params.id);
      });
   }

   date_pipe: DatePipe = new DatePipe('es-DO');
   currency_pipe: CurrencyPipe = new CurrencyPipe('es-DO');

   ngOnInit() {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAYK59CujuKV70EPagN15gWbqJo_sO7RZs&callback=initMap';
      
      this.api.get_operation_lot_transactions(this.lot, response => {
         const map_element = document.getElementById('map') as HTMLDivElement;

         (<any>window).initMap = () => {
            const google = (<any>window).google;
            if (response.succeeded) {
               if (!response.data.length || response.data.every(tx => (!tx.geo_latitude) && (!tx.geo_longitude))) {
                  environment.debug('No geo data');
                  this.has_data = false;
                  this.change_detector.detectChanges();
                  return;
               }

               const map = new google.maps.Map(map_element, { zoom: 15, center: { lat: 0, lng: 0 } });
            
               const paths = [];
               for (let i = 0; i < response.data.length;) {
                  while (i < response.data.length && !response.data[i].geo_latitude && !response.data[i].geo_longitude) i++;
                  const path = [];
                  while (i < response.data.length && response.data[i].geo_latitude && response.data[i].geo_longitude) {
                     path.push({lat: response.data[i].geo_latitude, lng: response.data[i].geo_longitude });
                     i++;
                  }
                  paths.push(path);
               }

               for (let path of paths)
                  new google.maps.Polyline({ path, map, geodesic: true, strokeColor: '#DC2626' });

               let bounds = new google.maps.LatLngBounds();

               response.data.forEach((tx, i) => {
                  if (tx.geo_latitude && tx.geo_longitude) {
                     const point = { lat: tx.geo_latitude, lng: tx.geo_longitude };
                     bounds.extend(point)
   
                     const marker = new google.maps.Marker({
                        position: point,
                        label: { text: (i + 1).toString(), color: '#FFF' }, map
                     });
   
                     const info_window = new google.maps.InfoWindow({
                        content: `
                           <h3 style="border-bottom: 1px solid rgba(0, 0, 0, .2)">Transacción ${tx.id}</h3>
                           <table>
                              <tr>
                                 <td>Fecha:</td><td style="text-align: right">${this.date_pipe.transform(tx.date, 'hh:mm aa, dd/MMM/yy', null, 'es-DO')}</td>
                              </tr>
                              <tr>
                                 <td>Facturador:</td><td style="text-align: right">${tx.invoicer?.description}</td>
                              </tr>
                              <tr>
                                 <td>Cliente:</td><td style="text-align: right;">${tx.client_reference}${tx.client_name? ' - ' + tx.client_name : ''}</td>
                              </tr>
                              <tr>
                                 <td>Monto:</td><td style="text-align: right; font-weight: bold">${this.currency_pipe.transform(tx.amount, 'RD$ ')}</td>
                              </tr>
                              <tr>
                                 <td>Cobrador:</td><td style="text-align: right">${tx.user?.name}</td>
                              </tr>
                           </table>
                        `
                     });
   
                     marker.addListener('click', () => {
                        info_window.open({ anchor: marker, map, shouldFocus: true });
                     });
                  }
               });

               map.fitBounds(bounds);
            } else {
               environment.debug('No geo data');
               this.has_data = false;
            }
         }

         if (!State.loaded_maps_api) {
            map_element.appendChild(script);
            environment.debug('[Maps API] Loading');
            State.loaded_maps_api = true;
         } else {
            environment.debug('[Maps API] Already loaded');
            (<any>window).initMap();
         }
      });
   }
}


/*

const map_script = document.createElement('script');
map_script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAYK59CujuKV70EPagN15gWbqJo_sO7RZs&callback=initMap';

(<any>window).initMap = () => {
   const google = (<any>window).google;

   const map = new google.maps.Map(map_element, { zoom: 15, center: { lat: 0, lng: 0 } });

   const marker = new google.maps.Marker({ position: { lat: 18.4746555, lng: -69.9550385 }, label: { text: 'JACQUE', color: '#FFF' }, map });

   let bounds = new google.maps.LatLngBounds();
   bounds.extend({ lat: 18.4746555, lng: -69.9550385 })

   map.fitBounds(bounds);
};

const map_element = document.getElementById('map') as HTMLDivElement;
map_element.appendChild(map_script);

*/