<div class="report-container" [class.mobile]="print_type == 1" [class.desktop]="print_type == 2">
	<div class="biggest text-center">
		Reporte de {{active? 'Lote Activo' : 'Cierre' }}
	</div>
	<div class="text-center">{{lot_report.point_of_sales.name}}</div>

	<div class="break"></div>

	<div style="display: flex; flex-direction: row;">
		<div style="flex-grow: 1;">Lote:</div>
		<div>{{lot_report.id}}</div>
	</div>
	<div style="display: flex; flex-direction: row;">
		<div style="flex-grow: 1;">Cajero:</div>
		<div>{{lot_report.user.name}}</div>
	</div>
	<div style="display: flex; flex-direction: row;">
		<div style="flex-grow: 1;">Inicio del lote:</div>
		<div style="text-align: right;">{{fix_time(lot_report.date_opened | date:'hh:mmaa dd/MMM/yy')}}</div>
	</div>
	<div *ngIf="!active" style="display: flex; flex-direction: row;">
		<div style="flex-grow: 1;">Cierre del lote:</div>
		<div style="text-align: right;">{{fix_time(lot_report.date_closed | date:'hh:mmaa dd/MMM/yy')}}</div>
	</div>

	<ng-container *ngIf="lot_report.invoicers.length > 1">
		<div class="break"></div>

		<div class="big text-center">Resúmen General</div>
	
		<table class="w-full">
			<tbody>
				<tr *ngFor="let group of total_groups(lot_report)" [class.font-bold]="['Total General', 'Total Postpago', 'Total Prepago'].includes(group.label)">
					<td>{{group.label}}</td>
					<td style="text-align: right; vertical-align: bottom; width: 0;">
						<div style="min-width: max-content;">{{group.total < 0? '-' : ''}}RD$</div>
					</td>
					<td style="text-align: right; vertical-align: bottom;">
						{{ (group.total > 0? group.total : -group.total) | currency:' ' }}
					</td>
				</tr>
			</tbody>
		</table>
	</ng-container>


	<ng-container *ngFor="let invoicer_report of lot_report.invoicers">
		<div class="break"></div>
		<img src="/assets/logos/logo_{{invoicer_report.invoicer.description | lowercase}}.png" alt="[LOGO {{invoicer_report.invoicer.description}}]"/>
		<table class="w-full">
			<tbody>
				<tr *ngFor="let group of total_groups(invoicer_report)" [class.font-bold]="['Total General', 'Total Postpago', 'Total Prepago'].includes(group.label)">
					<td>{{group.label}}</td>
					<td style="text-align: right; vertical-align: bottom; width: 0;">
						<div style="min-width: max-content;">{{group.total < 0? '-' : ''}}RD$</div>
					</td>
					<td style="text-align: right; vertical-align: bottom;">
						{{(group.total > 0? group.total : -group.total) | currency:' '}}
					</td>
				</tr>
			</tbody>
		</table>

		<ng-container *ngFor="let group of transaction_groups(invoicer_report)">
			<div class="break"></div>
			<div style="font-weight: bold;">{{group.label}}</div>
				<div *ngFor="let transaction of group.transactions" style="margin-bottom: 0.25rem; padding-bottom: 0.25rem; border-bottom: 0.5px dashed rgba(0, 0, 0, .5)">
					<div style="display: flex; flex-direction: row; flex-wrap: wrap;">
						<div style="flex-grow: 1;">{{transaction.sequence}} - {{transaction.id}}</div>
						<div style="text-align: right;">{{fix_time(transaction.date | date:'dd/MM/yy hh:mm aa')}}</div>
					</div>
					<div style="display: flex; flex-direction: row; flex-wrap: wrap;">
						<div style="flex-grow: 1;">{{[1, 2, 3].includes(invoicer_report.invoicer.id)? 'NIC ' : invoicer_report.invoicer.id === 6? 'Contr. ' : '' }}{{transaction.client_reference}}</div>
						<div style="text-align: right;">{{transaction.amount | currency:'RD$ '}}</div>
					</div>
				</div>
				<div *ngIf="!group.transactions.length" class="small">(No hubo {{group.label | lowercase}})</div>
		</ng-container>
	</ng-container>
	<div class="text-white text-center" [class.pb-4]="print_type == 1">_</div>
</div>