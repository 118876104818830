import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { MultiPayDetailedView, PointOfSalesView } from 'src/app/models/models';
import { error_toast, success_toast } from 'src/app/utils/toast.util';
import { is_nothing } from 'src/app/utils/utils';

@Component({
  selector: 'app-multipay-points-of-sales',
  templateUrl: './multipay-points-of-sales.component.html',
  styleUrls: []
})
export class MultipayPointsOfSalesComponent {
  _points_of_sales: PointOfSalesView[];
  _original_pos_ids: number[];
  working = false;
  multipay: MultiPayDetailedView;

  get points_of_sales(): PointOfSalesView[] {
    return this._points_of_sales?.filter(p => !this.multipay.points_of_sales.find(mp => mp.id === p.id)) ?? [];
  }

  get modified(): boolean {
    return !(this.multipay?.points_of_sales?.length === this._original_pos_ids?.length
      && this.multipay?.points_of_sales?.every(p => this._original_pos_ids.includes(p.id)));
  }

  constructor(private api: ApiService, private router: Router, active_route: ActivatedRoute) {
    active_route.params.subscribe(params => {
      if (is_nothing(params.id))
        router.navigate(['system', 'multipay-configuration']);
      else {
        api.get_multipay(Number.parseInt(params.id), response => {
          if (response.succeeded) {
            this.multipay = response.data;
            this._original_pos_ids = response.data.points_of_sales.map(p => p.id);

            api.get_points_of_sales(response => {
              if (response.succeeded) {
                this._points_of_sales = response.data;
              } else error_toast(response.error.message);
            });
          } else error_toast(response.error.message);
        });
      }
    })
  }

  include(point_of_sales: PointOfSalesView) {
    this.multipay.points_of_sales.push(point_of_sales);
  }

  exclude(point_of_sales: PointOfSalesView) {
    const index = this.multipay.points_of_sales.findIndex(p => p.id === point_of_sales.id);
    this.multipay.points_of_sales.splice(index, 1);
  }

  save() {
    this.working = true;
    this.api.associate_points_of_sales_to_multipay(this.multipay.id, this.multipay.points_of_sales.map(p => p.id), response => {
      this.working = true;
      if (response.succeeded) {
        success_toast('Configuración MultiPago actualizada');
        this.router.navigate(['system', 'multipay-configuration']);
      } else error_toast(response.error.message);
    });
  }
}
