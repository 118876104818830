import { environment } from "src/environments/environment";
import { is_nothing } from "./utils";

export const validation = {
   required: validate('Requerido.', _ => _ !== ''),
   email: validate('Formato invalido,', _ => (is_nothing(_) || _ === '') || (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(_)),
   phone: validate('Formato invalido.', _ => (is_nothing(_) || _ === '') || (/^\(?[0-9]{3}\)?[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/).test(_))
};

interface FieldState {
   valid: boolean;
   error: string;
}

interface FieldValidator {
   checker: (value: string) => boolean,
   message: string
}

interface Field {
   id: string;
   state: FieldState;
   validators: FieldValidator[];
}

export class ValidatorManager {
   private fields: Field[] = [];

   constructor(parameters: any) {
      for (let id of Object.keys(parameters)) {
         const field = { id, state: { valid: true, error: null }, validators: parameters[id] };
         this.fields.push(field);
         Object.defineProperty(this, id, { get: () => field.state, });
      }
   }
   
   private check_field(field: Field) {
      const value = get_input_value(field.id);
      field.state.valid = true;
      field.state.error = null;
      for (let validator of field.validators) {
         field.state.valid = validator.checker(value);
         if (field.state.valid !== true) {
            field.state.error = validator.message;
            break;
         }
      }
   }

   check(field_id: string): FieldState {
      const field = this.fields.find(v => v.id === field_id);
      this.check_field(field);
      return field.state;
   }

   check_all_valid(): boolean {
      for (let field of this.fields)
         this.check_field(field);
      return this.fields.every(f => f.state.valid);
   }
}

export function validator(fields: any): ValidatorManager & any {
   const validator = new ValidatorManager(fields);
   for (let field of (<any>validator).fields) {
      get_input(field.id)?.addEventListener('focusout', ev => (<any>validator).check_field(field));
   }
   return validator;
}

export function validate(message: string, checker: (value: string) => boolean): FieldValidator {
   return { checker, message };
}

function get_input(input_id: string): HTMLInputElement | HTMLTextAreaElement {
   var element = document.getElementById(input_id);
   if (!['input', 'textarea'].includes(element?.tagName?.toLowerCase())) {
      let inner_input: HTMLElement | HTMLTextAreaElement = element?.getElementsByTagName('input')[0];
      if (!inner_input)
         inner_input = element?.getElementsByTagName('textarea')[0];
      element = inner_input;
   }
   return <HTMLInputElement | HTMLTextAreaElement>element;
}

function get_input_value(input_id: string): string {
   return get_input(input_id)?.value;
}