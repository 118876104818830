<h1>Pago de Facturas</h1>

<div *ngIf="!result" class="card mt-8">
   <h3 class="border-b font-semibold flex flex-row items-bottom">
      Contratos
      <img src="/assets/logos/logo_{{ invoicer }}.png" alt="{{ invoicer }}" class="ml-2 mt-1 h-6">
   </h3>
   <!-- Filtro contratos -->
   <div class="my-4 rounded-lg border shadow-md px-3 flex flex-row items-baseline">
      <fa-icon icon="search" class="text-gray-500"></fa-icon>
      <input [(ngModel)]="filter" (keydown.enter)="filter_is_numeric? query({ contract_number: filter.trim() }) : null" type="text" placeholder="Buscar contrato..." class="min-w-0 flex-1 py-2 border-0 outline-none focus:ring-0">
      <button *ngIf="filter_is_numeric" (click)="query({ contract_number: filter.trim() })" [disabled]="working" class="btn btn-blue w-max">
         Consultar
         <working *ngIf="working === filter.trim()"></working>
      </button>
   </div>
   <div class="my-4 space-y-3">
      <!-- Filtros -->
      <div class="flex flex-col md:flex-row md:flex-wrap">
         <!-- por Dia de pago -->
         <div *ngIf="paydays?.length" class="mb-3 md:mx-4 flex flex-col md:flex-row md:items-baseline">
            <div class="font-semibold md:mr-2">Día de pago:</div>
            <select [(ngModel)]="selected_payday" class="py-1 rounded border-gray-300">
               <option value="">(Todos)</option>
               <option *ngFor="let payday of paydays" [value]="payday">{{ payday }}</option>
            </select>
         </div>
         <!-- por Zona -->
         <div *ngIf="zones?.length" class="md:mx-4 flex flex-col md:flex-row md:items-baseline">
            <div class="font-semibold md:mr-2">Zona:</div>
            <select [(ngModel)]="selected_zone" class="py-1 rounded md:mr-4 border-gray-300">
               <option value="">(Todas)</option>
               <option *ngFor="let zone of zones" [value]="zone">{{ zone }}</option>
            </select>
            <!-- Descargar contratos de la Zona -->
            <button *ngIf="online && selected_zone" (click)="download_for_zone(selected_zone)" [disabled]="working" class="btn btn-blue min-w-max mt-2 md:mt-0">
               {{ working !== 'download'? 'Descargar Cartera para esta Zona' : 'Descargando...' }}
               <fa-icon *ngIf="working !== 'download'" icon="arrow-circle-down"></fa-icon>
               <working *ngIf="working === 'download'"></working>
            </button>
         </div>
      </div>

      <table-control #contratos for=contratos [span]="mobile? 10 : 25" [source]=filtered_contracts [filter]=filter style="margin-top: 1rem !important;"></table-control>
      <working *ngIf="!contracts" color="black"></working>

      <table-paginator *ngIf="contratos.slice?.length" class="{{ mobile? 'w-max mx-auto' : '' }}" [notext]=mobile for="contratos" [show]="mobile ? 5 : 7" [size]="2"></table-paginator>

      <!-- Contratos -->
      <div *ngFor="let contract of contratos.slice" (click)="query(contract)"
         class="flex flex-row items-baseline border rounded-lg p-2 cursor-pointer shadow hover:bg-gray-100 hover:shadow-md"
         [class.disabled]="working && working !== contract.contract_number" [class.pointer-events-none]="working"
         [class.opacity-75]="contract.postponed_for_later || contract.already_paid" [class.bg-indigo-50]="contract.postponed_for_today">
         <fa-icon *ngIf=contract.postponed_for_today icon="exclamation-circle" class="font-bold mr-2 text-sm text-blue-600" title="Cobro agendado para hoy"></fa-icon>
         <fa-icon *ngIf=contract.postponed_for_later icon="clock" class="font-bold mr-2 text-sm text-gray-400" title="Cobro pospuesto"></fa-icon>
         <!-- <fa-icon *ngIf="!since(contract.date_created, { days: 30 })" icon="tag" class="font-bold mr-2 text-sm text-yellow-600" title="Nuevo contrato"></fa-icon> -->
         <fa-icon *ngIf=contract.already_paid icon="hand-holding-usd" class="font-bold mr-2 text-gray-400" title="Ha pagado este mes"></fa-icon>
         <div class="flex-1">{{ contract.contract_number }} - {{ contract.client_name }}</div>
         <working *ngIf="working === contract.contract_number" color="blue"></working>
      </div>

      <table-paginator class="{{ mobile? 'w-max mx-auto' : '' }}" for="contratos" [show]="mobile ? 5 : 7" [size]="2"></table-paginator>
   </div>
</div>

<!-- ================== -->
<!-- Resultado Consulta -->
<!-- ================== -->
<div *ngIf="result" class="card mt-8">
   <div class="space-y-2">
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Número de contrato:</div>
         <div class="font-semibold md:font-normal md:ml-4">{{ result.contract_number }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Nombre del titular:</div>
         <div class="font-semibold md:font-normal text-2xl md:ml-4">{{ result.client_name }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Dirección:</div>
         <div class="font-semibold md:font-normal md:ml-4">{{ result.address }}</div>
         <button (click)="query_location()" [disabled]="working" title="Ubicación del último cobro" class="btn md:mx-4 w-max" style="padding: 0 0.5rem;">
            <fa-icon *ngIf="working !== 'geolocation'" icon="map-marked-alt"></fa-icon>
            <working *ngIf="working === 'geolocation'" color="black"></working>
         </button>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Monto de cuota:</div>
         <div class="font-semibold md:font-normal md:ml-4">{{ result.fee_amount | currency:'RD$ ' }}</div>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Balance{{ result_date? ' (offline)' : '' }}:</div>
         <div class="font-semibold md:font-normal text-2xl md:ml-4"
            [class.text-red-900]="result.debt_amount <= 0" [class.text-green-800]="result.debt_amount > 0">
            {{ result.debt_amount <= 0? 'Debe ' : '' }}{{ (result.debt_amount < 0? -result.debt_amount : result.debt_amount) | currency:'RD$ ' }}{{ result.debt_amount > 0? ' a favor' : '' }}
            <span *ngIf="result_date" class="text-gray-500 text-base">(a las {{ result_date | date:'hh:mm aa' }})</span>
         </div>
      </div>
      <div *ngIf="postponed(result.contract_number)" class="flex flex-col md:flex-row md:flex-wrap md:items-baseline border-t pt-3">
         <div class="md:font-semibold w-52">Fecha cobro pospuesto:</div>
         <div class="font-semibold md:font-normal md:ml-4">{{ date_postponed(result.contract_number) | date:'EEEE dd, MMMM':null:'es-DO' | titlecase }}</div>
      </div>
      <div *ngIf="postponed(result.contract_number)" class="flex flex-col md:flex-row md:flex-wrap md:items-baseline">
         <div class="md:font-semibold w-52">Nota cobro pospuesto:</div>
         <div class="font-semibold md:font-normal md:ml-4">{{ reason_postponed(result.contract_number) }}</div>
      </div>
   </div>

   <div *ngIf="mode === 1" class="mt-4 border-t pt-3 mb-5 flex flex-col md:flex-row md:items-baseline">
      <div>Monto a pagar:</div>
      <input [(ngModel)]="amount_to_pay" [min]="partial_payments? 0 : result.fee_amount" (keydown.enter)="pay()" type="number" [placeholder]="result.fee_amount | currency:' '" class="py-1 rounded-md border-gray-300 md:ml-2">
   </div>

   <div *ngIf="mode === 2" class="flex flex-col items-start mt-4 mb-5 border-t">
      <div>Recordar en fecha:</div>
      <input id="date" type="date" [(ngModel)]="postpone_date" [min]="date_tomorrow" [max]="date_max" class="py-1 px-2 rounded-md text-right border-gray-300">
      <div class="field-error">{{ fields.date.error }}</div>
      <div>Razón:</div>
      <textarea id="reason" [(ngModel)]="postpone_reason" class="rounded border-gray-300 w-full md:w-max" rows="4" placeholder="El cobro se pospone porque..."></textarea>
      <div class="field-error">{{ fields.reason.error }}</div>
   </div>

   <div class="flex flex-row w-full md:w-max">
      <button class="flex-1 btn mr-1 min-w-max" [class.btn-blue]="mode === 1" (click)="mode === 1? pay() : set_mode(1)" [disabled]="!amount_to_pay || (!partial_payments && (amount_to_pay < result.fee_amount)) || working">
         Pagar
         <fa-icon icon="hand-holding-usd"></fa-icon>
      </button>
      <button *ngIf="online" class="flex-1 btn ml-1 min-w-max" [class.btn-blue]="mode === 2" (click)="mode === 2? postpone() : set_mode(2)" [disabled]="working">
         Posponer Pago
         <fa-icon *ngIf="working !== 'postpone'" icon="calendar-day"></fa-icon>
         <working *ngIf="working === 'postpone'"></working>
      </button>
   </div>
</div>