import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { ApiService } from 'src/app/api.service'
import { OperationOverview, UserView } from 'src/app/models/models'
import { error_toast, success_toast } from 'src/app/utils/toast.util'
import { is_today } from 'src/app/utils/utils'

@Component({
	selector: 'app-op-info',
	templateUrl: './op-info.component.html',
	styleUrls: [],
})
export class OperationInfoComponent {
	operation: OperationOverview

	get printing_frame_container(): HTMLDivElement {
		return <HTMLDivElement>document.getElementById('printing-frames')
	}

	working = false
	closing: number
	closingAnimation = false

	constructor(private api: ApiService, private router: Router) {
		this.load_operation()
	}

	load_operation() {
		this.api.get_operation_overview((response) => {
			if (response.succeeded) {
				this.operation = response.data
			} else {
				error_toast(response.error.message)
			}
		})
	}

	see(lot: { id: number }) {
		this.router.navigate(['supervisor', 'lots', lot.id])
	}

	close(lot: { id: number; user: UserView }) {
		let result = confirm(`Esta seguro de que desea cerrar el lote ${lot.id} del usuario ${lot.user?.name}?`)
		if (result) {
			this.working = true
			this.closing = lot.id
			this.api.close_lot(lot.id, (response) => {
        this.working = false
        this.closing = null
				if (response.succeeded) {
					success_toast('Lote cerrado.')
          const index = this.operation.active_lots.indexOf(lot as any)
          this.operation.active_lots.splice(index, 1)
					this.load_operation()
				} else {
					error_toast(response.error.message)
				}
			})
		}
	}

	go_to_map(lot: { id: number }) {
		this.router.navigate(['supervisor', 'geo', 'lot', lot.id])
	}

	is_today(date: Date | string): boolean {
		return is_today(date)
	}
}
