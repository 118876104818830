import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name:'sort' })
export class SortPipe<T> implements PipeTransform {
   transform(collection: T[], key: string, reverse: boolean = false): T[] {
      collection = collection?.sort((a, b) => {
         a = a[key];
         b = b[key];
         switch (typeof a) {
            case 'number':
            case 'bigint':
               return <any>a - <any>b;
            case 'string':
               return a.localeCompare(<any>b);
            default: 
               return null;
         }
      });
      if (reverse)
         collection.reverse();
      return collection;
   }
}