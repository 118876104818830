import { Component } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { NullificationRequestView } from 'src/app/models/models';
import { State } from 'src/app/state';
import { error_toast, success_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-pending-nullifications',
  templateUrl: './pending-nullifications.component.html',
  styleUrls: []
})
export class PendingNullificationsComponent {
  working = null;
  approving = null;
  rejecting = null;
  get show_pos(): boolean { return State.user_is_manager; }
  get operation_name(): string {
    const user = State.user;
    return State.user_is_manager? user.organization.description : user.point_of_sales.name;
  }
  null_requests: NullificationRequestView[] = [];

  constructor(private api: ApiService) {
    this.working = true;
    api.get_operation_nullification_requests(response => {
      this.working = false;
      if (response.succeeded) 
        this.null_requests = response.data;
      else error_toast(response.error.message);
    });
  }

  process(nul: NullificationRequestView, approve: boolean) {
    this.working = true;
    if (approve) this.approving = nul.id; else this.rejecting = nul.id;
    const refresh = () => this.api.get_operation_nullification_requests(response => {
      this.working = false; this.approving = this.rejecting = null;
      if (response.succeeded)
        this.null_requests = response.data
      else error_toast(response.error.message);
    });
    this.api.process_nullification(nul.id, approve, response => {
      if (response.succeeded) {
        if (!approve) {
          success_toast("Anulación rechazada");
          refresh();
        } else {
          const data = response.data;
          if (data.every(d => d.applied)) {
            success_toast("Transacción anulada.");
            refresh();
          } else if (data.every(d => !d.applied)) {
            error_toast("Transacción NO anulada. Error: " + data[0].error);
            this.working = false; this.approving = this.rejecting = null;
          } else {
            const al = data.filter(r => r.applied).length; const as = al == 1? '' : 's';
            const rl = data.length - al; const rs = rl == 1? '' : 's';
            error_toast(al + ' pago' + as + ' anulado' + as + ', ' + rl + ' pago' + rs + ' no anulado' + rs + '.  \nError: ' + data.find(d => !d.applied).error);
            this.working = false; this.approving = this.rejecting = null;
          }
        }
      } else {
        error_toast(response.error.message);
        this.working = false; this.approving = this.rejecting = null;
      }
    });
  }
}
