<h1>{{ !bundle_id? 'Crear' : 'Editar' }} Bolsa de Contratos</h1>

<div class="card mt-6 space-y-3">
   <div class="flex flex-col items-start">
      <div>Nombre:</div>
      <input type=text [(ngModel)]="client_name" class="py-2 px-2 md:py-0 rounded border-0 border-b border-gray-300">
   </div>
   <div class="flex flex-col items-start">
      <div>Facturador:</div>
      <select [(ngModel)]="invoicer_id" class="py-2 px-2 md:py-0 rounded border-0 border-b border-gray-300">
         <option *ngFor="let invoicer of invoicers" [value]="invoicer.id">{{ invoicer.description }}</option>
      </select>
   </div>
   <div class="flex flex-col items-start space-y-3">
      <div>Contratos:</div>
      <div class="flex flex-row flex-wrap">
         <div *ngFor="let contract of contracts; let i = index" class="border border-gray-400 rounded-md px-2 py-1 mr-3 mb-2">
            <fa-icon icon=trash-alt (click)="contracts.splice(i, 1)" class="hover:text-red-600 cursor-pointer"></fa-icon>
            {{ contract }}
         </div>
      </div>
      <div>
         <input type=text [(ngModel)]="contract" (keydown.enter)="add()" class="py-1 px-2 rounded-l-md border border-gray-300">
         <button (click)="add()" [disabled]="!contract" class="btn !rounded-l-none">
            Agregar
         </button>
      </div>
   </div>
   <div class="mt-6">
      <button (click)="save()" [disabled]="working.saving" class="btn btn-blue">
         <fa-icon icon=save></fa-icon>
         {{ !working.saving? 'Guardar' : 'Guardando...' }}
         <working *ngIf="working.saving"></working>
      </button>
   </div>
</div>