import { Component } from '@angular/core'
import { ApiService } from 'src/app/api.service'
import { Item, OrderDetailedView, OrderView } from 'src/app/models/models'
import { State } from 'src/app/state'
import { error_toast } from 'src/app/utils/toast.util'

@Component({
	selector: 'orders',
	template: `
		<h1>Ordenes creadas</h1>
		<h2 class="text-gray-500">por {{ user.name }}</h2>

		<div class="card grid gap-4">
			<div class="flex gap-4 items-end">
				<div class="grid">
					<label for="invoicer" class="font-semibold text-sm">Facturador</label>
					<div class="flex gap-1">
						<select id="invoicer" [(ngModel)]="selected_invoicer_id" (change)="query_orders()" class="rounded-lg border-gray-300">
							<option disabled [value]="0">(Seleccionar)</option>
							<option *ngFor="let i of invoicers" [value]="i.id">{{ i.description }}</option>
						</select>
						<button (click)="query_orders()" class="border border-gray-300 px-2 py-1 font-bold rounded-lg">
							<fa-icon icon="sync"></fa-icon>
						</button>
					</div>
				</div>
			</div>
			<table class="w-full border border-gray-300 rounded-lg">
				<thead>
					<th class="border-r px-2 py-1">Facturador</th>
					<th class="border-r px-2 py-1">Fecha</th>
					<th class="border-r px-2 py-1">Cliente</th>
					<th class="border-r px-2 py-1">Dirección</th>
					<th class="border-r px-2 py-1">Monto</th>
					<th class="w-0"></th>
				</thead>
				<tbody>
					<tr *ngFor="let order of orders" class="border-t">
						<td class="border-r px-2 py-1">
							<div class="min-w-max">
								{{ order.date | date : "hh:mmaa, dd 'de' MMM 'de' yyyy" : null : 'es-DO' }}
							</div>
						</td>
						<td class="border-r px-2 py-1">{{ order.invoicer.description }}</td>
						<td class="border-r px-2 py-1">{{ order.client.name }} - {{ order.client.document }}</td>
						<td class="border-r px-2 py-1">{{ order.client.address }}</td>
						<td class="border-r px-2 py-1 text-right">{{ order.amount | currency : 'RD$ ' }}</td>
						<td>
							<button (click)="query(order.id)" title="Ver detalles..." class="border border-gray-300 px-2 py-1">
								<fa-icon icon="list"></fa-icon>
							</button>
						</td>
					</tr>
					<tr *ngIf="!orders?.length" class="border-t">
						<td colspan="6" class="text-center font-semibold text-gray-500" title="No existen ordenes">
							<fa-icon icon="times"></fa-icon>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div *ngIf="order" class="fixed inset-0 grid place-items-center bg-black bg-opacity-10 backdrop-blur z-50">
			<div class="card relative">
				<h3 class="border-b font-semibold">Detalle de la orden</h3>

				<table class="my-6">
					<tbody>
						<tr>
							<td class="pr-2">
								<div class="min-w-max">Fecha:</div>
							</td>
							<td class="font-semibold">{{ order.date | date : "hh:mmaa, dd 'de' MMMM, yyyy" }}</td>
						</tr>
						<tr>
							<td class="pr-2">
								<div class="min-w-max">Facturador:</div>
							</td>
							<td class="font-semibold">{{ order.invoicer.description }}</td>
						</tr>
						<tr>
							<td class="pr-2">
								<div class="min-w-max">Documento de identificacion:</div>
							</td>
							<td class="font-semibold">{{ order.client.document }}</td>
						</tr>
						<tr>
							<td class="pr-2">
								<div class="min-w-max">Nombre:</div>
							</td>
							<td class="font-semibold">{{ order.client.name }}</td>
						</tr>
						<tr>
							<td class="pr-2">
								<div class="min-w-max">Direccion:</div>
							</td>
							<td class="font-semibold">{{ order.client.address }}</td>
						</tr>
					</tbody>
				</table>

				<table class="border border-gray-300">
					<thead>
						<th class="border-r px-2 py-1">Arbitrio</th>
						<th class="border-r px-2 py-1">Precio</th>
						<th class="border-r px-2 py-1">Cantidad</th>
						<th class="px-2 py-1">Total</th>
					</thead>
					<tbody>
						<tr *ngFor="let detail of order.details" class="border-t">
							<td class="px-2 py-1 border-r">{{ detail.product.code }} - {{ detail.product.description }}</td>
							<td class="px-2 py-1 border-r">{{ detail.product.price | currency : 'RD$ ' }}</td>
							<td class="px-2 py-1 border-r">{{ detail.quantity }}</td>
							<td class="px-2 py-1">{{ detail.total | currency : 'RD$ ' }}</td>
						</tr>
						<tr>
							<td colspan="3" class="border-t border-r px-2 py-1 text-right">Total:</td>
							<td class="px-2 py-1">
								{{ order.amount | currency : 'RD$ ' }}
							</td>
						</tr>
					</tbody>
				</table>

				<button (click)="order = null" class="px-2 absolute top-4 right-4 text-xl">
					<fa-icon icon="times"></fa-icon>
				</button>
			</div>
		</div>
	`,
})
export class OrdersComponent {
	invoicers: Item[] = []
	selected_invoicer_id = 0

	orders: OrderView[] = []
	order: OrderDetailedView = null

	user = State.user

	constructor(private api: ApiService) {
		api.get_point_of_sales_invoicers(State.user.point_of_sales.id, (response) => (this.invoicers = response.data))
	}

	query_orders() {
		this.api.get_orders(Number(this.selected_invoicer_id), (response) => {
			if (response.succeeded) {
				this.orders = response.data
			} else error_toast(response.error.message)
		})
	}

	query(order_id: number) {
		this.api.get_order(order_id, this.selected_invoicer_id, (response) => {
			if (response.succeeded) {
				this.order = response.data
			} else error_toast(response.error.message)
		})
	}
}
