<h1>Anulaciones Pendientes</h1>
<h2 class="text-gray-500">{{ operation_name }}</h2>

<div *ngIf="!null_requests?.length" class="rounded-lg w-full py-6 px-8 shadow-md bg-blue-200 mt-8 text-lg font-semibold">
   <fa-icon icon="info-circle" class="mr-2"></fa-icon>
   <working *ngIf="working" color="black"></working>
   <span *ngIf="!working">No tienes anulaciones pendientes</span>
</div>

<div *ngIf="null_requests?.length" class="card mt-8">
   <div class="w-full my-4 lg:table lg:border lg:rounded">
      <div class="hidden lg:block lg:table-header-group">
         <div class="table-cell text-center font-semibold py-1 px-2" title="ID del lote">ID Lote</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l" title="Usuario que solicita la anulación">Usuario</div>
         <div *ngIf="show_pos" class="table-cell text-center font-semibold py-1 px-2 border-l">Punto de Venta</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l" title="ID de la transacción">ID Tran.</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l">Facturador</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l">Fecha transacción</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l">Cliente</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l">Monto</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l" title="Fecha en que se solicitó la anulación de esta transacción">Fecha solicitud</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l">Razón</div>
         <div class="table-cell text-center font-semibold py-1 px-2 border-l"></div>
      </div>
      <div class="contents space-y-6 lg:table-row-group">
         <div *ngIf="!null_requests?.length" class="lg:table-row">
            <td title="No tiene solicitudes de anulaciones pendientes" colspan="9" class="w-full block text-center text-gray-500 lg:table-cell lg:border-t">
               <fa-icon icon="times"></fa-icon>
            </td>
         </div>
         <div *ngFor="let request of null_requests" class="bg-gray-50 rounded-md py-2 px-3 shadow-md lg:bg-transparent lg:px-0 lg:py-0 lg:shadow-none lg:table-row lg:even:bg-gray-100">
            <div class="flex flex-row lg:contents">
               <div class="flex-1 lg:contents">
                  <div class="lg:table-cell lg:py-2 lg:px-2 lg:border-t">
                     <div class="font-semibold lg:hidden">Lote: </div>
                     {{ request.transaction.lot }}
                  </div>
                  <div class="lg:table-cell lg:py-2 lg:px-2 lg:border-t lg:border-l">
                     <div class="font-semibold mr-[5px] lg:hidden">Solicitador:</div>
                     {{ request.requesting_user.name }}
                  </div>
                  <div *ngIf="show_pos" class="lg:table-cell lg:py-2 lg:px-2 lg:border-t lg:border-l">
                     <div class="font-semibold lg:hidden">Punto de Venta:</div>
                     {{ request.point_of_sales?.name }}
                  </div>
                  <div class="lg:table-cell lg:py-2 lg:px-2 lg:border-t lg:border-l">
                     <div class="font-semibold lg:hidden">ID Tran: </div>
                     {{ request.transaction.id }}
                  </div>
                  <div class="lg:table-cell lg:py-2 lg:px-2 lg:border-t lg:border-l">
                     <div class="font-semibold mr-[5px] lg:hidden">Fact:</div>
                     {{ request.transaction.invoicer.description }}
                  </div>
               </div>
               <div class="flex-1 lg:contents">
                  <div class="lg:table-cell lg:py-2 lg:px-2 lg:border-t lg:border-l">
                     <div class="font-semibold mr-[5px] lg:hidden">Fecha transacción:</div>
                     {{ request.transaction.date | date:'hh:mm aa dd/MM/yy':null:'es-DO' }}
                  </div>
                  <div class="lg:table-cell lg:py-2 lg:px-2 lg:border-t lg:border-l">
                     <div class="font-semibold mr-[5px] lg:hidden">Cliente:</div>
                     {{ request.transaction.client_name? request.transaction.client_name : request.transaction.client_reference }}
                  </div>
                  <div class="lg:table-cell lg:py-2 lg:px-2 lg:border-t lg:border-l">
                     <div class="font-semibold lg:hidden">Monto:</div>
                     {{ request.transaction.amount  | currency:'RD$ '}}
                  </div>
                  <div class="lg:table-cell lg:py-2 lg:px-2 lg:border-t lg:border-l">
                     <div class="font-semibold lg:hidden">Fecha solicitud:</div>
                     {{ request.date_requested | date:'hh:mm aa dd/MM/yy':null:'es-DO' }}
                  </div>
               </div>
            </div>
            <div class="lg:table-cell lg:py-2 lg:px-2 lg:border-t lg:border-l">
               <div class="font-semibold lg:hidden">Razón:</div>
               {{ request.reason? request.reason : '(No proporcionada)' }}
            </div>
            <div class="mt-4 lg:table-cell lg:py-2 lg:px-2 lg:border-t lg:border-l">
               <div *ngIf="!request.processed" class="flex flex-row">
                  <button (click)="process(request, true)"  [disabled]="working" class="btn flex-1 mr-[1px] min-w-max">
                     <div *ngIf="approving !== request.id" class="contents">
                        <fa-icon icon="eraser"></fa-icon> Anular
                     </div>
                     <div *ngIf="approving === request.id" class="min-w-max">
                        Anulando...
                        <working color="black"></working>
                     </div>
                  </button>
                  <button (click)="process(request, false)" [disabled]="working" class="btn flex-1 ml-[1px] min-w-max">
                     <div *ngIf="rejecting !== request.id" class="contents">
                        <fa-icon icon="times"></fa-icon> Rechazar
                     </div>
                     <div *ngIf="rejecting === request.id" class="min-w-max">
                        Rechazando...
                        <working color="black"></working>
                     </div>
                  </button>
               </div>
               <div *ngIf="request.processed" class="text-center text-lg font-bold lg:contents">
                  <div *ngIf="request.approved" ><fa-icon icon="check-circle"></fa-icon> Anulada</div>
                  <div *ngIf="!request.approved"><fa-icon icon="times-circle"></fa-icon> Rechazada</div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>