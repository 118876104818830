<h1>Configuración de MultiPagos</h1>

<div class="card mt-8">
   <div class="flex flex-row font-semibold border-b text-xl space-x-4 items-baseline mb-4 pb-1">
      <div>{{ multipay?.id }}</div>
      <div *ngFor="let invoicer of multipay?.invoicers">
         <fa-icon *ngIf="multipay.invoicers.indexOf(invoicer)" icon="plus" class="mr-4"></fa-icon>
         <img src="/assets/logos/logo_{{ invoicer.description| lowercase }}.png" class="h-6 inline-block" [class.mb-2]="invoicer.id < 4">
      </div>
   </div>
   <div class="space-y-8 md:flex md:flex-row md:space-y-0 md:items-stretch">
      <div class="md:flex-1 md:flex md:flex-col">
         <div class="text-center font-semibold">Asociados</div>
         <div class="border border-gray-400 rounded-md mx-2 md:mx-4 p-2 md:p-8 space-y-2 md:h-[400px] overflow-y-auto">
            <div *ngFor="let pos of multipay?.points_of_sales" (click)="exclude(pos)" class="cursor-pointer py-1 px-4 border rounded-lg space-x-3 flex flex-row items-center hover:bg-gray-300">
               <div class="flex-1">{{ pos.name }}</div>
               <fa-icon icon="chevron-right" class="text-red-500 text-lg hover:text-red-600 active:text-red-800"></fa-icon>
            </div>
         </div>
      </div>
      <div class="md:flex-1 md:flex md:flex-col">
         <div class="text-center font-semibold">Puntos de Venta</div>
         <div class="border border-gray-400 rounded-md mx-2 md:mx-4 p-2 md:p-8 space-y-2 md:h-[400px] overflow-y-auto">
            <div *ngFor="let pos of points_of_sales" (click)="include(pos)" class="cursor-pointer py-1 px-4 border rounded-lg space-x-3 flex flex-row items-center hover:bg-gray-300">
               <fa-icon icon="chevron-left" class="text-blue-500 text-lg hover:text-blue-600 active:text-blue-800"></fa-icon>
               <div>{{ pos.name }}</div>
            </div>
         </div>
      </div>
   </div>
   <div class="mt-4 pt-4 border-t">
      <button (click)="save()" [disabled]="working || !modified" class="btn btn-blue">
         <fa-icon icon="save"></fa-icon>
         Guardar
         <working *ngIf="working"></working>
      </button>
   </div>
</div>