import { Component } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { error_toast, success_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-print-configuration',
  templateUrl: './print-configuration.component.html',
  styleUrls: []
})
export class PrintConfigurationComponent {
  working = false;
  print_type = 0;
  constructor(private api: ApiService) {
    this.working = true;
    api.get_print_type(response => {
      this.working = false;
      if (response.succeeded)
        this.print_type = response.data;
      else error_toast(response.error.message);
    });
  }

  switch(print_type: number) {
    if (print_type !== this.print_type) {
      this.working = true;
      this.api.set_print_type(print_type, response => {
        this.working = false;
        if (response.succeeded) {
          success_toast('Tipo de impresora actualizado');
          this.print_type = print_type;
        } else error_toast(response.error.message);
      });
    }
  }
}
