import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { ClaroClientView } from 'src/app/models/invoicer_query.models';
import { State } from 'src/app/state';
import { error_toast } from 'src/app/utils/toast.util';
import { is_nothing } from 'src/app/utils/utils';
import { invoicer_queriable_to_item } from '../payments.component';

@Component({
   selector: 'app-claro',
   templateUrl: './claro.component.html',
   styleUrls: []
})
export class ClaroComponent {
   phone: string;
   result: ClaroClientView;
   tab = 0;
   working = false;

   post_pay_amount: number;
   pre_pay_amount: number;

   constructor(private api: ApiService, private router: Router) {
      if (is_nothing(State.active_invoicer))
         router.navigate(['cashier', 'pay']);
   }

   query() {
      this.working = true;
      this.api.query_claro(this.phone, response => {
         this.working = false;
         if (response.succeeded) {
            this.post_pay_amount = response.data.postpaid?.debt_amount;
            this.pre_pay_amount = response.data.prepaid?.minimum_charge_amount;

            if (!response.data.postpaid) {
               this.tab = 1;
            }

            this.result = response.data;
         } else error_toast(response.error.message);
      });
   }

   post_pay() {
      State.payment_package = [{
         name: this.result.name,
         document: this.phone,
         invoicer: invoicer_queriable_to_item(State.active_invoicer),
         type: 1,
         payloads: [{
            invoice_id: null,
            concept: null,
            amount: this.post_pay_amount
         }],
      }];
      this.router.navigate(['cashier', 'apply-payment']);
   }

   pre_pay() {
      State.payment_package = [{
         name: this.result.name,
         document: this.phone,
         invoicer: invoicer_queriable_to_item(State.active_invoicer),
         type: 2,
         payloads: [{
            invoice_id: null,
            concept: null,
            amount: this.pre_pay_amount
         }],
      }];
      this.router.navigate(['cashier', 'apply-payment']);
   }
}
