import { Component } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { error_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-postponed-payments',
  templateUrl: './postponed-payments.component.html',
  styleUrls: ['./postponed-payments.component.less']
})
export class PostponedPaymentsComponent {
  loaded = false;
  postponed_payments = []
  filter: string;

  constructor(api: ApiService) {
    api.get_operation_postponed_payments(response => {
      if (response.succeeded) {
        this.postponed_payments = response.data;
        this.loaded = true;
      } else error_toast(response.error.message);
    });
  }
}
