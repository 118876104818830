<h1>Pagos fuera de línea</h1>

<div id="printing-frame-container" class="invisible fixed top-0 inset-x-0 z-[-10]"></div>

<div class="card mt-8">
   <div class="flex flex-row-reverse">
      <button (click)="process_payments()" class="btn btn-primary" [disabled]="queued_payments_being_processed_keys?.length">
         {{ !queued_payments_being_processed_keys?.length? 'Procesar pagos pendientes' : 'Procesando pagos...' }}
         <fa-icon *ngIf="!queued_payments_being_processed_keys?.length" icon="sync"></fa-icon>
         <working *ngIf="queued_payments_being_processed_keys?.length"></working>
      </button>
   </div>
   <div class="w-full lg:table lg:border lg:rounded-md">
      <div class="hidden lg:table-header-group lg:font-semibold lg:text-center lg:text-lg">
         <div class="table-cell py-1 px-2">Fecha pago</div>
         <div class="table-cell py-1 px-2 border-l">Cliente</div>
         <div class="table-cell py-1 px-2 border-l">Facturador</div>
         <div class="table-cell py-1 px-2 border-l">Modo de pago</div>
         <div class="table-cell py-1 px-2 border-l">Monto</div>
         <div class="table-cell py-1 border-l w-0"></div>
      </div>
      <div class="lg:table-row-group">
         <div *ngFor="let qp of queued_payments" class="border rounded-md shadow p-4 bg-yellow-50 lg:bg-transparent lg:table-row">
            <div class="lg:table-cell lg:px-2 lg:py-1 lg:border-t lg:text-right">
               <span class="font-semibold lg:hidden">Fecha y hora de pago:</span>
               {{ qp.date | date:'hh:mm aa, dd/MMM/yy':null:'es-DO' }}
            </div>
            <div class="lg:table-cell lg:px-2 lg:py-1 lg:border-t lg:border-l">
               <div class="font-semibold lg:hidden">Cliente:</div>
               {{ qp.payment.client_reference }} - {{ qp.payment.client_name }}
            </div>
            <div class="lg:table-cell lg:px-2 lg:py-1 lg:border-t lg:border-l">
               <span class="font-semibold lg:hidden">Facturador:</span>
               {{ invoicer_name(qp.payment.invoicer_id) }}
            </div>
            <div class="lg:table-cell lg:px-2 lg:py-1 lg:border-t lg:border-l lg:text-right">
               <span class="font-semibold lg:hidden">Modo de Pago:</span>
               {{ method_name(qp.payment.invoicer_id, qp.payment.payment_method_id) | titlecase }}
            </div>
            <div class="lg:table-cell lg:px-2 lg:py-1 lg:border-t lg:border-l lg:text-right">
               <span class="font-semibold lg:hidden">Monto:</span>
               {{ qp.payment.total | currency:'RD$ ' }}
            </div>
            <div class="mt-2 lg:table-cell lg:border-t lg:border-l lg:font-semibold">
               <div *ngIf="is_being_processed(qp)" class="mx-auto w-max">
                  <working color="black"></working>
               </div>
               <div *ngIf="!is_being_processed(qp)" class="min-w-max flex flex-row items-stretch">
                  <button (click)="print(qp)" [disabled]="working" class="btn" title="Imprimir transacción" style="flex-grow: 1; padding: 5px {{ printing === qp.payment? '2px' : '10px' }}; margin-right: 2px;">
                     <span class="mr-2 lg:hidden">{{ printing !== qp.payment? 'Imprimir copia' : 'Imprimiendo...' }}</span>
                     <fa-icon *ngIf="printing !== qp.payment" icon="print"></fa-icon>
                     <working *ngIf="printing === qp.payment" color="black"></working>
                  </button>
                  <button (click)="cancel(qp)" [disabled]="working" class="btn" title="Cancelar" style="flex-grow: 1; padding: 5px {{ cancelling === qp.payment? '2px' : '10px' }};">
                     <span class="mr-2 lg:hidden">Cancelar pago</span>
                     <fa-icon *ngIf="cancelling !== qp.payment" icon="ban"></fa-icon>
                     <working *ngIf="cancelling === qp.payment" color="black"></working>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>