import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { State } from 'src/app/state';
import { format_phone } from 'src/app/utils/utils';
import { invoicer_queriable_to_item } from '../payments.component';

@Component({
  selector: 'app-digicel',
  templateUrl: './digicel.component.html',
  styleUrls: []
})
export class DigicelComponent {
  phone: string;
  amount_to_pay: number = 0;
  constructor(private router: Router) {
    if (!State.active_invoicer)
      router.navigate(['cashier', 'pay']);
  }
  pay() {
    State.payment_package = [{
      name: format_phone(this.phone),
      document: this.phone,
      invoicer: invoicer_queriable_to_item(State.active_invoicer),
      type: 2,
      payloads: [{
         invoice_id: null,
         concept: null,
         amount: Number.parseFloat(<any>this.amount_to_pay)
      }],
   }];
   this.router.navigate(['cashier', 'apply-payment']);
  }
}
