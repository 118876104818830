<h1>{{ !new_pos? 'Punto de Venta' : 'Nuevo Punto de Venta' }}</h1>

<working *ngIf="!loaded" color="#3B82F6" [size]="30"></working>

<div *ngIf="loaded">
   <h2 class="text-gray-500">{{ pos.name }}</h2>
   <div class="mt-8 card w-full space-y-6">
      <div *ngIf="!new_pos" class="!hidden md:!flex tabs">
         <div class="tab" (click)="tab = 0" [class.active]="tab == 0">Datos Principales</div>
         <div class="tab" (click)="tab = 1" [class.active]="tab == 1">Facturadores</div>
         <div class="tab" (click)="tab = 2" [class.active]="tab == 2">Modos de Pago</div>
         <div class="tab" (click)="tab = 3" [class.active]="tab == 3">Adquirientes</div>
         <div class="tab" (click)="tab = 4" [class.active]="tab == 4">Política de Pagos</div>
      </div>
      <select *ngIf="!new_pos" [(ngModel)]="tab"
         class="w-full py-0 border-0 border-b font-semibold text-lg focus:ring-0  md:hidden">
         <option [value]="0">Datos Principales</option>
         <option [value]="1">Facturadores</option>
         <option [value]="2">Modos de Pago</option>
         <option [value]="3">Adquirientes</option>
         <option [value]="4">Política de Pagos</option>
      </select>

      <!-- Tab Datos Principales -->
      <div *ngIf="tab == 0" id="tab-0" class="px-2 space-y-4">
         <div class="flex flex-col items-stretch md:flex-row md:items-baseline md:space-x-4">
            <div class="font-semibold flex-none w-44">Nombre del Punto</div>
            <input [(ngModel)]="pos.name" type="text" class="flex-1 py-1 md:py-0 px-2 rounded border-gray-300">
         </div>
         <div class="flex flex-col items-stretch md:flex-row md:items-baseline md:space-x-4">
            <div class="font-semibold flex-none w-44">Dirección de la Sucursal</div>
            <input [(ngModel)]="pos.address" type="text" class="flex-1 py-1 md:py-0 px-2 rounded border-gray-300">
         </div>
         <div class="flex flex-col items-stretch md:flex-row md:items-baseline md:space-x-4">
            <div class="font-semibold flex-none w-44">Código de PV CPE</div>
            <input [(ngModel)]="pos.cpe_code" type="text" class="flex-1 py-1 md:py-0 px-2 rounded border-gray-300">
         </div>
         <div class="flex flex-col items-stretch md:flex-row md:items-baseline md:space-x-4">
            <div class="font-semibold flex-none w-44">Token de PV CPE</div>
            <input [(ngModel)]="pos.cpe_token" type="text" class="flex-1 py-1 md:py-0 px-2 rounded border-gray-300">
         </div>
         <div class="flex flex-row items-center space-x-4">
            <label for="georeq" class="font-semibold flex-none w-48">Geolocalización Requerida</label>
            <input id="georeq" [(ngModel)]="pos.geolocation_required" type="checkbox" class="flex-none !p-3 md:!p-2">
         </div>
         <div class="flex flex-row items-center space-x-4">
            <label for="otpreq" class="font-semibold w-48">2FA Requerida</label>
            <input id="otpreq" [(ngModel)]="pos.otp_required" type="checkbox" class="flex-none !p-3 md:!p-2">
         </div>
         <div class="flex flex-row items-center space-x-4">
            <label for="posenab" class="font-semibold flex-none w-48">Habilitado</label>
            <input id="posenab" [(ngModel)]="pos.enabled" type="checkbox" class="flex-none !p-3 md:!p-2">
         </div>
         <button (click)="save_info()" [disabled]="working" class="btn btn-blue">
            {{ !working? 'Guardar' : 'Guardando...' }}
            <fa-icon *ngIf="!working" icon="save"></fa-icon>
            <working *ngIf="working"></working>
         </button>
      </div>

      <!-- Tab Facturadores -->
      <div *ngIf="tab == 1" id="tab-1" class="px-2 space-y-2">
         <div *ngFor="let invoicer of invoicers" class="items-baseline space-x-4">
            <div class="inline-block w-32 font-semibold">{{ invoicer.description }}</div>
            <input [(ngModel)]="selectable_invoicer(invoicer.id).selected" type="checkbox"
               class="inline-block !p-3 md:p-2">
         </div>
         <button (click)="save_invoicers()" [disabled]="working" class="btn btn-blue mt-4">
            {{ !working? 'Guardar' : 'Guardando...' }}
            <fa-icon *ngIf="!working" icon="save"></fa-icon>
            <working *ngIf="working"></working>
         </button>
      </div>

      <!-- Tab Modos de Pago -->
      <div *ngIf="tab == 2" id="tab-2" class="px-2 space-y-4">
         <div class="flex flex-col md:flex-row md:flex-wrap items-stretch">
            <div *ngFor="let invoicer of pos.invoicers" class="mx-2 md:mx-4 mb-8">
               <div class="border-b font-semibold text-lg">{{ invoicer.name }}</div>
               <div class="table w-full md:w-max">
                  <div *ngFor="let pay of payment_methods" class="table-row">
                     <div class="table-cell pr-8">{{ pay.description }}</div>
                     <div class="table-cell w-0 text-2xl">
                        <input [(ngModel)]="selectable_payment_method(invoicer.id, pay.id).selected" type="checkbox"
                           class="inline-block !p-3 md:!p-2">
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <button (click)="save_methods()" [disabled]="working" class="btn btn-blue">
            {{ !working? 'Guardar' : 'Guardando...' }}
            <fa-icon *ngIf="!working" icon="save"></fa-icon>
            <working *ngIf="working"></working>
         </button>
      </div>

      <!-- Tab Adquirientes -->
      <div *ngIf="tab == 3" id="tab-3" class="px-2 space-y-4">
         <div *ngFor="let invoicer of pos.invoicers">
            <div class="border-b text-lg font-semibold mb-2">{{ invoicer.name }}</div>
            <div class="flex flex-col items-stretch md:flex-row md:items-baseline md:space-x-4">
               <div>Adquiriente:</div>
               <select [(ngModel)]="invoicer.acquirer.id" class="rounded py-1 border-gray-300 md:py-0">
                  <option *ngIf="!invoicer.acquirer?.id" [value]="0">(Seleccionar adquiriente)</option>
                  <option *ngFor="let acquirer of acquirers" [value]="acquirer.id">{{ acquirer.description }}</option>
               </select>
               <div>Numero de Afiliado:</div>
               <input [(ngModel)]="invoicer.acquirer.affiliate_number" type="text"
                  class="flex-1 px-2 py-1 rounded border-gray-300 md:py-0">
            </div>
         </div>
         <button (click)="save_acquirers()" [disabled]="working" class="btn btn-blue">
            {{ !working? 'Guardar' : 'Guardando...' }}
            <fa-icon *ngIf="!working" icon="save"></fa-icon>
            <working *ngIf="working"></working>
         </button>
      </div>

      <!-- Tab Política de Pagos -->
      <div *ngIf="tab == 4" id="tab-4" class="px-2 space-y-4">
         <div *ngFor="let invoicer of pos.invoicers">
            <div class="border-b font-semibold text-lg mb-2">{{ invoicer.name }}</div>
            <div class="flex flex-col items-stretch md:flex-row md:items-baseline md:space-x-4">
               <div>Política de pago de facturas:</div>
               <select [(ngModel)]="invoicer.invoice_payment_policy.id" class="rounded py-1 border-gray-300 md:py-0">
                  <option *ngIf="!invoicer.invoice_payment_policy?.id" [value]="0">(Seleccionar política de pago)
                  </option>
                  <option *ngFor="let policy of invoice_payment_policies" [value]="policy.id">{{ policy.description }}
                  </option>
               </select>
               <div class="flex flex-row items-center mt-2">
                  <label for="partial-{{ invoicer.id }}">Pagos parciales</label>
                  <input [(ngModel)]="invoicer.partial_payments" type="checkbox" id="partial-{{ invoicer.id }}"
                     class="mx-2 !p-3 md:!p-2 rounded">
               </div>
               <div class="flex flex-row items-center mt-2">
                  <label for="partial-{{ invoicer.id }}">Pagos consolidados</label>
                  <input [(ngModel)]="invoicer.consolidate_payment" type="checkbox" id="partial-{{ invoicer.id }}"
                     class="mx-2 !p-3 md:!p-2 rounded">
               </div>
            </div>
         </div>
         <button (click)="save_policies()" [disabled]="working" class="btn btn-blue">
            {{ !working? 'Guardar' : 'Guardando...' }}
            <fa-icon *ngIf="!working" icon="save"></fa-icon>
            <working *ngIf="working"></working>
         </button>
      </div>
   </div>
</div>