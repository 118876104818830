<!-- Menu -->
<div *ngIf="!is_mobile_mode || sidebar_enabled" id="menu" [class.min-h-mobile]="is_mobile_mode">
   <!-- Menu Item -->
   <div *ngFor="let item of menu" (click)="go_to(item.link)"
      class="group relative {{ is_disabled(item)? 'cursor-not-allowed opacity-50' : item.link? 'cursor-pointer' : 'cursor-default' }}"
      [class.cursor-pointer]="item.link">
      <div class="top-menu-item {{item.link? 'hover:bg-blue-100 focus:bg-blue-100 active:bg-blue-300' : ''}}">
         <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
         <span class="mx-2">{{ item.label }}</span>
         <fa-icon *ngIf="item.children" icon="chevron-down" class="float-right"></fa-icon>
      </div>
      <div class="inner-menu w-full bg-white">
         <div *ngFor="let item of item.children" (click)="go_to(item.link)"
            class="inner-menu-item {{item.link? 'cursor-pointer hover:bg-blue-100 focus:bg-blue-100 active:bg-blue-300' : ''}}"
            [class.cursor-not-allowed]="!item.link" [class.opacity-50]="!item.link">
            <span>{{ item.label }}</span>
            <fa-icon *ngIf="item.icon" [icon]="item.icon" class="float-right text-gray-500 text-sm ml-2"></fa-icon>
         </div>
      </div>
   </div>
</div>
<!-- Content -->
<div class="absolute left-0 right-0 top-12 md:inset-x-0 bg-blue-50 hero" style="min-height: calc(100% - 3rem)">
   <div *ngIf="is_mobile_mode && sidebar_enabled" class="z-10 bg-white fixed top-12 left-0 w-72 bottom-0"></div>
   <div class="container mx-auto p-4 z-0" [class.pointer-events-none]="is_mobile_mode && sidebar_enabled">
      <router-outlet></router-outlet>
   </div>
   <div *ngIf="is_mobile_mode && sidebar_enabled" (click)="sidebar_enabled = false"
      class="bg-black opacity-20 absolute top-0 left-0 right-0 h-full"></div>
</div>
<!-- Mobile Top Bar -->
<div *ngIf="is_mobile_mode"
   class="{{ sidebar_enabled? 'fixed' : 'absolute' }} right-0 top-0 h-12 shadow-md z-20 flex flex-row py-2 left-0 px-4 bg-white">
   <button (click)="sidebar_enabled = !sidebar_enabled" class="shadow rounded bg-white hover:bg-gray-100 px-2">
      <fa-icon icon="bars"></fa-icon>
   </button>
</div>