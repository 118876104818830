import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { LotView, PointOfSalesView, UserView } from 'src/app/models/models';
import { State } from 'src/app/state';
import { error_toast } from 'src/app/utils/toast.util';
import { date_to_short_string, distinct, export_text_file, is_today, today, zero_pad } from 'src/app/utils/utils';

@Component({
  selector: 'app-lot-history',
  templateUrl: './lot-history.component.html',
  styleUrls: []
})
export class LotHistoryComponent {
  working = {
    loading: true,
    exporting: null
  };
  get busy(): boolean {
    return Object.values(this.working).some(_ => _);
  }

  get show_pos(): boolean { return State.user_is_manager; }
  get operation_name(): string {
    return State.user_is_manager? State.user.organization.description : State.user.point_of_sales.name;
  }


  date_end: Date | string =  date_to_short_string(today());
  date_start: Date | string = date_to_short_string(today());

  selected_point_of_sales_id: number = <any>"";
  selected_cashier_id: string = "";

  get points_of_sales(): PointOfSalesView[] {
    return distinct(this.lots.map(l => l.point_of_sales), p => p.id);
  }

  get cashiers(): UserView[] {
    return distinct(this.lots.map(l => l.user), p => p.id);
  }

  lots: LotView[];
  get filtered_lots(): LotView[] {
    const start_date = new Date(this.date_start);
    const end_date = new Date(this.date_end);
    end_date.setDate(end_date.getDate() + 1);

    let lots = this.lots?.filter(l => {
      const date_opened = new Date(l.date_opened);
      return date_opened >= start_date && date_opened <= end_date;
    }) ?? [];
    if (this.selected_point_of_sales_id)
      lots = lots.filter(l => l.point_of_sales.id == this.selected_point_of_sales_id);
    if (this.selected_cashier_id)
      lots = lots.filter(l => l.user.id == this.selected_cashier_id);
    return lots;
  }


  get mobile(): boolean { return State.mobile; }

  constructor(private api: ApiService, private router: Router) {
   this.searchLost();
  }


  searchLost(){
    this.api.get_operation_lots(this.date_start, this.date_end, response => {
      this.working.loading = false;
      if (response.succeeded) {
        this.lots = response.data.sort();

      } else error_toast(response.error.message);
    });
  }

  go(lot: LotView) { this.router.navigate(['supervisor', 'lots', lot.id]); }

  is_today(date: Date|string) { return is_today(date); }

  go_to_map(lot: { id: number }) {
    this.router.navigate(['supervisor', 'geo', 'lot', lot.id]);
  }

  export(lot: LotView) {
    this.working.exporting = lot;
    this.api.get_operation_lot(lot.id, response => {
      this.working.exporting = null;
      if (response.succeeded) {
        const lot = response.data;
        const headers = [
          'ID',
          'Facturador',
          'Método de pago',
          'Modalidad',
          'Referencia cliente',
          'Nombre cliente',
          'Monto',
          'Fecha y hora'
        ];
        const transactions = (
          lot.cash_transactions                 .map(t => ({...t, method: 'EFECTIVO'}))
          .concat(lot.cheque_transactions       .map(t => ({...t, method: 'CHEQUE'})))
          .concat(lot.card_transactions         .map(t => ({...t, method: 'TARJETA DE CREDITO/DEBITO'})))
          .concat(lot.bank_transfer_transactions.map(t => ({...t, method: 'TRANSFERENCIA'})))
          .concat(lot.bonoluz_transactions      .map(t => ({...t, method: 'BONOLUZ'})))
          .concat(lot.nullified_transactions    .map(t => ({...t, method: 'ANULACION'})))
        );
        export_text_file(
          `${formatDate(lot.date_opened, 'yyyy-MM-dd', 'es-DO')} - ${lot.point_of_sales.name} - lote ${lot.id}.csv`,
          [headers.map(h => `"${h}"`).join(',')]
          .concat(transactions.map(t => [
            t.id,
            t.invoicer.description,
            t.method,
            t.prepaid? 'Prepago' : 'Pospago',
            "'" + t.client_reference,
            t.client_name,
            t.amount,
            formatDate(t.date, "yyyy-MM-dd'T'HH:mm:ss", 'es-DO')
          ].map(_ => `"${_}"`).join(',')))
          .join('\n')
        );
      } else error_toast(response.error.message);
    });
  }
}
