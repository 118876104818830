import { environment } from 'src/environments/environment'
import { InvoicerQueriableView, Item, MultiPayQueriableView, PaymentPayload, UserView } from './models/models'
import { is_nothing, since } from './utils/utils'

export class State {
	private static _user: UserView
	static get user(): UserView {
		if (is_nothing(State._user)) State._user = JSON.parse(localStorage.getItem('active_user'))
		return State._user
	}
	static set user(value: UserView) {
		localStorage.setItem('active_user', JSON.stringify(value))
		State._user = value
	}

	static get user_is_cashier() {
		return State.user.roles.some((r) => [1, 5, 6].includes(r.id))
	}
	static get user_is_supervisor() {
		return State.user.roles.some((r) => r.id === 2)
	}
	static get user_is_manager() {
		return State.user.roles.some((r) => r.id === 3)
	}
	static get user_is_admin() {
		return State.user.roles.some((r) => r.id === 4)
	}
	static get user_is_big_clients() {
		return State.user.roles.some((r) => r.id === 5)
	}
	static get user_is_cashier_no_void() {
		return State.user.roles.some((r) => r.id === 6)
	}
	static get user_is_cashier_mobile() {
		return State.user.roles.some((r) => r.id === 7)
	}

	private static _auth_token: string
	static get auth_token(): string {
		if (is_nothing(State._auth_token)) State._auth_token = localStorage.getItem('auth_token')
		return State._auth_token
	}
	static set auth_token(token: string) {
		localStorage.setItem('auth_token', token)
		State._auth_token = token
	}

	private static timestamp_last_connection_lost: number = 0
	static connection_lost() {
		environment.error('[!] connection lost momentarily')
		State.timestamp_last_connection_lost = Date.now()
	}
	static bypass_connection_loss_timeout() {
		State.timestamp_last_connection_lost = 0
	}

	static get on_cordova(): boolean {
		return (window as any).cordova !== undefined
	}
	static get online() {
		return (
			since(this.timestamp_last_connection_lost, { seconds: 15 }) &&
			(State.on_cordova || window.navigator.onLine)
		)
	}

	static get mobile() {
		return State.on_cordova || window.innerWidth <= 768
	}

	static active_invoicer: InvoicerQueriableView
	static active_multipay: MultiPayQueriableView
	static preloaded_query
	static payment_package: PaymentPayload[]

	private static _payment_methods: { invoicer_id: number; payment_methods: Item[] }[]
	private static get payment_methods(): { invoicer_id: number; payment_methods: Item[] }[] {
		if (is_nothing(State._payment_methods))
			State._payment_methods = JSON.parse(localStorage.getItem('payment_methods')) ?? []
		return State._payment_methods
	}
	private static set payment_methods(value: { invoicer_id: number; payment_methods: Item[] }[]) {
		localStorage.setItem('payment_methods', JSON.stringify(value))
		State._payment_methods = value
	}

	static get_payment_methods(invoicer_id: number): Item[] {
		return State.payment_methods?.find((p) => p.invoicer_id === invoicer_id)?.payment_methods
	}
	static cache_payment_methods(invoicer_id: number, payment_methods: Item[]) {
		let ex_methods = State.payment_methods?.find((p) => p.invoicer_id === invoicer_id)
		if (ex_methods) ex_methods.payment_methods = payment_methods
		else State.payment_methods.push({ invoicer_id, payment_methods })
		State.payment_methods = State.payment_methods
	}

	private static _banks: Item[]
	static get banks(): Item[] {
		if (is_nothing(State._banks)) State._banks = JSON.parse(localStorage.getItem('banks'))
		return State._banks
	}
	static set banks(value: Item[]) {
		localStorage.setItem('banks', JSON.stringify(value))
		State._banks = value
	}

	private static _invoicers: InvoicerQueriableView[]
	static get invoicers(): InvoicerQueriableView[] {
		if (is_nothing(State._invoicers)) State._invoicers = JSON.parse(localStorage.getItem('invoicers'))
		return State._invoicers
	}
	static set invoicers(value: InvoicerQueriableView[]) {
		localStorage.setItem('invoicers', JSON.stringify(value))
		State._invoicers = value
	}

	private static _print_type: number
	static get print_type(): number {
		if (is_nothing(State._print_type)) State._print_type = JSON.parse(localStorage.getItem('print_type'))
		return State._print_type
	}
	static set print_type(value: number) {
		localStorage.setItem('print_type', JSON.stringify(value))
		State._print_type = value
	}

	static geolocation(callback: (coords: { latitude: number; longitude: number }) => void) {
		let max_time = 10_000
		const timestamp = Date.now()
		const options: PositionOptions = { enableHighAccuracy: true, maximumAge: 0, timeout: max_time }
		const handle_error = (error: GeolocationPositionError) => {
			environment.error(
				'geo location error:',
				error.code === error.PERMISSION_DENIED
					? 'PERMISSION DENIED'
					: error.code === error.POSITION_UNAVAILABLE
					? 'PERMISSION UNAVAILABLE'
					: 'TIMEOUT'
			)
			callback?.(null)
		}
		const report_geolocation = (data: GeolocationPosition) => {
			environment.debug('geo location report:', data.coords)
			if (data.coords.accuracy < 50)
				callback?.({ latitude: data.coords.latitude, longitude: data.coords.longitude })
			else {
				const elapsed = Date.now() - timestamp
				if (elapsed < max_time) {
					options.timeout = max_time - elapsed
					options.maximumAge++
					window.navigator.geolocation.getCurrentPosition(report_geolocation, handle_error, options)
				} else {
					environment.error('geo location error:', 'ACCURACY_TOO_lOW')
					callback?.(null)
				}
			}
		}
		window.navigator.geolocation.getCurrentPosition(report_geolocation, handle_error, options)
	}

	static loaded_maps_api: boolean = false

	static clear() {
		State.active_invoicer =
			State.active_multipay =
			State.preloaded_query =
			State.payment_package =
			State.payment_methods =
			State.invoicers =
			State.print_type =
			State.auth_token =
			State.user =
				null
	}
	static clear_payments() {
		State.active_invoicer = State.active_multipay = State.preloaded_query = State.payment_package = null
	}
}
