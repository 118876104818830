<h1>Pago de Facturas</h1>
<br>
<h2 *ngIf="available_invoicers?.length">Seleccione el servicio a pagar</h2>
<working *ngIf="!available_invoicers?.length" color="#3B82F6" [size]="30"></working>
<div class="mt-8 flex flex-wrap flex-col items-stretch md:flex-row md:justify-center">
   <ng-container *ngFor="let invoicer of invoicers">
      <div *ngIf="available(invoicer.name)" (click)="go_to(invoicer.name)" class="card payment-option hover:ring-4" [title]="invoicer.title">
         <img src="assets/logos/logo_{{ invoicer.name }}.png" class="h-20 mx-auto">
      </div>
   </ng-container>
</div>