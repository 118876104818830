<h1>{{ is_new? 'Nuevo usuario' : 'Usuario' }}</h1>
<h2 class="text-gray-500">{{ user?.name }}</h2>

<working *ngIf="!user" color="#3B82F6" [size]="30"></working>

<div *ngIf="user" class="card mt-8" [class.!bg-red-50]=!user.enabled>
   <div *ngIf="!user.enabled" class="font-bold text-red-600 text-lg border-b pb-2">
      <fa-icon icon="exclamation-circle"></fa-icon>
      Usuario Desactivado
   </div>
   <div class="md:flex flex-row items-stretch">
      <div *ngIf="!is_mobile || !select" class="flex-1 md:pr-2">
         <div class="flex flex-col md:flex-row md:items-baseline md:mt-3">
            <div class="w-44 font-semibold">Usuario</div>
            <input id="username" type="text" [(ngModel)]="user.username" [readonly]="!is_new" class="flex-1 py-1 rounded border-gray-300" [class.bg-gray-100]="!is_new" [class.bg-red-50]="fields.username.error">
         </div>
         <div class="field-error md:pl-44">{{ fields.username.error }}</div>
         <div class="flex flex-col md:flex-row md:items-baseline md:mt-3">
            <div class="w-44 font-semibold">Nombres</div>
            <input id="name" type="text" [(ngModel)]="user.name" class="flex-1 py-1 rounded border-gray-300" [class.bg-red-50]="fields.name.error">
         </div>
         <div class="field-error md:pl-44">{{ fields.name.error }}</div>
         <div class="flex flex-col md:flex-row md:items-baseline md:mt-3">
            <div class="w-44 font-semibold">Correo Electrónico</div>
            <input id="email" type="email" [(ngModel)]="user.email" class="flex-1 py-1 rounded border-gray-300" [class.bg-red-50]="fields.email.error">
         </div>
         <div class="field-error md:pl-44">{{ fields.email.error }}</div>
         <div class="flex flex-col md:flex-row md:items-baseline md:mt-3">
            <div class="w-44 font-semibold">Telefono Movil</div>
            <input id="mobile_phone" type="text" [(ngModel)]="user.mobile_phone" class="flex-1 py-1 rounded border-gray-300" [class.bg-red-50]="fields.mobile_phone.error">
         </div>
         <div class="field-error md:pl-44">{{ fields.mobile_phone.error }}</div>
         <div class="flex flex-col md:flex-row md:items-center md:mt-3">
            <div class="w-44 font-semibold">Unidad Organizacional</div>
            <div class="flex flex-row flex-1">
               <div *ngIf="user.organization" (click)="user.organization = null"
                  class="rounded py-1 px-2 cursor-pointer border-gray-300 border text-gray-500 hover:bg-gray-50 active:bg-gray-100">
                  <fa-icon icon="times"></fa-icon>
               </div>
               <div (click)="select = 'organization'" class="flex-1 bg-gray-50 border border-gray-300 rounded py-1 px-2 cursor-pointer">
                  <fa-icon *ngIf="user.organization" icon="sitemap" class="text-gray-500"></fa-icon>
                  {{ org_name(user.organization) }}
                  <fa-icon icon="chevron-right" class="float-right text-gray-500"></fa-icon>
               </div>
            </div>
         </div>
         <div class="flex flex-col md:flex-row md:items-center md:mt-3">
            <div class="w-44 font-semibold">Punto de Venta</div>
            <div class="flex flex-row flex-1">
               <div *ngIf="user.point_of_sales" (click)="user.point_of_sales = null"
                  class="border borde-gray-300 rounded py-1 px-2 cursor-pointer text-gray-500 hover:bg-gray-50 active:bg-gray-100">
                  <fa-icon icon="times"></fa-icon>
               </div>
               <div (click)="select = 'point_of_sales'" class="flex-1 bg-gray-50 border border-gray-300 rounded py-1 px-2 cursor-pointer">
                  <fa-icon *ngIf="user.point_of_sales" icon="store" class="text-gray-500"></fa-icon>
                  {{ user.point_of_sales?.name }}
                  <fa-icon icon="chevron-right" class="float-right text-gray-500"></fa-icon>
               </div>
            </div>
         </div>
         <div class="mt-8">
            <h3 class="border-b">Roles Asignados</h3>
            <div class="table">
               <div *ngFor="let role of roles"class="table-row">
                  <div class="table-cell cursor-pointer" (click)="selectable(role).selected = !selectable(role).selected">
                     <div class="pt-[5px]">{{ role.description }}</div>
                  </div>
                  <div class="table-cell text-2xl pl-4" style="vertical-align: middle;">
                     <input [(ngModel)]="selectable(role).selected" type="checkbox">
                  </div>
               </div>
            </div>
         </div>
         <div class="my-4 pt-4 border-t flex flex-row">
            <button *ngIf="!is_new" (click)="reset_password()" [disabled]="working" class="btn btn-yellow mr-3">
               <fa-icon *ngIf="working !== 'resetting'" icon="undo-alt"></fa-icon>
               {{ working === 'resetting'? 'Reseteando contraseña...' : 'Resetear contraseña' }}
               <working *ngIf="working === 'resetting'" color="black"></working>
            </button>
            <button *ngIf="!is_new && user.enabled" (click)="remove_user()" [disabled]=working class="btn btn-red">
               <fa-icon icon="trash-alt"></fa-icon>
               {{ working === 'removing'? 'Eliminando usuario...' : 'Eliminar/Desactivar usuario' }}
               <working *ngIf="working === 'removing'" color="black"></working>
            </button>
         </div>
      </div>
      <div class="flex-1 md:border-l md:pl-2 flex flex-col">
         <div *ngIf="select === 'organization'" class="flex-1 flex flex-col">
            <div class="flex flex-row items-baseline">
               <fa-icon (click)="select = null" icon="times" class="cursor-pointer text-gray-500 px-2 rounded hover:bg-gray-100 active:bg-gray-200"></fa-icon>
               <div class="flex-1 text-xl text-center font-semibold">
                  Seleccionar Unidad Organizacional <fa-icon icon="sitemap"></fa-icon>
               </div>
            </div>
            <div class="flex-1 border rounded-md my-2 overflow-y-auto space-y-3 p-3 max-h-[410px]">
               <div *ngFor="let organization of organizations" (click)="user.organization = organization" class="rounded-md border shadow p-2 cursor-pointer hover:bg-gray-100 hover:shadow-md" [class.bg-gray-100]="user.organization?.id === organization.id">
                  <fa-icon *ngIf="user.organization?.id !== organization.id" class="text-lg text-gray-600" [icon]="['far', 'circle']"></fa-icon>
                  <fa-icon *ngIf="user.organization?.id === organization.id" class="text-lg text-gray-600" [icon]="['far', 'dot-circle']"></fa-icon>
                  {{ organization.name }}
               </div>
            </div>
         </div>
         <div *ngIf="select === 'point_of_sales'" class="flex-1 flex flex-col">
            <div class="flex flex-row items-baseline">
               <fa-icon (click)="select = null" icon="times" class="cursor-pointer text-gray-500 px-2 rounded hover:bg-gray-100 active:bg-gray-200"></fa-icon>
               <div class="flex-1 text-xl text-center font-semibold">
                  Seleccionar Punto de Venta <fa-icon icon="store"></fa-icon>
               </div>
            </div>
            <div class="flex-1 border rounded-md my-2 overflow-y-auto space-y-3 p-3 max-h-[410px]">
               <div *ngFor="let point_of_sales of (user.organization? user.organization.points_of_sales : points_of_sales)" (click)="user.point_of_sales = point_of_sales" class="rounded-md border shadow p-2 cursor-pointer hover:bg-gray-100 hover:shadow-md" [class.bg-gray-100]="user.point_of_sales?.id === point_of_sales.id">
                  <fa-icon *ngIf="user.point_of_sales?.id !== point_of_sales.id" class="text-lg text-gray-600" [icon]="['far', 'circle']"></fa-icon>
                  <fa-icon *ngIf="user.point_of_sales?.id === point_of_sales.id" class="text-lg text-gray-600" [icon]="['far', 'dot-circle']"></fa-icon>
                  {{ point_of_sales.name }}
               </div>
            </div>
         </div>
      </div>
   </div>
   <div *ngIf="new_password" class="my-4 border-t border-b p-4 rounded-lg bg-red-50 border-red-100 text-lg font-semibold">
      Nueva Contraseña del Usuario: {{ new_password }}
   </div>
   <div class="flex flex-row w-full border-t pt-4">
      <button (click)="back()" [disabled]="working" class="btn mr-1">
         <fa-icon icon="arrow-left"></fa-icon>
         Volver
      </button>
      <button (click)="save()" [disabled]="working" class="btn btn-blue ml-1">
         <fa-icon icon="save"></fa-icon>
         {{ working === 'saving'? 'Guardando...' : 'Guardar' }}
         <working *ngIf="working === 'saving'"></working>
      </button>
      <div *ngIf="was_new" class="flex flex-row-reverse" style="flex-grow: 1">
         <button (click)="reset()" class="btn btn-green" [disabled]="is_new">
            <fa-icon icon="user-plus"></fa-icon>
            Nuevo usuario
         </button>
      </div>
   </div>
</div>