<h1>Bolsas de Contratos</h1>

<working *ngIf="working.loading" color="black" [size]="80"></working>

<div *ngIf="!working.loading" class="card mt-6 space-y-4">
   <div *ngIf="user_bundles?.length">
      <h2>Cajero</h2>
      <div *ngFor="let bundle of user_bundles" class="mt-4 border rounded-md p-2 border-gray-300">
         <div class="flex flex-row">
            <h3 class="flex-1 flex flex-row items-center space-x-4">
               <span>{{ bundle.description }}</span>
               <img class="h-5" src="/assets/logos/logo_{{ bundle.invoicer.description | lowercase }}.png" [title]="bundle.invoicer.description" [alt]="bundle.invoicer.description">
            </h3>
            <div>Contratos: {{ bundle.contract_count }}</div>
         </div>
         <div  class="flex flex-row justify-between">
            <div *ngIf="!bundle.processed" class="flex flex-row items-end space-x-3">
               Consulta en progreso...
               <working color=blue></working>
               <progress [value]="bundle.queried_count" [max]="bundle.contract_count"></progress>
               
            </div>
            <div *ngIf="bundle.processed" class="flex flex-row space-x-8">
               <div>Balance: <span class="font-medium">{{ bundle.last_balance | currency:'RD$ ' }}</span></div>
               <div [title]="bundle.date_last_balance | date:'hh:mm aa, dd/MMM/yyyy':null:'es-DO'">Ultima actualización: {{ bundle.date_last_balance | deltadate }}</div>
               <div *ngIf="bundle.errors?.length" [title]="errors_description(bundle.errors)">
                  <fa-icon icon=times-circle class="text-red-600"></fa-icon>
                  Errores: {{ bundle.errors?.length }}
               </div>
            </div>
            <div class="flex flex-row space-x-2">
               <button title="Ver histórico" routerLink="/cashier/bundles/{{bundle.id}}/history" class="btn !py-px !px-2">
                  <fa-icon icon=history></fa-icon>
                  Historial
               </button>
               <button title="Pagar" routerLink="/cashier/bundles/{{bundle.id}}/pay" [disabled]="!bundle.processed || working.querying[bundle.id]|| working.removing" class="btn btn-blue !py-px !px-2">
                  <fa-icon icon=dollar-sign></fa-icon>
                  Pagar
               </button>
               <button title="Refrescar" (click)="query(bundle)" [disabled]="!bundle.processed || working.querying[bundle.id]|| working.removing" class="btn btn-blue !py-px !px-2">
                  <fa-icon *ngIf="!working.querying[bundle.id]" icon=sync></fa-icon>
                  <working *ngIf="working.querying[bundle.id]"></working>
               </button>
               <button title="Editar" (click)="edit(bundle)" [disabled]="working.removing" class="btn !py-px !px-2">
                  <fa-icon icon=edit></fa-icon>
               </button>
               <button title="Remover" (click)="remove(bundle)" [disabled]="working.removing" class="btn !py-px !px-2">
                  <fa-icon *ngIf="working.removing !== bundle.id" icon=trash-alt></fa-icon>
                  <working *ngIf="working.removing === bundle.id" color="black"></working>
               </button>
            </div>
         </div>
      </div>
   </div>
   <hr *ngIf="user_bundles?.length && extra_bundles?.length">
   <div *ngIf="extra_bundles?.length">
      <h2>Punto de Ventas</h2>
      <div *ngFor="let bundle of extra_bundles" class="mt-4 border rounded-md p-2 border-gray-300">
         <div class="flex flex-row">
            <h3 class="flex-1 flex flex-row items-center space-x-4">
               <span>{{ bundle.description }}</span>
               <img class="h-5" src="/assets/logos/logo_{{ bundle.invoicer.description | lowercase }}.png" [title]="bundle.invoicer.description" [alt]="bundle.invoicer.description">
            </h3>
            <div>Contratos: {{ bundle.contract_count }}</div>
         </div>
         <div class="flex flex-row items-end space-x-3">
            <div *ngIf="!bundle.processed" class="flex flex-row items-end space-x-3">
               Consulta en progreso...
               <working color=blue></working>
               <progress [value]="bundle.queried_count" [max]="bundle.contract_count"></progress>
            </div>
            <div *ngIf="bundle.processed" class="flex flex-row space-x-4">
               <div>Balance: <span class="font-medium">{{ bundle.last_balance | currency:'RD$ ' }}</span></div>
               <div [title]="bundle.date_last_balance | date:'hh:mm aa, dd/MMM/yyyy':null:'es-DO'">Ultima actualización: {{ bundle.date_last_balance | deltadate }}</div>
               <div *ngIf="bundle.errors?.length" [title]="errors_description(bundle.errors)">
                  <fa-icon icon=times-circle class="text-red-600"></fa-icon>
                  Errores: {{ bundle.errors?.length }}
               </div>
            </div>
            <button title="Ver histórico" routerLink="/cashier/bundles/{{bundle.id}}/history" class="btn btn-blue !py-px !px-2">
               <fa-icon icon=history></fa-icon>
               Histórico
            </button>
            <button title="Pagar" routerLink="/cashier/bundles/{{bundle.id}}/pay" [disabled]="!bundle.processed" class="btn btn-blue !py-px !px-2">
               <fa-icon icon=dollar-sign></fa-icon>
               Pagar
            </button>
         </div>
      </div>
   </div>

   <div class="flex flex-row justify-end">
      <button routerLink="new" class="btn btn-blue">
         <fa-icon icon=plus-circle></fa-icon>
         Crear bolsa de contratos
      </button>
   </div>
</div>