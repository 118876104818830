export default class PrintService {
	static get printing() {
		return !!this.data || !!this.type
	}
	static type?: string
	static data?: any

	static print(type: string, data: any): Promise<void> {
		this.type = type
		this.data = data
		return new Promise((resolve) => {
			setTimeout(() => {
				;(window as any).cordova?.plugins?.printer?.print?.()
				window.print()
				this.type = this.data = undefined
				resolve()
			}, 250)
		})
	}

	static done?: () => void
	static render(type: string, data: any): Promise<void> {
		this.type = type
		this.data = data
		return new Promise(
			(resolve) =>
				(this.done = () => {
					resolve()
					this.type = this.data = this.done = undefined
				})
		)
	}
}
