import { State } from 'src/app/state';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { is_nothing } from 'src/app/utils/utils';
import { CAASDClientView } from 'src/app/models/invoicer_query.models';
import { invoicer_queriable_to_item } from '../payments.component';
import { error_toast } from 'src/app/utils/toast.util';

@Component({
  selector: 'app-caasd',
  templateUrl: './caasd.component.html',
  styleUrls: []
})
export class CaasdComponent {
  working = false;
  id: string;

  result: CAASDClientView;
  amount_to_pay: number;

  constructor(private api: ApiService, private router: Router) {
    if (is_nothing(State.active_invoicer))
      router.navigate(['cashier', 'pay']);
  }

  query() {
    this.working = true;
    this.api.query_caasd(this.id, response => {
      this.working = false;
      if (response.succeeded) {
        this.result = response.data;
        this.amount_to_pay = response.data.debt;
      } else error_toast(response.error.message);
    });
    
  }

  pay() {
    State.payment_package = [{
      document: this.id,
      name: this.result.name,
      invoicer: invoicer_queriable_to_item(State.active_invoicer),
      type: 1,
      payloads: [{
        concept: null, invoice_id: null,
        amount: this.amount_to_pay
      }]
    }]
    this.router.navigate(['cashier', 'apply-payment']);
  }
}
