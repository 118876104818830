<h1>Cierre de Lote</h1>
<h2 class="text-gray-500">{{ point_of_sales }}</h2>

<div id="printing-frames" class="invisible fixed top-0 insex-x-0 z-[-10]"></div>

<div *ngIf="lot" class="card mt-8">
   <div class="flex flex-col my-3 md:flex-row md:my-1 md:items-baseline">
      <div class="w-52 md:font-semibold">ID del lote:</div>
      <div class="font-semibold md:font-normal">{{ lot.id }}</div>
   </div>
   <div class="flex flex-col my-3 md:flex-row md:my-1 md:items-baseline">
      <div class="w-52 md:font-semibold">Fecha de inicio del lote:</div>
      <div class="font-semibold md:font-normal">{{ lot.date_opened | date:'MMMM dd, yyyy - hh:mm aa':undefined:'es-DO' }}</div>
   </div>
   <div class="flex flex-col my-3 md:flex-row md:my-1 md:items-baseline">
      <div class="w-52 md:font-semibold">Número de transacciones:</div>
      <div class="font-semibold md:font-normal">{{ lot.completed_transaction_count }}<br>
         <span *ngIf="lot.completed_transaction_count != lot.transaction_count" class="text-gray-500">({{ lot.transaction_count - lot.completed_transaction_count }} no completadas)</span></div>
   </div>
   <div class="flex flex-col my-3 md:flex-row md:my-1 md:items-baseline">
      <div class="w-52 md:font-semibold">Total cobrado:</div>
      <div class="font-semibold md:font-normal">{{ lot.total_charged | currency:'RD$ ' }}</div>
   </div>
   <div *ngIf="pending_null_request_count" class="mt-2 text-yellow-700 border-red-400 border rounded-lg px-2 py-1 w-full md:max-w-max bg-yellow-50">
      <fa-icon icon="exclamation-triangle"></fa-icon>
      Actualmente tienes <span class="font-bold underline">{{ pending_null_request_count }}</span> solicitudes de anulacion pendientes.<br class="md:hidden"/>
      Si cierras el lote ahora, esas transacciones <span class="font-semibold">NO</span> seran anuladas.
   </div>
   <div *ngIf="has_queued_payments" class="mt-2 text-red-700 border-red-400 border rounded-lg px-2 py-1 w-full md:max-w-max bg-red-50">
      <fa-icon icon="times-circle"></fa-icon>
      <span class="mr-4">Tienes pagos fuera de linea pendientes de aplicar.</span>
      <br class="md:hidden">
      <button (click)="go_to_queued_payments()" class="btn w-full !py-[2px] md:w-max">
         Ver
         <fa-icon icon="file-alt"></fa-icon>
      </button>
   </div>
   <button *ngIf="!has_queued_payments && !closed" (click)="close()" [disabled]="working" class="btn btn-yellow mt-4 w-full md:w-max">
      <fa-icon [icon]="['far', 'calendar-check']"></fa-icon>
      Cerrar lote 
      <working *ngIf="working" color="#6B7280"></working>
   </button>
   <button *ngIf="closed" (click)="print()" [disabled]="working" class="btn btn-blue mt-4 w-full md:w-max">
      <fa-icon *ngIf="!working" icon="print"></fa-icon>
      <working *ngIf="working"></working>
      {{ !working? 'Imprimir reporte de cierre' : 'Imprimiendo reporte...' }}
   </button>
</div>
<div *ngIf="!lot" class="rounded-lg w-full py-6 px-8 shadow-md bg-blue-200 mt-8 text-lg">
   <fa-icon icon="info-circle"></fa-icon>
   <working *ngIf="working" color="black"></working>
   <span *ngIf="!working" class="font-semibold"> No tienes lotes activos en este momento</span>
</div>