<h1>Configuración de MultiPagos</h1>

<working *ngIf="!multipays" color="#3B82F6" [size]="30"></working>

<div *ngIf="multipays && !invoicer_ids" class="card mt-8">
   <div *ngFor="let multipay of multipays" class="rounded-md border flex flex-row items-baseline p-4">
      <div class="font-bold px-2">{{ multipay.id }}</div>
      <div class="flex flex-row flex-wrap px-2 md:px-8" style="flex-grow: 1;">
         <div *ngFor="let invoicer of multipay.invoicers" class="ml-2">
            <fa-icon *ngIf="multipay.invoicers.indexOf(invoicer)" icon="plus" class="mr-2 md:mr-4"></fa-icon>
            <img src="/assets/logos/logo_{{ invoicer.description | lowercase }}.png" class="h-6 inline-block" [class.md:mb-2]="invoicer.id < 4">
         </div>
      </div>
      <button (click)="edit(multipay)" class="btn mx-2">
         <fa-icon icon="store"></fa-icon>
         Puntos de Venta
      </button>
   </div>
   <div class="mt-4 pt-4 border-t">
      <button (click)="new()" [disabled]="working" class="btn btn-blue">
         <fa-icon icon="plus-circle"></fa-icon>
         Crear nuevo MultiPago
      </button>
   </div>
</div>

<div *ngIf="invoicer_ids" class="card mt-8">
   <div class="table">
      <div *ngFor="let invoicer of invoicers" class="table-row" [title]="invoicer.description">
         <div class="table-cell" style="vertical-align: middle;">
            <input (click)="toggle(invoicer)" [checked]="includes(invoicer)" type="checkbox">
         </div>
         <div class="table-cell px-4 py-2">
            <img (click)="toggle(invoicer)" src="/assets/logos/logo_{{ invoicer.description | lowercase }}.png" [alt]="invoicer.description" class="cursor-pointer h-5">
         </div>
      </div>
   </div>
   <button (click)="create()" [disabled]="working || invoicer_ids.length < 2" class="btn btn-blue mt-4 w-full md:w-max">
      <fa-icon icon="save"></fa-icon>
      {{ (!working? 'Crear' : 'Creando...') }}
      <working *ngIf="working"></working>
   </button>
</div>