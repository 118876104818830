import { environment } from 'src/environments/environment'
import { App } from './app.component'
import { ContributorView, InvoiceView, QuotaContractView } from './models/invoicer_query.models'
import { State } from './state'
import { error_toast, success_toast } from './utils/toast.util'
import { is_nothing, sum } from './utils/utils'
import { PaymentRequest } from './models/models'

export class QueuedPayment {
	key: number
	date: Date
	payment: PaymentRequest
}

export class CachedMercelantContract {
	date: Date
	contract_number: string
	address: string
	contract: QuotaContractView
	debt_amount: number
}

export class CachedContributorView {
	date: Date
	document: string
	name: string
	address: string
	total_debt: number
	phone: string

	pending_invoices: InvoiceView[]
}

export class OfflineDatabase {
	private static _has_queued_payments: boolean
	static get has_queued_payments(): boolean {
		if (is_nothing(OfflineDatabase._has_queued_payments)) OfflineDatabase.get_queued_payment_count(null)
		return OfflineDatabase._has_queued_payments
	}

	private static open_or_create_db(callback: (db: IDBDatabase) => void) {
		const db_request = window.indexedDB.open('VirtualPoS Offline', 1)
		db_request.onupgradeneeded = (event) => {
			let db: IDBDatabase = (<any>event.target).result

			let queued_payments = db.createObjectStore('queued_payments', { autoIncrement: true })
			queued_payments.createIndex('date', 'date', { unique: false })

			db.createObjectStore('puertadivina_contracts', { keyPath: 'contract_number' })
			db.createObjectStore('jorem_contracts', { keyPath: 'contract_number' })
			db.createObjectStore('edenorte_contracts', { keyPath: 'document' })
			db.createObjectStore('asdn_contracts', { keyPath: 'document' })
		}
		db_request.onsuccess = (event) => {
			callback?.((<any>event.target).result)
		}
		db_request.onerror = (event) => {
			callback?.(null)
		}
	}

	private static _db: IDBDatabase
	private static db(callback: (response: IDBDatabase) => void) {
		if (is_nothing(OfflineDatabase._db)) {
			OfflineDatabase.open_or_create_db((db) => {
				OfflineDatabase._db = db
				callback?.(db)
			})
		} else {
			callback?.(OfflineDatabase._db)
		}
	}

	// ===============
	// Queued Payments
	// ===============
	static add_queued_payment(
		payment: PaymentRequest,
		callback: (success: boolean, queued_payment: QueuedPayment) => void = null
	) {
		OfflineDatabase.db((db) => {
			if (db) {
				const queued_payment: QueuedPayment = <any>{ date: new Date(Date.now()), payment }
				const queued_payments = db
					.transaction(['queued_payments'], 'readwrite')
					.objectStore('queued_payments')
				const add_request = queued_payments.add(queued_payment)
				add_request.onsuccess = (_) => {
					OfflineDatabase._has_queued_payments = true
					queued_payment.key = (<any>_.target).result
					callback?.(true, queued_payment)
				}
				add_request.onerror = (_) => callback?.(false, null)
			} else {
				callback?.(false, null)
			}
		})
	}

	static update_queued_payment(
		key: number,
		payment: PaymentRequest,
		callback: (success: boolean, queued_payment: QueuedPayment) => void = null
	) {
		OfflineDatabase.db((db) => {
			if (db) {
				const queued_payment: QueuedPayment = <any>{ date: new Date(Date.now()), payment }
				const queued_payments = db
					.transaction(['queued_payments'], 'readwrite')
					.objectStore('queued_payments')
				const update_request = queued_payments.put(queued_payment, key)
				update_request.onsuccess = (_) => {
					queued_payment.key = key
					callback?.(true, queued_payment)
				}
			} else {
				callback?.(false, null)
			}
		})
	}

	static get_queued_payments(callback: (response: QueuedPayment[]) => void) {
		OfflineDatabase.db((db) => {
			const queued_payments = db
				.transaction(['queued_payments'], 'readonly')
				.objectStore('queued_payments')
			// @Robustness(Gorky): Handle error
			const data: QueuedPayment[] = []
			const open_queued_payments_cursor = queued_payments.openCursor()
			open_queued_payments_cursor.onsuccess = (event) => {
				const cursor: IDBCursorWithValue = (<any>event.target).result
				if (cursor) {
					let queued_payment = cursor.value
					queued_payment.key = <number>cursor.key
					data.push(queued_payment)
					cursor.continue()
				} else {
					callback?.(data)
					open_queued_payments_cursor.onsuccess = null
				}
			}
		})
	}

	static get_queued_payment_count(callback: (response: number) => void) {
		OfflineDatabase.db((db) => {
			const queued_payments = db
				.transaction(['queued_payments'], 'readonly')
				.objectStore('queued_payments')
			// @Robustness(Gorky): Handle error
			queued_payments.count().onsuccess = (event) => {
				const data: number = (<any>event.target).result
				OfflineDatabase._has_queued_payments = data > 0

				if (OfflineDatabase.has_queued_payments && State.online) {
					apply_payments_when_online()
				}

				callback?.(data)
			}
		})
	}

	static delete_queued_payment(key: number, callback: () => void) {
		OfflineDatabase.db((db) => {
			const queued_payments = db
				.transaction(['queued_payments'], 'readwrite')
				.objectStore('queued_payments')
			// @Robustness(Gorky): Handle error
			queued_payments.delete(key).onsuccess = (_) => {
				callback?.()
				OfflineDatabase.get_queued_payment_count(null)
			}
		})
	}

	static drop(callback: (success: boolean) => void = null) {
		OfflineDatabase.db((db) => {
			const db_delete_request = window.indexedDB.deleteDatabase('VirtualPoS Offline')
			db_delete_request.onsuccess = (_) => callback?.(true)
			db_delete_request.onerror = (_) => callback?.(false)
		})
	}

	// =======================
	// Puerta Divina Contracts
	// =======================
	static get_puertadivina_contract(
		contract_number: string,
		callback: (data: CachedMercelantContract) => void
	) {
		OfflineDatabase.db((db) => {
			const puertadivina_contracts = db
				.transaction(['puertadivina_contracts'], 'readonly')
				.objectStore('puertadivina_contracts')
			const query = puertadivina_contracts.get(contract_number)
			query.onsuccess = (event) => {
				callback?.((<any>event.target).result)
			}
			query.onerror = (event) => {
				callback?.(null)
			}
		})
	}

	static get_puertadivina_contracts(callback: (data: CachedMercelantContract[]) => void) {
		OfflineDatabase.db((db) => {
			const puertadivina_contracts = db
				.transaction(['puertadivina_contracts'], 'readonly')
				.objectStore('puertadivina_contracts')
			puertadivina_contracts.getAll().onsuccess = (event) => {
				const data: CachedMercelantContract[] = (<any>event.target).result
				callback?.(data)
			}
		})
	}

	static add_or_update_puertadivina_contract(
		contract: CachedMercelantContract,
		callback: (success: boolean, cached_contract: CachedMercelantContract) => void = null
	) {
		OfflineDatabase.db((db) => {
			if (db) {
				const puertadivina_contracts = db
					.transaction(['puertadivina_contracts'], 'readwrite')
					.objectStore('puertadivina_contracts')
				const add_request = puertadivina_contracts.put(contract)
				add_request.onsuccess = (_) => callback?.(true, contract)
				add_request.onerror = (_) => callback?.(false, null)
			} else {
				callback?.(false, null)
			}
		})
	}

	static delete_puertadivina_contract(contract_number: string, callback: () => void = null) {
		OfflineDatabase.db((db) => {
			if (db) {
				const puertadicina_contracts = db
					.transaction(['puertadivina_contracts'], 'readwrite')
					.objectStore('puertadivina_contracts')
				puertadicina_contracts.delete(contract_number).onsuccess = (_) => callback?.()
			}
		})
	}

	// ===============
	// Jorem Contracts
	// ===============
	static get_jorem_contract(contract_number: string, callback: (data: CachedMercelantContract) => void) {
		OfflineDatabase.db((db) => {
			const jorem_contracts = db
				.transaction(['jorem_contracts'], 'readonly')
				.objectStore('jorem_contracts')
			const query = jorem_contracts.get(contract_number)
			query.onsuccess = (event) => {
				callback?.((<any>event.target).result)
			}
			query.onerror = (event) => {
				callback?.(null)
			}
		})
	}

	static get_jorem_contracts(callback: (data: CachedMercelantContract[]) => void) {
		OfflineDatabase.db((db) => {
			const jorem_contracts = db
				.transaction(['jorem_contracts'], 'readonly')
				.objectStore('jorem_contracts')
			jorem_contracts.getAll().onsuccess = (event) => {
				const data: CachedMercelantContract[] = (<any>event.target).result
				callback?.(data)
			}
		})
	}

	static add_or_update_jorem_contract(
		contract: CachedMercelantContract,
		callback: (success: boolean, cached_contract: CachedMercelantContract) => void = null
	) {
		OfflineDatabase.db((db) => {
			if (db) {
				const jorem_contracts = db
					.transaction(['jorem_contracts'], 'readwrite')
					.objectStore('jorem_contracts')
				const add_request = jorem_contracts.put(contract)
				add_request.onsuccess = (_) => callback?.(true, contract)
				add_request.onerror = (_) => callback?.(false, null)
			} else {
				callback?.(false, null)
			}
		})
	}

	static delete_jorem_contract(contract_number: string, callback: () => void = null) {
		OfflineDatabase.db((db) => {
			if (db) {
				const jorem_contracts = db
					.transaction(['jorem_contracts'], 'readwrite')
					.objectStore('jorem_contracts')
				jorem_contracts.delete(contract_number).onsuccess = (_) => callback?.()
			}
		})
	}

	// ====================
	//  Edenorte Contracts
	// ====================
	static get_edenorte_contracts(callback: (data: CachedContributorView[]) => void) {
		OfflineDatabase.db((db) => {
			const edenorte_contracts = db
				.transaction(['edenorte_contracts'], 'readonly')
				.objectStore('edenorte_contracts')
			edenorte_contracts.getAll().onsuccess = (event) => {
				const data: CachedContributorView[] = (<any>event.target).result
				callback?.(data)
			}
		})
	}
	static add_or_update_edenorte_contract(
		contract: CachedContributorView,
		callback: (success: boolean, cached_contract: CachedContributorView) => void = null
	) {
		OfflineDatabase.db((db) => {
			if (db) {
				const edenorte_contracts = db
					.transaction(['edenorte_contracts'], 'readwrite')
					.objectStore('edenorte_contracts')
				const add_request = edenorte_contracts.put(contract)
				add_request.onsuccess = (_) => callback?.(true, contract)
				add_request.onerror = (_) => callback?.(false, null)
			} else {
				callback?.(false, null)
			}
		})
	}
	static delete_edenorte_contract(contract_number: string, callback: () => void = null) {
		OfflineDatabase.db((db) => {
			if (db) {
				const edenorte_contracts = db
					.transaction(['edenorte_contracts'], 'readwrite')
					.objectStore('edenorte_contracts')
				edenorte_contracts.delete(contract_number).onsuccess = (_) => callback?.()
			}
		})
	}

	// ================
	//  ASDN Contracts
	// ================
	static get_asdn_contracts(callback: (data: CachedContributorView[]) => void) {
		OfflineDatabase.db((db) => {
			const asdn_contracts = db.transaction(['asdn_contracts'], 'readonly').objectStore('asdn_contracts')
			asdn_contracts.getAll().onsuccess = (event) => {
				const data: CachedContributorView[] = (<any>event.target).result.map((r) => {
					new Date(), r
				})
				callback?.(data)
			}
		})
	}
	static add_or_update_asdn_contract(
		contract: CachedContributorView,
		callback: (success: boolean, cached_contract: CachedContributorView) => void = null
	) {
		OfflineDatabase.db((db) => {
			if (db) {
				const asdn_contracts = db
					.transaction(['asdn_contracts'], 'readwrite')
					.objectStore('asdn_contracts')
				const add_request = asdn_contracts.put(contract)
				add_request.onsuccess = (_) => callback?.(true, contract)
				add_request.onerror = (_) => callback?.(false, null)
			} else {
				callback?.(false, null)
			}
		})
	}
	static delete_asdn_contract(contract_number: string, callback: () => void = null) {
		OfflineDatabase.db((db) => {
			if (db) {
				const asdn_contracts = db
					.transaction(['asdn_contracts'], 'readwrite')
					.objectStore('asdn_contracts')
				asdn_contracts.delete(contract_number).onsuccess = (_) => callback?.()
			}
		})
	}
}

let payments_in_process_keys: number[]
let offline_payment_processing_listener: (keys: number[]) => void
export function when_processing_payment(callback: (keys: number[]) => void) {
	offline_payment_processing_listener = callback
	if (payments_in_process_keys?.length) callback?.(payments_in_process_keys)
}
function emit_payments_in_process_keys(keys: number[]) {
	payments_in_process_keys = keys
	offline_payment_processing_listener?.(keys)
}

let applying_payments = false
function apply_payments_when_online() {
	if (!applying_payments) {
		applying_payments = true

		App.api((api) => {
			OfflineDatabase.get_queued_payments((data) => {
				const batch_size = 1 // @Note(Gorky): Innecesario; solo se puede aplicar un pago a la vez. ?
				let i = 0
				let batch = []

				let errors = []

				const apply_batch = () => {
					environment.debug(
						`[sync] applying batch of offline payments [${i}–${Math.min(i + batch_size, data.length)})`
					)
					const qp_batch = data.slice(i, i + batch_size)
					emit_payments_in_process_keys(qp_batch.map((qp) => qp.key))
					for (let item of qp_batch) {
						api.apply_payment(item.payment, (response) => {
							if (response.succeeded) {
								if (response.data.payments.every((p) => p.applied)) {
									OfflineDatabase.delete_queued_payment(item.key, () => {
										batch.push(item.key)
										check_and_handle_batch_done()
									})
								} else if (response.data.payments.some((p) => p.applied)) {
									const applied_invoice_ids = response.data.payments
										.filter((p) => p.applied)
										.map((p) => p.invoice_id)
									item.payment.invoices = item.payment.invoices.filter(
										(i) => !applied_invoice_ids.includes(i.invoice_id)
									)
									item.payment.total = sum(item.payment.invoices.map((i) => i.amount))
									OfflineDatabase.update_queued_payment(item.key, item.payment, (success, _) => {
										batch.push(item.key)
										check_and_handle_batch_done()
									})
								} else {
									batch.push(item.key)
									errors.push(item.key)
									check_and_handle_batch_done()
								}
							} else {
								// @Incomplete(Gorky) @Incomplete(Josias): Handle errors
								batch.push(item.key)
								errors.push(item.key)
								check_and_handle_batch_done()
							}
						})
					}
				}
				const check_and_handle_batch_done = () => {
					if (batch.length === Math.min(batch_size, data.length - i)) {
						environment.debug(
							`[sync] offline payments batch [${i}–${Math.min(i + batch_size, data.length)}) done`
						)
						i += batch_size
						if (i < data.length) {
							batch = []
							apply_batch()
						} else {
							applying_payments = false
							emit_payments_in_process_keys(null)
							if (data.length - errors.length)
								success_toast(`${data.length - errors.length} pago(s) offline aplicados.`)
							if (errors.length) error_toast(`Error al aplicar ${errors.length} pago(s) offline.`)
						}
					}
				}
				apply_batch()
			})
		})
	}
}

window.onoffline = (_) => {
	environment.debug('connection lost; ' + (State.online ? 'online' : 'offline'))
}
window.ononline = (_) => {
	environment.debug('connection regained; ' + (State.online ? 'online' : 'offline'))

	if (OfflineDatabase.has_queued_payments) apply_payments_when_online()
}
